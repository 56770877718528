import {database} from '..';
import {ACCOUNT_SCHEMA} from '../schema';

const account = database.collections.get(ACCOUNT_SCHEMA);

export const createAccount = async details => {
    return await database.write(
        async () =>
            await account.create(entry => {
                entry._raw.id = details.id;
                entry.name = details.name;
                entry.email = details.email;
                entry.phone_number = details.phone_number;
                entry.is_active = true;
            }),
    );
};

export const getAccountById = async id => await account.find(id);
