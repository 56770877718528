import {useThemeContext} from '../../../context';
import {Button} from '../../common';

const QtyIncreaseDecrase = ({handleToggle, toggle, handleUpdateItemQty, item, inputRef, handleChangeFocus, initialQuantity}) => {
    const {theme} = useThemeContext();
    const {quantity, id} = item;

    const onChange = event => {
        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            handleUpdateItemQty(item, value, id);
        }
    };
    return (
        <>
            {toggle ? (
                <div className="qtyIdealConatiner">
                    <Button
                        className="marBot0 "
                        type="iconButton"
                        icon_name="minusIcon"
                        disabled={quantity == 1}
                        icon_fill={theme.white}
                        icon_width={'18'}
                        icon_height={'18'}
                        icon_viewBox={'0 0 18 18'}
                        handleClick={() => handleUpdateItemQty(item, 'decrement', id)}
                    />
                    <input type="number" value={quantity} onChange={onChange} ref={inputRef} onKeyDown={handleChangeFocus} className="updateQtyinput marBot0" />

                    <Button
                        className="marBot0"
                        type="iconButton"
                        icon_name="add2Icon"
                        icon_fill={theme.white}
                        icon_width={'18'}
                        icon_height={'18'}
                        icon_viewBox={'0 0 18 18'}
                        handleClick={() => handleUpdateItemQty(item, 'increment')}
                        disabled={initialQuantity && quantity === initialQuantity}
                    />
                </div>
            ) : (
                <p
                    onClick={() => handleToggle(true)}
                    className="marBot0"
                    style={{
                        color: item.void ? theme.disableGray : theme.white,
                    }}>
                    {`x${quantity}`}
                </p>
            )}
        </>
    );
};

export default QtyIncreaseDecrase;
