import { Col, Container, Row } from "react-bootstrap";
import TableHeader from "../TableContainer/TableHeader";
import { useLanguageContext, useThemeContext } from "../../../context";
import { DEVICE_HEIGHT, DiscountCsvDatavalidate } from "../../../constants";
import CsvList from "./CsvList";
import { useEffect } from "react";

const DiscountCSVTable = ({ data, setErrors, errors }) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();

  let csvData = data?.formateRow;

  useEffect(() => {
    if (csvData.length) {
      handleError();
    }
  }, [csvData]);

  const handleError = () => {
    const formErrors = DiscountCsvDatavalidate(csvData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <div
        className="itemListContainer"
        fluid
        style={{ background: theme.blackBg, height: DEVICE_HEIGHT - 110 }}
      >
        <Container fluid>
          <Row>
            <Col>
              <TableHeader
                className={`fourColumn`}
                style={{ borderColor: theme.posRightBg }}
              >
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.discount_name}
                  </p>
                </div>
                <div className="BoxWidth justifyCenter">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.type}
                  </p>
                </div>
                <div className="BoxWidth justifyCenter">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.amount}
                  </p>
                </div>
                <div className="BoxWidth justifyCenter">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.maximum_discount_cap}
                  </p>
                </div>
              </TableHeader>

              <div
                className="tableListMainBox"
                style={{ height: DEVICE_HEIGHT - 220 }}
              >
                {csvData?.map((val, index) => (
                  <CsvList index={index} errors={errors} value={val} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DiscountCSVTable;
