import React from 'react';
import {firstLetterCaptilize} from '../../../../../constants';
import {useThemeContext} from '../../../../../context';
import {Button} from '../../../../common';

const PaymentMethod = ({payment_method, selected, handlePayment}) => {
    const {theme} = useThemeContext();
    return (
        <div className="paymentmethodBox width100">
            {payment_method?.map((item, index) => (
                <Button
                    key={index}
                    className="paymentmethodButton"
                    title={firstLetterCaptilize(item?.name || '')}
                    type="buttonTitleIcon"
                    backgroundColor={selected?.name === item.name.toLowerCase() ? theme.barclaysBlue : theme.seperatorTwo}
                    icon_name={item?.name === 'cash' ? 'cashIcon2' : item.name === 'card' ? 'cardIcon2' : item.name === 'credit' ? 'credit2Icon' : 'walletIcon2'}
                    handleClick={() => handlePayment(item)}
                    icon_fill={theme.white}
                    icon_width="24"
                    icon_height="24"
                    icon_viewBox="0 0 24 24"
                />
            ))}
        </div>
    );
};

export default PaymentMethod;
