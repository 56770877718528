import React from 'react';
import Icon from '../../../assets/icons';
import {formatDate, formatTime} from '../../../constants';

const OrderCompletionReceipt = React.forwardRef((props, ref) => {
    const totalHeight = `${15 * 30 + 150}px`;
    const {orderReceiptData} = props;
    const {order, orderLines, business, account, customerData, orderDiscount, givenPosDiscount, tax} = orderReceiptData || {};

    return (
        <div
            ref={ref}
            style={{
                padding: '20px 10px',
                background: '#ffff',
                height: 'min-content',
                fontSize: '12px',
            }}
            className="orderReceipt">
            <div className="textCenter">
                <Icon name={'oscarLogo'} fill={'black'} viewBox={'0 0 115 110'} width={'60'} height={'60'} />
            </div>
            <div className="width100">
                <p className="marTop20 textCenter">{business?.name}</p>
                <p className="marTop30 textCenter">{account?.phone_number}</p>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100 marTop20">
                <p>Date: {formatDate(order?.completed_at)}</p>
                <p>Order# {order?.number}</p>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100">
                <p>Time: {formatTime(order?.completed_at)}</p>
                <p>Cashier: Administrator</p>
            </div>

            {customerData && (
                <div className="width100 marTop20 textCenter">
                    <p>Customer Name: {customerData?.name}</p>
                </div>
            )}

            {customerData && (
                <div className="width100 textCenter">
                    <p>Customer Phone#: {customerData?.phone_number}</p>
                </div>
            )}

            <div className="width100 textCenter">
                <p>Table: Table 10</p>
            </div>

            <div className="width100 marTop20 marBot10 flex">
                <p className="width10 fontWeight700">Qty</p>
                <p className="width70 fontWeight700">Items</p>
                <p className="width20 fontWeight700 textRight">Total</p>
            </div>

            <div className="width100" style={{borderBottom: '1px dashed #000'}} />

            <div style={{padding: '20px 0px'}}>
                {orderLines?.map((order, ind) => {
                    return (
                        <div className={`marTop5 width100 flex`}>
                            <p className="width10">{order?.quantity}</p>
                            <p className="width70">{order?.name}</p>
                            <p className="width20 textRight">{order?.selling_price}</p>
                        </div>
                    );
                })}
            </div>

            <div style={{width: '100%', borderBottom: '1px dashed #000'}} />

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 0px',
                }}>
                <p>TOTAL</p>
                <p>{order?.total?.toFixed(2)}</p>
            </div>
            <div style={{width: '100%', borderBottom: '1px dashed #000'}} />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 0px 0px 0px',
                }}>
                <p>{order?.payment_method?.toUpperCase(2)} (PKR)</p>
                <p>{order?.received_amount?.toFixed(2)}</p>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 0px 10px 0px',
                }}>
                <p>CHANGE</p>
                <p>{order?.change.toFixed(2)}</p>
            </div>
            <div style={{width: '100%', borderBottom: '1px dashed #000'}} />

            {orderDiscount ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    className="width100 marTop20">
                    <p>Order Discount Earned</p>
                    <p>
                        {orderDiscount.type != 'percentage' ? 'Rs.' : null}
                        {Number(orderDiscount.value).toFixed(2)}
                    </p>
                </div>
            ) : null}

            {givenPosDiscount ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    className="width100">
                    <p>POS Discount Earned</p>
                    <p>{`Rs ${givenPosDiscount.toFixed(2)}`}</p>
                </div>
            ) : null}
            {tax ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    className="width100">
                    <p>Tax</p>
                    <p>{`Rs ${tax.toFixed(2)}`}</p>
                </div>
            ) : null}

            <div className="width100 marTop20">
                <p className="textCenter">Thank you for visiting us</p>
                <p className="textCenter marTop15 account">We are by OSCAR</p>
                <p className="textCenter">www.oscar.pk</p>
            </div>
        </div>
    );
});

export default OrderCompletionReceipt;
