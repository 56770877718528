import { useEffect, useRef } from "react";

const useFocus = (focusOnInitilarRender = true) => {
  const textBoxRef = useRef();

  useEffect(() => {
    if (focusOnInitilarRender) {
      handleFocus();
    }
  }, []);

  const handleFocus = () => {
    if (textBoxRef.current) {
      textBoxRef.current.focus();
    }
  };

  return { textBoxRef, handleFocus };
};

export default useFocus;
