import RangePicker from '../RangePicker';
import Dropdown from '../Dropdown';
import React from 'react';

const Filter = ({ filters }) => {
    return (
        <div className='filterMainBox'>
            {filters.map((item, index) => {
                return item.type == "date" ?
                    <RangePicker
                        onFilterDate={(lable, startDate, endDate) => item.action([startDate, endDate])}
                        className="orderCalender"
                        vs={false}
                    />
                    :
                    <Dropdown
                        type={item.type}
                        key={index}
                        dropDown={{
                            viewBox: "0 0 8 5",
                            name: 'downIcon',
                            fill: '#ffff',
                            height: "14",
                            width: "14",
                            ...item
                        }}
                    />
            })}
        </div>
    );
};

export default Filter;