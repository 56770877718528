import React, { useState } from "react";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../context";
import {
  DEVICE_HEIGHT,
  toSnakeCase,
  uuid,
  validateFloorPlanForm,
  validateTableData,
} from "../../../../../constants";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Dinein from "../PosCockpit/Dinein";
import Cockpit from "../PosCockpit/Cockpit";
import NewAppModal from "../../../../common/NewAppModal";
import CreateTable from "../PosCockpit/Dinein/CreateTable";
import Icon from "../../../../../assets/icons";
import RadioButton from "../../../../common/RadioButton";
import { Button, CustomContainer, TextField } from "../../../../common";
import { bulkCreateDineInTables } from "../../../../../pos-core/database/helpers/dine_in_table";

const CreateFloorPlan = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const { business } = useAuthContext();

  let navigate = useNavigate();

  const [floorName, setFloorName] = useState("");
  const [floorType, setFloorType] = useState("");
  const [floorTables, setFloorTables] = useState([]);
  const [errors, setErrors] = useState({});
  const [floorPlanErrors, setFloorPlanErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const [seats, setSeats] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [shape, setShape] = useState(null);
  const [name, setName] = useState("");
  const [showMessage, setShowMessage] = useState({
    visible: false,
    message: "",
  });
  const radioOptions = [
    {
      label: I18n.dine_in,
      value: "dinein",
    },
    {
      label: I18n.take_away,
      value: "takeaway",
    },
    {
      label: I18n.delivery,
      value: "delivery",
    },
  ];

  function handleClose(event) {
    navigate("/pos-cockpit");
  }

  const onChange = (label, value) => {
    if (label == I18n.floor_plan_name) setFloorName(value);

    setFloorPlanErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }
      return updatedErrors;
    });
  };

  const handleChange = async (e) => {
    const value = e?.target.value;
    setFloorType(value);

    const label = "floor_plan_type";
    setFloorPlanErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }
      return updatedErrors;
    });
  };

  const saveTable = (resetModal) => {
    const tableObj = {
      id: uuid(),
      name,
      seats,
      shape: shape?.name,
      height,
      width,
      xAxis: 20,
      yAxis: 20,
      xCoord: 20,
      yCoord: 20,
    };

    const formErrors = validateTableData(tableObj);
    console.log("formErrors", formErrors);
    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) return;

    setFloorTables((prev) => [...prev, tableObj]);
    reset();
    !resetModal && setVisible(false);
  };

  const reset = () => {
    setName("");
    setShape("");
    setSeats("");
    setWidth("");
    setHeight("");
  };

  const resetFloorDetails = () => {
    setFloorName("");
    setFloorType("");
    setFloorTables([]);
  };

  const handleDeleteTable = (tableData) => {
    const tableId = tableData?.id;
    const filterTables = floorTables.filter((table) => table.id !== tableId);
    setFloorTables(filterTables);
  };
  const handleSave = async () => {
    try {
      const floorPayload = {
        name: floorName,
        type: floorType,
        componentType: floorType === "dinein" ? "Dinein" : "Cockpit",
      };

      const formErrors = validateFloorPlanForm(floorPayload);
      console.log("formErrors", formErrors);
      setFloorPlanErrors(formErrors);

      if (Object.keys(formErrors).length > 0) return;

      const createdFloor = await business.createFloorPlan(floorPayload);

      if (floorType === "dinein" && floorTables.length > 0) {
        const createdTables = await bulkCreateDineInTables(
          floorTables,
          createdFloor
        );
      } else {
        const tableObj = [
          {
            id: uuid(),
            name: "Table 01",
            seats: 4,
            shape: "Square",
            height: 160,
            width: 200,
            xAxis: 20,
            yAxis: 20,
            xCoord: 20,
            yCoord: 20,
          },
        ];
        const createdTables = await bulkCreateDineInTables(
          tableObj,
          createdFloor
        );
      }
      handleClose();
      resetFloorDetails();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseModal = () => {
    reset();
    setErrors({});
    setVisible(false);
  };

  return (
    <>
      <NewAppModal
        className="deliveryFloorModal modal-backdrop-custom"
        toggle={visible}
        backCta={{
          name: "backArrowIcon",
          fill: theme.barclaysBlue,
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
        }}
        handleClose={() => handleCloseModal()}
        primaryCta={{
          title: "Save & new",
          action: () => saveTable(true),
        }}
        secondaryCta={{
          title: "Save & close",
          action: () => saveTable(),
        }}
        cancelCta={{
          title: I18n.cancel,
          action: () => setVisible(false),
        }}
        title="Create table"
      >
        <CreateTable
          name={name}
          setName={setName}
          width={width}
          setWidth={setWidth}
          height={height}
          setHeight={setHeight}
          shape={shape}
          setShape={setShape}
          seats={seats}
          setSeats={setSeats}
          errors={errors}
          setErrors={setErrors}
        />
      </NewAppModal>

      <CustomContainer className="createFloorPlanContainer">
        <div className="width100 flex verticalCenter padt20">
          <div
            className="width90 flex verticalCenter"
            onClick={() => handleClose()}
          >
            <Icon
              name="backArrowIcon"
              fill={theme.barclaysBlue}
              width={"18"}
              height={"18"}
              viewBox={"0 0 18 18"}
            />
            <p
              className="marBot0 fontSize18 fontWeight500 marLeft20"
              style={{ color: theme.white }}
            >
              {I18n.back}
            </p>
          </div>

          <div className="createFloorPlanButtonWrapper">
            <Button
              //   saveLoad={saveCta.saveLoad}
              title={I18n.save}
              handleClick={handleSave}
              //   disabled={saveCta?.disabled}
              className={`width100 borderRadius5 fontWeight600 fontSize16 cursorPointer marBot0 marRight20`}
            />
          </div>
        </div>

        <div className="width100 flex verticalCenter horizontalCenter">
          <div className="width40 marTop50">
            <p
              className="marBot30 fontSize24 fontWeight600 textCenter"
              style={{ color: theme.white }}
            >
              {I18n.create_floor_plan}
            </p>

            <p
              className="marBot20 fontSize20 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.floor_plan_details}
            </p>

            <TextField
              onChange={onChange}
              error={floorPlanErrors?.["floor_plan_name"]}
              label={I18n.floor_plan_name}
              placeholder={I18n.floor_plan_name}
              value={floorName}
            />

            <p
              className="marTop20 marBot20 fontSize14 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.is_it_dinein_floor_or_delivery_floor}
            </p>
            <RadioButton
              options={radioOptions}
              className="selectFloorPlan"
              selectedSize={floorType}
              handleChange={handleChange}
            />
            {floorPlanErrors?.floor_plan_type && (
              <p className="inputError">{floorPlanErrors.floor_plan_type}</p>
            )}

            {floorType === "dinein" && (
              <>
                <div className="divider width100 marTop20" />
                <p
                  className="marTop20 marBot20 fontSize20 fontWeight400"
                  style={{ color: theme.white }}
                >
                  {I18n.add_tables}
                </p>

                <div
                  className="addTableDivWrapper"
                  onClick={() => setVisible(true)}
                >
                  <Icon
                    name={"add2Icon"}
                    width="24"
                    height="24"
                    viewBox={"0 0 18 18"}
                    fill={theme.barclaysBlue}
                  />
                  <p
                    className="marBot0 fontSize14 fontWeight400"
                    style={{ color: theme.barclaysBlue }}
                  >
                    {I18n.add_tables}
                  </p>
                </div>

                {floorTables?.length > 0 && (
                  <div className="tablesDivWrapper">
                    <div className="flex">
                      <p
                        className="marBot0 fontSize14 fontWeight600"
                        style={{ color: theme.white, width: "50%" }}
                      >
                        Table name
                      </p>
                      <p
                        className="marBot0 fontSize14 fontWeight600"
                        style={{ color: theme.white, width: "20%" }}
                      >
                        Seats
                      </p>
                      <p
                        className="marBot0 fontSize14 fontWeight600"
                        style={{ color: theme.white, width: "20%" }}
                      >
                        Shape
                      </p>
                      <p
                        className="marBot0 fontSize14 fontWeight600"
                        style={{ color: theme.white, width: "10%" }}
                      />
                    </div>
                    {floorTables?.map((tableData) => {
                      return (
                        <>
                          <div className="divider width100 marTop10 marBot10" />
                          <div className="flex">
                            <p
                              className="marBot0 fontSize14 fontWeight400"
                              style={{ color: theme.white, width: "50%" }}
                            >
                              {tableData?.name}
                            </p>
                            <p
                              className="marBot0 fontSize14 fontWeight400"
                              style={{ color: theme.white, width: "20%" }}
                            >
                              {tableData?.seats}
                            </p>
                            <p
                              className="marBot0 fontSize14 fontWeight400"
                              style={{ color: theme.white, width: "20%" }}
                            >
                              {tableData?.shape}
                            </p>
                            <span className="cursorPointer">
                              <Icon
                                name="deleteIcon"
                                fill={theme.red}
                                width="17"
                                height="18"
                                viewBox={"0 0 15 16"}
                                onClick={() => handleDeleteTable(tableData)}
                              />
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </CustomContainer>
      {/* <ToastBox
            className="kdsToastBox"
            ToastVisiable={showMessage.visible}
            bodyPara={showMessage.message}
            showHeader={false}
            setShowMessage={setShowMessage}
          /> */}
    </>
  );
};

export default CreateFloorPlan;
