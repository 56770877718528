import { useState } from "react";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../context";
import { Button, CustomContainer, Header, TextField } from "../../../../common";
import { Col, Row } from "react-bootstrap";
import { DEVICE_HEIGHT } from "../../../../../constants";
import ListItem from "./ListItem";

const CreateOption = () => {
  const { business } = useAuthContext();
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [values, setValues] = useState([]);
  const [text, setText] = useState("");
  const [errors, setErrors] = useState({});

  const TYPES = [I18n.text, I18n.text_and_color];

  const onChange = (label, val) => {
    if (label == I18n.name) setName(val);
    else if (label == I18n.option_type) setType(val);
    else if (label == I18n.values) setText(val);

    setErrors({});
  };

  const handleAddOption = () => {
    if (!text) {
      setErrors({ text: I18n.please_enter_a_value });
      return;
    }

    if (values.filter((x) => x.toLowerCase() == text.toLowerCase()).length) {
      setErrors({ text: I18n.value_already_selected });
      return;
    }

    setValues((x) => {
      let arr = [...x];
      arr.push(text);
      return arr;
    });

    setText("");
  };

  const handleDelete = (item) => {
    setValues((x) => x.filter((y) => y != item));
  };

  const handleSave = async () => {
    try {
      let paramsForOption = {
        name,
        type,
        values,
      };

      let option = await business.createOption(paramsForOption);
    } catch (err) {
      console.log("err in handleSave", err);
    }
  };

  return (
    <>
      <Header
        title={I18n.create_variants}
        handleSave={handleSave}
        isDisabled={name && type && values.length ? false : true}
      />

      <CustomContainer className="addScreenMainBox">
        <Row className="justify-content-md-center">
          <Col md={6}>
            <div className="loginFormBox">
              <div className="signupTextBox">
                <h2
                  className="optionPara fontSize16 fontWeight500 marBot25 marTop0 textCenter"
                  style={{ color: theme.text }}
                >
                  <span>{I18n.create_variant_first_create_option}</span>
                  <span className="marLeft5">
                    <a style={{ color: theme.barclaysBlue }} href="#">
                      {I18n.see_video}
                    </a>
                  </span>
                </h2>
                <h3
                  className="fontSize20 fontWeight500 marBot20 marTop0"
                  style={{ color: theme.text }}
                >
                  {I18n.option_set}
                </h3>
              </div>

              <div
                className="itemFields"
                // style={{ height: DEVICE_HEIGHT * 0.75 }}
              >
                <div className="intventoryFormGridTwo">
                  <div className="priceSectionInputLeft">
                    <TextField
                      onChange={onChange}
                      error={errors?.name}
                      label={I18n.name}
                      placeholder={I18n.size_color_etc}
                      value={name}
                    />
                  </div>
                  <div className="priceSectionInputRight">
                    <TextField
                      label={I18n.option_type}
                      placeholder={I18n.option_set_type}
                      data={TYPES}
                      value={type}
                      onChange={onChange}
                      inputType="select"
                      type="text"
                      suffix="downIcon"
                      suffix_fill={"blackFont"}
                      suffix_width={"14"}
                      suffix_height={"11"}
                      suffix_viewBox={"0 0 8 5"}
                    />
                  </div>
                </div>

                <h3
                  className="fontSize18 fontWeight500 marBot5 marTop15 textLeft width100"
                  style={{ color: theme.text }}
                >
                  {I18n.option}
                </h3>

                <div className="optionFieldBox">
                  <TextField
                    onChange={onChange}
                    error={errors?.text}
                    label={I18n.values}
                    placeholder={I18n.small_medium_large}
                    value={text}
                  />

                  <Button
                    className={`addOptionButton borderRadius10`}
                    type="iconButton"
                    icon_name="newIcon"
                    icon_fill={theme.brightGreen}
                    icon_width={"28"}
                    icon_height={"28"}
                    icon_viewBox={"0 0 13 13"}
                    handleClick={handleAddOption}
                  />
                </div>

                <div className="optionList">
                  {values.map((val) => (
                    <ListItem value={val} handleDelete={handleDelete} />
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CustomContainer>
    </>
  );
};
export default CreateOption;
