import React, { useState } from "react";

import { useLanguageContext, useThemeContext } from "../../../context";
import CsvUploadComponent from "../CsvUploadComponent";
import ImageDragAndDropComponet from "../ImageDragAndDropComponet";

import { PosAppModal } from "../V2";

const ImageUploadModal = ({ toggle, setToggle, setImage }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const [data, setData] = useState([]);

  const handleClose = (event) => {
    setToggle(false);
  };

  return (
    <>
      <PosAppModal
        className="selectCustomerModal"
        toggle={toggle}
        handleClose={handleClose}
        title={I18n.back}
        backCta={{
          name: "backArrowIcon",
          fill: theme.barclaysBlue,
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
        }}
      >
        <ImageDragAndDropComponet
          setImage={setImage}
          setData={setData}
          data={data}
          handleClose={handleClose}
        />
      </PosAppModal>
    </>
  );
};

export default ImageUploadModal;
