import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useCategories = account => {
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [category, setCategory] = useState();

    useEffect(() => {
        handleCustomer(id);
    }, [id]);

    const handleCustomer = async () => {
        let categoryList = await account.getCategory(id).fetch();

        if (categoryList.length) {
            let selectedCategory = categoryList[0];
            setCategory(selectedCategory);
        }
        setIsLoading(false);
    };

    return {isLoading, category};
};

export default useCategories;
