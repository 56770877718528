import { database } from "..";
import { uuid } from "../../constants";
import { PO_LINE_SCHEMA } from "../schema";
import { Q } from "@nozbe/watermelondb";

const purchaseOrderLine = database.collections.get(PO_LINE_SCHEMA);

const prepareInsertion = (po, lines) => {
  return lines.map((line) => {
    return purchaseOrderLine.prepareCreate((obj) => {
      obj.purchase_order.set(po);
      obj.product.set(line.product);
      line.taxes && obj.purchase_tax.set(line.taxes);

      obj._raw.id = uuid();
      obj.name = line.name;

      obj.quantity = line.qty;
      obj.selling_price = line.selling_price;
      obj.cost_price = line.unit_cost;
      obj.discount = line.discount;
      obj.unit = line.unit;
      obj.archive = false;
    });
  });
};

const prepareUpdation = (po, lines) => {
  return lines.map((line) => {
    return line.ref.prepareUpdate((obj) => {
      line?.updatedObj?.taxes && obj.purchase_tax.set(line?.updatedObj?.taxes);

      obj.name = line.updatedObj.name;
      obj.quantity = line.updatedObj.qty;
      obj.selling_price = line.updatedObj.selling_price;
      obj.cost_price = line.updatedObj.unit_cost;
      obj.discount = line.updatedObj.discount;
      obj.unit = line.updatedObj.unit;
      obj.archive = false;
    });
  });
};

const prepareDeletion = (lines) => {
  return lines.map((line) => {
    return line.prepareMarkAsDeleted();
  });
};

export const bulkCreatePoLines = async (po, lines) => {
  await database.write(async () => {
    const allRecords = await prepareInsertion(po, lines);
    await database.batch(...allRecords);
    return allRecords;
  });
};

export const bulkUpdatePoLines = async (po, lines) => {
  await database.write(async () => {
    const allRecords = await prepareUpdation(po, lines);
    await database.batch(...allRecords);
    return allRecords;
  });
};

export const bulkDeletePoLines = async (lines) => {
  await database.write(async () => {
    const allRecords = await prepareDeletion(lines);
    await database.batch(...allRecords);
    return allRecords;
  });
};

export const observePOLine = () =>
  purchaseOrderLine.query(Q.where("archive", Q.notEq(true))).observe();
