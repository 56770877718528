import { database } from "..";
import { BUSINESS_SCHEMA, ITEM_SCHEMA } from "../schema";
import { Q } from "@nozbe/watermelondb";

const item = database.collections.get(ITEM_SCHEMA);

export const observeItemCount = () =>
  item
    .query(Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")))
    .observeCount();
