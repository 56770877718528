import { Container } from "react-bootstrap";
import { DEVICE_HEIGHT } from "../../../constants";
import { useThemeContext } from "../../../context";

const CustomContainer = ({ children, className, handlePress, ...rest }) => {
  const { theme } = useThemeContext();

  const { reduceHeight } = rest;

  return (
    <div
      className={className || "mainCont"}
      style={{
        backgroundColor: theme.blackBg,
        height: reduceHeight ? DEVICE_HEIGHT - reduceHeight : DEVICE_HEIGHT,
      }}
      onKeyDown={handlePress}
    >
      <Container {...rest}>{children}</Container>
    </div>
  );
};

export default CustomContainer;
