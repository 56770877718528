import {useLocation, useNavigate} from 'react-router-dom';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import withObservables from '@nozbe/with-observables';
import {DEVICE_HEIGHT, exportDataToCSV} from '../../../../../constants';
import {Col, Container, Row} from 'react-bootstrap';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import Icon from '../../../../../assets/icons';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import {observeActivedDiscount, observeArchivedDiscounts, observeDraftDiscounts} from '../../../../../pos-core/database/helpers';
import CsvUploadModal from '../../../../common/CsvUploadModal';
import usePermission from '../../../../../hooks/usePermission';

const DiscountList = ({discounts, archivedList, draftList}) => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const [selected, setSelected] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [cvsModal, setCvsModal] = useState(false);
    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_discounts);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('discounts');

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'discount_created') {
            setShowMessage({
                visible: true,
                message: I18n.discount_created_successfully,
            });
        } else if (renderToast === 'discount_update') {
            setShowMessage({
                visible: true,
                message: I18n.discount_updated_successfully,
            });
        } else if (renderToast === 'discount_published_successfully') {
            setShowMessage({
                visible: true,
                message: I18n.discount_published_successfully,
            });
        } else if (renderToast === 'discount_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.discount_saved_into_draft,
            });
        }
    }, [renderToast]);

    const renderList = text ? searchList : filter == I18n.archived ? archivedList : filter == I18n.drafts_saved ? draftList : discounts;

    const actionList = [
        {
            title: I18n.import_library,
            action: () => handleImportDiscount(),
        },
        {
            title: I18n.export_library,
            action: () => handleExportDiscount(),
        },
        {
            title: filter == I18n.all_discounts ? I18n.archived : I18n.unarchived,
            action: () => toggleArchiveModal(),
        },
    ];

    function handleImportDiscount() {
        setCvsModal(x => !x);
    }

    function handleExportDiscount() {
        let csvdata;
        if (Object.keys(selected).length) {
            csvdata = Object.values(selected);
        } else {
            csvdata = filter == I18n.archived ? archivedList : discounts;
        }
        exportDataToCSV('discount', csvdata);
    }

    const filterOption = [
        {
            title: I18n.all_discounts,
            action: () => handleFilter(I18n.all_discounts),
            checked: filter == I18n.all_discounts || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];
    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };
    const handleArchivedAction = async selected => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async item => {
                await item.archiveDiscount(!item.archive);
                selectedIds.push(item.id);
            }),
        );
        setSelected(selected => {
            let obj = {...selected};
            selectedIds.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });
        setloading(false);
    };

    const handleCreateDiscount = () => {
        navigate('/discounts/create');
    };

    function handleSearch(val) {
        return (filter == I18n.archived ? archivedList : discounts).filter(x => x.name.toLowerCase().includes(val.toLowerCase()));
    }

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';

        setText(value);
    };

    const toggleArchiveModal = () => {
        if (Object.values(selected)?.length) setArchiveModal(x => !x);
    };

    const handleSelectAll = () => {
        let list = filter == I18n.archived ? archivedList : discounts;
        if (Object.keys(selected).length != list.length) {
            list?.map(val => {
                setSelected(values => {
                    let obj = {...values};
                    obj[val.id] = val;
                    return obj;
                });
            });
        } else {
            setSelected({});
        }
    };

    const handleChecked = val => {
        let data = val.id;
        setSelected(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const checkedClick = () => {
        return Object.keys(selected)?.length > 0 && Object.keys(selected)?.length === (filter == I18n.archived ? archivedList.length : discounts.length) ? true : false;
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : discounts.length ? (
                <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                    <Header
                        type="search"
                        title={`${I18n.discount} (${renderList?.length})`}
                        search={{
                            type: 'pos',
                            placeholder: I18n.search_by_name,
                            prefix: {
                                name: 'search2Icon',
                                fill: theme.white,
                            },
                            onChange: onChange,
                        }}
                        className={'borderRadiusTopleftTopRigt'}
                        leftCta={{
                            title: filter,
                            name: 'downIcon2',
                            fill: theme.white,
                            width: '20',
                            height: '20',
                            viewBox: '0 0 18 18',
                            option: filterOption,
                        }}
                        rightCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.create_discount,
                                      action: handleCreateDiscount,
                                  }
                                : null
                        }
                        actionCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.actions,
                                      name: 'downIcon2',
                                      fill: theme.white,
                                      width: '20',
                                      height: '20',
                                      viewBox: '0 0 18 18',
                                      option: actionList,
                                  }
                                : null
                        }
                    />

                    <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <TableHeader className={`sixColumn`} style={{borderColor: theme.posRightBg}}>
                                        <div className="checkboxCol">
                                            <CheckBox
                                                checkboxLabel={
                                                    <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                        {I18n.name}
                                                    </p>
                                                }
                                                onChange={handleSelectAll}
                                                checked={checkedClick()}
                                            />
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.type}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.amount}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.discount_given}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.maximum_discount_cap}
                                            </p>
                                        </div>
                                        {userPermission?.canCrud && (
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.action}
                                                </p>
                                            </div>
                                        )}
                                    </TableHeader>

                                    <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                        {renderList?.length === 0 ? (
                                            <NonIdealScreen
                                                heading={text ? I18n.search : filter == I18n.archived ? I18n.archive_discount : I18n.draft_discount}
                                                subHeading={
                                                    text
                                                        ? I18n.we_couldnt_find_any_results_for_your_search
                                                        : filter == I18n.archived
                                                        ? I18n.no_archive_discounts_found
                                                        : I18n.no_draft_discounts_found
                                                }
                                                name={text ? 'seacrhNotFoundIcon' : filter == I18n.archived ? 'archiveIcon' : 'draftIcon'}
                                                fill={theme.white}
                                                isMultiStep={false}
                                                tablenonIdeal={true}
                                            />
                                        ) : (
                                            renderList.map(val => (
                                                <List
                                                    handleChecked={handleChecked}
                                                    selected={selected}
                                                    setSelected={setSelected}
                                                    filter={filter}
                                                    discount={val}
                                                    userPermission={userPermission}
                                                />
                                            ))
                                        )}
                                    </div>
                                    <ToastBox
                                        className="addSuccessfullyToast"
                                        ToastVisiable={showMessage.visible}
                                        bodyPara={showMessage.message}
                                        setShowMessage={setShowMessage}
                                        showIcon={true}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <MessageModal
                        setToggle={setArchiveModal}
                        toggle={archiveModal}
                        className="messageModal"
                        title={filter == I18n.all_discounts ? I18n.archive_discount : I18n.unarchive_discount}
                        description={
                            filter == I18n.all_discounts
                                ? I18n.are_you_sure_you_want_to_archive_the_selected_discount
                                : I18n.are_you_sure_you_want_to_unarchive_the_selected_discount
                        }
                        secondaryCta={{
                            title: I18n.no,
                            action: () => toggleArchiveModal,
                        }}
                        primaryCta={{
                            title: I18n.yes,
                            action: () => handleArchivedAction(selected),
                            isDelete: true,
                        }}
                    />
                </div>
            ) : (
                <NonIdealScreen
                    heading={text ? I18n.search : I18n.discount}
                    subHeading={I18n.discount_credit_cards_entire_orders}
                    name="discountIcon"
                    fill={theme.white}
                    secondaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_discount,
                                  action: handleCreateDiscount,
                              }
                            : null
                    }
                    primaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.import_discount,
                                  action: handleImportDiscount,
                              }
                            : null
                    }
                    isMultiStep={false}
                />
            )}
            <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} type={'discount'} />
        </>
    );
};

const enhance = withObservables([], () => ({
    discounts: observeActivedDiscount(),
    archivedList: observeArchivedDiscounts(),
    draftList: observeDraftDiscounts(),
}));

export default enhance(DiscountList);
