import Icon from "../../../../assets/icons";
import { useThemeContext } from "../../../../context";
import Button from "../../Button";
import NameSlice from "../../NameSlice";

const ListItem = ({ waiter, onPress, order }) => {
  const { theme } = useThemeContext();

  let isSelected = order.waiter.id == waiter._raw.id ? true : false;

  const handleSelect = (waiter) => {
    if (isSelected) {
      onPress("removeWaiter");
    } else {
      onPress(waiter);
    }
  };
  return (
    <div
      className="selectCustomerInnerRow cursorPointer"
      style={{ borderColor: theme.darkSlateBlue2 }}
    >
      <div className="customerInitials">
        <NameSlice value={waiter?.name || ""} />
      </div>
      <div className="customerDetailsBox">
        <span>
          <p className="marBot0 fontSize18" style={{ color: theme.white }}>
            {waiter.name}
          </p>
          <p
            className="marBot0 fontSize14"
            style={{ color: theme.lightGrayTwo }}
          >
            {waiter.phone_number}
          </p>
        </span>

        <div>
          <Button
            title={isSelected ? "Unassign" : "Assign"}
            handleClick={() => handleSelect(waiter)}
            className={`${
              isSelected ? "unAssignBtn" : "assignBtn"
            } fontWeight400 fontSize14 cursorPointer`}
          />
        </div>
      </div>
    </div>
  );
};

export default ListItem;
