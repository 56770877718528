import { CustomContainer } from "../../../../common";
import CreateCustomerComponent from "../../../../common/CreateCustomerComponent";

const CreateCustomer = () => {
  return (
    <>
      <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox  createCustomerContainer"
      >
        <CreateCustomerComponent />
      </CustomContainer>
    </>
  );
};

export default CreateCustomer;
