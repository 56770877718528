import { CreateCompanyComponent } from "../../../../common";

const CreateCompany = () => {
  return (
    <>
      <CreateCompanyComponent />
    </>
  );
};

export default CreateCompany;
