import {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import Listitem from './ListItem';
import {DEVICE_HEIGHT} from '../../../constants';
import {useOrderFilter, useSearch} from '../../../hooks';
import TableHeader from '../TableContainer/TableHeader';
import withObservables from '@nozbe/with-observables';
import {observeHeldOrders} from '../../../pos-core/database/helpers';
import {PosAppModal} from '../V2';
import SearchBox from '../SearchBox';
import NonIdealScreen from '../NonIdeal';

const CreditHistoryModal = ({toggle, setToggle, orders, customer}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {text, setText, searchList} = useSearch(handleSearch);
    const {list, filter, setFilter} = useOrderFilter(handleFilter);
    const [creditTransaction, setCreditTransaction] = useState(null);

    const getCreditTransaction = async () => {
        const response = await customer?.credit_transactions.fetch();
        setCreditTransaction(response);
    };

    useEffect(() => {
        if (customer) {
            getCreditTransaction();
        }
    }, [customer, toggle]);

    const handleClose = event => {
        setToggle(false);
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    function handleFilter(selected) {
        let filteredList = [...orders];
        setFilter(selected);
        if (selected && selected != 'all') {
            if (selected == 'most recent') filteredList = orders.sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at));
            else if (selected == 'amount') filteredList = orders.sort((a, b) => a.total - b.total);
        } else {
            filteredList = orders;
        }
        return filteredList;
    }
    const handleChange = () => {
        // handleChange
    };

    async function handleSearch(val) {
        let value = val.toLowerCase();
        const filteredList = await Promise.all(
            list?.map(async x => {
                const customerData = await x?.customer?.fetch();
                return x.number.toLowerCase().includes(value) || customerData?.name.toLowerCase().includes(value);
            }),
        );
        return list?.filter((_, index) => filteredList[index]);
    }

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            handleClose={handleClose}
            title={I18n.credit_history}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}>
            {!list.length == 0 && (
                <SearchBox
                    type="pos"
                    placeholder={I18n.search_by_amount}
                    prefix={{
                        name: 'search2Icon',
                        fill: theme.white,
                    }}
                    onChange={onChange}
                />
            )}

            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        {creditTransaction?.length ? (
                            <div className="posOrderList">
                                <TableHeader className={`fourColumn`} style={{borderColor: theme.posRightBg}}>
                                    <div className="BoxWidth fontWeight600 justifyStart marLeft30">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.customer}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.pay_date}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.status}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox orderListBodyBox" style={{height: DEVICE_HEIGHT - 300}}>
                                    {(text ? searchList : creditTransaction)?.map((val, index) => (
                                        <Listitem key={index} order={val} handleClose={handleClose} customer={customer} transaction={val} />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <NonIdealScreen
                                heading={I18n.credit_history}
                                subHeading={I18n.credit_history_not_found}
                                name="clockhistoryIcon"
                                fill={theme.white}
                                isMultiStep={false}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </PosAppModal>
    );
};
const enhance = withObservables([], ({}) => ({
    orders: observeHeldOrders(),
}));
export default enhance(CreditHistoryModal);
