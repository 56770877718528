import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../common";
import Icon from "../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../context";
import { useNavigate } from "react-router-dom";

const POHeader = ({
  title,
  handleSave,
  approveTitle,
  rejectTitle,
  editIcon,
  isDisabled = false,
  ...rest
}) => {
  let navigate = useNavigate();

  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleBack = () => navigate(-1);

  return (
    <section
      className="addScreenTopBar noborder"
      style={{
        backgroundColor: theme.blackBg,
      }}
    >
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="backInnerBox">
              <p className="marBot0 white">
                <span
                  className="backBoxIcon cursorPointer"
                  onClick={handleBack}
                >
                  <Icon
                    name="backArrowIcon"
                    fill={theme.barclaysBlue}
                    width={"18"}
                    height={"18"}
                    viewBox={"0 0 18 18"}
                  />
                </span>
                <span className="marLeft10 fontSize18">{title}</span>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="closeButtonBox">
              {editIcon && (
                <span
                  className="marRight25 cursorPointer"
                  onClick={editIcon.action}
                >
                  <Icon
                    name="editIcon"
                    fill={theme.white}
                    width="20"
                    height="20"
                    viewBox={"0 0 18 18"}
                  />
                </span>
              )}
              {approveTitle ? (
                <Button
                  value={approveTitle}
                  disabled={isDisabled}
                  className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                  title={approveTitle}
                  handleClick={handleSave}
                  {...rest}
                />
              ) : null}
              {rejectTitle ? (
                <Button
                  value={rejectTitle}
                  disabled={isDisabled}
                  className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                  title={rejectTitle}
                  handleClick={handleSave}
                  backgroundColor={theme.red}
                  {...rest}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default POHeader;
