import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {useCustomerOrdersCalculation} from '../../../../../hooks';
import {firstLetterCaptilize, formatDate, formatDateComparison, formatNum, formatSingleDigitNum} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';

const List = ({customer, orders, handleChecked, selected, setSelected, setShowMessage, filter, userPermission}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {totalSpent, averageSpend} = useCustomerOrdersCalculation(orders);

    const [archiveModal, setArchiveModal] = useState(false);

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async customer => {
        setArchiveModal(false);
        let data = customer?.id;
        if (selected[data]) {
            setSelected(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }

        await customer.archiveCustomer(customer?.archive ? false : true);
        setShowMessage({
            visible: true,
            message: customer?.archive ? I18n.customer_archive_successfully : I18n.customer_unarchive_successfully,
        });
    };

    const handleAction = async type => {
        switch (type) {
            case 'delete':
                let deleted = await customer.archiveCustomer();
                break;
            case 'details':
                navigateToNextScreen();
                break;
            case 'edit':
                navigate(`${customer.id}/edit`, {
                    state: {screenName: filter},
                });
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
        {
            title: I18n.details,
            action: () => handleAction('details'),
        },
        {
            title: I18n[customer.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    const draftAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
        {
            title: I18n[customer.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];
    const navigateToNextScreen = () => {
        if (!userPermission?.canCrud) return;
        if (customer.draft) {
            handleAction('edit');
        } else {
            navigate(`/customers/${customer.id}`);
        }
    };

    return (
        <>
            <TableBody className={`eightColumn cursorPointer`} onClick={navigateToNextScreen} style={{borderColor: theme.inputBorder}}>
                <div
                    className="checkboxCol"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <CheckBox
                        checkboxLabel={
                            <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.white}}>
                                {firstLetterCaptilize(customer.name)}
                            </p>
                        }
                        onChange={() => handleChecked(customer)}
                        checked={selected[customer?.id]}
                    />
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {customer.phone_number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {orders.length ? formatDateComparison(orders[orders.length - 1].completed_at) : '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {`${formatSingleDigitNum(orders.length)}`}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {customer.created_at ? formatDate(customer.created_at) : '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {formatNum(averageSpend || 0)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {formatNum(totalSpent)}
                    </p>
                </div>
                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: customer.draft ? draftAction : tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n[customer.archive ? 'do_you_really_want_to_unarchive_this_customer' : 'do_you_really_want_to_archive_this_customer']}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n[customer.archive ? 'unarchive' : 'archive'],
                    action: () => {
                        handleArchive(customer);
                    },
                }}
            />
        </>
    );
};

const enhance = withObservables(['customer'], ({customer}) => ({
    customer: customer.observe(),
    orders: customer.order.observe(),
}));

export default enhance(List);
