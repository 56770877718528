import { database } from "..";
import { Q } from "@nozbe/watermelondb";
import { PRODUCT_TEMPLATE_SCHEMA } from "../schema";

const product_template = database.collections.get(PRODUCT_TEMPLATE_SCHEMA);

export const findProductTemplateByID = async (id) =>
  await product_template.find(id);

export const getTemplateImagesToUpload = async () =>
  await product_template
    .query(Q.where("image", Q.like("%data:image%")))
    .fetch();
