import React, {useState, useEffect} from 'react';
import AppModal from '../AppModal';
import {useLanguageContext} from '../../../context';
import CreateDevice from '../../screens/Authenticated/KitchenDisplay/CreateDevice';
import Loading from '../Loading';
import DeviceComponent from '../../screens/Authenticated/KitchenDisplay/DeviceComponent';
import SendDeviceCode from '../../screens/Authenticated/KitchenDisplay/SendDeviceCode';
import CreatePin from '../../screens/Authenticated/KitchenDisplay/CreatePin';
import ThirdPartyIntegration from '../../screens/Authenticated/KitchenDisplay/ThirdPartyIntegration';
import {ToastBox} from '../../common';
import {validateCreateDeviceForm, validateOdooForm, validateSendEmailForm} from '../../../constants';
import {createDevice, sendEmail, updateOdooConfig, updatePin} from '../../../api';

const CreateDeviceModal = ({toggle, setToggle, deviceData, setDeviceData}) => {
    const {I18n} = useLanguageContext();
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [deviceStep, setDeviceStep] = useState('initial');
    const [errors, setErrors] = useState({});
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);

            return () => clearTimeout(timeoutId);
        }

        if (deviceData) {
            setStep(3);
        }
    }, [showMessage, toggle]);

    const clear = () => {
        setDeviceData(null);
        setName('');
        setLocation('');
    };

    function handleClose(event) {
        clear();
        setStep(1);
        setDeviceStep('initial');
        setToggle(false);
    }

    const handleAddDevice = async payload => {
        try {
            const devicePayload = {
                name: name,
                location: location.name,
            };
            const formErrors = validateCreateDeviceForm(devicePayload);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) return;

            setStep(2);
            const response = await createDevice(devicePayload);
            setDeviceData(response?.data);
            setStep(3);
            console.log('response', response);
        } catch (error) {
            console.log('error', error);
            setStep(1);
        }
    };

    function handleDeviceStep(val) {
        console.log('press', val);
        if (val?.includes('https')) {
            window.open(val, '_blank');
        } else {
            setDeviceStep(val);
        }
    }
    const handleSubmit = async val => {
        try {
            const deviceId = deviceData?.id;
            const pinPayload = {
                pin: val,
                pin_active: true,
            };
            const response = await updatePin(pinPayload, deviceId);
            if (response.success) {
                setDeviceData(response?.data);
                setStep(3);
                setDeviceStep('initial');
                setShowMessage({
                    visible: true,
                    message: 'Pin setup successfully',
                });
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleConnectOdoo = async values => {
        const deviceId = deviceData?.id;
        const odoo_payload = {
            ...values,
            odoo_active: true,
        };

        try {
            const formErrors = validateOdooForm(values);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            const response = await updateOdooConfig(odoo_payload, deviceId);
            if (response.success) {
                setDeviceData(response?.data);
                setStep(3);
                setDeviceStep('initial');
                setShowMessage({
                    visible: true,
                    message: 'Odoo connected',
                });
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleSend = async value => {
        try {
            setLoading(true);
            let emailFormObj = {
                email: value,
            };
            const formErrors = await validateSendEmailForm(emailFormObj);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            const emailPayload = {
                secret_key: deviceData?.secret_key,
                email: value,
            };
            const response = await sendEmail(emailPayload);
            if (response.success) {
                setStep(3);
                setDeviceStep('initial');
                setShowMessage({
                    visible: true,
                    message: 'Code sent successfully',
                });
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    console.log('state', showMessage);
    return (
        <>
            <div className="createDeviceModal">
                <AppModal
                    className="createCustomerModal"
                    toggle={toggle}
                    handleClose={handleClose}
                    title={I18n.new_kitchen_display}
                    showHeader={true}
                    saveCta={
                        step === 1
                            ? {
                                  title: I18n.next,
                                  action: handleAddDevice,
                              }
                            : false
                    }>
                    {step === 1 && <CreateDevice name={name} setName={setName} location={location} setLocation={setLocation} setErrors={setErrors} errors={errors} />}

                    {step === 2 && <Loading heading={I18n.generating_device_code} subHeading={I18n.stay_few_seconds} reduceHeight={110} />}

                    {step === 3 && (
                        <div>
                            {deviceStep === 'initial' && <DeviceComponent handleDeviceStep={handleDeviceStep} setShowMessage={setShowMessage} deviceData={deviceData} />}
                            {deviceStep === 'send-device-code' && <SendDeviceCode handleSend={handleSend} loading={loading} errors={errors} setErrors={setErrors} />}
                            {deviceStep === 'create-pin' && <CreatePin handleSubmit={handleSubmit} />}
                            {deviceStep === 'connect-odoo' && <ThirdPartyIntegration handleConnectOdoo={handleConnectOdoo} errors={errors} setErrors={setErrors} />}
                        </div>
                    )}

                    <ToastBox className="kdsToastBox" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} showHeader={false} setShowMessage={setShowMessage} />
                </AppModal>
            </div>
        </>
    );
};

export default CreateDeviceModal;
