import { Col, Container, Row } from "react-bootstrap";
import TableHeader from "../TableContainer/TableHeader";
import { useLanguageContext, useThemeContext } from "../../../context";
import { CustomerCsvDatavalidate, DEVICE_HEIGHT } from "../../../constants";
import CsvList from "./CsvList";
import { useEffect } from "react";

const CustomerCSVTable = ({ data, setErrors, errors }) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();

  let csvData = data?.formateRow;

  useEffect(() => {
    if (csvData.length) {
      handleError();
    }
  }, [csvData]);

  const handleError = async () => {
    const formErrors = await CustomerCsvDatavalidate(csvData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    }
  };
  const renderColumn = (label, textStyle) => (
    <div className={`BoxWidth ${textStyle}`}>
      <p
        className="fontSize16 OneLineTruncate"
        style={{ color: theme.white }}
        title={label}
      >
        {label}
      </p>
    </div>
  );

  return (
    <div
      className="itemListContainer"
      style={{ background: theme.blackBg, height: DEVICE_HEIGHT - 110 }}
    >
      <Container fluid>
        <Row>
          <Col>
            <TableHeader
              className={`eightColumn`}
              style={{ borderColor: theme.posRightBg }}
            >
              {renderColumn(I18n.customer, "justifyStart")}
              {renderColumn(I18n.phone, "justifyCenter")}
              {renderColumn(I18n.email, "justifyCenter")}
              {renderColumn(I18n.dob, "justifyCenter")}
              {renderColumn(I18n.country, "justifyCenter")}
              {renderColumn(I18n.province, "justifyCenter")}
              {renderColumn(I18n.city, "justifyCenter")}
              {renderColumn(I18n.address, "justifyCenter")}
            </TableHeader>
            <div
              className="tableListMainBox"
              style={{ height: DEVICE_HEIGHT - 280 }}
            >
              {csvData?.map((val, index) => (
                <CsvList
                  errors={errors}
                  key={index}
                  index={index}
                  customer={val}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomerCSVTable;
