import {BUSINESS_SCHEMA, PAYMENT_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';
import {database} from '..';
import {uuid} from '../../constants';

const payment = database.collections.get(PAYMENT_SCHEMA);

export const observePaginatedPayments = (start, stop) => payment.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.sortBy('created_at', Q.desc)).observe();
export const getPaginatedPayments = (start, stop) => payment.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.sortBy('completed_at', Q.desc)).fetch();
export const observepaymentByID = id => payment.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('id', id)).observe();
export const observePaymentsByBusiness = () => payment.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id'))).observe();

export const getFilteredPayments = async (payment_method, status, type, total, date) => {
    let filterQuery = [];

    if (date[1]) filterQuery.push(Q.where('created_at', Q.between(Number(date[0]), Number(date[1]))));
    if (payment_method) filterQuery.push(Q.where('payment_method', payment_method));
    if (status) filterQuery.push(Q.where('status', status));
    if (type) filterQuery.push(Q.where('amount', Q[type](total)));

    return await payment.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), ...filterQuery).fetch();
};

const prepareInsertion = (data, business) => {
    return data.map(details => {
        try {
            return payment.prepareCreate(item => {
                item.business.set(business);
                details.order?.customer?.id && item.customer.set(details.order?.customer);
                item.order.set(details.order);
                item._raw.id = uuid();
                item.payment_method = details.order.payment_method;
                item.card_number = details.card_number;
                item.amount = details.total;
                item.status = details.status;
                item.type = details?.type;
                item.completed_at = details.completed_at;
                item.expiry_date = details.expiry_date;
                item.started_at = details.started_at;
                item.created_at = details.created_at;
            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreatePayments = async (data, business) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(data, business);
        await database.batch(allRecords);
    });
};
