import React from "react";
import { useLanguageContext, useThemeContext } from "../../../../context";
import TextField from "../../TextField";

const PaymentStep2 = ({ data }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <div className="paymentStep2Conatiner">
      <p
        className="fontSize20  marTop30 fontWeight400"
        style={{ color: theme.white }}
      >
        {I18n.customer_information}
      </p>

      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>{I18n.account_title}</span>
        <span style={{ color: theme.white }}>
          {data?.account_title || "Zubair"}
        </span>
      </p>
      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>{I18n.account_number}</span>
        <span style={{ color: theme.white }}>
          {data?.account_number || "03472003439"}
        </span>
      </p>
    </div>
  );
};

export default PaymentStep2;
