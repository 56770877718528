import { Col, Container, Row } from "react-bootstrap";
import { Loading, NonIdealScreen } from "../../../../common";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../context";
import TableHeader from "../../../../common/TableContainer/TableHeader";
import {
  DEVICE_HEIGHT,
  formatStringAndCapitalize,
} from "../../../../../constants";
import List from "./List";
import { useEffect, useState } from "react";
import SidebarModal from "../../../../common/SidebarModal";
import OrderDetail from "./OrderDetail";
import { getKitchenOrdersHistory } from "../../../../../api";

const KitchenOrderHistory = ({
  setShowMessage,
  filter,
  search,
  showMessage,
}) => {
  const { deviceConfiguration } = useAuthContext();
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [sidebar, setSidebar] = useState(null);
  const [ordersHistory, setOrdersHistory] = useState([]);
  const [filteredOrderHistory, setFilteredOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrdersHistory = async () => {
    try {
      setLoading(true);
      const secret_key = deviceConfiguration?.secret_key;
      const payload = {
        history: true,
      };
      const response = await getKitchenOrdersHistory(secret_key, payload);
      setOrdersHistory(response.orders);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrdersHistory();
  }, [showMessage]);

  useEffect(() => {
    if (filter !== "All") {
      const filteredByType = ordersHistory?.filter(
        (item) => formatStringAndCapitalize(item.order_type) === filter
      );

      if (search !== "") {
        const filteredBySearch = filteredByType.filter(
          (x) =>
            x.order_id.toLowerCase().includes(search.toLowerCase()) ||
            x.waiter_name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredOrderHistory(filteredBySearch);
      } else {
        setFilteredOrderHistory(filteredByType);
      }
    } else {
      if (search !== "") {
        const filteredBySearch = ordersHistory.filter(
          (x) =>
            x.order_id.toLowerCase().includes(search.toLowerCase()) ||
            x.waiter_name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredOrderHistory(filteredBySearch);
      } else {
        setFilteredOrderHistory(null);
      }
    }
  }, [filter, search, ordersHistory]);

  return (
    <div className="kitchendOrderHistoryWrapper">
      {loading ? (
        <Loading />
      ) : ordersHistory?.length > 0 ? (
        <Container fluid>
          <Row>
            <Col>
              <TableHeader
                className={`eightColumn`}
                style={{ borderColor: theme.posRightBg }}
              >
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.order_number}
                  </p>
                </div>
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.date}
                  </p>
                </div>
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.order_completed}
                  </p>
                </div>
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.waiter}
                  </p>
                </div>
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.order_type}
                  </p>
                </div>
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.order_status}
                  </p>
                </div>
                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.time_taken}
                  </p>
                </div>

                <div className="BoxWidth justifyStart">
                  <p
                    className="fontSize16 OneLineTruncate"
                    style={{ color: theme.white }}
                  >
                    {I18n.actions}
                  </p>
                </div>
              </TableHeader>
              <div
                className="tableListMainBox"
                style={{ height: DEVICE_HEIGHT - 280 }}
              >
                {(filteredOrderHistory
                  ? filteredOrderHistory
                  : ordersHistory
                )?.map((val, index) => (
                  <List key={index} order={val} setSidebar={setSidebar} />
                ))}
              </div>
            </Col>
          </Row>
          <SidebarModal
            sidebar={sidebar}
            setSidebar={setSidebar}
            placement="end"
            className="notificationSidebar"
            children={
              <OrderDetail
                order={sidebar}
                setSidebar={setSidebar}
                setShowMessage={setShowMessage}
              />
            }
          />
        </Container>
      ) : (
        <div style={{ height: DEVICE_HEIGHT - 110 }}>
          <NonIdealScreen
            subHeading={I18n.history}
            paragraph={I18n.completed_orders_will_show_up_here}
            name="clockIcon"
            fill={theme.white}
            isMultiStep={false}
            width="100"
            height="100"
            viewBox="0 0 100 100"
          />
        </div>
      )}
    </div>
  );
};

export default KitchenOrderHistory;
