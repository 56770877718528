import {useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {useNavigate} from 'react-router-dom';
import {formatNum} from '../../../../../constants';
import newImagePlaceholder from '../../../../../assets/images/newImagePlaceholder.svg';

const List = ({product, category, template, handleChecked, selecteditem, setSelecteditem, hanldeChange, userPermission, prodBusiness}) => {
    let navigate = useNavigate();

    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const [archiveModal, setArchiveModal] = useState(false);

    const onChange = () => {
        // onChange
    };

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async product => {
        setArchiveModal(false);
        let data = product?.id;
        if (selecteditem[data]) {
            setSelecteditem(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }
        await product?.archiveProduct(!product?.archive);
        hanldeChange(0);
    };

    const handleAction = type => {
        switch (type) {
            case 'archive':
                toggleArchiveModal();
                break;
            case 'edit':
                navigate(`${product.id}/edit`);
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
        {
            title: I18n[product.archive ? 'unarchive' : 'archive'],
            action: () => handleAction('archive'),
        },
    ];

    const navigateToDetails = () => {
        if (!userPermission?.canCrud) return;
        handleAction('edit');
    };

    return (
        <>
            <TableBody className={'sevenColumn'} style={{borderColor: theme.posRightBg}} onClick={navigateToDetails}>
                <div
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    className="BoxWidth justifyStart itemListBox">
                    <CheckBox
                        className={'itemlistCheckBox'}
                        checkboxLabel={
                            <p className="fontSize14 fontWeight600" style={{color: theme.white}}>
                                <span className="itemListImage">
                                    {template?.image ? (
                                        <img src={template?.image} style={{resizeMode: 'cover'}} />
                                    ) : (
                                        <img src={newImagePlaceholder} style={{resizeMode: 'cover'}} />
                                    )}
                                </span>
                                <span className="OneLineTruncate">{product.name}</span>
                            </p>
                        }
                        onChange={() => handleChecked(product)}
                        checked={selecteditem[product?.id]}
                    />
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {category?.name || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {`${formatNum(prodBusiness[0]?.quantity)} ${template?.unit}`}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {formatNum(product.low_stock)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {formatNum(product.selling_price)}
                    </p>
                </div>
                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                className="messageModal"
                setToggle={setArchiveModal}
                toggle={archiveModal}
                title={I18n.archive_product}
                description={I18n[product.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive'] + product.name + '?'}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => handleArchive(product),
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables(['product'], ({product}) => ({
    product: product?.observe(),
    category: product?.category.observe(),
    template: product?.product_template.observe(),
    prodBusiness: product?.getProdBusiness(),
}));

export default enhance(List);
