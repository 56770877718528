import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useProduct = (account) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [product, setProduct] = useState();

  useEffect(() => {
    handleProduct(id);
  }, [id]);

  const handleProduct = async (id) => {
    let productList = await account.getProduct(id).fetch();

    if (productList.length) setProduct(productList[0]);

    setIsLoading(false);
  };

  return { isLoading, product };
};

export default useProduct;
