import {useEffect, useState} from 'react';
import {useLanguageContext} from '../../context';

const useTaxes = (salesTax, purchaseTax, filter = '') => {
    const {I18n} = useLanguageContext();

    const [isLoading, setIsLoading] = useState(true);
    const [taxList, setTaxList] = useState([]);

    useEffect(() => {
        handleTaxes(salesTax, purchaseTax, filter);
    }, [salesTax, purchaseTax, filter]);

    const handleTaxes = (sales, purchase) => {
        let salesTaxes = [],
            purchaseTaxes = [];

        if (filter === 'Drafts saved') {
            salesTaxes = filter === I18n.purchase_tax ? [] : sales.filter(x => x.draft === true);
            purchaseTaxes = filter === I18n.sales_tax ? [] : purchase.filter(x => x.draft === true);
        } else {
            salesTaxes =
                filter === I18n.purchase_tax
                    ? []
                    : sales.filter(x => {
                          return filter === I18n.archived ? x.archive && x.draft !== true : !x.archive && x.draft !== true;
                      });

            purchaseTaxes =
                filter === I18n.sales_tax
                    ? []
                    : purchase.filter(x => {
                          return filter === I18n.archived ? x.archive : !x.archive;
                      });
        }

        let arr = [...salesTaxes, ...purchaseTaxes];

        arr.sort((a, b) => a.created_at - b.created_at);

        setTaxList(arr);
        setIsLoading(false);
    };

    return {isLoading, taxList};
};

export default useTaxes;
