import { CreateItemComponent } from "../../../../common";

const CreateItem = () => {
  return (
    <>
      <CreateItemComponent />
    </>
  );
};

export default CreateItem;
