import { Col } from "react-bootstrap";
import { DEVICE_HEIGHT } from "../../../constants";
import { useThemeContext } from "../../../context";

const RightPane = ({ children }) => {
  const { theme } = useThemeContext();
  return (
    <Col md={6} className="noPadding">
      <div
        className="posrightSection"
        style={{
          background: theme.topBarBG,
          height: DEVICE_HEIGHT - 45,
          borderLeft: `1px solid ${theme.inputBorder}`,
        }}
      >
        {children}
      </div>
    </Col>
  );
};

export default RightPane;
