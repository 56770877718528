import {fetchOrdersByDate} from '../../pos-core/database/helpers';
import {FILTER_MONTHS} from '../../constants';
import {useState} from 'react';

const useOrderByDate = () => {
    const [overView, setOverView] = useState({});
    const [loading, setLoading] = useState(true);

    const getOverview = (label, currentStartDate, currentEndDate, diffInDays, locationId) => {
        try {
            let filter = FILTER_MONTHS.find(x => x.filter == label);

            currentEndDate = currentEndDate || new Date(new Date().setHours(23, 59, 59, 999));
            currentStartDate = currentStartDate || new Date(new Date().setHours(0, 0, 0, 0));

            let previousStartDate = new Date(currentStartDate);
            let previousEndDate = new Date(currentEndDate);

            switch (filter.value) {
                case 'week':
                    previousStartDate.setDate(previousStartDate.getDate() - 7);
                    previousEndDate.setDate(previousEndDate.getDate() - 7);
                    break;
                case 'month':
                    previousStartDate = new Date(previousStartDate.getFullYear(), previousStartDate.getMonth() - 1, 1);
                    previousEndDate = new Date(previousEndDate.getFullYear(), previousEndDate.getMonth(), 0, 23, 59, 59, 999);
                    break;
                case 'year':
                    previousStartDate.setFullYear(previousStartDate.getFullYear() - 1);
                    previousEndDate.setFullYear(previousEndDate.getFullYear() - 1);
                    break;
                case 'day':
                    previousStartDate.setDate(previousStartDate.getDate() - 1);
                    previousEndDate.setDate(previousEndDate.getDate() - 1);
                    break;
                default:
                    previousStartDate = new Date(previousStartDate.setDate(previousStartDate.getDate() - diffInDays));
                    previousEndDate = new Date(previousEndDate.setDate(previousEndDate.getDate() - diffInDays));
                    break;
            }

            Promise.all([fetchOrdersByDate(currentStartDate, currentEndDate, locationId), fetchOrdersByDate(previousStartDate, previousEndDate, locationId)])
                .then(orders => {
                    let obj = {
                        cancelled_orders: {percent: 0, amount: 0},
                        total_orders: {percent: 0, amount: 0},
                        total_sales: {percent: 0, amount: 0},
                        hold_orders: {percent: 0, amount: 0},
                    };

                    let total_previous_cancelled_orders = 0,
                        total_previous_hold_orders = 0,
                        total_previous_orders = 0,
                        total_previous_sales = 0;

                    orders[0].map(item => {
                        if (item.status == 'deleted') obj['cancelled_orders'].amount += 1;
                        if (item.status == 'hold') obj['hold_orders'].amount += 1;
                        if (item.status == 'complete') {
                            obj['total_sales'].amount += item.total;
                            obj['total_orders'].amount += 1;
                        }
                    });

                    orders[1].map(ordr => {
                        if (ordr.status == 'complete') {
                            total_previous_sales += ordr.total;
                            total_previous_orders += 1;
                        }
                        if (ordr.status == 'hold') total_previous_hold_orders += 1;
                        if (ordr.status == 'deleted') total_previous_cancelled_orders += 1;
                    });

                    obj['cancelled_orders'].percent = ((obj['cancelled_orders'].amount - total_previous_cancelled_orders) / (total_previous_hold_orders || 1)) * 100;
                    obj['hold_orders'].percent = ((obj['hold_orders'].amount - total_previous_hold_orders) / (total_previous_hold_orders || 1)) * 100;
                    obj['total_orders'].percent = ((obj['total_orders'].amount - total_previous_orders) / (total_previous_orders || 1)) * 100;
                    obj['total_sales'].percent = ((obj['total_sales'].amount - total_previous_sales) / (total_previous_sales || 1)) * 100;

                    setOverView(obj);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        } catch (err) {
            setLoading(false);
        }
    };

    return {
        getOverview,
        overView,
        loading,
    };
};

export default useOrderByDate;
