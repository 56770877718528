import { useLanguageContext, useThemeContext } from "../../../../../context";

const CategoryItem = ({ category, onPress, background }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <div
      className="posItemsBox cursorPointer"
      onClick={() => onPress(category)}
      style={{
        background: category?.color_code
          ? category?.color_code
          : theme.seperatorTwo,
        borderColor: theme.white,
      }}
    >
      <p
        className="fontSize16 marBot0 twoLineTruncate"
        style={{
          color: theme.white,
        }}
      >
        {category.name}
      </p>
    </div>
  );
};

export default CategoryItem;
