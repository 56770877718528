import {fetchCustomersByDate, fetchOrdersByCustomer} from '../../pos-core/database/helpers';
import {useState} from 'react';

const useCustomerByDate = () => {
    const [customerOverview, setCustomerOverview] = useState({});
    const [loading, setLoading] = useState(true);

    const getCustomerOverview = async (currentStartDate, currentEndDate) => {
        try {
            let obj = {
                avg_spent_per_customer: 0,
                returning_customers: 0,
                conversion_rate: 0,
                total_customers: 0,
                new_customers: 0,
            };

            let total_ordered_customers = 0,
                total_revenue = 0;

            currentEndDate = currentEndDate || new Date(new Date().setHours(23, 59, 59, 999));
            currentStartDate = currentStartDate || new Date(new Date().setHours(0, 0, 0, 0));

            let customers = await fetchCustomersByDate(currentStartDate, currentEndDate);

            await Promise.all(
                customers.map(async cust => {
                    const orders = await fetchOrdersByCustomer(cust.id);

                    if (orders.length) {
                        if (orders.length > 1) obj['returning_customers'] += 1;
                        if (orders.length == 1) obj['new_customers'] += 1;

                        total_revenue += orders.reduce((total, val) => (total += val.total), 0);
                        total_ordered_customers += 1;
                    }
                }),
            );

            obj['conversion_rate'] = total_ordered_customers ? (total_ordered_customers / customers.length) * 100 : 0;
            obj['avg_spent_per_customer'] = total_revenue ? total_revenue / total_ordered_customers : 0;
            obj['total_customers'] = customers.length;

            setCustomerOverview(obj);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    return {
        getCustomerOverview,
        customerOverview,
        loading,
    };
};

export default useCustomerByDate;
