import React from "react";
import TimeList from "./TimeList";
import MessageModal from "../MessageModal";
import AppModal from "../AppModal";

const TimerModal = ({ timerModal, setTimerModal, handleSet, setTimer }) => {
  const handleClose = () => {
    setTimerModal(false);
    setTimer({});
  };
  return (
    <div>
      <AppModal
        hideIconText
        className="timerModal"
        toggle={timerModal}
        handleClose={handleClose}
        title={null}
        primaryCta={{
          title: "Set Timer",
          action: () => handleSet(),
        }}
      >
        <div>
          <TimeList setTimer={setTimer} />
        </div>
      </AppModal>
    </div>
  );
};

export default TimerModal;
