import { useEffect, useState } from "react";

const useTime = () => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => calculateTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  const calculateTime = () => {
    const now = new Date();
    const hours = now.getHours() % 12 || 12;
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const ampm = now.getHours() >= 12 ? "PM" : "AM";
    const currentTime = `${hours}:${minutes} ${ampm}`;
    setCurrentTime(currentTime);
  };

  return { currentTime };
};

export default useTime;
