const NonIdeal = () => {
  return (
    <div className="nonIdealBox">
      <p>
        <i className="posNonIdealIcon"></i>
      </p>
    </div>
  );
};

export default NonIdeal;
