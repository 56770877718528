import { useEffect, useState } from "react";

const useFilter = (handleFilter, archived) => {
  const [filteredList, setFilteredList] = useState([]);
  const [option, setOption] = useState("");

  useEffect(() => {
    handleFilteredList(option);
  }, [option, archived]);

  const handleFilteredList = async (val) =>
    setFilteredList(await handleFilter(val));

  return { filteredList, setOption, option };
};

export default useFilter;
