import {useLanguageContext, useThemeContext} from '../../../../../context';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {capitalizeFirstAlphabet, formatDate, formatNum} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {Dropdown} from '../../../../common';

const List = ({order, customer}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const tableAction = [
        {
            title: I18n.print_receipt,
            action: () => handleAction('print_receipt'),
        },
        {
            title: I18n.order_details,
            action: () => handleAction('details'),
        },
    ];

    const handleAction = async type => {
        switch (type) {
            case 'print_receipt':
                console.log('print_receipt');
                break;
            case 'details':
                navigateToNextScreen();
                break;
        }
    };

    function navigateToNextScreen() {
        navigate(`/orders/${order.number}`);
    }

    return (
        <TableBody className={`sevenColumn cursorPointer`} style={{borderColor: theme.posRightBg}} onClick={navigateToNextScreen}>
            <div className="BoxWidth justifyStart itemListBox">
                <p className="fontSize14" style={{color: theme.barclaysBlue}}>
                    {order.number}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDate(order.started_at)}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {customer?.name || '-'}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {capitalizeFirstAlphabet(order?.status) || '-'}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {I18n[order.payment_method] || '-'}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {formatNum(order.total)}
                </p>
            </div>

            <div
                className="BoxWidth justifyCenter"
                onClick={e => {
                    e.stopPropagation();
                }}>
                <div className="listActionBox">
                    <Dropdown
                        dropDown={{
                            name: 'threeDotIcon',
                            fill: theme.barclaysBlue,
                            width: 18,
                            height: 4,
                            viewBox: '0 0 18 4',
                            option: tableAction,
                        }}
                    />
                </div>
            </div>
        </TableBody>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    customer: order.customer.observe(),
    user: order.user.observe(),
}));

export default enhance(List);
