import {useLanguageContext, useThemeContext} from '../../../../context';
import RangePicker from '../../../common/RangePicker';
import {useCustomerByDate} from '../../../../hooks';
import {formatNum} from '../../../../constants';
import Icon from '../../../../assets/icons';
import React, {useEffect} from 'react';

const CustomerSummary = () => {
    const {getCustomerOverview, customerOverview, loading} = useCustomerByDate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    useEffect(() => {
        onFilterDate();
    }, []);

    const CUSTOMERS_OVERVIEW = [
        {
            title: I18n.total_customers,
            label: 'total_customers',
        },
        {
            title: I18n.new_customers,
            label: 'new_customers',
        },
        {
            title: I18n.returning_customers,
            label: 'returning_customers',
        },
        {
            title: I18n.avg_spent_per_customer,
            label: 'avg_spent_per_customer',
        },
        {
            title: I18n.conversion_rate,
            label: 'conversion_rate',
        },
    ];

    const onFilterDate = (label = 'Today', startDate = '', endDate = '') => getCustomerOverview(startDate, endDate);

    return (
        <>
            <div className="summaryConatiner">
                <div className="summaryBoxHeading">
                    <p className="fontSize24 fontWeight700 marBot0" style={{color: theme.white}}>
                        {I18n.customers}
                    </p>
                </div>

                {/* -------------Date filter calender------------- */}
                <RangePicker onFilterDate={onFilterDate} vs={false} />
                {/* -------------Date filter calender------------- */}

                {loading ? (
                    <span>Loading ...</span>
                ) : (
                    CUSTOMERS_OVERVIEW.map((item, index) => {
                        return (
                            <div key={index} className="customerSummaryRow">
                                <p className=" fontSize16 fontWeight400 marBot0" style={{color: theme.white}}>
                                    {item.title}
                                </p>

                                <p className="fontSize16 fontWeight400 marBot0" style={{color: theme.white}}>
                                    {item.title == I18n.avg_spent_per_customer ? 'Rs. ' : null}
                                    {formatNum(customerOverview[item.label])}
                                    {item.title == I18n.conversion_rate ? '%' : null}
                                </p>
                            </div>
                        );
                    })
                )}
            </div>
        </>
    );
};

export default CustomerSummary;
