import React from "react";
import Icon from "../../../assets/icons";
import { CATEGORY_COLORS } from "../../../constants";
import { useThemeContext } from "../../../context";

const ColorPicker = ({ selectedColor, setSelectedColor }) => {
  const { theme } = useThemeContext();

  const hanldleSelect = (colorCode) => {
    setSelectedColor(colorCode);
  };

  return (
    <div className="colorPalette">
      {CATEGORY_COLORS?.map((color, index) => {
        return (
          <div
            key={index}
            onClick={() => hanldleSelect(color.hexCode)}
            className="colorBox"
            style={{
              background: color.hexCode,
              borderColor:
                selectedColor === color.hexCode ? "black" : color.hexCode,
            }}
          >
            {selectedColor === color?.hexCode ? (
              <Icon
                name="tickCircleIcon"
                fill={theme.white}
                width={"24"}
                height={"24"}
                viewBox={"0 0 18 18"}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ColorPicker;
