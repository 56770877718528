import {useThemeContext} from '../../../context';
import CheckBox from '../CheckBox';

const List = ({location, selectedLocations, setSelectedLocations, locations, singleSelect = false}) => {
    const {theme} = useThemeContext();
    let locationId = location._raw?.id;

    const handleCheck = () => {
        setSelectedLocations(prev => {
            let obj = singleSelect ? {} : {...prev};

            if (locationId === 'all') {
                const allSelected = Object.keys(obj).length === locations.length;

                if (allSelected) {
                    obj = {};
                } else {
                    locations.forEach(item => {
                        obj[item._raw.id] = item._raw.id;
                    });
                }
            } else {
                let item = obj[locationId];
                if (item) {
                    delete obj[locationId];
                    delete obj['all'];
                } else {
                    obj[locationId] = locationId;
                }
            }

            return obj;
        });
    };

    return (
        <div className="locationModalList">
            <CheckBox
                className="locationListCheck"
                onChange={handleCheck}
                checked={selectedLocations?.[locationId]}
                checkboxLabel={
                    <p className="fontSize16 fontWeight500 marBot0" style={{color: theme.text}}>
                        {location.name}
                    </p>
                }
            />
        </div>
    );
};

export default List;
