import Icon from "../../../../assets/icons";
import { useThemeContext } from "../../../../context";
import NameSlice from "../../NameSlice";

const Listitem = ({ customer, onPress, order }) => {
  const { theme } = useThemeContext();

  let isSelected = order.customer.id == customer._raw.id ? true : false;

  const handleSelect = (customer) => {
    if (isSelected) {
      onPress("removeCustomer");
    } else {
      onPress(customer);
    }
  };
  return (
    <div
      className="selectCustomerInnerRow cursorPointer"
      onClick={() => handleSelect(customer)}
      style={{ borderColor: theme.darkSlateBlue2 }}
    >
      <div className="customerInitials">
        <NameSlice value={customer?.name || ""} />
      </div>
      <div className="customerDetailsBox">
        <span>
          <p className="marBot0 fontSize18" style={{ color: theme.white }}>
            {customer.name}
          </p>
          <p
            className="marBot0 fontSize14"
            style={{ color: theme.lightGrayTwo }}
          >
            {customer.phone_number}
          </p>
        </span>
        <span>
          {customer.available_points > 0 && (
            <>
              <Icon
                name="pointsIcon"
                fill={theme.mustardYellow}
                width={"24"}
                height={"24"}
                viewBox={"0 0 24 24"}
              />
              <p className="marBot0 fontSize18" style={{ color: theme.white }}>
                {`${customer.available_points} points`}
              </p>
            </>
          )}

          {isSelected && (
            <Icon
              name="tickCircleIcon"
              fill={theme.brightGreen}
              viewBox={"0 0 18 18"}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default Listitem;
