import {useAuthContext, useThemeContext} from '../../../context';
import {Button} from '../../common';
import Icon from '../../../assets/icons';
import NonIdealContainer from './NonIdealContainer';

const NonIdealScreen = ({
    heading,
    subHeading,
    paragraph,
    name,
    fill,
    width = '100',
    height = '100',
    viewBox = '0 0 18 18',
    isMultiStep = false,
    primaryCta,
    secondaryCta,
    skipText,
    backgroundColor,
    listNonideal = false,
    tablenonIdeal = false,
}) => {
    const {theme} = useThemeContext();
    const {business, user} = useAuthContext();

    return (
        <NonIdealContainer tablenonIdeal={tablenonIdeal} listNonideal={listNonideal} disabled={isMultiStep}>
            <div
                style={{
                    backgroundColor: backgroundColor || theme.blackBg,
                }}
                className={isMultiStep ? 'allScreenNonIdealInner onboardingNonideal' : 'allScreenNonIdealInner'}>
                <div className="nonIdealIconBox">
                    <Icon name={name} fill={fill} width={width} height={height} viewBox={viewBox} />
                </div>
                <div className="nonIdealHeadingBox">
                    <h1 className="fontSize24 fontWeight400 marBot0" style={{color: theme.white}}>
                        {heading}
                    </h1>
                </div>
                <div className="nonIdealSubHeadingBox">
                    <h3 className="fontSize16  marTop10 fontWieght400 marBot0" style={{color: theme.darkGrayTwo}}>
                        {subHeading}
                    </h3>
                    {paragraph && (
                        <p className="fontSize18 fontWeight400 marTop10 marBot0 width50 textCenter" style={{color: theme.darkGrayTwo}}>
                            {paragraph}
                        </p>
                    )}
                </div>
                <div className="nonIdealActionBox">
                    {secondaryCta?.title ? (
                        <Button
                            type="secondary"
                            className={`fontWeight600 fontSize16 cursorPointer marRight15`}
                            backgroundColor={theme.brightGreen}
                            txtColor={theme.white}
                            title={secondaryCta.title}
                            handleClick={secondaryCta.action}
                        />
                    ) : null}

                    {primaryCta && (
                        <Button
                            type="primaryButton"
                            className={`fontWeight600 fontSize16 cursorPointer`}
                            title={primaryCta.title}
                            backgroundColor={'transparent'}
                            borderColor={theme.barclaysBlue}
                            handleClick={primaryCta.action}
                        />
                    )}
                </div>
                {skipText && (
                    <p onClick={skipText?.action} className="fontSize14 fontWeight400 marTop10 marBot0 width50 textCenter cursorPointer" style={{color: theme.barclaysBlue}}>
                        {skipText.title}
                        <Icon name={'forwardNewIcon'} fill={theme.barclaysBlue} width={'15'} height={'15'} viewBox={'0 0 18 18'} />
                    </p>
                )}
            </div>
        </NonIdealContainer>
    );
};

export default NonIdealScreen;
