import { Col, Container, Row } from "react-bootstrap";
import TableHeader from "../TableContainer/TableHeader";
import { useLanguageContext, useThemeContext } from "../../../context";
import { DEVICE_HEIGHT, taxCsvDatavalidate } from "../../../constants";
import CsvList from "./CsvList";
import { useEffect } from "react";

const TaxCSVTable = ({ data, setErrors, errors }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    useEffect(() => {
        if (data.length) {
            handleError()
        }
    }, [data])

    const handleError = () => {
        const formErrors = taxCsvDatavalidate(data)
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        }
    }

    return (
        <>
            <div
                className="itemListContainer"
                fluid
                style={{ background: theme.topBarBG, height: DEVICE_HEIGHT - 110 }}
            >
                <Container fluid>
                    <Row>
                        <Col>
                            <TableHeader
                                className={`sixColumn`}
                                style={{ borderColor: theme.posRightBg }}
                            >
                                <div className="BoxWidth justifyStart">
                                    <p
                                        className="fontSize16 OneLineTruncate"
                                        style={{ color: theme.white }}
                                    >
                                        {I18n.name}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p
                                        className="fontSize16 OneLineTruncate"
                                        style={{ color: theme.white }}
                                    >
                                        {I18n.type}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p
                                        className="fontSize16 OneLineTruncate"
                                        style={{ color: theme.white }}
                                    >
                                        {I18n.rate}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p
                                        className="fontSize16 OneLineTruncate"
                                        style={{ color: theme.white }}
                                    >
                                        {I18n.taxation_behaviour}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p
                                        className="fontSize16 OneLineTruncate"
                                        style={{ color: theme.white }}
                                    >
                                        {I18n.applied_on}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p
                                        className="fontSize16 OneLineTruncate"
                                        style={{ color: theme.white }}
                                    >
                                        {I18n.tax_calculation}
                                    </p>
                                </div>


                            </TableHeader>

                            <div
                                className="tableListMainBox"
                                style={{ height: DEVICE_HEIGHT - 220 }}
                            >
                                {data?.map((val, index) => (
                                    <CsvList index={index} errors={errors} value={val} />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default TaxCSVTable;
