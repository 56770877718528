import {Col, Container, Row} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBodyList from './TableBodyList';
import {DEVICE_HEIGHT, UNITS, toSnakeCase, validateCreateItemVariantForm} from '../../../constants';
import {useState} from 'react';
import {useVariationList} from '../../../hooks';
import TextField from '../TextField';
import CheckBox from '../CheckBox';
import withObservables from '@nozbe/with-observables';
import {PosAppModal} from '../V2';
import {observeSalesTax} from '../../../pos-core/database/helpers';

const VariationListModal = ({toggle, setToggle, variants, updateVariantList, sales_tax}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {variations, setVariations} = useVariationList(variants);
    const [unit, setUnit] = useState();
    const [tax, setTax] = useState();
    const [isTaxable, setIsTaxable] = useState(false);
    const [isTrackable, setIsTrackable] = useState(false);
    const [secondaryUnit, setSecondaryUnit] = useState();
    const [rate, setRate] = useState(0);
    const [errors, setErrors] = useState({});

    let HEADER = [I18n.variations, I18n.barcode, I18n.quantity, I18n.cost_price, I18n.sell_price, I18n.low_stock];
    const handleClose = event => {
        setToggle(false);
    };
    const handleDiscard = () => {
        handleClose();
    };
    const onChange = (label, value) => {
        if (label == I18n.unit) setUnit(value.name);
        else if (label == I18n.tax_value) setTax(value);
        else if (label == I18n.secondary_unit) setSecondaryUnit(value.name);
        else if (label == I18n.rate) setRate(value);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const onChangeDetails = (title, label, value) => {
        setVariations(data => {
            let obj = {...data};
            obj[title][label] = value;
            return obj;
        });
    };

    const handleSave = async () => {
        let updates = {
            variants: variations,
            tax,
            unit,
            isTaxable,
            isTrackable,
            secondaryUnit,
            rate,
        };

        const formErrors = await validateCreateItemVariantForm(updates);
        console.log('form Errors', formErrors);
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) {
            return;
        }

        updateVariantList(updates);
    };

    const toggleTaxable = () => setIsTaxable(x => !x);
    const toggleTrackable = () => setIsTrackable(x => !x);

    return (
        <PosAppModal
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            className="selectCustomerModal"
            toggle={toggle}
            handleClose={handleClose}
            title={I18n.variation_list}
            bottomCancelCta={{
                title: I18n.discard,
                action: handleDiscard,
            }}
            bottomSaveCta={{
                title: I18n.save,
                action: handleSave,
            }}>
            <div
                className="selectCustomerListBox"
                style={{
                    height: DEVICE_HEIGHT - 250,
                }}>
                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            <div className="loginFormBox">
                                <div className="variationListTableHeader" style={{borderColor: theme.seperatorBg}}>
                                    {HEADER.map((value, index) => {
                                        return (
                                            <div
                                                className={`${index == 0 ? 'variationsHeaderColFirstLeft' : ''}
                           variationsHeaderColFirst variationTableHeaderCol`}
                                                style={{borderColor: theme.seperatorBg}}>
                                                <p className="fontSize16 marBot0" style={{color: theme.white}}>
                                                    {value}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tableBodyMain" style={{height: DEVICE_HEIGHT * 0.5}}>
                                    {Object.keys(variations).map((value, index) => (
                                        <TableBodyList title={value} data={variations[value]} onChange={onChangeDetails} errors={errors} />
                                    ))}
                                </div>
                                <p style={{background: theme.topBarBG}} className="itemSeperator"></p>
                                <div className="variantListBottom">
                                    <div className="variantListBottomLeft">
                                        <h3 className="fontSize20 fontWeight500 marBot10 marTop0 textLeft width100" style={{color: theme.text}}>
                                            {I18n.tax_value}
                                        </h3>
                                        {/* <TextField
                    label={I18n.category}
                    placeholder={I18n.category}
                    onChange={onChange}
                    //   data={categoryList}
                    //   value={category?.name}
                    //   errors={errors.category}
                    inputType="select"
                    type="text"
                    suffix="downIcon"
                    suffix_fill={"blackFont"}
                    suffix_width={"14"}
                    suffix_height={"11"}
                    suffix_viewBox={"0 0 8 5"}
                  /> */}
                                        <TextField
                                            label={I18n.tax_value}
                                            placeholder={I18n.tax_value}
                                            onChange={onChange}
                                            data={[
                                                // {
                                                //   name: I18n.create_tax,
                                                //   color: theme.barclaysBlue,
                                                // },
                                                ...sales_tax,
                                            ]}
                                            highlighter={true}
                                            value={tax?.name || ''}
                                            // errors={errors.tax_value}
                                            inputType="select"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                        <div className="itemScreenCheckbox">
                                            <CheckBox
                                                checkboxLabel={
                                                    <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                        {I18n.charge_tax}
                                                    </p>
                                                }
                                                onChange={toggleTaxable}
                                                checked={isTaxable}
                                            />
                                        </div>
                                    </div>
                                    <div className="variantListBottomRight">
                                        <h3 className="fontSize20 fontWeight500 marBot10 marTop0 textLeft width100" style={{color: theme.text}}>
                                            {I18n.inventory}
                                        </h3>
                                        <div>
                                            <TextField
                                                label={I18n.unit}
                                                placeholder={I18n.unit}
                                                onChange={onChange}
                                                data={UNITS}
                                                highlighter={true}
                                                value={unit}
                                                error={errors['unit']}
                                                inputType="select"
                                                type="text"
                                                suffix="downIcon2"
                                                suffix_fill={theme.white}
                                                suffix_width={'24'}
                                                suffix_height={'24'}
                                                suffix_viewBox={'0 0 18 18'}
                                            />
                                            <TextField
                                                label={I18n.secondary_unit}
                                                placeholder={I18n.secondary_unit}
                                                onChange={onChange}
                                                data={UNITS}
                                                highlighter={true}
                                                value={secondaryUnit}
                                                inputType="select"
                                                type="text"
                                                suffix="downIcon2"
                                                suffix_fill={theme.white}
                                                suffix_width={'24'}
                                                suffix_height={'24'}
                                                suffix_viewBox={'0 0 18 18'}
                                            />
                                        </div>
                                        {unit && secondaryUnit && (
                                            <div className="conversionRateSectionRight">
                                                <div className="">
                                                    <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                        {`1 ${unit} =`}
                                                    </p>
                                                </div>
                                                <div className="primaryRateSection">
                                                    <TextField type="numeric" onChange={onChange} error={errors['rate']} label={I18n.rate} placeholder={0} value={rate} />
                                                </div>
                                                <div className="">
                                                    <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                        {secondaryUnit}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <div className="itemScreenCheckbox">
                                            <CheckBox
                                                checkboxLabel={
                                                    <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                        {I18n.track_quantity}
                                                    </p>
                                                }
                                                onChange={toggleTrackable}
                                                checked={isTrackable}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </PosAppModal>
    );
};

const enhance = withObservables([], () => ({
    sales_tax: observeSalesTax(),
}));
export default enhance(VariationListModal);
