import TextField from '../TextField';
import TableBody from '../TableContainer/TableBody';
import {toSnakeCase} from '../../../constants';
import Icon from '../../../assets/icons';
import withObservables from '@nozbe/with-observables';
import {switchMap} from 'rxjs';

const List = ({I18n, theme, item, purchaseTax, updateListItem, handleDeleteItem, errors, disablFields, product_template}) => {
    const {unit, secondary_unit, conversion_rate} = product_template;

    let isErrorField = Object.keys(errors?.poLines?.[item.id] || {}).length > 0 ? true : false;
    const handleListItem = (label, val) => {
        updateListItem(toSnakeCase(label), val, item.id);
    };

    const calculateAmount = (quantity, unitPrice) => {
        if (quantity && unitPrice) {
            return quantity * unitPrice;
        }
        return 0;
    };

    const aggregateUnits = () => {
        const units = [{name: unit}, {name: secondary_unit}];
        return units;
    };

    return (
        <>
            <TableBody className="potableColume">
                <div className="BoxWidth justifyStart itemListBox">
                    <TextField onChange={handleListItem} label={I18n.items} value={item?.name || ''} disabled={true} />
                </div>
                <div className="BoxWidth">
                    <TextField
                        onChange={handleListItem}
                        label={I18n.qty}
                        placeholder={0}
                        onlyNumbers={true}
                        error={errors?.poLines?.[item.id]?.[toSnakeCase(I18n.qty)]}
                        value={item?.qty}
                        disabled={disablFields}
                    />
                </div>
                <div className="BoxWidth">
                    <TextField
                        onChange={handleListItem}
                        label={I18n.unit}
                        placeholder={0}
                        data={aggregateUnits()}
                        value={item?.unit}
                        inputType="select"
                        type="text"
                        suffix="downIcon2"
                        suffix_fill={theme.white}
                        suffix_width={'18'}
                        suffix_height={'18'}
                        suffix_viewBox={'0 0 18 18'}
                        disabled={disablFields || !product_template?.secondary_unit}
                    />
                </div>
                <div className="BoxWidth ">
                    <TextField
                        onChange={handleListItem}
                        error={errors?.poLines?.[item.id]?.[toSnakeCase(I18n.unit_cost)]}
                        label={I18n.unit_cost}
                        onlyNumbers={true}
                        placeholder={0}
                        value={item?.unit_cost || ''}
                        disabled={disablFields}
                    />
                </div>

                <div className="BoxWidth">
                    <TextField onChange={handleListItem} onlyNumbers={true} label={I18n.discount} placeholder={0} value={item?.discount || ''} disabled={disablFields} />
                </div>
                <div className="BoxWidth">
                    <TextField
                        onChange={handleListItem}
                        label={I18n.taxes}
                        placeholder={0}
                        data={[...purchaseTax]}
                        value={item?.taxes?.name}
                        inputType="select"
                        type="text"
                        suffix="downIcon2"
                        suffix_fill={theme.white}
                        suffix_width={'18'}
                        suffix_height={'18'}
                        suffix_viewBox={'0 0 18 18'}
                        disabled={disablFields}
                    />
                </div>
                <div className="BoxWidth">
                    <TextField onChange={handleListItem} label={I18n.amount} placeholder={0} value={calculateAmount(item?.qty, item?.unit_cost)} disabled={true} />
                </div>
                <div className="pad10 deleteIcon cursorPointer">
                    <Icon name="delete2Icon" fill={theme.red} width="20" height="20" viewBox={'0 0 18 18'} onClick={() => !disablFields && handleDeleteItem(item?.id)} />
                </div>
            </TableBody>

            {isErrorField && <p className="inputError">{I18n.po_item_error}</p>}
        </>
    );
};

const enhance = withObservables(['item'], ({item}) => ({
    product_template: item.product.observe().pipe(switchMap(product => product.product_template.observe())),
}));

export default enhance(List);
