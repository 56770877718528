import { Toast } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useThemeContext } from "../../../context";
const WithOutHeaderToast = ({ bodyPara, ToastVisiable = false, className }) => {
  const { theme } = useThemeContext();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(ToastVisiable);
  }, [ToastVisiable]);
  return (
    <>
      {ToastVisiable ? (
        <Toast
          autohide={true}
          delay={3000}
          onClose={() => setShow(false)}
          className={`toastMainBox${className}`}
          show={show}
          style={{ borderColor: theme.blueTxt }}
        >
          <Toast.Body style={{ color: theme.blackFont }}>
            <p className="fontSize16 me-auto marBot0 fontWeight500">
              <span className="marRight15" style={{ color: theme.red }}>
                Error
              </span>
              {bodyPara}
            </p>
          </Toast.Body>
        </Toast>
      ) : null}
    </>
  );
};
export default WithOutHeaderToast;
