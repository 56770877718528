import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import withObservables from "@nozbe/with-observables";
import { TabBox } from "../../../../common";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import Cockpit from "./Cockpit";
import Dinein from "./Dinein";
import { DEVICE_HEIGHT, cockpitOrders } from "../../../../../constants";
import Icon from "../../../../../assets/icons";
import { observeActiveFloorPlan } from "../../../../../pos-core/database/helpers/floor_plan";
import { observeActiveOrder } from "../../../../../pos-core/database/helpers";

const PosCockpit = ({ floorPlans, orders }) => {
  let navigate = useNavigate();

  const [tabValue, setTabVal] = useState(0);
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [tabs, setTabs] = useState([
    { id: "cockpit", name: "POS Cockpit", type: "cockpit", archive: false },
  ]);
  const [tabComponent, setTabComponent] = useState(tabs[0]);
  let order = orders[0];

  const handleChange = (event, newValue) => {
    const clickedTab = tabs[newValue];
    setTabVal(newValue);
    setTabComponent(clickedTab);
  };

  useEffect(() => {
    setTabs((prev) => [...prev, ...floorPlans]);
  }, []);

  return (
    <>
      <Row style={{ margin: "0px", height: DEVICE_HEIGHT - 250 }}>
        <Col
          md={12}
          lg={12}
          style={{
            padding: "0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TabBox
            tabValue={tabValue}
            tabs={tabs}
            tabsObj={true}
            handleChange={handleChange}
            className="pos-cockpit-tabs"
          />

          <div
            className="addTableIconWrapper"
            onClick={() => navigate("/create-floor-plan")}
          >
            <Icon
              name={"add2Icon"}
              width="30"
              height="30"
              viewBox={"0 0 18 18"}
              fill={theme.white}
            />
          </div>
        </Col>
        <Col md={12} lg={12} style={{ padding: "0px" }}>
          {tabComponent &&
            (tabComponent.type === "dinein" ? (
              <Dinein floorData={tabComponent} activeOrder={order} />
            ) : (
              <Cockpit
                floorData={tabComponent}
                activeOrder={order}
                setTabComponent={setTabComponent}
                setTabVal={setTabVal}
              />
            ))}
        </Col>
      </Row>
    </>
  );
};

const enhance = withObservables([], () => ({
  floorPlans: observeActiveFloorPlan(),
  orders: observeActiveOrder(),
}));

export default enhance(PosCockpit);
