import React from "react";
import { TextField } from "../../../../common";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import { CITY, toSnakeCase } from "../../../../../constants";

const CreateDevice = ({
  name,
  setName,
  location,
  setLocation,
  setErrors,
  errors,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const onChange = async (label, val) => {
    if (label == I18n.device_name) {
      setName(val);
    } else if (label == I18n.location) setLocation(val);

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }
      return updatedErrors;
    });
  };

  return (
    <div className="createDeviceWrapper">
      <div className="createDeviceFieldsWrapper">
        <p
          className="fontSize24 textCenter fontWeight400"
          style={{ color: theme.white }}
        >
          {I18n.new_kitchen_display}
        </p>
        <TextField
          onChange={onChange}
          error={errors["device_name"]}
          label={I18n.device_name}
          placeholder={I18n.device_name}
          value={name}
        />

        <TextField
          label={I18n.location}
          error={errors["location"]}
          placeholder={I18n.location}
          onChange={onChange}
          data={CITY}
          highlighter={true}
          value={location}
          inputType="select"
          type="text"
          suffix="downIcon"
          suffix_fill={"blackFont"}
          suffix_width={"14"}
          suffix_height={"11"}
          suffix_viewBox={"0 0 8 5"}
        />
      </div>
    </div>
  );
};

export default CreateDevice;
