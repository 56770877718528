import React, { useState } from "react";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../context";
import { FormCheck } from "react-bootstrap";
import TimerModal from "../../../common/TimerModal";
import { updateDeviceConfiguration } from "../../../../api";

const TimerSetting = () => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();
  const { deviceConfiguration, handleDeviceCofiguration } = useAuthContext();
  const [timerModal, setTimerModal] = useState(false);
  const [timer, setTimer] = useState({});
  const [isSoundActiveOnNewOrder, setIsSoundActiveOnNewOrder] = useState(
    deviceConfiguration?.configuration?.play_sound_order_arrived
  );
  const [isSoundActiveOnOrderCancel, setIsSoundActiveOnOrderCancel] = useState(
    deviceConfiguration?.configuration?.play_sound_order_cancelled
  );

  const HandleToggle = async (label, e) => {
    const value = e.target.checked;
    const deviceId = deviceConfiguration?.id;

    if (label === "play_sound_order_arrived") {
      setIsSoundActiveOnNewOrder(value);
    }
    if (label === "play_sound_order_cancelled") {
      setIsSoundActiveOnOrderCancel(value);
    }

    const devicePayload = {
      [label]: value,
    };

    const response = await updateDeviceConfiguration(devicePayload, deviceId);
    handleDeviceCofiguration(response?.data);
    console.log("response", response);
  };

  const handleSet = async () => {
    const deviceId = deviceConfiguration?.id;
    try {
      if (Object.keys(timer).length < 2) return;
      const { label, value } = timer;
      const devicePayload = {
        [label]: value,
      };
      const response = await updateDeviceConfiguration(devicePayload, deviceId);
      handleDeviceCofiguration(response?.data);
      setTimerModal(false);
      setTimer({});
    } catch (error) {
      console.log("error", error);
    }
  };

  const openTimerModal = (label) => {
    setTimerModal(true);
    setTimer((prev) => ({ ...prev, label: label }));
  };

  return (
    <>
      <div className="timerConfigurationWrapper">
        <p
          className="marBot0 fontSize20 fontWeight600"
          style={{ color: theme.white }}
        >
          {I18n.activate_ticket_timer_after}
        </p>

        <div className="flex width20 marTop40 marBot20">
          <div className="timerColorBox brightGreenBg"></div>
          <div className="flex justifySpacebetween padLeft20 width100">
            <p
              className="marBot0 fontSize14 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.green_timer}
            </p>
            <p
              className="marBot0 fontSize14 fontWeight400 cursorPointer"
              onClick={() => openTimerModal("green_timer")}
              style={{ color: theme.barclaysBlue }}
            >
              {`${deviceConfiguration?.configuration?.green_timer} minutes`}
            </p>
          </div>
        </div>

        <div className="flex width20 marTop20 marBot20">
          <div className="timerColorBox orangeBg"></div>
          <div className="flex justifySpacebetween padLeft20 width100">
            <p
              className="marBot0 fontSize14 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.yellow_timer}
            </p>
            <p
              className="marBot0 fontSize14 fontWeight400 cursorPointer"
              onClick={() => openTimerModal("yellow_timer")}
              style={{ color: theme.barclaysBlue }}
            >
              {`${deviceConfiguration?.configuration?.yellow_timer} minutes`}
            </p>
          </div>
        </div>

        <div className="flex width20 marTop20 marBot20">
          <div className="timerColorBox redBg"></div>
          <div className="flex justifySpacebetween padLeft20 width100">
            <p
              className="marBot0 fontSize14 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.red_timer}
            </p>
            <p
              className="marBot0 fontSize14 fontWeight400 cursorPointer"
              style={{ color: theme.barclaysBlue }}
              onClick={() => openTimerModal("red_timer")}
            >
              {`${deviceConfiguration?.configuration?.red_timer} minutes`}
            </p>
          </div>
        </div>

        <p className="notificationHeadSeperator"></p>

        <div>
          <p
            className="marBot30 marTop30 fontSize20 fontWeight600"
            style={{ color: theme.white }}
          >
            {I18n.play_sound}
          </p>

          <div className="flex width20 marTop20 marBot20 justifySpacebetween alignCenter">
            <p
              className="marBot0 fontSize14 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.play_sound_when_new_order_arrives}
            </p>
            <FormCheck
              id="play_sound_order_arrived"
              type="switch"
              onChange={(e) => HandleToggle("play_sound_order_arrived", e)}
              checked={isSoundActiveOnNewOrder}
            />
          </div>

          <div className="flex width20 marTop20 marBot20 justifySpacebetween alignCenter">
            <p
              className="marBot0 fontSize14 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.play_sound_when_item_or_order_cancelled}
            </p>
            <FormCheck
              type="switch"
              id="play_sound_order_cancelled"
              onChange={(e) => HandleToggle("play_sound_order_cancelled", e)}
              checked={isSoundActiveOnOrderCancel}
            />
          </div>
        </div>
      </div>

      <TimerModal
        timerModal={timerModal}
        setTimerModal={setTimerModal}
        handleSet={handleSet}
        setTimer={setTimer}
      />
    </>
  );
};

export default TimerSetting;
