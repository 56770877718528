import { Model } from '@nozbe/watermelondb';
import { field, text, relation, children, writer, date, lazy } from '@nozbe/watermelondb/decorators';
import { ITEM_SCHEMA } from '../schema';

export default class Item extends Model {
    static table = ITEM_SCHEMA;

    static associations = {
        business: { type: 'belongs_to', key: 'business_id' },
        category: { type: 'belongs_to', key: 'category_id' },
        
    }

    @text('name') name;
    @text('description') description;
    @text('image') image;
    @text('barcode') barcode;
    @field('selling_price') selling_price;
    @field('cost_price') cost_price;
    @field('profit') profit;
    @field('margin') margin;
    @field('quantity') quantity;
    @field('low_stock') low_stock;
    @field('is_taxable') is_taxable;
    @field('is_trackable') is_trackable;

    @relation('business', 'business_id') business;
    @relation('category', 'category_id') category;
}