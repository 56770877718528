import React from "react";
import { Accordion, Nav } from "react-bootstrap";
import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const HamburgerSubItems = ({ hamburgerTitle, hamburgerIcon, subItems }) => {
  const { theme } = useThemeContext();

  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Icon
              viewBox={"0 0 18 18"}
              name={hamburgerIcon}
              fill={"white"}
              height={"24"}
              width={"24"}
            />
            <span
              className="fontSize14 marLeft20"
              style={{ color: theme.white }}
            >
              {hamburgerTitle}
            </span>
          </Accordion.Header>
          {subItems.map((subItem) => (
            <Accordion.Body>
              <Nav.Item>
                <Nav.Link
                  eventKey={subItem.eventKey}
                  style={{
                    textDecoration: "none",
                    color: "#ffff",
                    padding: "0px",
                  }}
                >
                  {subItem.name}
                </Nav.Link>
              </Nav.Item>
            </Accordion.Body>
          ))}
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default HamburgerSubItems;
