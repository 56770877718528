import { Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import Icon from "../../../assets/icons";
import Button from "../../common/Button";
import { useLanguageContext, useThemeContext } from "../../../context";

const NewAppModal = ({
  children,
  toggle,
  handleClose,
  className,
  title,
  rightCta,
  backCta,
  primaryCta,
  secondaryCta,
  cancelCta,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <Modal
      dialogClassName={"animate-bottom"}
      className={`${className}`}
      backdropClassName="modal-backdrop-custom"
      onHide={() => handleClose()}
      keyboard={false}
      show={toggle}
      centered
      scrollable={true}
    >
      <ModalHeader>
        <div className="modal-head-wrapper">
          <div className="modalHeaderLeftSide">
            <span
              onClick={() => handleClose()}
              className="cursorPointer moddalheaderleftIconBox"
            >
              <Icon
                name={backCta.name}
                fill={backCta.fill}
                height={backCta.height}
                width={backCta.width}
                viewBox={backCta.viewBox}
              />
            </span>
            {title ? (
              <span>
                <p
                  className="marBot0 fontSize24  fontWeight400"
                  style={{ color: theme.white }}
                >
                  {title}
                </p>
              </span>
            ) : null}
          </div>

          {rightCta && (
            <div className="modalHeaderRightSide">
              <Button
                type="buttonTitleIcon"
                handleClick={rightCta.action}
                className={`cursorPointer addCustomerBtn`}
                title={rightCta.title}
                backgroundColor={"transparent"}
                txtColor={theme.barclaysBlue}
                icon_name={"add2Icon"}
                icon_fill={theme.barclaysBlue}
                icon_width={"18"}
                icon_height={"18"}
                icon_viewBox={"0 0 18 18"}
              />
            </div>
          )}
        </div>
        <div className="flex justifyCenter">
          <div className="head-bottom-border" />
        </div>
      </ModalHeader>

      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <div className="posAppmodalBottom">
          {cancelCta && (
            <span className="cancelCtaButton">
              <Button
                saveLoad={cancelCta.saveLoad}
                title={cancelCta.title}
                handleClick={cancelCta.action}
                disabled={cancelCta?.disabled}
                borderColor={theme.darkSlateBlue}
                backgroundColor={theme.midnightBlue}
                className={`fontWeight500 fontSize14 cursorPointer marBot0 marRight20`}
              />
            </span>
          )}
          {secondaryCta && (
            <span className="secondaryCtaButton">
              <Button
                saveLoad={secondaryCta.saveLoad}
                title={secondaryCta.title}
                handleClick={secondaryCta.action}
                disabled={secondaryCta?.disabled}
                borderColor={theme.barclaysBlue}
                backgroundColor={theme.midnightBlue}
                className={`fontWeight500 fontSize14 cursorPointer marBot0 marRight20`}
              />
            </span>
          )}
          {primaryCta && (
            <span className="primaryCtaButton">
              <Button
                saveLoad={primaryCta.saveLoad}
                title={primaryCta.title}
                handleClick={primaryCta.action}
                disabled={primaryCta?.disabled}
                className={`fontWeight500 fontSize14 cursorPointer marBot0 marRight20`}
              />
            </span>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default NewAppModal;
