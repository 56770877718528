import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../../context";

function VerificationModal({ toggle, setToggle, title }) {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();
  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => {
    setToggle(false);
  };

  return (
    <div>
      <Modal
        className="verifiySpinerModal"
        show={toggle}
        onHide={handleCloseModal}
        centered
        backdrop
      >
        <Modal.Body>
          <div className="flexRow">
            <Spinner animation="border" className="modalSpinner" />

            <p
              className="fontWeight600 fontSize24 marBot0 marLeft20"
              style={{ color: theme.white }}
            >
              {title || I18n.loading}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VerificationModal;
