import {Model} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date, lazy} from '@nozbe/watermelondb/decorators';
import {uuid} from '../../constants';
import {PRODUCT_SCHEMA, PRODUCT_TEMPLATE_SCHEMA} from '../schema';

export default class ProductTemplate extends Model {
    static table = PRODUCT_TEMPLATE_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        category: {type: 'belongs_to', key: 'category_id'},
        product: {type: 'has_many', foreignKey: 'product_template_id'},
    };

    @text('name') name;
    @text('description') description;
    @text('image') image;
    @text('unit') unit;
    @field('is_taxable') is_taxable;
    @field('is_trackable') is_trackable;
    @field('secondary_unit') secondary_unit;
    @field('conversion_rate') conversion_rate;

    @children('product') product;

    @relation('account', 'account_id') account;
    @relation('category', 'category_id') category;

    @writer async createProduct(details) {
        return await this.collections.get(PRODUCT_SCHEMA).create(product => {
            product.product_template.set(this);
            product.account.set(details.account);
            details.category && product.category.set(details.category);
            details.tax && product.sales_tax.set(details.tax);
            product._raw.id = uuid();
            product.name = details.name;
            product.selling_price = details.selling_price;
            product.base_price = details.base_price;
            product.cost_price = details.cost_price;
            product.profit = details.profit;
            product.margin = details.margin;
            product.quantity = details.quantity;
            product.barcode = details.barcode;
            product.low_stock = details.low_stock;
            product.product_level_discount = details.item_level_discount;
            product.created_at = new Date().getTime();
            product.draft = details.draft;
        });
    }

    @writer async updateDetails(details) {
        return await this.update(template => {
            template.description = details.description;
            template.image = details.image;
            template.unit = details.unit;
            template.is_taxable = details.is_taxable;
            template.is_trackable = details.is_trackable;
            template.secondary_unit = details.secondaryUnit;
            template.conversion_rate = details.rate;
        });
    }

    @writer async updateImage(url) {
        return await this.update(template => {
            template.image = url;
        });
    }
}
