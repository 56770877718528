import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import Icon from "../../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../../context";
import Button from "../../Button";
import ToastBox from "../../ToastBox";

const PosAppModal = ({
  children,
  toggle,
  handleClose,
  className,
  title,
  rightCta,
  backCta,
  bottomSaveCta,
  bottomCancelCta,
  toastBox,
  hideFooter = false,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [showMessage, setShowMessage] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    setShowMessage({
      visible: true,
      message: toastBox?.message,
    });
  }, [toastBox]);

  useEffect(() => {
    if (showMessage.visible) {
      const timeoutId = setTimeout(() => {
        setShowMessage({
          visible: false,
          message: "",
        });
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [showMessage]);

  return (
    <Modal
      dialogClassName={"animate-bottom"}
      className={`${className}`}
      onHide={() => handleClose()}
      keyboard={false}
      show={toggle}
      centered
      scrollable={true}
    >
      <ModalHeader>
        <div className="modalHeaderLeftSide">
          {backCta && (
            <span
              onClick={() =>
                backCta.action ? backCta.action() : handleClose()
              }
              className="cursorPointer moddalheaderleftIconBox"
            >
              <Icon
                name={backCta.name}
                fill={backCta.fill}
                height={backCta.height}
                width={backCta.width}
                viewBox={backCta.viewBox}
              />
            </span>
          )}
          {title ? (
            <span>
              <p
                className="marBot0 fontSize18  fontWeight400"
                style={{ color: theme.white }}
              >
                {title}
              </p>
            </span>
          ) : null}
        </div>

        {rightCta && (
          <div className="modalHeaderRightSide">
            <Button
              type="buttonTitleIcon"
              handleClick={rightCta.action}
              className={`cursorPointer addCustomerBtn`}
              title={rightCta.title}
              backgroundColor={"transparent"}
              txtColor={theme.barclaysBlue}
              icon_name={rightCta.iconName}
              icon_fill={rightCta.fill}
              icon_width={rightCta.width}
              icon_height={rightCta.height}
              icon_viewBox={rightCta.viewBox}
            />
          </div>
        )}
      </ModalHeader>

      <ModalBody>{children}</ModalBody>
      {hideFooter ? null : (
        <ModalFooter>
          <div className="posAppmodalBottom">
            {bottomCancelCta && (
              <span className="bottomButton">
                <Button
                  saveLoad={bottomCancelCta.saveLoad}
                  title={bottomCancelCta.title}
                  handleClick={bottomCancelCta.action}
                  disabled={bottomCancelCta?.disabled}
                  borderColor={theme.darkSlateBlue}
                  backgroundColor={theme.midnightBlue}
                  className={`fontWeight600 fontSize16 cursorPointer marBot0 marRight20`}
                />
              </span>
            )}
            {bottomSaveCta && (
              <span className="bottomButton">
                <Button
                  saveLoad={bottomSaveCta.saveLoad}
                  title={bottomSaveCta.title}
                  handleClick={bottomSaveCta.action}
                  disabled={bottomSaveCta?.disabled}
                  className={`fontWeight600 fontSize16 cursorPointer marBot0 marRight20`}
                />
              </span>
            )}
          </div>
          {toastBox && (
            <ToastBox
              className={`addSuccessfullyToast ${
                toastBox?.errorToast ? "errorToastMessage" : ""
              } `}
              ToastVisiable={showMessage?.visible}
              bodyPara={showMessage?.message}
              setShowMessage={setShowMessage}
              showIcon={true}
              errorToast={toastBox?.errorToast}
            />
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default PosAppModal;
