import withObservables from '@nozbe/with-observables';
import {useLanguageContext, useThemeContext} from '../../../context';
import {observeActiveOrder} from '../../../pos-core/database/helpers';
import {useNavigate} from 'react-router-dom';
import TableBody from '../TableContainer/TableBody';
import {formatDateAndTimewithoutYear, formatNum} from '../../../constants';

const Listitem = ({order, customer, activeOrders, handleClose, handleHeldOrderCount, setShowMessage, isRestaurantPos}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    let navigate = useNavigate();

    const handleArchiveOrder = async () => {
        await order.archiveOrder();
        handleHeldOrderCount();
        handleClose();
    };

    const handleActiveOrder = async () => {
        if (isRestaurantPos) {
            navigate(`/restaurant-pos?id=${order?.number}`);
        } else {
            const activeOrder = activeOrders[0];
            await order.updateOrderStatus('active');
            await activeOrder.archiveOrder();
            setShowMessage({
                visible: true,
                message: I18n.order_resumed_successfully,
            });

            handleHeldOrderCount();
            handleClose();
        }
    };

    return (
        <TableBody className={`fiveColumn`} style={{borderColor: theme.posRightBg}}>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {customer?._raw?.name ? customer?._raw?.name : '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDateAndTimewithoutYear(order.completed_at)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {`Order ${order?.number}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {formatNum(order?.total)}
                </p>
            </div>
            <div className="BoxWidth justifyevenly cursorPointer">
                <p style={{color: theme.barclaysBlue}} onClick={handleActiveOrder} className="fontSize14 fontWeight400">
                    {I18n.resume_order}
                </p>
                <p className="fontSize14 fontWeight400 cursorPointer" style={{color: theme.red}} onClick={handleArchiveOrder}>
                    {I18n.delete}
                </p>
            </div>
        </TableBody>
    );
};
const enhance = withObservables(['order'], ({order}) => ({
    activeOrders: observeActiveOrder(),
    customer: order.customer.observe(),
}));

export default enhance(Listitem);
