const WebSocketService = {
  socket: null,
  messageCallback: null,

  connect: (url, callback) => {
    WebSocketService.socket = new WebSocket(url);
    WebSocketService.messageCallback = callback;

    WebSocketService.socket.onopen = () => {
      console.log("WebSocket connected");
    };

    WebSocketService.socket.onclose = (event) => {
      console.error("WebSocket closed:", event);

      setTimeout(() => {
        console.log("Attempting to connect ...", url);
        WebSocketService.connect(url, callback);
      }, 1000);
    };

    WebSocketService.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    WebSocketService.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("WebSocket message received:", data);

      if (WebSocketService.messageCallback) {
        WebSocketService.messageCallback(data);
      }
    };
  },

  disconnect: () => {
    if (WebSocketService.socket) {
      WebSocketService.socket.close();
    }
  },

  sendMessage: (message) => {
    if (
      WebSocketService.socket &&
      WebSocketService.socket.readyState === WebSocket.OPEN
    ) {
      WebSocketService.socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket not connected");
    }
  },
};

export default WebSocketService;
