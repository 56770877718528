import {Row, Col, ProgressBar} from 'react-bootstrap';
import {CustomContainer, NonIdealScreen, ToastBox} from '../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import withObservables from '@nozbe/with-observables';
import {observeActiveProducts} from '../../../../pos-core/database/helpers';
import {useOnboarding} from '../../../../hooks';
import CsvUploadModal from '../../../common/CsvUploadModal';

const Onboarding = ({productCount}) => {
    const navigate = useNavigate();
    const {state} = useLocation();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [cvsModal, setCvsModal] = useState(false);
    const {activeIndex, setActiveIndex} = useOnboarding(productCount);
    const {renderToast, setRenderToast} = useAuthContext();
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'onboarding_item_created') {
            setShowMessage({
                visible: true,
                message: I18n.item_created_successfully,
            });
        }
        if (renderToast === 'onboarding_customer_created') {
            setShowMessage({
                visible: true,
                message: I18n.customer_created_successfully,
            });
        }
    }, [renderToast]);

    useEffect(() => {
        if (state?.screenName == 'is_printer') setActiveIndex(2);
    }, [state]);

    const toggleIndex = val => setActiveIndex(val);

    const handleCreateItem = () => {
        navigate('/items/create', {
            state: {screenName: 'is_onboarding'},
        });
    };

    const togglecsvModal = () => {
        setCvsModal(x => !x);
    };

    const handleCreateMember = () => {
        navigate('/team/create');
    };

    function handleCreateCustomer() {
        navigate('/customers/create', {
            state: {screenName: 'is_onboarding'},
        });
    }

    const handleNavigatTopos = () => {
        navigate('/pos');
    };
    const steps = [
        {
            icon: 'itemNewIcon',
            title: I18n.set_up_items,
            description: I18n.before_you_open_your_business_first_you_need_some_items,
            primaryCta: {
                title: I18n.import_items,
                action: togglecsvModal,
            },
            secondaryCta: {
                title: I18n.create_item,
                action: handleCreateItem,
            },
            skipText: {
                title: I18n.skip_this_step,
                action: () => toggleIndex(1),
            },
        },
        {
            icon: 'customerSquareIcon',
            title: I18n.customer,
            description: I18n.create_customer_and_add_them_into_sales,

            primaryCta: {
                title: I18n.import_customers,
                action: togglecsvModal,
            },
            secondaryCta: {
                title: I18n.create_customer,
                action: handleCreateCustomer,
            },
            skipText: {
                title: I18n.skip_this_step,
                action: () => toggleIndex(2),
            },
        },
        {
            icon: 'teamNewIcon',
            title: I18n.set_up_team,
            description: I18n.set_up_your_team_members,
            secondaryCta: {
                title: I18n.create_member,
                action: handleCreateMember,
            },
            primaryCta: {
                title: I18n.go_to_pos,
                action: handleNavigatTopos,
            },
        },
    ];

    return (
        <CustomContainer className="allScreenNonIdealBox">
            <Row className="justify-content-md-center">
                <Col md={4} className="progressbarMainBox">
                    <div className="progressbarMainBoxInner">
                        <ProgressBar onClick={() => toggleIndex(0)} now={activeIndex == 0 ? 100 : 0} />
                        <ProgressBar onClick={() => toggleIndex(1)} now={activeIndex == 1 ? 100 : 0} />
                        <ProgressBar onClick={() => toggleIndex(2)} now={activeIndex == 2 ? 100 : 0} />
                    </div>
                </Col>

                <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} type={activeIndex === 0 ? 'item' : activeIndex === 1 ? 'customer' : ''} />

                <NonIdealScreen
                    heading={steps[activeIndex].title}
                    subHeading={steps[activeIndex].description}
                    name={steps[activeIndex].icon}
                    fill={theme.white}
                    skipText={steps[activeIndex].skipText}
                    primaryCta={steps[activeIndex].primaryCta}
                    secondaryCta={steps[activeIndex].secondaryCta}
                    isMultiStep={true}
                />
                <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
            </Row>
        </CustomContainer>
    );
};

const enhance = withObservables([], () => ({
    productCount: observeActiveProducts().observeCount(),
}));

export default enhance(Onboarding);
