import {Col, Container, Row} from 'react-bootstrap';
import TableHeader from '../TableContainer/TableHeader';
import {useLanguageContext, useThemeContext} from '../../../context';
import {DEVICE_HEIGHT, itemCsvDatavalidate} from '../../../constants';
import CsvList from './CsvList';
import {useEffect, useState} from 'react';

const ItemCSVTable = ({data, setErrors, errors, setData, products}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [productImage, setProductImage] = useState();

    let csvData = data?.formateRow;
    useEffect(() => {
        if (csvData?.length) {
            handleError();
        }
    }, [csvData]);

    const handleError = () => {
        const formErrors = itemCsvDatavalidate(csvData, products);

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        }
    };

    const handleUpadteImage = (image, value, index) => {
        setData(prevData => {
            const newData = {...prevData};

            if (Array.isArray(newData?.formateRow)) {
                newData.formateRow = newData?.formateRow?.map((item, idx) => (idx === index ? {...item, image: image} : item));
            }

            return newData;
        });
    };

    return (
        <>
            <div className="itemListContainer" style={{background: theme.blackBg, height: DEVICE_HEIGHT - 110}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <TableHeader className={`tenColumn`} style={{borderColor: theme.posRightBg}}>
                                <div className="BoxWidth justifyStart">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.items}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.category}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.description}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.price}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.cost_per_item}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.add_qty}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.low_stock_alert}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.barcode}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.unit}
                                    </p>
                                </div>
                            </TableHeader>

                            <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                {csvData?.map((val, index) => (
                                    <CsvList index={index} errors={errors} value={val} handleUpadteImage={handleUpadteImage} />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ItemCSVTable;
