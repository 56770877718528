import {Model} from '@nozbe/watermelondb';
import {SALES_TAX_SCHEMA} from '../schema';
import {field, text, date, relation, writer, children} from '@nozbe/watermelondb/decorators';

export default class SalesTax extends Model {
    static table = SALES_TAX_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        product: {type: 'has_many', foreignKey: 'sales_tax_id'},
        order: {type: 'has_many', foreignKey: 'sales_tax_id'},
        payment_method: {type: 'has_many', foreignKey: 'sales_tax_id'},
    };

    @text('name') name;
    @field('rate') rate;
    @field('is_inclusive') is_inclusive;
    @field('draft') draft;
    @field('is_pre_discounted') is_pre_discounted;
    @field('is_selected_items') is_selected_items;
    @field('archive') archive;
    @date('created_at') created_at;

    @relation('account', 'account_id') account;

    @children('product') product;
    @children('order') order;
    @children('payment_method') payment_method;

    @writer async archiveTax(status = true) {
        return await this.update(tax => {
            tax.archive = status;
        });
    }

    @writer async updateDetails(details) {
        return await this.update(tax => {
            tax.name = details.name;
            tax.rate = details.rate;
            tax.is_inclusive = details.is_inclusive;
            tax.is_pre_discounted = details.is_pre_discounted;
            tax.is_selected_items = details.is_selected_items;
            tax.draft = details.draft;
        });
    }
}
