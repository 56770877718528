import { useEffect, useState } from "react";

const useOrderFilter = (handleFilter) => {

    const [list, setList] = useState([]);
    const [filter, setFilter] = useState("all");

    useEffect(() => {
        handleFilteredList(filter);
    }, [filter, handleFilter]);

    const handleFilteredList = (val) => setList(handleFilter(val));

    return { list, setFilter, filter };
}

export default useOrderFilter;