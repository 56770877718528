import { useLanguageContext, useThemeContext } from "../../../../../../context";
import { observepaymentByID } from "../../../../../../pos-core/database/helpers";
import { DEVICE_HEIGHT, formatDayTime, formatNum } from "../../../../../../constants";
import { NewPosHeader } from "../../../../../common/V2";
import withObservables from "@nozbe/with-observables";
import { switchMap } from "rxjs";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { CustomContainer } from "../../../../../common";
import StatusBox from "../../../../../common/StatusBox";

const TransactionDetail = ({ payment }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  payment = payment[0];

  const {
    payment_method,
    expiry_date,
    card_number,
    created_at,
    amount,
    status,
    id,
  } = payment || {};

  const [payDetail, setPayDetail] = useState({
    order_line: [],
    loading: true,
    customer: {},
    business: {},
    netAmount: 0,
    taxAmount: 0,
    saleTax: {},
  });

  useEffect(() => {
    getPaymentDetails();
  }, []);

  const getPaymentDetails = async () => {
    let customer = await payment?.customer.fetch();
    let business = await payment.business.fetch();
    let order = await payment.order.fetch();
    let order_line = await order.order_line.fetch();
    let saleTax = await order.sales_tax.fetch();

    let netAmount, taxAmount;

    if (saleTax?.id) {
      netAmount = payment.amount - (payment.amount / 100) * saleTax.rate;
      taxAmount = (saleTax.rate / 100) * payment.amount;
    } else netAmount = payment.amount;

    setPayDetail({
      loading: false,
      order_line,
      netAmount,
      taxAmount,
      customer,
      business,
      saleTax,
    });
  };

  return (

    <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox createCustomerContainer transactionDetail"
      >


         <Row className="justify-content-md-center">
                         <NewPosHeader
          backBtnCta={true}
          title={{
            name: `Transaction ID | ${id.split("-")[0]}`,
            decs: `(${formatDayTime(created_at)})`,
          }}
        />
                <Col md={12}>
                {payDetail.loading ? (
          <Spinner
            animation="border"
            variant="white"
            className="loaderCircle marBot25"
          />
        ) : (
          <div className="transactionDetailWrapper">
            <div className="transactionDetailAmountSection">
              <div className="transactionDetailAmountTop">
                <p className="marBot5" style={{ color: theme.darkGrayTwo }}>{I18n.amount}</p>
                <p className="transactionDetailAmountStatus">
                  <span className="fontSize24 fontWeight600 marRight5" style={{ color: theme.white }}>
                    Rs. {formatNum(amount)}
                  </span>                
                    <StatusBox status={status} />                                        
                </p>                
              </div>
              <div className="transactionDetailAmountBottom">
                
                <p className="transactionDetailAmountBotInner">
                  <span className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo }}>
                    {I18n.location}
                  </span>
                  <span className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                  {payDetail.business.address || "-"}  
                  </span>
                </p>

                <p className="transactionDetailAmountBotInner">
                  <span className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo }}>
                    {I18n.customer_id}
                  </span>
                  <span className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                  {payDetail.customer?.phone_number || "-"}<i className="marLeft5 marRight5">|</i>{payDetail.customer?.name || "-"}
                  
                  </span>
                </p>
                <p className="transactionDetailAmountBotInner">
                  <span className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo }}>
                    {I18n.payment_method}
                  </span>
                  <span className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                  {payment_method == I18n.card
                    ? card_number || "-"
                    : payment_method == I18n.cash
                    ? "COD"
                    : payment_method == I18n.wallet
                    ? "-"
                    : "-"}
                  </span>
                </p>
              </div>
              
            </div>

            <hr className="detailSeperator" />

            <div className="transactionDetailItemPurchased">
              
              <h3 className="fontSize16 fontWeight600" style={{ color: theme.white }}>Items purchased</h3>
              {payDetail.order_line.map((item, index) => {
                return (
                  <div className="detailItemPurchasedInner" key={index}>
                    <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>
                      {item.name}
                    </p>
                    <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                      Rs. {formatNum(item.selling_price)}
                    </p>
                  </div>
                );
              })}
            </div>

            <hr className="detailSeperator" />

            <div className="transactionDetailItemPurchased">
              <h3 className="fontSize16 fontWeight600" style={{ color: theme.white }}>Payment details</h3>

              <div className="detailItemPurchasedInner">
                <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>Gross amount</p>
                <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                  Rs. {formatNum(amount)}
                </p>
              </div>

              {payDetail.saleTax?.id ? (
                <div className="detailItemPurchasedInner">
                  <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>
                    Tax {payDetail.saleTax.rate}%
                  </p>
                  <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                    Rs. {formatNum(payDetail.taxAmount)} (
                    {payDetail.saleTax.is_inclusive ? "inclusive" : "exclusive"}
                    )
                  </p>
                </div>
              ) : null}

              <div className="detailItemPurchasedInner">
                <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>
                  Net amount
                </p>
                <p className="fontSize14 fontWeight400" style={{ color: theme.white,}}>
                  Rs. {formatNum(payDetail.netAmount)}
                </p>
              </div>
            </div>

            <hr className="detailSeperator" />
            
            <div className="transactionDetailItemPurchased">
                <h3 className="fontSize16 fontWeight600" style={{ color: theme.white }}>Payment method</h3>              

                {card_number ? (
                  <div className="detailItemPurchasedInner">
                    <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>Number</p>
                    <p className="fontSize14 fontWeight400" style={{ color: theme.white,}}>{card_number}</p>
                  </div>
                ) : null}

                {expiry_date ? (
                  <div className="detailItemPurchasedInner">
                    <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>Expires</p>
                    <p className="fontSize14 fontWeight400" style={{ color: theme.white,}}>
                      {formatDayTime(expiry_date)}
                    </p>
                  </div>
                ) : null}

                <div className="detailItemPurchasedInner">
                  <p className="fontSize12 fontWeight400" style={{ color: theme.darkGrayTwo,}}>Type</p>
                  <p className="fontSize14 fontWeight400" style={{ color: theme.white,}}>
                    {payment_method == I18n.cash
                      ? "CASH IMAGE HERE"
                      : payment_method == I18n.card
                      ? <span className="visaCard"></span>
                      : payment_method == I18n.wallet
                      ? <span className="easyPaisa"></span>
                        /* <span className="jazzCash"></span> */
                      :                       
                      <span className="masterCard"></span>
                      }
                  </p>
                </div>
              
            </div>

          </div>
        )}
                </Col>
                </Row>
      </CustomContainer>


        
                       

  );
};

const enhance = withObservables([""], ({}) => {
  let id = window.location.pathname.split("/")[2];
  return {
    customer: observepaymentByID(id).pipe(
      switchMap((payments) => payments[0]?.customer.observe())
    ),
    business: observepaymentByID(id).pipe(
      switchMap((payments) => payments[0].business.observe())
    ),
    order: observepaymentByID(id).pipe(
      switchMap((payments) => payments[0]?.order.observe())
    ),
    payment: observepaymentByID(id),
  };
});

export default enhance(TransactionDetail);
