import { Model } from "@nozbe/watermelondb";
import { DINE_IN_TABLE_SCHEMA } from "../schema";
import {
  field,
  text,
  relation,
  children,
  writer,
  date,
  lazy,
} from "@nozbe/watermelondb/decorators";

export default class DineInTable extends Model {
  static table = DINE_IN_TABLE_SCHEMA;

  static associations = {
    floor_plan: { type: "belongs_to", key: "floor_plan_id" },
    order: { type: "has_many", foreignKey: "dine_in_table_id" },
  };

  @text("name") name;
  @field("height") height;
  @field("width") width;
  @field("seats") seats;
  @field("shape") shape;
  @field("xAxis") xAxis;
  @field("yAxis") yAxis;
  @field("xCoord") xCoord;
  @field("yCoord") yCoord;
  @date("created_at") created_at;
  @field("archive") archive;
  @field("is_active") is_active;

  @relation("floor_plan", "floor_plan_id") floor_plan;

  @children("order") order;

  @writer async updateTableDetails(item) {
    return await this.update((table) => {
      table.name = item.name;
      table.height = item.height;
      table.width = item.width;
      table.seats = item.seats;
      table.shape = item.shape;
      table.xAxis = item.xAxis;
      table.yAxis = item.yAxis;
      table.xCoord = item.xCoord;
      table.yCoord = item.yCoord;
    });
  }

  @writer async archiveTable() {
    return await this.update((table) => {
      table.archive = true;
    });
  }
}
