import React, { useState } from "react";
import { Container, Col, ModalBody, Row } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import { Button, Calculator, TextField } from "../../../../common";
import { DEVICE_HEIGHT } from "../../../../../constants";

const CreatePin = ({ handleSubmit }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirm] = useState("");
  const [step, setStep] = useState(1);

  const headTitle = step === 1 ? I18n.create_four_digit_pin : I18n.confirm_pin;
  const btnTitle = step === 1 ? I18n.create : I18n.confirm;

  const onChange = (label, val) => {
    if (step === 1) {
      setPin(val);
    } else if (step === 2) {
      setConfirm(val);
    }
  };

  const handleCreate = async () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      if (pin === confirmPin) {
        await handleSubmit(pin);
        setPin("");
        setConfirm("");
      } else {
        setConfirm("");
        console.error("Pins do not match");
      }
    }
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={4}>
            <div className="createPin">
              <div>
                <h2
                  className="optionPara fontSize20 fontWeight500 marBot25 marTop0 textCenter"
                  style={{ color: theme.text }}
                >
                  {headTitle}
                </h2>
              </div>
              <div className="itemFields posPinField">
                <TextField
                  autoFocus={true}
                  onChange={onChange}
                  //   error={pin.length === 4 && errors?.pin ? errors?.pin : null}
                  value={step === 1 ? pin : confirmPin}
                  type="password"
                  onlyNumbers={true}
                  maxLength={4}
                />
                <div
                  className="pinCodeCalculatorBox"
                  style={{ height: DEVICE_HEIGHT * 0.34 }}
                >
                  <Calculator
                    columnFour={false}
                    setState={setPin}
                    maxLength={4}
                  />
                  <Button
                    className={`width100 fontWeight600 fontSize16 cursorPointer marTop20`}
                    title={btnTitle}
                    handleClick={handleCreate}
                    disabled={
                      step === 1 ? pin?.length < 4 : confirmPin?.length < 4
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreatePin;
