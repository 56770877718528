import { useNavigate } from "react-router-dom";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../context";
import { Col, Container, Row } from "react-bootstrap";
import { Dropdown, SearchBox } from "../../common";
import Icon from "../../../assets/icons";
import { updateDeviceConfiguration } from "../../../api";
import Cookies from "js-cookie";

const KdsHeader = ({
  leftCta,
  rightCta,
  actionCta,
  rightAction,
  search,
  setSidebar,
}) => {
  const navigate = useNavigate();

  const { I18n } = useLanguageContext();
  const { counter } = useAuthContext();
  const { theme } = useThemeContext();
  const { deviceConfiguration, handleDeviceCofiguration } = useAuthContext();
  const unReadNotifications = Cookies.get("notificationsCount") || 0;

  const changeGrid = async (value) => {
    try {
      const deviceId = deviceConfiguration?.id;
      const devicePayload = {
        card_size: value,
      };
      const response = await updateDeviceConfiguration(devicePayload, deviceId);
      handleDeviceCofiguration(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSoundConfiguration = async () => {
    try {
      const deviceId = deviceConfiguration?.id;
      const value =
        deviceConfiguration?.configuration?.play_sound_order_arrived;

      const devicePayload = {
        play_sound_order_arrived: !value,
        play_sound_order_cancelled: !value,
      };
      const response = await updateDeviceConfiguration(devicePayload, deviceId);
      handleDeviceCofiguration(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleNotification = () => {
    Cookies.set("notificationsCount", 0);
    setSidebar(true);
  };

  return (
    <section style={{ background: theme.topBarBG }} className="topBarMainCont">
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="topBarLeftSide">
              {leftCta && (
                <Dropdown
                  type="radioList"
                  dropDown={{
                    title: leftCta.title,
                    name: leftCta.name,
                    fill: leftCta.fill,
                    width: leftCta.width,
                    height: leftCta.height,
                    viewBox: leftCta.viewBox,
                    option: leftCta.option,
                  }}
                />
              )}
              <SearchBox {...search} />
            </div>
          </Col>
          <Col md={6}>
            <div className="topBarRightSide gap10">
              <div
                className="headerIconBox cursorPointer"
                onClick={() => navigate("/displaysetting")}
              >
                <Icon
                  name="settingIcon"
                  fill={"white"}
                  width={"24"}
                  height={"24"}
                  viewBox={"0 0 18 18"}
                />
              </div>
              <div
                className="headerIconBox cursorPointer"
                onClick={handleNotification}
              >
                <Icon
                  name="notificationIcon"
                  fill={"white"}
                  viewBox={"0 0 27 30"}
                />
                <div className="notificationCount">
                  <p
                    className="fontSize10 marBot0"
                    style={{ color: theme.white }}
                  >
                    {unReadNotifications || 0}
                  </p>
                </div>
              </div>

              <div
                className="headerIconBox cursorPointer"
                onClick={() => handleSoundConfiguration()}
              >
                <Icon
                  name={
                    deviceConfiguration?.configuration?.play_sound_order_arrived
                      ? "soundIcon"
                      : "noSoundIcon"
                  }
                  fill={"white"}
                  viewBox={`0 0 ${
                    deviceConfiguration?.configuration?.play_sound_order_arrived
                      ? "28"
                      : "33"
                  } 30`}
                />
              </div>
              <div
                className="headerIconBox cursorPointer"
                onClick={() => changeGrid("standard")}
              >
                <Icon
                  name={
                    deviceConfiguration?.configuration?.card_size === "standard"
                      ? "halfLengthGrid"
                      : "dullColoredHalfGrid"
                  }
                  fill={"white"}
                  width={"49"}
                  height={"27"}
                  viewBox={"0 0 49 27"}
                />
              </div>

              <div
                className="headerIconBox cursorPointer"
                onClick={() => changeGrid("fullLength")}
              >
                <Icon
                  name={
                    deviceConfiguration?.configuration?.card_size ===
                    "fullLength"
                      ? "fullLengthGridIcon"
                      : "dullColoredFullLengthGrid"
                  }
                  fill={"white"}
                  width={"49"}
                  height={"27"}
                  viewBox={"0 0 49 27"}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default KdsHeader;
