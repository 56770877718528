import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {DateSelector, TextField, Header, MessageModal, Loading} from '../../common';
import {validateCreateCustomerForm, toSnakeCase, Country_Name, validateDraftCreateCustomer} from '../../../constants';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Sync, getAllCountry} from '../../../api';
import {fetchOrderByNum} from '../../../pos-core/database/helpers';

const CreateCustomerComponent = ({customer}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast, account} = useAuthContext();
    const [searchParams] = useSearchParams();

    let navigate = useNavigate();
    const {state} = useLocation();

    const [name, setName] = useState(customer?.name || '');
    const [phone, setPhone] = useState(customer?.phone_number || '');
    const [email, setEmail] = useState(customer?.email || '');
    const [dob, setDob] = useState(customer?.dob || '');
    const [province, setProvince] = useState(customer?.province || '');
    const [country, setCountry] = useState(customer?.country || '');
    const [city, setCity] = useState(customer?.city || '');
    const [address, setAddress] = useState(customer?.address || '');
    const [errors, setErrors] = useState({});
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [provincesList, setProvincesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            setLoading(true);
            try {
                const res = await getAllCountry();
                setData(res?.data);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCountries();
    }, []);

    /**
     * Handles the enter key press event and run handleSave.
     *
     * @param {Event} event - The key press event.
     * @return {void} This function does not return anything.
     */
    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            handleCreateCustomer('save');
        }
    };

    const onChange = (label, val) => {
        if (label == I18n.full_name) setName(val);
        else if (label == I18n.phone) setPhone(val);
        else if (label == I18n.email) setEmail(val);
        else if (label === I18n.country) {
            setCountry(val);
            setProvince('');
            setCity('');
            setCitiesList([]);
            const selectedCountry = data.find(item => item.countryName === val);
            setProvincesList(selectedCountry ? selectedCountry.provinces : []);
        } else if (label === I18n.province) {
            setProvince(val);
            setCity('');
            setCitiesList([]);
            const selectedProvince = provincesList.find(item => item?.provinceName === val);
            setCitiesList(selectedProvince ? selectedProvince.cities : []);
        } else if (label === I18n.city) setCity(val);
        else if (label == I18n.address) setAddress(val);
        else if (label === I18n.date_of_birth) setDob(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const orderNumber = searchParams.get('id');
    const screenName = searchParams.get('route');
    /**
     * Asynchronously handles the save operation for creating or updating a customer.
     *
     * @return {Promise<void>} A Promise that resolves when the save operation is complete.
     */
    async function handleCreateCustomer(val) {
        const {draft: customerDraftStatus, id: customerId} = customer || {};
        const order = orderNumber ? await fetchOrderByNum(orderNumber) : null;
        try {
            const paramsForCustomer = {
                name,
                phone,
                email,
                dob: new Date(dob).getTime(),
                province,
                city,
                country,
                address,
                draft: val === 'saveDraft',
            };
            let createdCustomer;
            const formErrors = await (val === 'save' ? validateCreateCustomerForm(paramsForCustomer, customerId) : validateDraftCreateCustomer(paramsForCustomer));
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;
            if (customer) {
                createdCustomer = await customer.updateDetails(paramsForCustomer);
            } else {
                createdCustomer = await account.createCustomer(paramsForCustomer);
                if (orderNumber && val === 'save') {
                    await order[0]?.updateCustomer(createdCustomer);
                }
            }
            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = 'customer_saved_into_draft';
            } else {
                toastMessage = customerDraftStatus
                    ? 'customer_published_successfully'
                    : customerId
                    ? 'customer_update'
                    : state?.screenName == 'is_onboarding'
                    ? 'onboarding_customer_created'
                    : 'customer_created';
            }
            if (screenName == 'restuantpos' && order) {
                navigate(`/restaurant-pos?id=${order[0]?.number}`);
            } else navigate(-1);
            setRenderToast(toastMessage);
            Sync(user?.email);
        } catch (error) {
            console.log(error);
        }
    }

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        navigate(-1);
    };
    return loading ? (
        <Loading />
    ) : (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={toggleDiscardModal}
                saveCta={{
                    title: I18n.save,
                    action: () => handleCreateCustomer('save'),
                }}
                primaryCta={
                    !customer?.id
                        ? {
                              title: I18n.save_draft,
                              action: () => handleCreateCustomer('saveDraft'),
                          }
                        : null
                }
            />

            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className="signupTextBox">
                        <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {I18n.create_customer}
                        </h3>
                        <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop0" style={{color: theme.text}}>
                            {I18n.customer_details}
                        </h3>
                    </div>

                    <div className="itemFields">
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    onChange={onChange}
                                    error={errors['name']}
                                    label={I18n.full_name}
                                    placeholder={I18n.enter_name}
                                    value={name}
                                    required={true}
                                    autoFocus={true}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    onChange={onChange}
                                    error={errors['phone_number']}
                                    label={I18n.phone}
                                    placeholder={I18n.enter_phone_number}
                                    value={phone}
                                    type={'numeric'}
                                    maxLength={11}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField onChange={onChange} error={errors.email} label={I18n.email} placeholder={I18n.enter_email_address} value={email} />
                            </div>
                            <div className="priceSectionInputRight">
                                <DateSelector label={I18n.date_of_birth} disableFutureDate={true} value={dob} setValue={onChange} />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    label={I18n.country}
                                    placeholder={I18n.select_country}
                                    data={data?.map(item => item?.countryName)}
                                    value={country}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    label={I18n.province}
                                    placeholder={I18n.select_province}
                                    data={provincesList?.map(item => item.provinceName)}
                                    value={province}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    label={I18n.city}
                                    placeholder={I18n.select_city}
                                    onChange={onChange}
                                    data={citiesList}
                                    value={city}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField onChange={onChange} errors={errors} label={I18n.address} placeholder={I18n.enter_address} value={address} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <MessageModal
                className="messageModal"
                setToggle={setDiscardModalToggle}
                toggle={discardModalToggle}
                description={I18n.do_you_really_want_to_discard}
                subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleDiscardModal,
                }}
                primaryCta={{
                    title: I18n.discard,
                    backgroundColor: theme.white,
                    borderColor: theme.red,
                    txtColor: theme.red,
                    action: handleDiscardAction,
                    isDelete: true,
                }}
            />
        </div>
    );
};

export default CreateCustomerComponent;
