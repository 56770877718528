const UploadButton = ({ onChange, className, title }) => {
  return (
    <button className={`buttonStyle uploadFIleCont ${className}`}>
      <input
        accept=".jpeg, .png, .jpg"
        onChange={onChange}
        name="image"
        type="file"
      />
      <span>{title}</span>
    </button>
  );
};
export default UploadButton;
