import {useLanguageContext, useThemeContext} from '../../../context';
import withObservables from '@nozbe/with-observables';
import {convertToArray, DEVICE_HEIGHT, firstLetterCaptilize} from '../../../constants';
import {useCustomerOrdersCalculation, useOrderCalculation} from '../../../hooks';
import Button from '../Button';
import BuyerSummary from './BuyerSummary';
import AmountBox from './AmountBox';
import {useEffect, useState} from 'react';
import {ItemDiscountsModal} from '../V2';
import ToastBox from '../ToastBox';
import CreditHistoryModal from '../CreditHistoryModal';
import ResturantSummary from './ResturantSummary';
import MessageModal from '../MessageModal';
import RefundItemModal from '../RefundItemModal';

const CustomerDetailComponent = ({customer, buyerSummary = false, amountBoxShow = false, isResturant = false, order, sales_tax, discount, order_lines}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [creditModal, setCreditModal] = useState(false);
    const [creditHistoryModal, setCreditHistoryModal] = useState(false);
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    let orders = convertToArray(order);
    const {name, phone_number, email, available_points, total_credit, created_at} = customer || {};
    const {subTotal, total, tax, givenOrderDiscount, givenPosDiscount} = useOrderCalculation({orderLines: order_lines, discount, sales_tax});
    const {totalSpent, averageSpend} = useCustomerOrdersCalculation(orders);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    const toggleCreditModal = () => setCreditModal(x => !x);
    const toggleCreditHistory = () => setCreditHistoryModal(x => !x);

    const handleCreditHistory = () => {
        toggleCreditHistory();
    };

    const handlePayCredit = () => {
        if (customer?.total_credit) toggleCreditModal();
    };

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };
    const handleDiscardAction = () => {
        console.log('action called');
        setRefundModal(true);
        setDiscardModalToggle(false);
    };

    const handleRefund = () => {
        setDiscardModalToggle(true);
    };

    const handlePrintReceipt = () => {};

    const handleAddCreditAmount = async (type, value) => {
        const creditPayload = {
            payment_method: type,
            amount: Number(value),
            status: 'completed',
            completed_at: new Date().getTime(),
        };

        await customer.createCreditTransaction(creditPayload);
        await customer.updateCredit(Number(value), 'subtract');

        toggleCreditModal();
        setShowMessage({
            visible: true,
            message: `Rs. ${value} credit paid successfully`,
        });
    };

    return (
        <>
            <div className={`orderDetailCustomerBox`} style={{height: DEVICE_HEIGHT - 105}}>
                <div className={amountBoxShow ? 'orderSummaryContainer' : ''} style={{height: '88%'}}>
                    <div className="rowConatainer garyBorderBottom">
                        <div className="detailBotCol">
                            <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                {I18n.customer_details}
                            </p>
                            <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                {firstLetterCaptilize(name) || '--'}
                            </p>
                        </div>

                        <div className="detailBotCol">
                            <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                {I18n.phone_number}
                            </p>
                            <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                {phone_number || '--'}
                            </p>
                        </div>

                        <div className="detailBotCol">
                            <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                {I18n.email_address}
                            </p>
                            <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                {email || '--'}
                            </p>
                        </div>
                    </div>

                    {!amountBoxShow && (
                        <div className="orderDetailsLoyalityBox">
                            <div className="detailBotCol">
                                <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                    {I18n.loyalty_point_available}
                                </p>
                                <p className="fontSize18 marBot0" style={{color: theme.mustardYellow}}>
                                    {available_points || 0}
                                </p>
                            </div>

                            <div className="detailBotCol">
                                <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                    {I18n.credit}
                                </p>
                                <p className="fontSize18 marBot0" style={{color: theme.red}}>
                                    {`Rs. ${total_credit?.toFixed(2) || 0}`}
                                </p>
                            </div>
                        </div>
                    )}
                    {isResturant && <ResturantSummary />}
                    {buyerSummary && <BuyerSummary orders={order} totalSpent={totalSpent} averageSpend={averageSpend} created_at={created_at} />}
                    {amountBoxShow && (
                        <AmountBox subTotal={subTotal} givenPosDiscount={givenPosDiscount} givenOrderDiscount={givenOrderDiscount} order={order} tax={tax} total={total} />
                    )}
                </div>

                {orders?.length > 0 && (
                    <div className="rowContainer">
                        <Button
                            className={`marleft10 marRight10 cursorPointer width100`}
                            title={amountBoxShow ? I18n.refund : I18n.pay_credits}
                            backgroundColor={amountBoxShow ? theme.seperatorTwo : theme.blackBg}
                            borderColor={theme.darkSlateBlue}
                            handleClick={amountBoxShow ? handleRefund : handlePayCredit}
                        />
                        <Button
                            type={amountBoxShow ? '' : 'primaryButton'}
                            title={amountBoxShow ? I18n.print_receipt : I18n.credit_history}
                            handleClick={amountBoxShow ? handlePrintReceipt : handleCreditHistory}
                            borderColor={amountBoxShow ? theme.brightGreen : theme.barclaysBlue}
                            className={`marleft10 marRight10 cursorPointer width100`}
                        />
                    </div>
                )}
            </div>
            <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
            <ItemDiscountsModal
                setToggle={setCreditModal}
                toggle={creditModal}
                handleSave={handleAddCreditAmount}
                title={I18n.credit_amount}
                selectedItem={customer}
                from="customer"
            />
            <MessageModal
                className="messageModal"
                setToggle={setDiscardModalToggle}
                toggle={discardModalToggle}
                description={I18n.do_you_want_to_proceed_this_order}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleDiscardModal,
                }}
                primaryCta={{
                    title: I18n.continue,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    action: handleDiscardAction,
                }}
            />
            <CreditHistoryModal setToggle={setCreditHistoryModal} toggle={creditHistoryModal} setShowMessage={setShowMessage} customer={customer} />
            <RefundItemModal toggle={refundModal} setToggle={setRefundModal} order_lines={order_lines} />
        </>
    );
};

export default CustomerDetailComponent;
