import Icon from '../../../assets/icons';
import {firstLetterCaptilize, formatDateAndTime, formatNum} from '../../../constants';
import {useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const OrderListItem = ({order, customerDetails, OrderModalToggle}) => {
    const {theme} = useThemeContext();

    return (
        <TableBody className={`fiveColumn`} style={{borderColor: theme.posRightBg}} onClick={() => OrderModalToggle(order)}>
            <div className="BoxWidth justifyStart marLeft10">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDateAndTime(order.completed_at)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                    {order?.number}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {customerDetails ? `+ ${order?.earned_points || 0}` : `Rs. ${formatNum(order.total)}`}
                </p>
            </div>
            <div className="BoxWidth BoxActionColumn justifyCenter">
                {customerDetails ? (
                    <>
                        <p className="fontSize14 OneLineTruncate" style={{color: order?.payment_method === 'credit' ? theme.brightOrange : theme.white}}>
                            {`Rs. ${formatNum(order.total)}`}
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            <Icon name="printIcon" fill={theme.white} width="21" height="24" viewBox={'0 0 21 24'} />
                        </p>
                        <p>
                            <Icon name="viewIcon" fill={theme.white} width="22" height="16" viewBox={'0 0 22 16'} />
                        </p>
                    </>
                )}
            </div>
            {customerDetails ? (
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(order?.payment_method || '')}
                    </p>
                </div>
            ) : null}
        </TableBody>
    );
};

export default OrderListItem;
