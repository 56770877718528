import {useLanguageContext, useThemeContext} from '../../../context';
import {Dropdown} from 'react-bootstrap';
import {CheckBox} from '../../common';

const DropdownRadioList = ({dropDown}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return dropDown.option.map((item, index) => {
        const {checked, action, title} = item || {};
        return (
            <Dropdown.Item key={index} onClick={action}>
                <CheckBox
                    checkboxLabel={
                        <p style={{color: theme.blackFont}} className="marBot0 fontSize14 OneLineTruncate">
                            {I18n[title] || title}
                        </p>
                    }
                    onChange={action}
                    checked={checked}
                />
            </Dropdown.Item>
        );
    });
};

export default DropdownRadioList;
