import { useEffect, useState } from "react";

const useInternet = () => {

    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        window.addEventListener("offline", handleOffline);

        window.addEventListener("online", handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        }
    }, []);

    const handleOnline = () => {
        setIsOffline(false)
    }

    const handleOffline = () => {
        setIsOffline(true)
    }

    return { isOffline }
}

export default useInternet;