import Icon from "../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../context";

const ListItem = ({ val, title, handleDelete }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <div className="optionListInner" style={{ borderColor: theme.topBarBG }}>
      <p className="fontSize14 marBot0" style={{ color: theme.white }}>
        {val}
      </p>
      <p className="cursorPointer marBot0">
        <Icon
          name="delete2Icon"
          fill={theme.red}
          width="18"
          height="18"
          viewBox={"0 0 18 18"}
          onClick={() => handleDelete(title, val)}
        />
      </p>
    </div>
  );
};

export default ListItem;
