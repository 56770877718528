import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Menu, PosPin, KdsPin} from '../components/common';
import {
    BusinessDetails,
    CreateCustomer,
    CreateDicounts,
    CreateItem,
    CreateOption,
    CreateOrder,
    CreateSession,
    CreateTeam,
    EditTeamMember,
    CreateSalesTax,
    CreatePurchaseTax,
    CustomerList,
    DiscountList,
    Home,
    ImportItems,
    ItemList,
    Login,
    Onboarding,
    OptionList,
    OrderPayment,
    SignUp,
    TeamList,
    SendEmail,
    SalesTaxList,
    OrderHistoryList,
    PurchaseOrderList,
    CreatePurchaseOrder,
    PurchaseOrderDetails,
    EditCustomer,
    EditSalesTax,
    EditPurchaseTax,
    EditItem,
    EditDiscount,
    OrderDetails,
    SendPurchaseorder,
    SupplierList,
    CustomerDetailScreen,
    ReportList,
    InvoiceList,
    AppSettings,
    Location,
    Dashboard,
    KitchenDisplay,
    KdsLogin,
    DisplaySetting,
    TimerSetting,
    WaiterList,
    CreateWaiter,
    EditWaiter,
    CreateCategories,
    CategoriesList,
    EditCategories,
    CompanyList,
    EditCompany,
    CreateCompany,
    ForgotPassword,
    PaymentTaxList,
} from '../components/screens';
import SecondaryMenu from '../components/common/SecondaryMenu';
import KitchenDisplayOrder from '../components/screens/Authenticated/KItchenDisplayOrder';
import PosCockpit from '../components/screens/Authenticated/Restaurant/PosCockpit';
import FloorPlans from '../components/screens/Authenticated/Restaurant/FloorPlans';
import CreateFloorPlan from '../components/screens/Authenticated/Restaurant/CreateFloorPlan';
import ModifierList from '../components/screens/Authenticated/Restaurant/Modifiers/ModifierList';
import CreateModifier from '../components/screens/Authenticated/Restaurant/Modifiers/CreateModifier';
import RestaurantOrder from '../components/screens/Authenticated/POS/CreateOrder/RestaurantOrder';
import Transactions from '../components/screens/Authenticated/Payments/Transactions/TransactionList';
import TransactionDetail from '../components/screens/Authenticated/Payments/Transactions/TransactionDetail';
import WalletList from '../components/screens/Authenticated/Payments/Wallet/WalletList';
import CreateLocation from '../components/screens/Authenticated/Setting/Location/CreateLocation';
import EditLocation from '../components/screens/Authenticated/Setting/Location/EditLocation';
import Unauthorized from '../components/screens/FallbackScreens/Unauthorized';
import ProtectedRoute from './ProtectedRoute';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Onbaording */}
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="kds-login" element={<KdsLogin />} />

                <Route path="register" element={<SignUp />} />
                <Route path="unauthorized" element={<Unauthorized />} />

                {/* KDS Authenticated Routes */}
                <Route element={<SecondaryMenu />}>
                    <Route element={<KdsPin />}>
                        <Route path="kitchendisplay" element={<KitchenDisplayOrder />} />
                        <Route path="displaysetting" element={<DisplaySetting />} />
                        <Route path="timersetting" element={<TimerSetting />} />
                    </Route>
                </Route>

                {/* Authenticated */}
                <Route element={<Menu />}>
                    {/* <Route path={'/'} element={<Navigate to="dashboard" replace />} /> */}
                    <Route
                        path="dashboard"
                        element={
                            <ProtectedRoute permissionName="dashboard">
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="pos-cockpit"
                        element={
                            <ProtectedRoute permissionName="pos-cockpit">
                                <PosCockpit />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="floor-plans"
                        element={
                            <ProtectedRoute permissionName="floor-plans">
                                <FloorPlans />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="create-floor-plan"
                        element={
                            <ProtectedRoute permissionName="create-floor-plans">
                                <CreateFloorPlan />
                            </ProtectedRoute>
                        }
                    />

                    <Route path="business-details" element={<BusinessDetails />} />
                    <Route path="onboarding" element={<Onboarding />} />
                    <Route path="session" element={<CreateSession />} />

                    <Route element={<PosPin />}>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute permissionName="pos">
                                    <CreateOrder />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="pos"
                            element={
                                <ProtectedRoute permissionName="pos">
                                    <CreateOrder />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="restaurant-pos"
                            element={
                                <ProtectedRoute permissionName="restaurant-pos">
                                    <RestaurantOrder />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="pos/billing"
                            element={
                                <ProtectedRoute permissionName="pos">
                                    <OrderPayment />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="customers"
                        element={
                            <ProtectedRoute permissionName="customers">
                                <CustomerList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/:id/edit"
                        element={
                            <ProtectedRoute permissionName="customers">
                                <EditCustomer />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/create"
                        element={
                            <ProtectedRoute permissionName="customers">
                                <CreateCustomer />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/:id"
                        element={
                            <ProtectedRoute permissionName="customers">
                                <CustomerDetailScreen />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="waiters"
                        element={
                            <ProtectedRoute permissionName="waiters">
                                <WaiterList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="waiters/create"
                        element={
                            <ProtectedRoute permissionName="waiters">
                                <CreateWaiter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="waiters/:id/edit"
                        element={
                            <ProtectedRoute permissionName="waiters">
                                <EditWaiter />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="items"
                        element={
                            <ProtectedRoute permissionName="items">
                                <ItemList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="items/:id/edit"
                        element={
                            <ProtectedRoute permissionName="items">
                                <EditItem />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="items/create"
                        element={
                            <ProtectedRoute permissionName="items">
                                <CreateItem />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="modifiers"
                        element={
                            <ProtectedRoute permissionName="modifiers">
                                <ModifierList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="modifiers/create"
                        element={
                            <ProtectedRoute permissionName="modifiers">
                                <CreateModifier />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="option/create"
                        element={
                            <ProtectedRoute permissionName="option">
                                <CreateOption />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="option"
                        element={
                            <ProtectedRoute permissionName="option">
                                <OptionList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team"
                        element={
                            <ProtectedRoute permissionName="team">
                                <TeamList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team/create"
                        element={
                            <ProtectedRoute permissionName="team">
                                <CreateTeam />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team/send-email"
                        element={
                            <ProtectedRoute permissionName="team">
                                <SendEmail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team/:id/edit"
                        element={
                            <ProtectedRoute permissionName="team">
                                <EditTeamMember />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="discounts"
                        element={
                            <ProtectedRoute permissionName="discounts">
                                <DiscountList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="discounts/:id/edit"
                        element={
                            <ProtectedRoute permissionName="discounts">
                                <EditDiscount />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="discounts/create"
                        element={
                            <ProtectedRoute permissionName="discounts">
                                <CreateDicounts />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="tax"
                        element={
                            <ProtectedRoute permissionName="tax">
                                <SalesTaxList />
                            </ProtectedRoute>
                        }
                    />
                    {/* <Route
                        path="payment_method_tax"
                        element={
                            <ProtectedRoute permissionName="payments">
                                <PaymentTaxList />
                            </ProtectedRoute>
                        }
                    /> */}

                    <Route
                        path="tax/sales/create"
                        element={
                            <ProtectedRoute permissionName="tax">
                                <CreateSalesTax />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="tax/purchase/create"
                        element={
                            <ProtectedRoute permissionName="tax">
                                <CreatePurchaseTax />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="tax/sales/:id/edit"
                        element={
                            <ProtectedRoute permissionName="tax">
                                <EditSalesTax />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="tax/purchase/:id/edit"
                        element={
                            <ProtectedRoute permissionName="tax">
                                <EditPurchaseTax />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="category/create"
                        element={
                            <ProtectedRoute permissionName="category">
                                <CreateCategories />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="category"
                        element={
                            <ProtectedRoute permissionName="category">
                                <CategoriesList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="category/:id/edit"
                        element={
                            <ProtectedRoute permissionName="category">
                                <EditCategories />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="orders"
                        element={
                            <ProtectedRoute permissionName="orders">
                                <OrderHistoryList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="orders/:id"
                        element={
                            <ProtectedRoute permissionName="orders">
                                <OrderDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder"
                        element={
                            <ProtectedRoute permissionName="purchaseorder">
                                <PurchaseOrderList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder/create"
                        element={
                            <ProtectedRoute permissionName="purchaseorder">
                                <CreatePurchaseOrder />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder/:id"
                        element={
                            <ProtectedRoute permissionName="purchaseorder">
                                <PurchaseOrderDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder/share"
                        element={
                            <ProtectedRoute permissionName="purchaseorder">
                                <SendPurchaseorder />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="suppliers"
                        element={
                            <ProtectedRoute permissionName="suppliers">
                                <SupplierList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="report"
                        element={
                            <ProtectedRoute permissionName="report">
                                <ReportList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="invoice"
                        element={
                            <ProtectedRoute permissionName="report">
                                <InvoiceList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="setting"
                        element={
                            <ProtectedRoute permissionName="setting">
                                <AppSettings />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="location"
                        element={
                            <ProtectedRoute permissionName="location">
                                <Location />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="location/create"
                        element={
                            <ProtectedRoute permissionName="location">
                                <CreateLocation />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="location/:id/edit"
                        element={
                            <ProtectedRoute permissionName="location">
                                <EditLocation />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="device"
                        element={
                            <ProtectedRoute permissionName="device">
                                <KitchenDisplay />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="payments-transactions"
                        element={
                            <ProtectedRoute permissionName="payments">
                                <Transactions />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="payments-transactions/:id"
                        element={
                            <ProtectedRoute permissionName="payments">
                                <TransactionDetail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="payments-wallet"
                        element={
                            <ProtectedRoute permissionName="payments">
                                <WalletList />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="company"
                        element={
                            <ProtectedRoute permissionName="company">
                                <CompanyList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="company/:id/edit"
                        element={
                            <ProtectedRoute permissionName="company">
                                <EditCompany />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="company/create"
                        element={
                            <ProtectedRoute permissionName="company">
                                <CreateCompany />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="vendor"
                        element={
                            <ProtectedRoute permissionName="vendor">
                                <Unauthorized />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
