import {database} from '..';
import {uuid} from '../../constants';
import {PRODUCT_MODIFIER_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';

const productModifier = database.collections.get(PRODUCT_MODIFIER_SCHEMA);

const prepareInsertion = (modifier, selectedItems) => {
    return selectedItems.map(elem => {
        return productModifier.prepareCreate(obj => {
            obj.modifier.set(modifier);
            obj.product.set(elem.item);
        });
    });
};

export const bulkCreateProductModifierRelation = async (modifier, selectedItems) => {
    return await database.write(async () => {
        const allRecords = await prepareInsertion(modifier, selectedItems);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareUpdation = (modifier, selectedOptions) => {
    return selectedOptions.map(async elem => {
        console.log('Modifier', modifier);
        const prodMod = await elem.product_modifier.fetch();
        console.log('prodMod', prodMod);
        const modToDelete = prodMod.find(item => item.modifier.id === modifier.id);
        console.log('modToDelete', modToDelete);
        return modToDelete.prepareMarkAsDeleted();
    });
};

export const bulkUpdateProductModifierRelation = async (modifier, selectedOptions) => {
    await database.write(async () => {
        const allRecords = await prepareUpdation(modifier, selectedOptions);
        await database.batch(...allRecords);
        return allRecords;
    });
};
