import { Model } from "@nozbe/watermelondb";
import {
  field,
  text,
  relation,
  children,
  writer,
  date,
  lazy,
} from "@nozbe/watermelondb/decorators";
import { USER_BUSINESS_SCHEMA } from "../schema";

export default class UserBusiness extends Model {
  static table = USER_BUSINESS_SCHEMA;

  static associations = {
    user: { type: "belongs_to", key: "user_id" },
    business: { type: "belongs_to", key: "business_id" },
    role: { type: "belongs_to", key: "role_id" },
  };

  @relation("user", "user_id") user;
  @relation("business", "business_id") business;
  @relation("role", "role_id") role;

  @writer async setUserBusinessRole(details) {
    return await this.update((userBusiness) => {
      userBusiness.role.set(details.role);
    });
  }
}
