import React from "react";
import TableBody from "../../../../common/TableContainer/TableBody";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import { Button } from "../../../../common";
import {
  formatDate,
  formatStringAndCapitalize,
  formatTime,
  getOrderStatusColor,
  isDisableRecall,
} from "../../../../../constants";

const List = ({ order, setSidebar }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const {
    order_id,
    created_at,
    completed_at,
    waiter_name,
    order_type,
    status,
    time_taken,
    process_status,
  } = order;

  const { color } = getOrderStatusColor(process_status);

  return (
    <TableBody
      className={`eightColumn`}
      style={{ borderColor: theme.posRightBg }}
      // onClick={navigateToDetail}
    >
      <div className="BoxWidth justifyStart">
        <p className="fontSize14" style={{ color: theme.barclaysBlue }}>
          <span className="OneLineTruncate">{order_id}</span>
        </p>
      </div>

      <div className="BoxWidth justifyStart">
        <p className="fontSize14" style={{ color: theme.white }}>
          <span className="OneLineTruncate">{formatDate(created_at)}</span>
        </p>
      </div>
      <div className="BoxWidth justifyStart">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {formatTime(completed_at, false)}
        </p>
      </div>
      <div className="BoxWidth justifyStart">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {waiter_name}
        </p>
      </div>
      <div className="BoxWidth justifyStart">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {formatStringAndCapitalize(order_type)}
        </p>
      </div>
      <div className="BoxWidth justifyStart">
        <p className={`fontSize14 OneLineTruncate ${color}`}>
          {formatStringAndCapitalize(process_status)}
        </p>
      </div>
      <div className="BoxWidth justifyStart">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {time_taken}
        </p>
      </div>
      <div className="BoxWidth justifyStart" style={{ padding: "0px" }}>
        <Button
          className={`width60 height35 fontWeight600 fontSize14 ${
            isDisableRecall(order) ? "grayColoredDisableCta" : "secondaryCta"
          }`}
          title={isDisableRecall(order) ? I18n.paid : I18n.recall_order}
          disabled={isDisableRecall(order)}
          handleClick={() => setSidebar(order)}
        />
      </div>
    </TableBody>
  );
};

export default List;
