import {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';
import {observeAllActiveCategories, observeArchivedCategories} from '../../../../../pos-core/database/helpers';
import List from './List';
import {useSearch} from '../../../../../hooks';
import usePermission from '../../../../../hooks/usePermission';

const CategoriesList = ({categories, archivedList}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);

    const [filter, setFilter] = useState(I18n.all_categories);
    const [selected, setSelected] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [categoriesCount, setCategoiesCount] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('category');

    useEffect(() => {
        let count;
        if (filter === I18n.archived) {
            count = archivedList.length;
        } else {
            count = categories?.length;
        }
        setCategoiesCount(count);
    }, [filter, categories, archivedList]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'create_category') {
            setShowMessage({
                visible: true,
                message: I18n.category_created_successfully,
            });
        } else if (renderToast === 'update_category') {
            setShowMessage({
                visible: true,
                message: I18n.category_updated_successfully,
            });
        }
    }, [renderToast]);

    const toggleArchiveModal = () => {
        if (Object.values(selected)?.length) setArchiveModal(x => !x);
    };

    const filterOption = [
        {
            title: I18n.all_categories,
            action: () => handleFilter(I18n.all_categories),
            checked: filter == I18n.all_categories || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
    ];

    const actionList = [
        {
            title: filter == I18n.archived ? I18n.unarchived : I18n.archived,
            action: toggleArchiveModal,
        },
    ];

    const handleArchivedAction = async selected => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async item => {
                await item.archiveCategory(!item.archive);
                selectedIds.push(item.id);
            }),
        );
        setSelected(selected => {
            let obj = {...selected};
            selectedIds.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });
        setloading(false);
    };

    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };

    const handleCreateCategories = () => {
        navigate('/category/create');
    };

    function handleSearch(val) {
        const filterList = filter === I18n.archived ? archivedList : categories;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values));
    }

    const handleSelectAll = () => {
        let list = filter == I18n.archived ? archivedList : categories;
        if (Object.keys(selected).length != list.length) {
            list?.map(val => {
                setSelected(values => {
                    let obj = {...values};
                    obj[val.id] = val;
                    return obj;
                });
            });
        } else {
            setSelected({});
        }
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelected(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const checkedClick = () =>
        Object.keys(selected)?.length > 0 && Object.keys(selected)?.length === (filter == I18n.archived ? archivedList.length : categories?.length) ? true : false;

    const onChange = (label, value) => setText(value);

    const renderList = text ? searchList : filter === I18n.archived ? archivedList : categories;

    return loading ? (
        <Loading />
    ) : categories.length ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.categories} (${categoriesCount})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    className={'borderRadiusTopleftTopRigt'}
                    // leftCta={{
                    //   title: filter,
                    //   name: "downIcon2",
                    //   fill: theme.white,
                    //   width: "20",
                    //   height: "20",
                    //   viewBox: "0 0 18 18",
                    //   option: filterOption,
                    // }}
                    rightCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_category,
                                  action: handleCreateCategories,
                              }
                            : null
                    }
                    // actionCta={{
                    //   title: I18n.actions,
                    //   name: "downIcon2",
                    //   fill: theme.white,
                    //   width: "20",
                    //   height: "20",
                    //   viewBox: "0 0 18 18",
                    //   option: actionList,
                    // }}
                />

                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`threeColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol BoxWidth">
                                        {/* <CheckBox
                      checkboxLabel={
                        <p
                          className="marBot0 fontSize16 OneLineTruncate"
                          style={{ color: theme.text }}
                        >
                          {I18n.name}
                        </p>
                      }
                      onChange={handleSelectAll}
                      checked={checkedClick()}
                    /> */}
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.name}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.item}
                                        </p>
                                    </div>

                                    {userPermission?.canCrud && (
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.action}
                                            </p>
                                        </div>
                                    )}
                                </TableHeader>
                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={I18n.search}
                                            subHeading={I18n.we_couldnt_find_any_results_for_your_search}
                                            name={'seacrhNotFoundIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => (
                                            <List
                                                handleChecked={handleChecked}
                                                selected={selected}
                                                setSelected={setSelected}
                                                key={index}
                                                category={val}
                                                filter={filter}
                                                setShowMessage={setShowMessage}
                                                userPermission={userPermission}
                                            />
                                        ))
                                    )}
                                </div>
                                <ToastBox
                                    className="addSuccessfullyToast"
                                    ToastVisiable={showMessage.visible}
                                    bodyPara={showMessage.message}
                                    setShowMessage={setShowMessage}
                                    showIcon={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <MessageModal
                    setToggle={setArchiveModal}
                    toggle={archiveModal}
                    className="messageModal"
                    title={filter == I18n.all_categories ? I18n.archive_customer : I18n.unarchive_customer}
                    description={
                        filter == I18n.all_categories ? I18n.are_you_sure_you_want_to_archive_the_selected_Customer : I18n.are_you_sure_you_want_to_unarchive_the_selected_Customer
                    }
                    secondaryCta={{
                        title: I18n.no,
                        action: toggleArchiveModal,
                    }}
                    primaryCta={{
                        title: I18n.yes,
                        action: () => {
                            handleArchivedAction(selected);
                        },
                        isDelete: true,
                    }}
                />
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.categories}
                subHeading={I18n.create_categories_and_add_them_into_items}
                name="categoryIcon"
                fill={theme.white}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                              title: I18n.create_categories,
                              action: handleCreateCategories,
                          }
                        : null
                }
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    categories: observeAllActiveCategories(),
    archivedList: observeArchivedCategories(),
}));

export default enhance(CategoriesList);
