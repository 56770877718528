import { useEffect, useState } from "react"

const useOnboarding = (productCount) => {

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        handleIndex(productCount);
    }, [productCount])

    const handleIndex = (productCount) => {
        if (productCount) setActiveIndex(1);
    }

    return { activeIndex, setActiveIndex }
}

export default useOnboarding;