import React, {useEffect, useRef, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import Button from '../Button';
import Icon from '../../../assets/icons';
import {firstLetterCaptilize} from '../../../constants';

const ImageDragAndDropComponent = ({setData, data, setImage, handleClose}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const drop = useRef(null);
    const [isValidFormat, setIsValidFormat] = useState(true);

    useEffect(() => {
        const handleDragOver = e => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDrop = e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                handleImage(e.dataTransfer.files);
                e.dataTransfer.clearData();
            }
        };

        const dropArea = drop.current;
        dropArea.addEventListener('dragover', handleDragOver);
        dropArea.addEventListener('drop', handleDrop);

        return () => {
            dropArea.removeEventListener('dragover', handleDragOver);
            dropArea.removeEventListener('drop', handleDrop);
        };
    }, []);

    const handleConfirm = () => {
        if (data?.img) {
            setImage(data?.img);
            handleClose();
        }
    };

    const handleImage = files => {
        const file = files[0];
        if (!file) {
            setData('');
            setIsValidFormat(true);
            return;
        }
        const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!validFormats.includes(file.type)) {
            setIsValidFormat(false);
            setData('');
            return;
        }

        setIsValidFormat(true);
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => setData({img: reader.result, name: file.name, size: file.size});
        } catch (error) {
            setData('');
            console.log(error.message);
        }
    };

    const handleChange = e => {
        handleImage(e.target.files);
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

    const checkFileSize = data?.size > MAX_FILE_SIZE;

    const handleDeletImage = () => {
        setData('');
        setImage('');
    };
    return (
        <div className="importLibararyMainBox">
            <div ref={drop} className="csvSeletedBox">
                <div className="csvSeletedInsideBox">
                    <Icon name={'uploadFileIcon'} fill={theme.white} width={'60'} height={'60'} viewBox={'0 0 60 60'} />
                    <p style={{color: theme.text}}>{I18n.upload_file_or_drag_here}</p>
                    <p style={{color: isValidFormat ? theme.darkGrayTwo : theme.red}}>{I18n.upload_only_jpeg_jpg_or_png}</p>
                    <p
                        className="marBot5 marTop5"
                        style={{
                            color: checkFileSize ? theme.red : theme.darkGrayTwo,
                        }}>
                        {firstLetterCaptilize(I18n.max_5MB || '-')}
                    </p>

                    {data?.img ? (
                        <div className="footerButtonBox">
                            <div className="changefileBtn">
                                <button className="btn">
                                    <span>{I18n.change_file}</span>
                                </button>
                                <input name="image" type="file" onChange={handleChange} accept=".jpeg, .jpg, .png" />
                            </div>
                            <Button
                                className={`fontWeight600 width50 marLeft20 fontSize16 cursorPointer`}
                                handleClick={handleConfirm}
                                disabled={checkFileSize}
                                title={I18n.confirm}
                            />
                        </div>
                    ) : (
                        <div className="uploadphotoBtn">
                            <button className="btn marTop20">
                                <span>{I18n.upload_image}</span>
                                <input name="image" type="file" onChange={handleChange} accept=".jpeg, .jpg, .png" />
                            </button>
                        </div>
                    )}

                    {data?.img && (
                        <div className="fileNameBox">
                            <p style={{color: theme.white}}>{data.name}</p>
                            <p className="cursorPointer">
                                <Icon name="delete2Icon" fill={theme.darkGrayTwo} width="18" height="18" viewBox={'0 0 18 18'} onClick={handleDeletImage} />
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageDragAndDropComponent;
