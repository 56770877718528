import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const usePurchaseOrder = (business) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [purchaseOrder, setPurchaseOrder] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [poLine, setPOLine] = useState();

  useEffect(() => {
    handleOrder(id);
  }, [id]);

  const handleOrder = async (id) => {
    let PO = await business.getPurchaseOrder(id).fetch();
    if (PO.length) {
      setPurchaseOrder(PO[0]);

    
      let company = await PO[0]?.company?.fetch();
      let poLine = await PO[0]?.purchase_order_line?.fetch();
      setCompanyDetails(company);
      setPOLine(poLine);
    }

    setIsLoading(false);
  };

  return { isLoading, purchaseOrder, companyDetails, poLine };
};

export default usePurchaseOrder;
