import React, { useEffect, useState } from "react";
import Icon from "../../../../../assets/icons";
import { useThemeContext } from "../../../../../context";
import {
  formatDate,
  formatStringAndCapitalize,
  formatTime,
} from "../../../../../constants";

const NotificationBar = ({ notification }) => {
  const { created_at, order_id, message, order_type } = notification;
  const [icon, setIcon] = useState({});
  const { theme } = useThemeContext();

  const getMessageIcon = () => {
    if (message?.includes("received")) {
      const iconObj = {
        iconName: "arrowDownIcon",
        height: "20",
        width: "18",
        viewBox: "0 0 18 20",
      };
      setIcon(iconObj);
    } else if (message?.includes("cancelled")) {
      const iconObj = {
        iconName: "crossColored",
        height: "24",
        width: "24",
        viewBox: "0 0 24 24",
      };
      setIcon(iconObj);
    } else {
      const iconObj = {
        iconName: "tickBlueColored",
        height: "14",
        width: "19",
        viewBox: "0 0 19 14",
      };
      setIcon(iconObj);
    }
  };

  useEffect(() => {
    getMessageIcon();
  }, []);

  return (
    <div className="notificationRow flex width100 greenShadeBg marTop10">
      <div className="notificationIconBox width10">
        <Icon
          name={icon?.iconName}
          width={icon?.width}
          height={icon?.height}
          viewBox={icon?.viewBox}
        />
      </div>
      <div className="marLeft10 width90">
        <div className="flex justifySpacebetween">
          <p
            className="fontSize16 fontWeight600 marBot0"
            style={{ color: theme.white }}
          >
            {message}
          </p>
          <p className="fontSize12  marBot0" style={{ color: theme.white }}>
            {formatDate(created_at)}
          </p>
        </div>

        <div className="flex justifySpacebetween">
          <p className="fontSize14 marBot0 " style={{ color: theme.white }}>
            {`Order # ${order_id} | ${formatStringAndCapitalize(order_type)}`}
          </p>
          <p className="fontSize12  marBot0" style={{ color: theme.white }}>
            {formatTime(created_at)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
