import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Dropdown} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {useNavigate} from 'react-router-dom';
import {firstLetterCaptilize} from '../../../../../constants';

const List = ({company, userPermission}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {name, phone_number, city, province, email, id} = company;
    const handleAction = async type => {
        switch (type) {
            case 'edit':
                navigate(`${id}/edit`);
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
    ];

    const navigateToNextScreen = () => {
        if (!userPermission?.canCrud) return;
        navigate(`${id}/edit`);
    };

    return (
        <>
            <TableBody className={`sixColumn cursorPointer`} onClick={navigateToNextScreen} style={{borderColor: theme.inputBorder}}>
                <div className="checkboxCol BoxWidth">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(name || '-')}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: email ? theme.barclaysBlue : theme.white}}>
                        {firstLetterCaptilize(email || '-')}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(phone_number || '-')}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(province || '-')}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(city || '-')}
                    </p>
                </div>
                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>
        </>
    );
};

export default List;
