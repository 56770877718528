export const SUB_NAV_LIST = {
    payments: [
        {name: 'Wallet', route: 'payments-wallet'},
        {name: 'Transactions', route: 'payments-transactions'},
    ],
    suppliers: [
        {name: 'Vendor', route: 'vendor'},
        {name: 'Company', route: 'company'},
    ],
    itemLibrary: [
        {name: 'Items', route: 'items'},
        {name: 'Categories', route: 'category'},
    ],
    taxes: [
        {name: 'tax', route: 'tax'},
        // {name: 'payment method tax', route: 'payment_method_tax'},
    ],
};
