import { Container, Row, Col } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../context";
import AppModal from "../AppModal";
import { useNavigate } from "react-router-dom";

const MessageModal = ({
  toggle,
  setToggle,
  title,
  subDescription,
  description,
  primaryCta,
  secondaryCta,
  className,
}) => {
  const navigate = useNavigate();
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleClose = (event) => {
    setToggle(false);
  };

  return (
    <AppModal
      className={className || ""}
      toggle={toggle}
      handleClose={handleClose}
      title={title}
      secondaryCta={secondaryCta}
      primaryCta={primaryCta}
    >
      <Container>
        <Row className="">
          <div className="messageModalBody">
            <p
              className="fontSize16 fontWeight500 marBot0"
              style={{ color: theme.white }}
            >
              {description}
            </p>
            {subDescription && (
              <p
                className="fontSize14 fontWeight500 marBot0"
                style={{ color: theme.darkGrayTwo }}
              >
                {subDescription}
              </p>
            )}
          </div>
        </Row>
      </Container>
    </AppModal>
  );
};

export default MessageModal;
