import { useThemeContext } from "../../../context";

const TableColumn = ({ children, isName }) => {
  const { theme } = useThemeContext();

  return (
    <div
      className={`${isName ? "variationsHeaderColFirstLeft" : ""}
                           variationsHeaderColFirst variationTableHeaderCol`}
      style={{ borderColor: theme.seperatorBg }}
    >
      <p className="fontSize16 marBot0" style={{ color: theme.white }}>
        {children}
      </p>
    </div>
  );
};

export default TableColumn;
