import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const TitleIconButton = ({
  value,
  className,
  disabled,
  handleClick,
  icon_name,
  icon_fill,
  icon_height,
  icon_width,
  icon_viewBox,
  title,
  backgroundColor,
  txtColor,
  borderColor,
}) => {
  const { theme } = useThemeContext();

  return (
    <button
      value={value}
      className={`buttonStyle ${className}`}
      disabled={disabled}
      onClick={handleClick}
      style={{
        backgroundColor: backgroundColor,
        // txtColor: txtColor,
        borderColor: borderColor,
      }}
    >
      <span className="verticalButtonIcon">
        <Icon
          name={icon_name}
          fill={icon_fill}
          height={icon_height || "19"}
          width={icon_width || "20"}
          viewBox={icon_viewBox || "0 0 16 15"}
        />
      </span>
      <span
        className="fontSize14 fontWeight400"
        style={{ color: txtColor ? txtColor : theme.white }}
      >
        {title}
      </span>
    </button>
  );
};

export default TitleIconButton;
