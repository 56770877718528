import {useEffect, useState} from 'react';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {FormCheck} from 'react-bootstrap';
import RadioButton from '../../../../common/RadioButton';
import MarginBreakDownComponent from '../../../../common/MarginBreakDownComponent';

const AppSettings = () => {
    const {business} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [appSettings, setAppSettings] = useState(null);
    const [selected, setSelected] = useState({});
    const [type, setType] = useState('');

    console.log('business', business);

    const radioOptions = [
        {
            label: 'Items',
            value: 'items',
        },
        {
            label: 'Services',
            value: 'services',
        },
    ];

    const HandleToggle = async (e, option) => {
        const enable = e.target.checked ? 'on' : 'off';
        const {name} = option;
        setSelected(prev => {
            let all = {...prev};
            if (all[name]) delete all[name];
            else all[name] = {name: name};

            return all;
        });

        await option.updateOption(enable);
    };

    const handleChange = async (e, option) => {
        const value = e?.target.value;
        setType(value);
        await option.updateOption(value);
    };

    const getBusinessSetting = async () => {
        const result = {};
        let isItem = null;
        const businessSettings = await business.setting_config.fetch();
        const sortedBusinessSettings = businessSettings?.sort((a, b) => a.sub_title.localeCompare(b.sub_title));

        setAppSettings(sortedBusinessSettings);
        businessSettings?.forEach(item => {
            if (item.status === 'on') {
                result[item.name] = {name: item.name};
            }
            if (item.name === 'item_service_library') {
                isItem = item.status === 'items' ? 'items' : 'services';
            }
        });
        setSelected(result);
        setType(isItem);
    };

    useEffect(() => {
        getBusinessSetting();
    }, []);

    return (
        <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
            <div className="appSettingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <p className="fontSize18 marBot20 marTop20 fontWeight600" style={{color: theme.white}}>
                    {I18n.app_settings}
                </p>

                {appSettings?.map((opt , index) => {
                    return (
                        <div key={index}>
                       
                            <p className="fontSize14 marBot10 marTop30 fontWeight500" style={{color: theme.white}}>
                                {opt.sub_title}
                            </p>

                            {opt.name === 'item_service_library' ? (
                                <div>
                                    <RadioButton options={radioOptions} className="selectItemService" selectedSize={type} handleChange={e => handleChange(e, opt)} />
                                </div>
                            ) : opt.name === 'Margin_and_reports' ? (
                                <div>
                                    <div className="appSettingSwitchWrapper flex gap20 verticalCenter">
                                        <FormCheck type="switch" id="custom-switch" onChange={e => HandleToggle(e, opt)} checked={selected[opt.name]} />

                                        <p className="fontSize14 marBot20 marTop20 fontWeight500" style={{color: theme.white}}>
                                            {opt.status === 'on' ? I18n.enabled : I18n.disabled}
                                        </p>
                                    </div>
                                    
                                   {opt.status === 'on' ? <MarginBreakDownComponent/> : null }
                                </div>
                            ) : (
                                <div className="appSettingSwitchWrapper flex gap20 verticalCenter">
                                    <FormCheck type="switch" id="custom-switch" onChange={e => HandleToggle(e, opt)} checked={selected[opt.name]} />

                                    <p className="fontSize14 marBot20 marTop20 fontWeight500" style={{color: theme.white}}>
                                        {opt.status === 'on' ? I18n.enabled : I18n.disabled}
                                    </p>
                                </div>
                            )}

                            <div className="thinDivider width100 marTop20" />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AppSettings;
