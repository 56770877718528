import { useEffect, useState } from "react"

const useVariationList = (variants) => {

    const [variations, setVariations] = useState({ ...variants });

    useEffect(() => {
        setVariations({ ...variants });
    }, [variants]);

    return { variations, setVariations };
}

export default useVariationList;