import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../context";
import { Button } from "../../common";
const PosHeader = ({
  leftButton,
  orderTitle,
  holdButton,
  newButton,
  deleteButton,
  orderLines,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <section style={{ background: theme.topBarBG }} className="topBarMainCont">
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="topBarLeftSide">
              <h1
                className="fontSize20 fontWeight600 marBot0 marRight5"
                style={{ color: theme.white }}
              >
                {orderTitle}
              </h1>
              <p className="headerTitleSeperator marLeft10 marRight10 height20"></p>
              <Button
                className={`addCustomerButton fontSize12 fontWeight600 marLeft10`}
                type="secondary"
                title={leftButton.title}
                txtColor={
                  orderLines?.length > 0 ? theme.brightGreen : theme.white
                }
                buttonIcon={true}
                icon_name={leftButton.icon}
                icon_fill={
                  orderLines?.length > 0 ? theme.brightGreen : theme.white
                }
                icon_width={"22"}
                icon_height={"16"}
                icon_viewBox={"0 0 20 14"}
                handleClick={leftButton.action}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="topBarRightSide">
              {holdButton ? (
                <Button
                  type="verticalButton"
                  title={holdButton.title}
                  count={holdButton.count}
                  icon_name={holdButton.icon}
                  icon_fill={theme.white}
                  icon_width={"15"}
                  icon_height={"15"}
                  icon_viewBox={"0 0 13 13"}
                  handleClick={holdButton.action}
                />
              ) : null}
              <Button
                type="verticalButton"
                title={newButton.title}
                handleClick={newButton.action}
                icon_name={newButton.icon}
                icon_fill={theme.white}
                icon_width={"13"}
                icon_height={"13"}
                icon_viewBox={"0 0 13 13"}
              />
              <Button
                type="verticalButton"
                title={deleteButton.title}
                handleClick={deleteButton.action}
                icon_name={deleteButton.icon}
                icon_fill={theme.white}
                icon_width={"14"}
                icon_height={"15"}
                icon_viewBox={"0 0 15 16"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default PosHeader;
