import "./App.css";
import {
  AuthProvider,
  LanguageProvider,
  MenuProvider,
  SessionProvider,
  ThemeProvider,
} from "./context";
import AppRoutes from "./routes";
import DatabaseProvider from "@nozbe/watermelondb/DatabaseProvider";
import { database } from "./pos-core/database";

const App = () => {
  return (
    <DatabaseProvider database={database}>
      <AuthProvider>
        <ThemeProvider>
          <LanguageProvider>
            <SessionProvider>
              <MenuProvider>
                <AppRoutes />
              </MenuProvider>
            </SessionProvider>
          </LanguageProvider>
        </ThemeProvider>
      </AuthProvider>
    </DatabaseProvider>
  );
};

export default App;
