import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {observeActiveProducts, queryItems, observeAllUnAchriveProducts} from '../../../../../pos-core/database/helpers';
import withObservables from '@nozbe/with-observables';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT, INITIAL_PAGE, ITEMS_PER_PAGE, exportDataToCSV} from '../../../../../constants';
import List from './List';
import {useEffect, useState} from 'react';
import CsvUploadModal from '../../../../common/CsvUploadModal';
import usePermission from '../../../../../hooks/usePermission';

const ItemList = ({activeProducts, all_product}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [selecteditem, setSelecteditem] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [cvsModal, setCvsModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState('');
    const [option, setOption] = useState('all_items');
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const {renderToast, setRenderToast, business} = useAuthContext();
    const userPermission = usePermission('items');

    const actionList = [
        {
            title: I18n.import_library,
            action: () => handleImport(),
        },
        {
            title: I18n.export_library,
            action: () => handleExport(),
        },
        {
            title: option === 'archived' ? I18n.unarchived : I18n.archived,
            action: () => toggleArchiveModal(),
        },
    ];

    function handleImport() {
        setCvsModal(x => !x);
    }

    function handleExport() {
        let csvdata;
        if (Object.keys(selecteditem).length) {
            csvdata = Object.values(selecteditem);
        } else {
            csvdata = products;
        }
        exportDataToCSV('item', csvdata);
    }

    const filterOption = [
        {
            title: I18n.all_items,
            action: () => handleFilterItems('all_items'),
            checked: option === 'all_items' || option === '' ? true : false,
        },
        {
            title: I18n.low_stock,
            action: () => handleFilterItems('low_stock'),
            checked: option === 'low_stock' ? true : false,
        },
        {
            title: I18n.sold_out,
            action: () => handleFilterItems('sold_out'),
            checked: option === 'sold_out' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilterItems('archived'),
            checked: option === 'archived' ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilterItems('drafts_saved'),
            checked: option === 'drafts_saved' ? true : false,
        },
    ];

    const handleCreateItem = () => {
        navigate('/items/create');
    };

    const handleFilterItems = async selected => {
        const {data, totalCount} = await queryItems(selected, search?.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE);
        setOption(selected);
        setProducts(data);
        setIsFetching(false);
        setCurrentPage(INITIAL_PAGE);
        setHasMore(totalCount > ITEMS_PER_PAGE);
    };

    const onChange = async (label, val) => {
        setSearch(val);
        const {data, totalCount} = await queryItems(option, val.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE);
        setProducts(data);
        setIsFetching(false);
        setCurrentPage(INITIAL_PAGE);
        setHasMore(totalCount > ITEMS_PER_PAGE);
    };

    const handleSelectAll = () => {
        let list = products;
        if (Object.keys(selecteditem)?.length != list.length) {
            list?.map(val => {
                setSelecteditem(values => {
                    let obj = {...values};
                    obj[val?.id] = val;
                    return obj;
                });
            });
        } else {
            setSelecteditem({});
        }
    };

    const checkedClick = () => (Object.keys(selecteditem)?.length > 0 && Object.keys(selecteditem)?.length === products?.length ? true : false);

    const handleChecked = val => {
        let data = val?.id;
        setSelecteditem(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const toggleArchiveModal = () => {
        if (Object.values(selecteditem)?.length) {
            setArchiveModal(x => !x);
        } else {
            setShowMessage({
                visible: true,
                message: 'Please select item first',
            });
        }
    };

    const handleArchivedAction = async selected => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async item => {
                await item?.archiveProduct(!item.archive);
                selectedIds.push(item.id);
            }),
        );
        setSelecteditem(selected => {
            let obj = {...selected};
            selectedIds?.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });

        setloading(false);
        handleFilterItems(option);
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'item_created') {
            setShowMessage({
                visible: true,
                message: I18n.item_created_successfully,
            });
        } else if (renderToast === 'item_update') {
            setShowMessage({
                visible: true,
                message: I18n.item_updated_successfully,
            });
        } else if (renderToast === 'item_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.item_saved_into_draft,
            });
        } else if (renderToast === 'item_published_successfully') {
            setShowMessage({
                visible: true,
                message: I18n.item_published_successfully,
            });
        }
    }, [renderToast]);

    let nonIdealContent =
        option === 'sold_out'
            ? {
                  heading: I18n.sold_out,
                  subHeading: I18n.item_sold_out_please_add_more_items,
                  iconName: 'soldOutIcon',
              }
            : option === 'low_stock'
            ? {
                  heading: I18n.low_stock,
                  subHeading: I18n.item_is_on_low_stock_please_add_more_items,
                  iconName: 'lowStockIcon',
              }
            : option === 'archived'
            ? {
                  heading: I18n.archived,
                  subHeading: I18n.no_archive_items_found,
                  iconName: 'archiveIcon',
              }
            : option === 'drafts_saved'
            ? {
                  heading: I18n.drafts_saved,
                  subHeading: I18n.no_draft_items_found,
                  iconName: 'draftIcon',
              }
            : {
                  heading: I18n.search,
                  subHeading: I18n.we_couldnt_find_any_results_for_your_search,
                  iconName: 'seacrhNotFoundIcon',
              };

    useEffect(() => {
        if (isFetching) {
            fetchData(currentPage);
        }
    }, [currentPage, isFetching]);

    const fetchData = async pageNum => {
        const {data} = await queryItems(option, search.toLowerCase(), pageNum, ITEMS_PER_PAGE);
        if (data.length === 0) {
            setHasMore(false);
        } else {
            setProducts(prevData => [...prevData, ...data]);
        }
        setIsFetching(false);
    };

    useEffect(() => {
        handleFilterItems();
    }, [activeProducts]);

    const fetchMoreData = () => {
        setIsFetching(true);
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handleArchiveUnarchive = async pageNum => {
        const {data, totalCount} = await queryItems(option, search.toLowerCase(), pageNum, ITEMS_PER_PAGE);
        setProducts(data);
        setCurrentPage(pageNum);
        setHasMore(totalCount > ITEMS_PER_PAGE);
        setIsFetching(false);
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : activeProducts.length ? (
                <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                    <Header
                        type="search"
                        leftCta={{
                            title:
                                option === 'sold_out'
                                    ? I18n.sold_out
                                    : option === 'low_stock'
                                    ? I18n.low_stock
                                    : option === 'archived'
                                    ? I18n.archived
                                    : option === 'drafts_saved'
                                    ? I18n.drafts_saved
                                    : I18n.all_items,

                            name: 'downIcon2',
                            fill: theme.white,
                            width: '20',
                            height: '20',
                            viewBox: '0 0 18 18',
                            option: filterOption,
                        }}
                        search={{
                            type: 'pos',
                            placeholder: I18n.search_by_name,
                            prefix: {
                                name: 'search2Icon',
                                fill: theme.white,
                            },
                            onChange: onChange,
                        }}
                        title={`${I18n.items} (${products?.length})`}
                        className={'borderRadiusTopleftTopRigt'}
                        rightCta={userPermission?.canCrud ? {title: I18n.create_item_new, action: handleCreateItem} : null}
                        actionCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.actions,
                                      name: 'downIcon2',
                                      fill: theme.white,
                                      width: '20',
                                      height: '20',
                                      viewBox: '0 0 18 18',
                                      option: actionList,
                                  }
                                : null
                        }
                    />

                    <div className="borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <TableHeader className={`sixColumn`} style={{borderColor: theme.posRightBg}}>
                                        <div
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                            className="BoxWidth justifyStart itemListBox">
                                            <CheckBox
                                                className={'itemlistCheckBox'}
                                                checkboxLabel={
                                                    <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                                        {I18n.name}
                                                    </p>
                                                }
                                                onChange={handleSelectAll}
                                                checked={checkedClick()}
                                            />
                                        </div>

                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.category}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.stock}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.low_stock}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.price}
                                            </p>
                                        </div>
                                        {userPermission?.canCrud && (
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.action}
                                                </p>
                                            </div>
                                        )}
                                    </TableHeader>

                                    <div id="scrollableDiv" style={{height: DEVICE_HEIGHT - 290, overflow: 'auto'}}>
                                        {products?.length === 0 ? (
                                            <NonIdealScreen
                                                heading={search ? I18n.search : nonIdealContent?.heading}
                                                subHeading={search ? I18n.we_couldnt_find_any_results_for_your_search : nonIdealContent?.subHeading}
                                                name={search ? 'seacrhNotFoundIcon' : nonIdealContent?.iconName}
                                                fill={theme.white}
                                                isMultiStep={false}
                                                tablenonIdeal={true}
                                            />
                                        ) : (
                                            <InfiniteScroll
                                                className="infiniteScrollBar"
                                                dataLength={products.length}
                                                next={fetchMoreData}
                                                hasMore={hasMore}
                                                loader={<h4>Loading...</h4>}
                                                scrollableTarget="scrollableDiv">
                                                {products?.map((val, index) => (
                                                    <List
                                                        handleChecked={handleChecked}
                                                        selecteditem={selecteditem}
                                                        product={val}
                                                        key={index}
                                                        setSelecteditem={setSelecteditem}
                                                        hanldeChange={handleArchiveUnarchive}
                                                        userPermission={userPermission}
                                                    />
                                                ))}
                                            </InfiniteScroll>
                                        )}
                                    </div>
                                    <ToastBox
                                        className="addSuccessfullyToast"
                                        ToastVisiable={showMessage.visible}
                                        bodyPara={showMessage.message}
                                        setShowMessage={setShowMessage}
                                        showIcon={true}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            ) : (
                <NonIdealScreen
                    heading={I18n.items}
                    subHeading={I18n.create_items_and_start_selling}
                    name="itemNewIcon"
                    fill={theme.white}
                    primaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.import_items,
                                  action: () => handleImport(),
                              }
                            : null
                    }
                    secondaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_item_new,
                                  action: handleCreateItem,
                              }
                            : null
                    }
                    isMultiStep={false}
                />
            )}
            <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} type={'item'} />

            <MessageModal
                className="messageModal"
                setToggle={setArchiveModal}
                toggle={archiveModal}
                description={option === 'archived' ? I18n.are_you_sure_you_want_to_unarchive_the_selected_product : I18n.are_you_sure_you_want_to_archive_the_selected_product}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => handleArchivedAction(selecteditem),
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    activeProducts: observeActiveProducts().observeWithColumns(['archive']),
    all_product: observeAllUnAchriveProducts(),
}));

export default enhance(ItemList);
