import {Model} from '@nozbe/watermelondb';
import {ORDER_LINE_SCHEMA} from '../schema';
import {field, text, date, relation, writer, json} from '@nozbe/watermelondb/decorators';

export default class OrderLine extends Model {
    static table = ORDER_LINE_SCHEMA;

    static associations = {
        order: {type: 'belongs_to', key: 'order_id'},
        product: {type: 'belongs_to', key: 'product_id'},
    };

    @text('name') name;
    @field('quantity') quantity;
    @field('base_price') base_price;
    @field('aggregate_discount') aggregate_discount;
    @field('aggregate_tax') aggregate_tax;
    @field('sub_total') sub_total;
    @field('total') total;
    @field('selling_price') selling_price;
    @field('cost_price') cost_price;
    @field('discount') discount;
    @field('discount_type') discount_type;
    @field('unit') unit;
    @field('rate') rate;
    @field('is_open') is_open;
    @field('tax_name') tax_name;
    @field('tax_rate') tax_rate;
    @field('is_tax_inclusive') is_tax_inclusive;
    @date('transacted_at') transacted_at;
    @field('archive') archive;
    @text('note') note;
    @field('void') void;
    @text('void_reason') void_reason;

    @json('order_line_modifiers', val => val) order_line_modifiers;

    @relation('order', 'order_id') order;
    @relation('product', 'product_id') product;

    @writer async updateQuantity(val) {
        return await this.update(line => {
            line.quantity = val;
        });
    }

    @writer async updateUnitAndRate(unit, rate) {
        return await this.update(line => {
            line.unit = unit;
            line.rate = rate;
        });
    }

    @writer async updateName(name) {
        return await this.update(line => {
            line.name = name;
        });
    }

    @writer async updateNote(note) {
        return await this.update(line => {
            line.note = note;
        });
    }

    @writer async voidOrderLine(reason) {
        return await this.update(line => {
            line.void = true;
            line.void_reason = reason;
        });
    }

    @writer async archiveOrderLine() {
        return await this.update(order => {
            order.archive = true;
        });
    }

    @writer async deleteOrderLine() {
        return await this.markAsDeleted();
    }

    @writer async updateItemDistcount(obj) {
        return await this.update(line => {
            line.discount = obj.value;
            line.discount_type = obj.type;
        });
    }

    @writer async updatePrice(val) {
        return await this.update(line => {
            line.selling_price = val;
            line.base_price = val;
        });
    }

    @writer async updateOrderLineModifiers(data) {
        return await this.update(line => {
            line.order_line_modifiers = data;
        });
    }

    @writer async updateItem(obj) {
        return await this.update(line => {
            line.name = obj.name;
            line.cost_price = obj.cost_price;
            line.selling_price = obj.selling_price;
            line.discount = obj.discount;
            line.discount_type = obj.discount_type;
            line.quantity = obj.quantity;
            line.is_open = obj.is_open;
            line.note = obj.note;
        });
    }
}
