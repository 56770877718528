import Filter from '../../../../../common/Filter';
import React, { useState } from 'react';

let AMOUNT_TYPE = {
    "eq": { name: 'Is equal to', value: 'eq' },
    // "between": { name: 'Is between', value: 'between' },
    "gt": { name: 'Is greater than', value: 'gt' },
    "lt": { name: 'Is less than', value: 'lt' },
};

let BANKS = {
    "bank_al_falah": { name: 'Bank Al Falah', value: 'bank_al_falah' },
    "meezan_bank": { name: 'Meezan Bank', value: 'meezan_bank' },
    "easypaisa": { name: 'EasyPaisa', value: 'easypaisa' },
    "jazzcash": { name: 'JazzCash', value: 'jazzcash' },
    "all": { name: 'All Banks', value: 'all' },
};

const FilterItem = ({ getSortedPayments }) => {

    const [filterData, setFilterData] = useState({
        amount: { type: 'eq', total: '' },
        date: [null, null],
        method: 'all',
        type: 'all',
    });

    const handleFilters = (type, value) => {
        setFilterData(prev => {
            let all = { ...prev };
            all[type] = value;
            return all;
        });
    };

    const onApply = (filters) => {
        const type = filters['Filter type'] || 'eq';
        const method = filters['Bank type'] || null;
        const total = filters['Amount'] || 0;

        getSortedPayments({ ...filterData, method, total, type });
    };

    let FILTER_DATA = [
        {
            action: (value) => handleFilters('date', value),
            type: 'date',
        },
        {
            subTitle: Number(filterData.amount.total) > 0 ? AMOUNT_TYPE[filterData.amount.type].name : null,
            type: 'radioInput',
            ctaDisabled: false,
            title: 'amount',
            onApply,
            textInputs: [
                {
                    placeholder: "Select amount type",
                    data: Object.values(AMOUNT_TYPE),
                    label: "Filter type",
                    inputType: "select",
                    suffix: 'downIcon2',
                },
                {
                    placeholder: "Enter amount",
                    label: "Amount",
                    type: 'numeric'
                },
            ]
        },
        {
            subTitle: filterData['type'] != 'all' ? filterData['type'] : null,
            type: 'radioList',
            title: 'type',
            option: [
                {
                    checked: Boolean(filterData['type'] == 'all'),
                    action: () => handleFilters('type', 'all'),
                    title: 'all'
                },
                {
                    checked: Boolean(filterData['type'] == 'money_in'),
                    action: () => handleFilters('type', 'money_in'),
                    title: 'money_in',
                },
                {
                    checked: Boolean(filterData['type'] == 'money_out'),
                    action: () => handleFilters('type', 'money_out'),
                    title: 'money_out',
                },
            ]
        },
        {
            subTitle: filterData.method != 'all' ? BANKS[filterData.method].name : null,
            type: 'radioInput',
            ctaDisabled: false,
            title: 'method',
            onApply,
            textInputs: [
                {
                    placeholder: "Select bank type",
                    data: Object.values(BANKS),
                    inputType: "select",
                    suffix: 'downIcon2',
                    label: "Bank type",
                }
            ]
        },
    ];
    return <Filter filters={FILTER_DATA} />
};

export default FilterItem