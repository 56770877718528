import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT, exportDataToCSV, uuid} from '../../../../../constants';
import Icon from '../../../../../assets/icons';
import withObservables from '@nozbe/with-observables';
import {observeActiveCustomers, observeArchivedCustomers, observeDraftCustomers} from '../../../../../pos-core/database/helpers';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import CsvUploadModal from '../../../../common/CsvUploadModal';
import usePermission from '../../../../../hooks/usePermission';

const CustomerList = ({customers, archivedList, draftList}) => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('customers');

    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_customers);
    const [selected, setSelected] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [cvsModal, setCvsModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [customerCount, setCustomerCount] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    useEffect(() => {
        let count;
        if (filter === I18n.archived) {
            count = archivedList.length;
        } else if (filter === I18n.drafts_saved) {
            count = draftList.length;
        } else {
            count = customers.length;
        }
        setCustomerCount(count);
    }, [filter, customers, archivedList, draftList]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'customer_created') {
            setShowMessage({
                visible: true,
                message: I18n.customer_created_successfully,
            });
        } else if (renderToast === 'customer_update') {
            setShowMessage({
                visible: true,
                message: I18n.customer_updated_successfully,
            });
        } else if (renderToast === 'customer_published_successfully') {
            setShowMessage({
                visible: true,
                message: I18n.customer_published_successfully,
            });
        } else if (renderToast === 'customer_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.customer_saved_into_draft,
            });
        }
    }, [renderToast]);

    const toggleArchiveModal = () => {
        if (Object.values(selected)?.length) setArchiveModal(x => !x);
    };

    function handleSearch(val) {
        const filterList = filter === I18n.archived ? archivedList : customers;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values) || x?.phone_number?.includes(values));
    }

    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };

    function handleImportCustomer() {
        setCvsModal(x => !x);
    }

    function handleExportCustomer() {
        let csvdata;
        if (Object.keys(selected).length) {
            csvdata = Object.values(selected);
        } else {
            csvdata = filter == I18n.archived ? archivedList : customers;
        }
        exportDataToCSV('customer', csvdata);
    }

    const handleCreateCustomer = () => {
        navigate('/customers/create');
    };

    const handleArchivedAction = async selected => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async item => {
                await item.archiveCustomer(!item.archive);
                selectedIds.push(item.id);
            }),
        );
        setSelected(selected => {
            let obj = {...selected};
            selectedIds.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });

        setloading(false);
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelected(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const handleSelectAll = () => {
        let list = filter == I18n.archived ? archivedList : customers;
        if (Object.keys(selected).length != list.length) {
            list?.map(val => {
                setSelected(values => {
                    let obj = {...values};
                    obj[val.id] = val;
                    return obj;
                });
            });
        } else {
            setSelected({});
        }
    };

    const checkedClick = () =>
        Object.keys(selected)?.length > 0 && Object.keys(selected)?.length === (filter == I18n.archived ? archivedList.length : customers.length) ? true : false;

    const onChange = (label, value) => setText(value);

    const filterOption = [
        {
            title: I18n.all_customers,
            action: () => handleFilter(I18n.all_customers),
            checked: filter == I18n.all_customers || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    const actionList = [
        {
            title: I18n.import_library,
            action: handleImportCustomer,
        },
        {
            title: I18n.export_library,
            action: handleExportCustomer,
        },
        {
            title: filter == I18n.archived ? I18n.unarchive : I18n.archive,
            action: toggleArchiveModal,
        },
    ];

    const renderList = text ? searchList : filter === I18n.archived ? archivedList : filter === I18n.drafts_saved ? draftList : customers;

    return (
        <>
            {loading ? (
                <Loading />
            ) : customers.length || archivedList.length ? (
                <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                    <Header
                        type="search"
                        title={`${I18n.customers} (${customerCount})`}
                        search={{
                            type: 'pos',
                            placeholder: I18n.search_by_name,
                            prefix: {
                                name: 'search2Icon',
                                fill: theme.white,
                            },
                            onChange: onChange,
                        }}
                        className={'borderRadiusTopleftTopRigt'}
                        leftCta={{
                            title: filter,
                            name: 'downIcon2',
                            fill: theme.white,
                            width: '20',
                            height: '20',
                            viewBox: '0 0 18 18',
                            option: filterOption,
                        }}
                        rightCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.create_customer,
                                      action: handleCreateCustomer,
                                  }
                                : null
                        }
                        actionCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.actions,
                                      name: 'downIcon2',
                                      fill: theme.white,
                                      width: '20',
                                      height: '20',
                                      viewBox: '0 0 18 18',
                                      option: actionList,
                                  }
                                : null
                        }
                    />

                    <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <TableHeader className={`eightColumn`} style={{borderColor: theme.inputBorder}}>
                                        <div className="checkboxCol">
                                            <CheckBox
                                                checkboxLabel={
                                                    <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                                        {I18n.customer}
                                                    </p>
                                                }
                                                onChange={handleSelectAll}
                                                checked={checkedClick()}
                                            />
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.phone}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.last_visited}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.visits}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.created}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.average_spend}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.total_spent}
                                            </p>
                                        </div>
                                        {userPermission?.canCrud && (
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.action}
                                                </p>
                                            </div>
                                        )}
                                    </TableHeader>
                                    <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                        {renderList?.length === 0 ? (
                                            <NonIdealScreen
                                                heading={
                                                    text
                                                        ? I18n.search
                                                        : filter == I18n.archived
                                                        ? I18n.archive_customer
                                                        : filter == I18n.drafts_saved
                                                        ? I18n.draft_customer
                                                        : I18n.customer
                                                }
                                                subHeading={
                                                    text
                                                        ? I18n.we_couldnt_find_any_results_for_your_search
                                                        : filter == I18n.archived
                                                        ? I18n.no_archive_customers_found
                                                        : filter == I18n.drafts_saved
                                                        ? I18n.no_draft_customers_found
                                                        : I18n.no_customer_found
                                                }
                                                name={text ? 'seacrhNotFoundIcon' : filter == I18n.archived ? 'archiveIcon' : 'draftIcon'}
                                                fill={theme.white}
                                                isMultiStep={false}
                                                tablenonIdeal={true}
                                            />
                                        ) : (
                                            renderList?.map((val, index) => (
                                                <List
                                                    handleChecked={handleChecked}
                                                    selected={selected}
                                                    setSelected={setSelected}
                                                    key={index}
                                                    customer={val}
                                                    filter={filter}
                                                    setShowMessage={setShowMessage}
                                                    userPermission={userPermission}
                                                />
                                            ))
                                        )}
                                    </div>
                                    <ToastBox
                                        className="addSuccessfullyToast"
                                        ToastVisiable={showMessage.visible}
                                        bodyPara={showMessage.message}
                                        setShowMessage={setShowMessage}
                                        showIcon={true}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            ) : (
                <NonIdealScreen
                    heading={I18n.customers}
                    subHeading={I18n.create_customer_and_add_them_into_sales}
                    name="customerSquareIcon"
                    fill={theme.white}
                    secondaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_customer,
                                  action: handleCreateCustomer,
                              }
                            : null
                    }
                    primaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.import_customers,
                                  action: () => handleImportCustomer(),
                              }
                            : null
                    }
                    isMultiStep={false}
                />
            )}

            <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} type={'customer'} />

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={filter == I18n.archived ? I18n.are_you_sure_you_want_to_unarchive_the_selected_Customer : I18n.are_you_sure_you_want_to_archive_the_selected_Customer}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => {
                        handleArchivedAction(selected);
                    },
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    customers: observeActiveCustomers(),
    archivedList: observeArchivedCustomers(),
    draftList: observeDraftCustomers(),
}));

export default enhance(CustomerList);
