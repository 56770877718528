import Icon from "../../../../../assets/icons";
import { useThemeContext } from "../../../../../context";

const ListItem = ({ value, handleDelete }) => {
  const { theme } = useThemeContext();
  return (
    <div className="optionListInner" style={{ borderColor: theme.topBarBG }}>
      <p className="fontSize14 marBot0" style={{ color: theme.white }}>
        {value}
      </p>
      <p className="cursorPointer marBot0">
        <Icon
          name="deleteIcon"
          fill={theme.red}
          width="19"
          height="20"
          viewBox={"0 0 15 16"}
          onClick={() => handleDelete(value)}
        />
      </p>
    </div>
  );
};

export default ListItem;
