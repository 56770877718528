import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const List = ({ val, handleClick, data }) => {
  const { theme } = useThemeContext();

  let isSelected = data && data?._raw.id == val._raw.id ? true : false;

  const handleSelect = (val) => {
    if (isSelected) {
      handleClick("remove_category");
    } else {
      handleClick(val);
    }
  };
  return (
    <div onClick={() => handleSelect(val)} className="orderDiscountModalList">
      <p className="marBot0" style={{ color: theme.text }}>
        <span className="fontSize16 fontWeight500">{val.name}</span>
      </p>

      <p className="marBot0">
        {isSelected && (
          <span>
            <Icon
              name="tickCircleIcon"
              fill={theme.brightGreen}
              width={"24"}
              height={"24"}
              viewBox={"0 0 24 24"}
            />
          </span>
        )}
      </p>
    </div>
  );
};

export default List;
