import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../../../context";
import IconAction from "./IconAction";

const BackHeader = ({ action, onClick, type }) => {

    const { theme } = useThemeContext();

    let navigate = useNavigate();

    const handleNavigation = () => {
        if (action) {
            action()
        } else {
            navigate(-1)
        }
    }

    return (
        <section className="backBox">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className={type != 'skip' ? "backInnerBox" : "backInnerBox skipInnerBox"}>
                            <p className="marBot0 cursorPointer" onClick={handleNavigation}>
                                {
                                    type != 'skip'
                                        ? <IconAction type={type} />
                                        : null
                                }
                                <span className={`${type != 'skip' ? "marLeft10" : "marRight10"} white marBot0 fontSize20`}>
                                    {
                                        type == 'skip'
                                            ? "Skip"
                                            : type === "back"
                                                ? "Back"
                                                : type === "close"
                                                    ? "Close"
                                                    : null
                                    }
                                </span>
                                {type == 'skip'
                                    ? <IconAction type={type} />
                                    : null}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BackHeader;