import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { MONTHS } from "../../../constants";
import { Container } from "react-bootstrap";

const LineGraph = ({ data }) => {
    const uData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    return (
        <>
            <div className="lineChart">
                <LineChart
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    height={120}
                    series={[
                        {
                            data: Object.values(data).map(item => item.amount),
                            color: "#00AEEF",
                            showMark: false,
                            area: true,

                        },
                    ]}
                    xAxis={[
                        {
                            data: Object.keys(data),
                            scaleType: "point",
                        },
                    ]}
                    sx={{
                        tspan: { display: "none" },
                        line: { display: "none" },
                    }}
                />
            </div>
        </>
    );
};

export default LineGraph;
