import Icon from '../../../assets/icons';
import {Col} from 'react-bootstrap';
import {Button} from '../../common';
import {useAuthContext, useLanguageContext, useMenuContext, useThemeContext} from '../../../context';
import {useLocation} from 'react-router-dom';
import {HEADER_VALUES} from '../../../constants';
import {useEffect} from 'react';

const NavbarLeft = () => {
    const {user, userRole, business, setPersistData} = useAuthContext();
    const {theme} = useThemeContext();
    const {isVisible, setIsVisible} = useMenuContext();
    const {I18n} = useLanguageContext();

    const location = useLocation();

    const toggleMenu = () => setIsVisible(x => !x);

    return (
        <Col md={6}>
            <div className="headerLeftSide">
                <Button
                    className="marBot0"
                    type="iconButton"
                    icon_name="menuIcon"
                    icon_fill={theme.white}
                    icon_width={'17'}
                    icon_height={'13'}
                    icon_viewBox={'0 0 17 13'}
                    handleClick={toggleMenu}
                />

                <div className="appBreadcrum">
                    <p className="marBot0 marLeft10 fontSize20 " style={{color: theme.white}}>
                        {HEADER_VALUES[location.pathname.slice(1).split('/')[0]]?.name || 'POS'}
                    </p>
                </div>
                <p className="topBarseperator  height20"></p>

                <div className="posManagerName">
                    <Button
                        className={`headerCustomerButton`}
                        title={`${user?.first_name} ${user?.last_name}`}
                        type="buttonTitleIcon"
                        icon_name="profile"
                        icon_fill={theme.white}
                        icon_width={'18'}
                        icon_height={'19'}
                        icon_viewBox={'0 0 18 19'}
                    />
                    <p className="topBarseperator  height20"></p>

                    <p className="marBot0 marLeft0 fontSize14" style={{color: theme.white}}>
                        {business?.location_name}
                    </p>
                </div>
            </div>
        </Col>
    );
};

export default NavbarLeft;
