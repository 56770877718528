import {useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';
import newImagePlaceholder from '../../../assets/images/newImagePlaceholder.svg';
import {formatNum} from '../../../constants';
import withObservables from '@nozbe/with-observables';
import {of, switchMap} from 'rxjs';

const OrderDetailList = ({line, product_template}) => {
    const {theme} = useThemeContext();

    return (
        <TableBody className={`detailTbleColumn`} style={{borderColor: theme.inputBorder}}>
            <div className="BoxWidth justifyStart  marLeft10 itemListBox">
                <p className="fontSize14 " style={{color: theme.white}}>
                    <span className="itemListImage">
                        {product_template?.image ? (
                            <img src={product_template?.image} style={{resizeMode: 'cover'}} />
                        ) : (
                            <img src={newImagePlaceholder} style={{resizeMode: 'cover'}} />
                        )}
                    </span>
                    <span className="OneLineTruncate">{line.name}</span>
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {`${line.quantity} ${line.unit}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {line.discount_type === 'amount' && `Rs.`} {line.discount || 0} {line.discount_type === 'percentage' && ` %`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {formatNum((line.selling_price / line.rate) * line.quantity)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    paid
                </p>
            </div>
        </TableBody>
    );
};

const enhance = withObservables(['line'], ({line}) => ({
    product_template: line.product.observe().pipe(
        switchMap(product => {
            if (!line.is_open) {
                return product.product_template.observe();
            } else {
                return of(null);
            }
        }),
    ),
}));
export default enhance(OrderDetailList);
