import {
  getFilteredPayments,
  getPaginatedPayments,
} from "../../pos-core/database/helpers";
import { useEffect, useState } from "react";

const usePayments = (allPayments, payments) => {
  const [filteredObj, setFilteredObj] = useState({
    active: false,
    filteredList: [],
  });
  const [overView, setOverView] = useState({
    totalCompleted: 0,
    totalCompTrans: 0,
    totalPenTrans: 0,
    totalRefTrans: 0,
    totalPending: 0,
    totalRefund: 0,
    totalTrans: 0,
    total: 0,
  });

  useEffect(() => {
    paymentOverview();
  }, []);

  const paymentOverview = () => {
    let totalTrans = allPayments.length,
      totalCompleted = 0,
      totalCompTrans = 0,
      totalPenTrans = 0,
      totalRefTrans = 0,
      totalPending = 0,
      totalRefund = 0,
      total = 0;

    allPayments.map((item) => {
      total += item.amount;
      console.log("item.status", item.status);
      if (item.status == "complete") {
        totalCompleted += item.amount;
        totalCompTrans += 1;
      } else if (item.status == "pending") {
        totalPending += item.amount;
        totalPenTrans += 1;
      } else if (item.status == "refund") {
        totalRefund += item.amount;
        totalRefTrans += 1;
      }
    });

    setOverView({
      totalCompleted,
      totalCompTrans,
      totalPenTrans,
      totalRefTrans,
      totalPending,
      totalRefund,
      totalTrans,
      total,
    });
  };

  const getSearchItem = async (value) => {
    if (value) {
      const filterPromise = await Promise.all(
        allPayments.map(async (pay) => {
          const customer = await pay.customer.fetch();

          return (
            pay.id.includes(value) ||
            customer?.name.includes(value) ||
            customer?.phone_number.includes(value)
          );
        })
      );

      const filteredList = allPayments.filter(
        (pay, index) => filterPromise[index]
      );
      setFilteredObj({ active: true, filteredList });
    } else setFilteredObj({ active: false, filteredList: [] });
  };

  const getSortedPayments = async (filters) => {
    let { payment_method, status, total, type, date } = filters || {};

    if (
      payment_method == "all" &&
      status == "all" &&
      Number(total) == 0 &&
      date[1] == null
    )
      setFilteredObj({ active: false, filteredList: [] });
    else {
      payment_method = payment_method == "all" ? null : payment_method;
      status = status == "all" ? null : status;
      total = Number(total);

      let filteredList = await getFilteredPayments(
        payment_method,
        status,
        type,
        Number(total),
        date
      );
      setFilteredObj({ active: true, filteredList });
    }
  };

  const getPagePayments = (current, nextPage) => {
    console.log("=========== current, nextPage", current, nextPage);
    // payments = getPaginatedPayments();
  };

  return {
    ...filteredObj,
    ...overView,
    payments,
    getSortedPayments,
    getPagePayments,
    getSearchItem,
  };
};

export default usePayments;
