import {database} from '../index';
import {CATEGORY_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';
import {uuid} from '../../constants';

const categories = database.collections.get(CATEGORY_SCHEMA);

export const observeActiveCategories = (currentPage = 0, itemsPerPage = 10) =>
    categories.query(Q.where('archive', Q.notEq(true)), Q.skip(currentPage * itemsPerPage), Q.take(itemsPerPage), Q.sortBy('name', Q.asc)).observe();

export const observeAllCategories = () => categories.query().observe();

export const observeAllActiveCategories = () => categories.query(Q.where('archive', Q.notEq(true))).observe();

export const observeArchivedCategories = () => categories.query(Q.where('archive', Q.eq(true))).observe();

export const isCategoryExist = name => categories.query(Q.where('name', Q.eq(name))).fetch();

export const queryCategories = async (selectedFilter, searchTerm, currentPage = 0, itemsPerPage = 10) => {
    let filterCondition;

    switch (selectedFilter) {
        case 'archived':
            filterCondition = Q.where('archive', Q.eq(true));
            break;
        default:
            filterCondition = Q.where('archive', Q.notEq(true));
    }

    const totalCountQuery = categories.query(Q.where('name', Q.like(`%${searchTerm}%`)), filterCondition);

    const totalCount = await totalCountQuery.fetchCount();

    const data = await categories
        .query(Q.where('name', Q.like(`%${searchTerm}%`)), filterCondition, Q.skip(currentPage * itemsPerPage), Q.take(itemsPerPage), Q.sortBy('name', Q.asc))
        .fetch();

    return {data, totalCount};
};

const prepareInsertionCategory = (data, account) => {
    return data?.map(categoryName => {
        try {
            return categories.prepareCreate(field => {
                field.account.set(account);
                field._raw.id = uuid();
                field.name = categoryName;
                field.color_code = '#1759B1';
            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateCategory = async (data, account) => {
    await database.write(async () => {
        const allRecords = prepareInsertionCategory(data, account);
        await database.batch(allRecords);
    });
};
