import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSearch } from "../../../../../hooks";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import { DEVICE_HEIGHT } from "../../../../../constants";
import { SearchBox } from "../../../../common";
import { PosAppModal } from "../../../../common/V2";

const Modal = ({
  toggle,
  setToggle,
  handleConfirm,
  title,
  searchPlaceholder,
  createButtonTitle,
  bottomCancelCta,
  bottomSaveCta,
  onChange,
  children,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  function handleClose(event) {
    setToggle(false);
  }

  return (
    <div>
      <PosAppModal
        className="selectCustomerModal"
        toggle={toggle}
        backCta={{
          name: "backArrowIcon",
          fill: theme.barclaysBlue,
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
        }}
        handleClose={handleClose}
        rightCta={{
          title: createButtonTitle,
          action: handleConfirm,
          iconName: "add2Icon",
          fill: theme.barclaysBlue,
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
        }}
        bottomCancelCta={
          bottomCancelCta
            ? {
                title: bottomCancelCta.title,
                action: () => bottomCancelCta.action(),
              }
            : null
        }
        bottomSaveCta={
          bottomSaveCta
            ? {
                title: bottomSaveCta.title,
                action: () => bottomSaveCta.action(),
              }
            : null
        }
        title={title}
      >
        <>
          <SearchBox
            type="pos"
            placeholder={searchPlaceholder}
            prefix={{
              name: "search2Icon",
              fill: theme.white,
            }}
            onChange={onChange}
          />

          <Container fluid>
            <Row className="justify-content-md-center">
              <Col md={12}>
                <div
                  className="selectCustomerListBox tableListMainBox"
                  style={{ height: DEVICE_HEIGHT - 150 }}
                >
                  {children}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      </PosAppModal>
    </div>
  );
};

export default Modal;
