import React from "react";
import { useLanguageContext, useThemeContext } from "../../../../context";
import Button from "../../Button";
import jazzCashMPIN from "../../../../assets/images/jazzCashMPIN.png";

const MPINverify = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <>
      <div className="mpinConatiner">
        <p
          className="marTop30 fontSize20 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {I18n.customer_se_kahein_woh_apni_Jazzcash_app_se_MPIN_verify_kare}
        </p>

        <p
          className="fontSize16 fontWeight400"
          style={{ color: theme.lightGrayTwo }}
        >
          {I18n.waiting_for_MPIN_confirmation}
        </p>
        <div className="mpinImageBox">
          <img src={jazzCashMPIN} alt="JazzCashLogo" title="JazzCashLogo" />
        </div>

        <div>
          <p
            className="fontSize16 fontWeight400 textCenter marTop20"
            style={{ color: theme.white }}
          >
            {I18n.code_nahi_mila}
          </p>
          <div className="callAndSmsBox">
            <Button
              className={`headerCustomerButton`}
              title={I18n.request_MPIN}
              type="buttonTitleIcon"
              icon_name="smsIcon"
              icon_fill={theme.barclaysBlue}
              icon_width={"22"}
              icon_height={"17"}
              icon_viewBox={"0 0 22 17"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MPINverify;
