import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT, exportDataToCSV} from '../../../../../constants';
import Icon from '../../../../../assets/icons';
import withObservables from '@nozbe/with-observables';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import CsvUploadModal from '../../../../common/CsvUploadModal';
import {observeActiveFloorPlan} from '../../../../../pos-core/database/helpers/floor_plan';
import usePermission from '../../../../../hooks/usePermission';

const FloorPlans = ({floorPlans}) => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);

    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_customers);
    const [selected, setSelected] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [cvsModal, setCvsModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [customerCount, setCustomerCount] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const userPermission = usePermission('floor-plans');

    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';

        setText(value);
    };

    const filterOption = [
        {
            title: I18n.all_customers,
            action: () => handleFilter(I18n.all_customers),
            checked: filter == I18n.all_customers || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    function handleSearch(val) {
        return floorPlans.filter(x => x?.name?.toLowerCase().includes(val) || x?.phone_number?.includes(val));
    }

    const handleCreateFloor = () => {
        // handleChange
    };

    return loading ? (
        <Loading />
    ) : floorPlans.length ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.customers} (${customerCount})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name_or_order_number,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    rightCta={{
                        title: I18n.create_customer,
                        action: handleCreateFloor,
                    }}
                />

                <div className="itemListContainer borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 160}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`threeColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="BoxWidth justifyStart">
                                        <p className="fontSize16 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                                            {I18n.name}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                                            {I18n.type}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                                            {I18n.actions}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                    {(text ? searchList : floorPlans).map((val, index) => (
                                        <List floorPlan={val} />
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.customers}
                subHeading={I18n.customer}
                name="customerSquareIcon"
                fill={theme.white}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                              title: 'Create Floor Plans',
                              action: handleCreateFloor,
                          }
                        : null
                }
                // primaryCta={{
                //   title: I18n.import_customers,
                //   action: () => handleImportCustomer(),
                // }}
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    floorPlans: observeActiveFloorPlan(),
}));

export default enhance(FloorPlans);
