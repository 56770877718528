import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import AppModal from '../AppModal';
import {DEVICE_HEIGHT} from '../../../constants';
import {Button, Calculator, TextField} from '../../common';
import {useEffect, useState} from 'react';
import useFocus from '../../../hooks/useFocus';
import {PosAppModal} from '../V2';

const OrderDiscountsModal = ({toggle, setToggle, handleSave, title, showDiscountType}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {textBoxRef} = useFocus();

    const [type, setType] = useState('amount');
    const [value, setValue] = useState(0);
    const [errors, setErrors] = useState({});

    const handleClose = event => {
        setToggle(false);
        setValue(0);
    };

    const onChange = (label, val) => {
        if (type == 'percentage') {
            if (val <= 100) {
                setValue(val);
            } else {
                setErrors({
                    textBox: 'Percentage must be less than 100',
                });
            }
        } else {
            setValue(val);
        }
        setErrors({});
    };

    const handleType = val => {
        setType(val);
        setValue(0);
    };

    const handleDone = () => handleSave(type, value);
    const handleCancel = () => {
        handleClose();
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter' && value) {
            handleDone();
            handleClose();
        }
    };

    console.log('value', value);

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            handleClose={handleClose}
            bottomSaveCta={{
                title: I18n.save,
                action: () => handleDone(),
                disabled: value < 0 || Object.keys(errors)?.length,
            }}
            bottomCancelCta={{
                title: I18n.cancel,
                action: () => handleCancel(),
            }}
            title={title || I18n.order_level_discount}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <div
                            className="itemLevelPopupMain"
                            style={{
                                height: DEVICE_HEIGHT - 200,
                            }}
                            onKeyDown={handleEnterPress}>
                            <div className="discoountButtonInputBox">
                                {showDiscountType && (
                                    <div className="selectDiscountButton">
                                        <Button
                                            className={`rupeesDiscount fontWeight400 fontSize22 cursorPointer`}
                                            backgroundColor={type == 'amount' ? theme.barclaysBlue : theme.seperatorTwo}
                                            txtColor={theme.white}
                                            title={I18n.rupees}
                                            handleClick={() => handleType('amount')}
                                        />
                                        <Button
                                            className={`percentDiscount  fontWeight400 fontSize22 cursorPointer`}
                                            backgroundColor={type == 'percentage' ? theme.barclaysBlue : theme.seperatorTwo}
                                            txtColor={theme.white}
                                            title={I18n.percentage}
                                            handleClick={() => handleType('percentage')}
                                        />
                                    </div>
                                )}

                                <div className={errors?.textBox ? 'discountTextFeildBox discountErrorTextFeildBox' : 'discountTextFeildBox'}>
                                    <TextField
                                        onChange={onChange}
                                        label={type == 'amount' ? I18n.rupees : I18n.percentage}
                                        value={value}
                                        maxLength={10}
                                        error={errors?.textBox}
                                        autoFocus={true}
                                        type="numeric"
                                        inputRef={textBoxRef}
                                    />
                                </div>
                            </div>

                            <div className="billScreenCalculator">
                                <Calculator className={`width55`} columnFour={false} setState={setValue} state={value} maxLength={10} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PosAppModal>
    );
};

export default OrderDiscountsModal;
