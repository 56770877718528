import React from 'react';
import {useThemeContext, useLanguageContext} from '../../../../../context';
import {DEVICE_HEIGHT, KDSIntegrations} from '../../../../../constants';
import Icon from '../../../../../assets/icons';
import List from '../List';

const DeviceComponent = ({handleDeviceStep, setShowMessage, deviceData}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {name, key_expired} = deviceData || {};

    return (
        <>
            <div className="deviceComponentWrapper" style={{height: DEVICE_HEIGHT - 110}}>
                <div className="deviceBox">
                    <div className="flex verticalCenter justifySpacebetween">
                        <div className="flex verticalCenter gap10">
                            <Icon name="tickFilledNewIcon" width={'20'} height={'20'} viewBox={'0 0 20 20'} />
                            <p className="marBot0 fontSize20 fontWeight500" style={{color: theme.white}}>
                                {deviceData ? name : I18n.device_created}
                            </p>
                        </div>

                        <div>
                            <p className="marBot0 fontSize16" style={{color: theme.white}}>
                                {deviceData ? (key_expired ? I18n.paired : I18n.unpaired) : I18n.device_created}
                            </p>
                        </div>
                    </div>
                    <div>
                        {!key_expired && (
                            <p className="marBot0 fontSize22" style={{color: '#4E515A'}}>
                                {I18n.connect_your_kds_device_with_code}
                            </p>
                        )}

                        <p className="headSeperator"></p>
                    </div>

                    <div>
                        {KDSIntegrations.map(item => (
                            <List item={item} handleDeviceStep={handleDeviceStep} setShowMessage={setShowMessage} deviceData={deviceData} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeviceComponent;
