import { Container, Row, Col } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../context";
import AppModal from "../AppModal";
import { Header } from "../../common";
import { observeActiveCustomers } from "../../../pos-core/database/helpers";
import Listitem from "./ListItem";
import withObservables from "@nozbe/with-observables";
import { DEVICE_HEIGHT } from "../../../constants";
import { useSearch } from "../../../hooks";

const SelectCustomerModal = ({
  toggle,
  setToggle,
  customers,
  handleSelect,
  handleCreateCustomer,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const { text, setText, searchList } = useSearch(handleSearch);

  function handleClose(event) {
    setToggle(false);
  }

  const handleCreate = () => {
    handleCreateCustomer();
    handleClose();
  };

  const onChange = (event, key) => {
    let value = key || event?.target?.value || "";

    setText(value);
  };

  function handleSearch(val) {
    return customers.filter(
      (x) =>
        x.name.toLowerCase().includes(val.toLowerCase()) ||
        x.phone_number.toLowerCase().includes(val.toLowerCase())
    );
  }

  return (
    <AppModal
      className="selectCustomerModal headerButtonRadius"
      toggle={toggle}
      handleClose={handleClose}
      title={I18n.all_customers}
    >
      <>
        <Header
          type="select"
          search={{
            label: I18n.search_customer_by_name,
            onChange: onChange,
          }}
          rightCta={{
            title: I18n.create_customer,
            action: handleCreateCustomer,
          }}
        />

        <Container fluid>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <div
                className="selectCustomerListBox"
                style={{ height: DEVICE_HEIGHT - 173 }}
              >
                {(text ? searchList : customers).map((val, index) => (
                  <Listitem key={index} customer={val} onPress={handleSelect} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </AppModal>
  );
};

const enhance = withObservables([], () => ({
  customers: observeActiveCustomers(),
}));

export default enhance(SelectCustomerModal);
