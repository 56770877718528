const CheckBox = ({ label, onChange, checked, disabled, checkboxLabel , className }) => (
  <div className={`checkBoxMainBox ${checked ? "bgColor" : "bgTransparent"} ${className}`}>
    <label>
      <input
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        type="checkbox"
        name="checkbox"
      />
      {checkboxLabel}
    </label>
  </div>
);

export default CheckBox;
