import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useDiscount = (account) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [discount, setDiscount] = useState();

  useEffect(() => {
    handleDiscount(id);
  }, [id]);

  const handleDiscount = async () => {
    let discountList = await account.getDiscount(id).fetch();

    if (discountList.length) setDiscount(discountList[0]);

    setIsLoading(false);
  };

  return { isLoading, discount };
};

export default useDiscount;
