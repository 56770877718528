import {useEffect, useState} from 'react';

const useOptions = (options, handleSelect, createdOption, toggle) => {
    const [optionList, setOptionList] = useState([...options]);

    useEffect(() => {
        if (createdOption) handleSelect('new', createdOption);
    }, [createdOption]);

    useEffect(() => {
        setOptionList([...options]);
    }, [toggle]);

    const handleRemoveOption = val => {
        setOptionList(x => x.filter(y => y.name != val.name));
    };

    const handleAddOption = title => {
        setOptionList(data => {
            let arr = [...data];
            let toAdd = options.filter(x => x.name == title);
            arr.push(...toAdd);
            return arr;
        });
    };

    return {optionList, handleRemoveOption, handleAddOption};
};

export default useOptions;
