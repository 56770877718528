import {useLanguageContext, useThemeContext} from '../../../../context';
import RangePicker from '../../../common/RangePicker';
import TotalTiles from '../../../common/TotalTiles';
import {formatNum} from '../../../../constants';
import LineGraph from '../../../common/LineGraph';
import {useSaleByDate} from '../../../../hooks';
import Icon from '../../../../assets/icons';
import React, {useEffect} from 'react';

const LineGraphComponent = ({selectedLocation}) => {
    const {getSaleOverview, saleOverview, loading} = useSaleByDate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    useEffect(() => {
        onFilterDate();
    }, [selectedLocation]);

    const GRAPH_OVERVIEW = [
        {
            heading: 'gross_sales',
            label: 'gross_sales',
            percent: 25,
            amount: 4545,
            rs: true,
        },
        {
            heading: 'transactions',
            label: 'transactions',
            percent: 25,
            amount: 10,
        },
        {
            heading: 'avg_net_sale',
            label: 'avg_net_sale',
            percent: 25,
            amount: 4545,
            rs: true,
        },
        {
            heading: 'returns',
            label: 'returns',
            percent: 25,
            amount: 0,
        },
    ];

    const onFilterDate = (label = 'Today', startDate = '', endDate = '', diffInDays = 0) => getSaleOverview(label, startDate, endDate, diffInDays, selectedLocation?.id);

    return (
        <div className="lineChartMainContainer">
            <RangePicker onFilterDate={onFilterDate} />
            <div className="graphBox">
                <div className="graphboxLeft">
                    <p className="fontSize14 marBot0 fontWeight400" style={{color: theme.white}}>
                        {I18n.net_sales}
                    </p>
                    <p className="fontSize24 marTop5 marBot5 fontWeight600" style={{color: theme.white}}>
                        Rs. {formatNum(saleOverview['net_sale']?.amount || 0)}
                    </p>

                    <div className="amountIdeal amountIncrease">
                        <Icon fill={theme.brightGreen} viewBox={'0 0 7 8'} name={'upNewIcon'} height={'8'} width={'8'} />

                        <p className="fontSize14 fontWeight500 marBot0 OneLineTruncate marLeft5" style={{color: theme.brightGreen}}>
                            {saleOverview['net_sale']?.percent || 'N/A'}
                        </p>
                    </div>
                </div>

                <div className=" ">
                    <LineGraph data={saleOverview['graph_net_sales']?.list || []} />
                </div>
            </div>
            <div className="flexRow justifySpacebetween marTop5">
                {loading ? (
                    <span>Loading ...</span>
                ) : (
                    GRAPH_OVERVIEW.map((item, index) => {
                        return <TotalTiles percent={saleOverview[item.label]?.percent} amount={saleOverview[item.label]?.amount} withOutIcon={true} item={item} key={index} />;
                    })
                )}
            </div>
        </div>
    );
};

export default LineGraphComponent;
