import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header} from '../../common';
import {toSnakeCase, validateSalesTaxForm} from '../../../constants';
import {useState} from 'react';
import {Sync} from '../../../api';
import {useNavigate} from 'react-router-dom';

const CreatePurchasetaxComponent = ({tax}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {user, business, setRenderToast} = useAuthContext();

    const [name, setName] = useState(tax?.name || '');
    const [tax_rate, setTaxRate] = useState(tax?.rate || '');
    const [errors, setErrors] = useState({});
    let navigate = useNavigate();

    const onChange = (label, val) => {
        if (label == I18n.tax_name) setName(val);
        else if (label == I18n.tax_rate) setTaxRate(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (label === 'Tax rate %') {
                label = 'tax_rate';
            }
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleCreate = async () => {
        try {
            let params = {
                name,
                rate: Number(tax_rate),
            };

            const formErrors = await validateSalesTaxForm(params);
            console.log('form Errors', formErrors);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) {
                return;
            }

            let selectedTax = tax ? await tax.updateDetails(params) : await business.createPurchaseTax(params);
            let toastMessage = tax?.id ? 'tax_update' : 'tax_created';
            setRenderToast(toastMessage);
            Sync(user?.email);
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    };
    const handleEnterPress = event => {
        if (event.key === 'Enter' && name) {
            handleCreate();
        }
    };

    return (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                saveCta={{
                    title: I18n.save,
                    action: () => handleCreate(),
                }}
            />
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className="loginFormBox" onKeyDown={handleEnterPress}>
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.create_purchase_tax}
                            </h3>
                            <h3 className="fontSize20 fontWeight500 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.purchase_tax_details}
                            </h3>
                        </div>
                        <div className="itemFields">
                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField
                                        autoFocus={true}
                                        onChange={onChange}
                                        error={errors['tax_name']}
                                        label={I18n.tax_name}
                                        placeholder={I18n.enter_sales_tax_name}
                                        value={name}
                                        required={true}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField onChange={onChange} error={errors['tax_rate']} label={I18n.tax_rate} placeholder={I18n.enter_tax_rate} value={tax_rate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CreatePurchasetaxComponent;
