import {Model} from '@nozbe/watermelondb';
import {PO_LINE_SCHEMA, PURCHASE_ORDER_SCHEMA} from '../schema';
import {field, text, relation, children, date, writer, json} from '@nozbe/watermelondb/decorators';
import {sanitizeOrderDiscount, uuid} from '../../constants';

export default class PurchaseOrder extends Model {
    static table = PURCHASE_ORDER_SCHEMA;

    static associations = {
        business: {type: 'belongs_to', key: 'business_id'},
        company: {type: 'belongs_to', key: 'company_id'},
        purchase_order_line: {type: 'has_many', foreignKey: 'purchase_order_id'},
    };

    @text('status') status;
    @text('number') number;
    @text('reference') reference;
    @text('ship_to') ship_to;
    @text('notes') notes;
    @text('notememo') notememo;
    @field('amount') amount;
    @date('po_date') po_date;
    @date('created_at') created_at;
    @date('po_expected_date') po_expected_date;
    @text('shipping_method') shipping_method;
    @field('archive') archive;
    @field('is_delivered') is_delivered;
    @json('purchase_order_discount', val => sanitizeOrderDiscount(val))
    purchase_order_discount;

    @relation('business', 'business_id') business;
    @relation('company', 'company_id') company;

    @children('purchase_order_line') purchase_order_line;

    @writer async archivePO(status = true) {
        return await this.update(po => {
            po.archive = status;
        });
    }

    @writer async markDeliveredPO(status = true) {
        return await this.update(po => {
            po.is_delivered = status;
        });
    }

    @writer async updatePOStatus(status) {
        return await this.update(po => {
            po.status = status;
        });
    }

    @writer async updatePO(details) {
        return await this.update(order => {
            order.status = details.status;
            details.company && order.company.set(details.company);
            details.reference && (order.reference = details.reference);
            details.ship_to && (order.ship_to = details.ship_to);
            details.rate && (order.rate = details.rate);
            details.purchase_order_date && (order.po_date = details.purchase_order_date);
            details.amount && (order.amount = details.amount);
            details.notes && (order.notes = details.notes);
            details.notememo && (order.notememo = details.notememo);
            details.expected_on && (order.po_expected_date = details.expected_on);
            details.shipping_method && (order.shipping_method = details.shipping_method);
        });
    }

    // @writer async createPOLine(details) {
    //   return await this.collections.get(PO_LINE_SCHEMA).create((line) => {
    //     line.purchase_order.set(this);
    //     line.product.set(details.product);
    //     line.purchase_tax.set(details.purchase_tax);

    //     line._raw.id = uuid();
    //     line.name = details.name;

    //     line.quantity = details.quantity;
    //     line.selling_price = details.selling_price;
    //     line.cost_price = details.cost_price;
    //     line.discount = details.discount;
    //     line.archive = false;
    //   });
    // }
}
