import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, MessageModal} from '../../common';
import {useState, useEffect} from 'react';
import {getAccountBusiness, getHashMap, getLocationNames, toSnakeCase, validateDiscountForm, validateDraftDiscount} from '../../../constants';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Sync} from '../../../api';
import LocationModal from '../LocationModal';
import {bulkCreateDiscountBusiness} from '../../../pos-core/database/helpers/discountBusiness';

const CreateDiscountComponent = ({discount}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast, account, userBusiness, persistData, setPersistData} = useAuthContext();

    const isCreatedDiscount = !Boolean(discount);

    let navigate = useNavigate();

    const {name_data, tax_behavior_data, discount_type_data, amount_limit_data, max_discount_cap_data, selectedLocations_data} = persistData?.discounts || {};

    const [name, setName] = useState((isCreatedDiscount ? name_data : discount?.name) || '');
    const [location, setLocation] = useState(null);
    const [locationModal, setLocationModal] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState(isCreatedDiscount ? selectedLocations_data : {});
    const [locationsObj, setLocationsObj] = useState();
    const [tax_behavior, setTaxBehavior] = useState(isCreatedDiscount ? tax_behavior_data : discount ? I18n[discount.is_inclusive ? 'tax_inclusive' : 'tax_exclusive'] : '');
    const [discount_type, setDiscountType] = useState(
        isCreatedDiscount ? discount_type_data : discount ? I18n[discount.type == 'percentage' ? 'type_percentage' : 'type_amount'] : '',
    );
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [amount_limit, setAmountLimit] = useState(isCreatedDiscount ? amount_limit_data : discount?.limit || '');
    const [max_discount_cap, setMaxDiscountCap] = useState(isCreatedDiscount ? max_discount_cap_data : discount?.max_discount_cap || '');
    const [errors, setErrors] = useState({});
    const [searchParams] = useSearchParams();

    const discountType = [I18n.type_amount, I18n.type_percentage];
    const location_id = searchParams?.get('location_id');
    const orderNumber = searchParams.get('order_id');
    let discountPersistData = {
        name_data: name,
        tax_behavior_data: tax_behavior,
        discount_type_data: discount_type,
        amount_limit_data: amount_limit,
        max_discount_cap_data: max_discount_cap,
        selectedLocations_data: selectedLocations || {},
    };

    useEffect(() => {
        getBusinessOfAccount();
    }, []);

    useEffect(() => {
        if (location_id && location) {
            setSelectedLocations(prev => {
                let obj = {...prev};
                obj[location_id] = location_id;
                return obj;
            });
        }
    }, [location_id, location]);

    useEffect(() => {
        // for handling location field, onchange isn't working there
        if (Object.values(selectedLocations || {}).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = {...prevErrors};
                let label = 'Location';
                if (updatedErrors[toSnakeCase(label)]) {
                    delete updatedErrors[toSnakeCase(label)];
                }

                return updatedErrors;
            });
        }
    }, [selectedLocations]);
    const onChange = (label, val) => {
        if (label == I18n.discounts_name) setName(val);
        else if (label == I18n.taxation_behaviour) setTaxBehavior(val);
        else if (label == I18n.amount_type) setDiscountType(val);
        else if (label == I18n.amount) setAmountLimit(val);
        else if (label == I18n.maximum_discount_cap) setMaxDiscountCap(val);
        else if (label == I18n.location) setLocationModal(true);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleCreate = async val => {
        const {draft, id} = discount || {};
        try {
            if (selectedLocations.hasOwnProperty('all')) {
                delete selectedLocations['all'];
            }
            const locationsArray = Object.values(selectedLocations || {}).map(item => locationsObj[item]);

            let paramsForDiscount = {
                name,
                is_inclusive: tax_behavior == I18n.tax_inclusive ? true : false,
                type: discount_type == I18n.type_amount ? 'amount' : discount_type == I18n.type_percentage ? 'percentage' : '',
                limit: Number(amount_limit),
                max_discount_cap: max_discount_cap ? Number(max_discount_cap) : null,
                draft: val === 'saveDraft',

                locationsArray,
            };
            let createdDiscount;

            const formErrors = await (val === 'save' ? validateDiscountForm(paramsForDiscount) : validateDraftDiscount(paramsForDiscount));
            setErrors(formErrors);
            console.log('form errors', formErrors);

            if (Object.keys(formErrors).length > 0) return;

            if (discount) {
                createdDiscount = await discount.updateDetails(paramsForDiscount);
            } else {
                createdDiscount = await account.createDiscount(paramsForDiscount);
                console.log('created disc', createdDiscount);
                const discountBussPayload = {
                    locations: locationsArray,
                    discount: createdDiscount,
                };
            
                const response = bulkCreateDiscountBusiness(discountBussPayload);
                console.log('created disc business', response);
            }

            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = 'discount_saved_into_draft';
            } else {
                toastMessage = draft ? 'discount_published_successfully' : id ? 'discount_update' : 'discount_created';
            }

            setRenderToast(toastMessage);
            setPersistData(null);
            Sync(user?.email);
            if(location_id){
                navigate('/discounts');          
            }else {
                navigate(-1);          
            }
            
        } catch (error) {
            console.log(error);
        }
    };

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        setPersistData(null);
        navigate('/discounts');          
    };

    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            handleCreate('save');
        }
    };

    async function getBusinessOfAccount() {
        const singleSelection = false;
        const businessLocations = await getAccountBusiness(account, business, userBusiness, singleSelection);
        const businessObj = await getHashMap(account);

        if (discount) {
            const discountBusiness = await discount.getDiscBusiness();
            const businessId = discountBusiness[0]?.business.id;
            setSelectedLocations({[businessId]: businessId});
        }

        setLocation(businessLocations);
        setLocationsObj(businessObj);
    }

    const handleToggleDiscountModal = () => {
        if (!discount) {
            setLocationModal(true);
            setPersistData({discounts: discountPersistData});
        }
    };

    return (
        <>
            <div onKeyDown={handleEnterPress}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    saveCta={{
                        title: I18n.save,
                        action: () => handleCreate('save'),
                    }}
                    backAction={toggleDiscardModal}
                    primaryCta={
                        !discount?.id
                            ? {
                                  title: I18n.save_draft,
                                  action: () => handleCreate('saveDraft'),
                              }
                            : null
                    }
                />

                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.create_discount}
                            </h3>
                            <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop0" style={{color: theme.text}}>
                                {I18n.discount_details}
                            </h3>

                            <div className="itemFields">
                                <TextField
                                    onChange={onChange}
                                    error={errors['discount_name']}
                                    label={I18n.discounts_name}
                                    placeholder={I18n.discounts_name}
                                    value={name}
                                    autoFocus={true}
                                    required={true}
                                />
                                <div className="intventoryFormGridTwo">
                                    <div className="priceSectionInputLeft">
                                        <TextField
                                            label={I18n.amount_type}
                                            placeholder={I18n.amount_type}
                                            error={errors['amount_type']}
                                            onChange={onChange}
                                            data={discountType}
                                            required={true}
                                            value={discount_type}
                                            inputType="select"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                    </div>
                                    <div className="priceSectionInputRight">
                                        <TextField
                                            onChange={onChange}
                                            error={errors['amount']}
                                            label={I18n.amount}
                                            placeholder={discount_type == I18n.type_percentage ? '0%' : 'Rs. 0'}
                                            value={amount_limit}
                                            type={'numeric'}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="intventoryFormGridTwo">
                                    <div className="priceSectionInputLeft">
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.maximum_discount_cap}
                                            placeholder={I18n.maximum_discount_cap}
                                            disabled={discount_type == I18n.type_amount}
                                            value={max_discount_cap}
                                            type={'numeric'}
                                        />
                                    </div>
                                    <div onClick={handleToggleDiscountModal} className="priceSectionInputRight">
                                        <TextField
                                            label={I18n.location}
                                            placeholder={I18n.location}
                                            error={errors?.['location']}
                                            onChange={onChange}
                                            value={getLocationNames(locationsObj, selectedLocations)}
                                            inputType="text"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                            disabled={discount ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <LocationModal
                toggle={locationModal}
                setToggle={setLocationModal}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                locations={location}
                singleSelection={false}
                screenName="discount"
            />
            <MessageModal
                className="messageModal"
                setToggle={setDiscardModalToggle}
                toggle={discardModalToggle}
                description={I18n.do_you_really_want_to_discard}
                subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleDiscardModal,
                }}
                primaryCta={{
                    title: I18n.discard,
                    backgroundColor: theme.white,
                    borderColor: theme.red,
                    txtColor: theme.red,
                    action: handleDiscardAction,
                    isDelete: true,
                }}
            />
        </>
    );
};

export default CreateDiscountComponent;
