import React, {useState} from 'react';
import {TextField, Button} from '..';
import {Col, Container, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';
import Cookies from 'js-cookie';
import {BASE_URL, dateFilterMapping, DateFilterOption} from '../../../constants';
import API_ENDPOINTS from '../../../api/endpoints';

const DownloadMarginReport = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, userBusiness} = useAuthContext();
    const [selectedLocation, setSelectedLocation] = useState({name: business.location_name, id: business.id});
    const [dateFilter, setDateFilter] = useState({name: 'Today'});
    const [error, setError] = useState('');

    /**
     * Updates the selected location or date filter based on the provided label and value.
     *
     * @param {string} label - The label associated with the value.
     * @param {object} value - The value containing location or date information.
     * @return {void}
     */
    const onChange = (label, value) => {
        if (label === I18n.location) {
            setSelectedLocation({name: value?._raw?.location_name, id: value?._raw?.id});
        } else if (label === I18n.date) {
            setDateFilter({name: value.name});
        }
        setError('');
    };

    /**
     * Downloads a margin report in Excel format.
     *
     * @return {Promise<void>} - A promise that resolves when the download is complete.
     * @throws {Error} - If there is an error downloading the report.
     */
    const handleDownloadReport = async () => {
        try {
            const url = new URL(`${BASE_URL}${API_ENDPOINTS.GET_MARGIN_REPORT}`);
            url.searchParams.append('business_id', selectedLocation?.id);
            url.searchParams.append('date_range', dateFilterMapping[dateFilter?.name]);
            const response = await fetch(url, {
                method: 'GET',
                headers: {Authorization: `Token ${Cookies.get('access_token')}`},
                'Content-Type': 'application/json',
            });

            if (response?.status == 200) {
                const blob = await response.blob();
                const urlObject = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = urlObject;
                link.download = 'report.xlsx';
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(urlObject);
            } else {
                let jsonRes = await response?.json();
                setError(jsonRes.mesg);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.log('Error downloading the Excel file:', error);
        }
    };

    return (
        <Container fluid>
            <div className="DownloadMarginReportContainer">
                <Row className="align-items-center">
                    <Col md={6} sm={6}>
                        <p className="fontSize16 fontWeight500" style={{color: theme.white}}>
                            {I18n.download_reports}
                        </p>
                    </Col>
                    <Col md={4} sm={4}>
                        <TextField
                            label={I18n.location}
                            placeholder={I18n.location}
                            data={[
                                ...userBusiness.map(item => ({
                                    ...item,
                                    name: item.location_name,
                                })),
                            ]}
                            value={selectedLocation?.name}
                            onChange={onChange}
                            inputType="select"
                            type="text"
                            suffix="downIcon2"
                            suffix_fill={theme.white}
                            suffix_width="24"
                            suffix_height="24"
                            suffix_viewBox="0 0 18 18"
                        />
                    </Col>
                    <Col md={2} sm={2}>
                        <TextField
                            label={I18n.date}
                            placeholder={I18n.date}
                            data={DateFilterOption}
                            value={dateFilter?.name}
                            onChange={onChange}
                            inputType="select"
                            type="text"
                            suffix="downIcon2"
                            suffix_fill={theme.white}
                            suffix_width="24"
                            suffix_height="24"
                            suffix_viewBox="0 0 18 18"
                        />
                    </Col>
                    <Col md={12}>
                        <p className="fontSize12 fontWeight400 marTop15 flexRow verticalCenter" style={{color: theme.white}}>
                            <Icon name="infocircleIcon" fill={theme.barclaysBlue} width="18" height="18" viewBox="0 0 18 18" />
                            <span className="marLeft5">{I18n.you_can_download_reports_based_on_the_last_file_shared}</span>
                        </p>
                        <Button
                            className="DownloadReportButton fontSize14 fontWeight500"
                            borderColor={theme.barclaysBlue}
                            type="primaryButton"
                            title={I18n.download_reports}
                            handleClick={handleDownloadReport}
                        />
                        {error ? (
                            <p className="fontSize12 fontWeight400 marTop15 flexRow verticalCenter" style={{color: theme.red}}>
                                <Icon name="infocircleIcon" fill={theme.red} width="18" height="18" viewBox="0 0 18 18" />
                                <span className="marLeft10">{error}</span>
                            </p>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default DownloadMarginReport;
