import {useNavigate} from 'react-router-dom';
import {useAuthContext, useLanguageContext} from '../../../../../context';
import {useCategories} from '../../../../../hooks';
import {CreateCategoryComponent, CustomContainer, Loading} from '../../../../common';

const EditCategories = () => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {business, user, account} = useAuthContext();

    const {isLoading, category} = useCategories(account);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateCategoryComponent category={category} />
            </CustomContainer>
        </>
    );
};

export default EditCategories;
