import { Model } from '@nozbe/watermelondb';
import { field, text, relation, children, writer, date, lazy } from '@nozbe/watermelondb/decorators';
import { CATEGORY_SCHEMA, COUNTER_SCHEMA } from '../schema';

export default class Counter extends Model {
    static table = COUNTER_SCHEMA;

    static associations = {
        user: { type: 'belongs_to', key: 'user_id' },
    }

    @field('auth_session_count') auth_session_count
    @field('pos_session_count') pos_session_count
    @field('order_count') order_count

    @relation('user', 'user_id') user;

    @writer async updateDetails(details) {
        return await this.update((counter) => {
            counter.auth_session_count = details.auth_session_count
            counter.pos_session_count = details.pos_session_count
            counter.order_count = details.order_count
        });
    }

    @writer async updatePosSession(val) {
        return await this.update((counter) => {
            counter.pos_session_count = val
        });
    }

    @writer async updateOrderCount(val) {
        return await this.update((counter) => {
            counter.order_count = val
        });
    }
}