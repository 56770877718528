import { database } from "..";
import { BUSINESS_SCHEMA, OPTION_SCHEMA } from "../schema";
import { Q } from "@nozbe/watermelondb";

const option = database.collections.get(OPTION_SCHEMA);

export const observeActiveOptions = () =>
  option
    .query(Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")))
    .observe();
