import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {Col, Container, Row} from 'react-bootstrap';
import RangePicker from '../../../common/RangePicker';
import LineGraphComponent from './LineGraphComponent';
import TotalTiles from '../../../common/TotalTiles';
import CategoriesSummary from './CategoriesSummary';
import {useOrderByDate} from '../../../../hooks';
import CustomerSummary from './CustomerSummary';
import PaymentSummary from './PaymentSummary';
import React, {useEffect, useState} from 'react';
import ItemSummary from './ItemSummary';
import {DEVICE_HEIGHT} from '../../../../constants';
import {TextField} from '../../../common';
import usePermission from '../../../../hooks/usePermission';

const DashboardComponent = () => {
    const {business, userBusiness, userRole} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [selectedLocation, setSelectedLocation] = useState({name: business.location_name, id: business.id});
    const {getOverview, overView, loading} = useOrderByDate();
    const userPermission = usePermission('dashboard');

    useEffect(() => {
        onFilterDate();
    }, [selectedLocation]);

    const onFilterDate = (label = 'Today', startDate = '', endDate = '', diffInDays = 0) => getOverview(label, startDate, endDate, diffInDays, selectedLocation?.id);

    const onChange = (label, value) => {
        setSelectedLocation({name: value?._raw?.location_name, id: value?._raw?.id});
    };

    const OVERVIEW_DATA = [
        {
            iconBGcolor: theme.mediumGreen,
            iconName: 'totalSaleIcon',
            heading: 'total_sales',
            label: 'total_sales',
            rs: true,
        },
        {
            iconBGcolor: theme.purples,
            iconName: 'totalOrderIcon',
            heading: 'total_orders',
            label: 'total_orders',
        },
        {
            iconBGcolor: theme.brightOrange,
            iconName: 'holdOrderBagIcon',
            heading: 'hold_orders',
            label: 'hold_orders',
        },
        {
            heading: 'cancelled_orders',
            iconName: 'cancelOrderIcon',
            label: 'cancelled_orders',
            iconBGcolor: theme.red,
        },
    ];

    return (
        <div
            className="dashboardBox padt10"
            style={{
                background: theme.topBarBG,
                height: DEVICE_HEIGHT - 40,
            }}>
            <Container fluid>
                <Row>
                    {userPermission?.canCrud && (
                        <>
                            <Col md={4} sm={4}>
                                <TextField
                                    label={I18n.location}
                                    placeholder={I18n.location}
                                    data={[
                                        ...userBusiness.map(item => ({
                                            ...item,
                                            name: item.location_name,
                                        })),
                                    ]}
                                    value={selectedLocation?.name}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>
                            <Col md={8} sm={8} />
                        </>
                    )}

                    <Col md={6} sm={12}>
                        <div className="flexRow marTop10 marBot15 justifySpacebetween alignCenter">
                            <h1 className="fontSize24 fontWeight700 dashboardHeading" style={{color: theme.white}}>
                                {I18n.orders_overview}
                            </h1>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="OrderCalenderConatiner">
                            <RangePicker onFilterDate={onFilterDate} className="orderCalender" />
                        </div>
                    </Col>
                    {loading ? (
                        <span>Loading ...</span>
                    ) : (
                        OVERVIEW_DATA.map((item, index) => {
                            return (
                                <Col key={index} md={3} xs={12}>
                                    <TotalTiles percent={overView[item.label].percent} amount={overView[item.label].amount} item={item} />
                                </Col>
                            );
                        })
                    )}

                    {/* --------summary Box----- */}

                    <Col md={12} sm={12}>
                        <LineGraphComponent selectedLocation={selectedLocation} />
                    </Col>
                    <Col md={6} sm={12}>
                        <CustomerSummary />
                    </Col>
                    <Col md={6} sm={12}>
                        <PaymentSummary selectedLocation={selectedLocation} />
                    </Col>
                    <Col md={6} sm={12}>
                        <CategoriesSummary selectedLocation={selectedLocation} />
                    </Col>
                    <Col md={6} sm={12}>
                        <ItemSummary selectedLocation={selectedLocation} />
                    </Col>
                </Row>
                {/* --------summary Box----- */}
            </Container>
        </div>
    );
};
export default DashboardComponent;
