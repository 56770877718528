import {useNavigate} from 'react-router-dom';
import {useAuthContext, useLanguageContext} from '../../../../../context';
import {useCompany} from '../../../../../hooks';
import {Loading, CreateCompanyComponent} from '../../../../common';

const EditCategories = () => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {business, user, account} = useAuthContext();

    const {isLoading, company} = useCompany(account);

    return isLoading ? <Loading /> : <CreateCompanyComponent company={company} />;
};

export default EditCategories;
