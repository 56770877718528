import {useNavigate} from 'react-router-dom';
import {Sync} from '../../../../../api';
import {useAuthContext} from '../../../../../context';
import {useDiscount} from '../../../../../hooks';
import {CreateDiscountComponent, CustomContainer, Loading} from '../../../../common';

const EditDiscount = () => {
    let navigate = useNavigate();

    const {business, user, account} = useAuthContext();

    const {isLoading, discount} = useDiscount(account);

    const handleSave = async () => {
        await Sync(user.email);
        navigate('/discounts', {
            state: {
                isArchived: discount._raw.archive,
            },
        });
    };

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer reduceHeight={45} className="addScreenMainBox  createCustomerContainer">
                <CreateDiscountComponent handleSelect={handleSave} discount={discount} />
            </CustomContainer>
        </>
    );
};

export default EditDiscount;
