import React from "react";
import { Offcanvas } from "react-bootstrap";
import { DEVICE_HEIGHT } from "../../../constants";
import { useThemeContext } from "../../../context";
import Icon from "../../../assets/icons";

const SidebarModal = ({
  sidebar,
  setSidebar,
  placement,
  className,
  heading,
  children,
}) => {
  const { theme } = useThemeContext();

  const toggleMenu = () => {
    setSidebar(false);
  };

  return (
    <Offcanvas
      placement={placement}
      className={className}
      backdrop={false}
      show={sidebar}
      onHide={toggleMenu}
      style={{
        backgroundColor: theme.background,
        height: DEVICE_HEIGHT,
      }}
    >
      <Offcanvas.Body>
        <div className="flex justifySpacebetween pad20">
          <p className="marBot0 fontSize24" style={{ color: theme.white }}>
            {heading}
          </p>
          <span onClick={() => toggleMenu()} className="cursorPointer">
            <Icon
              name="closeIcon"
              fill={theme.white}
              width={"12"}
              height={"12"}
              viewBox={"0 0 15 15"}
            />
          </span>
        </div>

        <div className="sidebarChildren">{children}</div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SidebarModal;
