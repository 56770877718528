import { useLanguageContext } from "../../../context";

const SkipButton = ({ backgroundColor, txtColor, handleClick, forwardIcon }) => {

    const { I18n } = useLanguageContext();

    return (
        <p
            style={{
                backgroundColor: backgroundColor,
                color: txtColor,
                cursor: "pointer",
                fontWeight: 400,
                fontSize: 22,
            }}
            onClick={handleClick}
        >
            <span style={{ marginLeft: 20 }}> {I18n.skip} </span> <img src={forwardIcon} />
        </p>
    )
}

export default SkipButton;