import { useLanguageContext, useThemeContext } from "../../../context";
import TextField from "../TextField";
import TableColumn from "./TableBodyCol";

const TableBodyList = ({ title, data, onChange, errors }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <div
      className="variationListTableHeader"
      style={{ borderColor: theme.seperatorBg }}
    >
      <TableColumn isName={true}>{data?.name}</TableColumn>

      <TableColumn>
        <div className="barcodeDivWrapper">
          {errors?.[data?.name] && <p className="aesterisk">*</p>}
          <TextField
            onChange={(label, value) => onChange(title, "barcode", value)}
            placeholder={I18n.enter_barcode}
            value={data?.barcode}
            type="text"
          />
        </div>
      </TableColumn>

      <TableColumn>
        <TextField
          onChange={(label, value) => onChange(title, "quantity", value)}
          //   errors={errors}
          value={data?.quantity}
          type="numeric"
        />
      </TableColumn>

      <TableColumn>
        <TextField
          onChange={(label, value) => onChange(title, "costPrice", value)}
          //   errors={errors}
          value={data?.costPrice}
          type="numeric"
        />
      </TableColumn>

      <TableColumn>
        <TextField
          onChange={(label, value) => onChange(title, "sellingPrice", value)}
          //   errors={errors}
          value={data?.sellingPrice}
          type="numeric"
        />
      </TableColumn>

      <TableColumn>
        <TextField
          onChange={(label, value) => onChange(title, "lowStock", value)}
          //   errors={errors}
          value={data?.lowStock}
          type="numeric"
        />
      </TableColumn>
    </div>
  );
};

export default TableBodyList;
