import {useThemeContext} from '../../../context';
import TableBody from '../../common/TableContainer/TableBody';
import {firstLetterCaptilize, formatDate, isValidDateFormat} from '../../../constants';

const CsvList = ({customer, errors}) => {
    const {theme} = useThemeContext();

    const {address, city, dob, email, id, country, name, phone_number, province} = customer;

    let is_invalid = {
        name: errors[id]?.name,
        phone_number: errors[id]?.phone_number,
        email: errors[id]?.email,
        dob: errors[id]?.dob,
        province: errors[id]?.province,
        country: errors[id]?.country,
        city: errors[id]?.city,
        address: errors[id]?.address,
    };

    return (
        <>
            <TableBody className={`eightColumn cursorPointer`} style={{borderColor: theme.posRightBg}}>
                <div className="BoxWidth justifyStart" key={id}>
                    <p
                        className="fontSize14"
                        style={{
                            color: is_invalid?.name ? theme.red : theme.white,
                        }}
                        title={is_invalid?.name ? is_invalid?.name.message : name}>
                        <span className="OneLineTruncate">{firstLetterCaptilize(name || '--')}</span>
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.phone_number ? theme.red : theme.white,
                        }}
                        title={is_invalid?.phone_number ? is_invalid?.phone_number.message : phone_number}>
                        {phone_number || '--'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.email ? theme.red : theme.white,
                        }}
                        title={is_invalid?.email ? is_invalid?.email.message : email}>
                        {email || '--'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.dob ? theme.red : theme.white,
                        }}
                        title={is_invalid?.dob ? is_invalid?.dob.message : formatDate(dob)}>
                        {isValidDateFormat(dob) ? formatDate(dob) : '--'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.country ? theme.red : theme.white,
                        }}
                        title={country}>
                        {firstLetterCaptilize(country || '--')}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.province ? theme.red : theme.white,
                        }}
                        title={is_invalid?.province ? is_invalid?.province.message : province}>
                        {province || '--'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.city ? theme.red : theme.white,
                        }}
                        title={is_invalid?.city ? is_invalid?.city.message : city}>
                        {city || '--'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid?.address ? theme.red : theme.white,
                        }}
                        title={is_invalid?.address ? is_invalid?.address.message : address}>
                        {address || '--'}
                    </p>
                </div>
            </TableBody>
        </>
    );
};

export default CsvList;
