import { useEffect, useState } from "react";
import Icon from "../../../assets/icons";
import { handleOrderStatus } from "../../../constants";
import { useLanguageContext, useThemeContext } from "../../../context";

const StatusBox = (status) =>{

    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [orderStatus, setOrderStatus] = useState();

useEffect(()=>{
    
   let data = handleOrderStatus("complete", theme)
   setOrderStatus(data)
} , [])  

console.log("ssss", orderStatus?.backgroundColor)

    return(
        <p className="textWithTagInner" style={{background: orderStatus?.backgroundColor, borderColor: orderStatus?.borderColor}}>
            <span className="fontSize10 fontWeight500" style={{ color: orderStatus?.titleColor}}>{orderStatus?.title}</span>
            <span className="marLeft5">
                <Icon name={orderStatus?.icon} viewBox="0 0 13 10" width="10" height="7" fill={orderStatus?.iconColor} />
            </span>
        </p>
    )
}

export default StatusBox;