import { Model } from "@nozbe/watermelondb";
import { field, text, relation, writer, date } from "@nozbe/watermelondb/decorators";
import { SETTING_CONFIG_SCHEMA } from "../schema";

export default class SettingConfig extends Model {
  static table = SETTING_CONFIG_SCHEMA;

  static associations = {
    business: { type: "belongs_to", key: "business_id" },
  };

  @text("name") name;
  @text("sub_title") sub_title;
  @text("status") status;
  @field("is_active") is_active;
  @date('created_at') created_at;

  @relation("business", "business_id") business;

  @writer async updateSetting(details) {
    return await this.update((setting) => {
      setting.name = details.name;
      setting.sub_title = details.sub_title;
      setting.status = details.status;
      setting.is_active = details.is_active;
    });
  }

  @writer async updateOption(val) {
    return await this.update((setting) => {
      setting.status = val;
    });
  }
}
