import React from "react";
import { useLanguageContext, useThemeContext } from "../../../context";

const ResturantSummary = () => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();
  return (
    <>
      <div className="detailBotCol "></div>
      <div className="rowConatainer ">
        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.floor_plan}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {"first Floor"}
          </p>
        </div>

        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.type}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {"Dine In"}
          </p>
        </div>

        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.table}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {"Table 01"}
          </p>
        </div>
        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.guests}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {"02"}
          </p>
        </div>
        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.waiter}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {"--"}
          </p>
        </div>
      </div>
    </>
  );
};

export default ResturantSummary;
