import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAuthContext, useLanguageContext} from '../../../../../context';
import {CreatePurchaseOrderComponent, CustomContainer, Header, OrderDiscountsModal} from '../../../../common';
import {useEffect, useState} from 'react';
import {bulkUpdateProducts, getTotalItemsCount, observePOCount} from '../../../../../pos-core/database/helpers';
import {bulkCreatePoLines, bulkUpdatePoLines, bulkDeletePoLines} from '../../../../../pos-core/database/helpers/po_line';
import {calculateTotal, filteredObjects, validateFormFields} from '../../../../../constants';
import {Sync} from '../../../../../api';

const CreatePurchaseOrder = ({purchaseOrder, companyDetails, poLine}) => {
    let navigate = useNavigate();
    
    const {I18n} = useLanguageContext();
    const {business, user, handleCreatePurchaseOrder, setRenderToast, persistData, setPersistData} = useAuthContext();

    const isCreatedPO = !Boolean(purchaseOrder);
    const {
        company_data,
        disablFields_data,
        expected_data,
        item_data,
        notememo_data,
        orderLevelDiscount_data,
        poDate_data,
        reference_data,
        ship_data,
        shippingMethod_data,
        totalItemsCount_data,
        notes_data,
    } = persistData?.purchaseOrder || {};

    const [disablFields, setDisablFields] = useState(purchaseOrder ? true : false);
    const [company, setCompany] = useState(isCreatedPO ? company_data : '');
    const [ship, setShip] = useState(isCreatedPO ? ship_data : '');
    const [reference, setReference] = useState(isCreatedPO ? reference_data : '');
    const [poDate, setPoDate] = useState(isCreatedPO ? poDate_data : '');
    const [expected, setExpected] = useState(isCreatedPO ? expected_data : '');
    const [shippingMethod, setShippingMethod] = useState( shippingMethod_data?.name || '');
    const [notememo, setNoteMemo] = useState(isCreatedPO ? notememo_data : '');
    const [notes, setNotes] = useState(isCreatedPO ? notes_data : '');
    const [orderLevelDiscount, setOrderLevelDiscount] = useState(isCreatedPO ? orderLevelDiscount_data : {});
    const [itemData, setItemData] = useState(item_data || []);
    const [totalItemsCount, setTotalItemsCount] = useState(isCreatedPO ? totalItemsCount_data : 0);
    const [errors, setErrors] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [orderDiscountModal, setOrderDiscountModal] = useState(false);
    const [toggleCompanyModal, setToggleCompanyModal] = useState(false);
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [searchParams] = useSearchParams();

    let po_data = {
        item_data: itemData || [],
        company_data: company,
        poDate_data: poDate,
        expected_data: expected,
        ship_data: ship,
        reference_data: reference,
        shippingMethod_data: {name: shippingMethod},
        notememo_data: notememo,
        notes_data: notes,
        disablFields_data: disablFields,
        orderLevelDiscount_data: orderLevelDiscount,
        totalItemsCount_data: totalItemsCount,
    };

    const company_id = searchParams?.get('company_id');
    useEffect(() => {
        if (company_id) {
            setDisablFields(false);
        }
    }, [company_id]);

    const handleSaveDaft = async () => {
        const {total} = calculateTotal(itemData, orderLevelDiscount);

        let params = {
            company: company || '',
            reference: reference || '',
            ship_to: ship,
            purchase_order_date: new Date(poDate).getTime() || '',
            expected_on: new Date(expected).getTime() || '',
            shipping_method: shippingMethod?.name || '',
            status: 'draft',
            po_line: itemData || [],
            amount: total,
            notes: notes || '',
            notememo: notememo || '',
            purchase_order_discount: orderLevelDiscount,
        };

        try {
            let count = await observePOCount();
            let order = await business.createPO(`PO # 00${count + 1}`, params);
            params?.po_line?.length > 0 && (await bulkCreatePoLines(order, params?.po_line));

            setOrderLevelDiscount({});
            setRenderToast('po_saved_into_draft');
            Sync(user.email);
            navigate(-1);
        } catch (e) {
            console.log('THIS PO HAS ALREADY BEEN CREATED !', e);
        }
    };

    const handleCreate = async () => {
        const {total} = calculateTotal(itemData, orderLevelDiscount);

        let params = {
            company,
            reference,
            ship_to: ship,
            purchase_order_date: new Date(poDate).getTime(),
            expected_on: new Date(expected).getTime(),
            shipping_method: shippingMethod?.name,
            status: 'pending',
            po_line: itemData,
            amount: total,
            notes,
            notememo,
            purchase_order_discount: orderLevelDiscount,
        };

        const formErrors = validateFormFields(params);
        setErrors(formErrors);

        if (itemData?.length === 0 || Object.keys(formErrors).length > 0) {
            return;
        }

        try {
            setBtnLoading(true);

            if (purchaseOrder) {
                let updatedPO = await purchaseOrder.updatePO(params);
                const clonePO = [...poLine];

                const {addPOS, deletePOS, updatePOS} = filteredObjects(clonePO, itemData);

                await bulkCreatePoLines(updatedPO, addPOS);
                await bulkUpdatePoLines(updatedPO, updatePOS);
                await bulkDeletePoLines(deletePOS);
            } else {
                let count = await observePOCount();
                let order = await business.createPO(`PO # 00${count + 1}`, params);
                await bulkCreatePoLines(order, params?.po_line);
            }
            let ToastMessage = purchaseOrder?.status ? `PO ${purchaseOrder?.status}` : 'PO created';
            setRenderToast(ToastMessage);
            Sync(user.email);
            setPersistData(null);
            navigate('/purchaseorder');
        } catch (e) {
            console.log('ERROR CREATING PO', e);
        } finally {
            setBtnLoading(false);
            setOrderLevelDiscount({});
        }
    };

    const handleUpdate = async val => {
        let status;
        if (val.target.value === 'Approve') {
            status = 'approved';
        }
        if (val.target.value === 'Reject') {
            status = 'rejected';
        }
        if (val.target.value === 'Save') {
            return await handleCreate();
        }

        await purchaseOrder.updatePOStatus(status);
        if (purchaseOrder?.status) {
            setRenderToast(`po_${purchaseOrder?.status}`);
        }

        Sync(user.email);
        navigate(-1);
    };

    const updatePO = () => {
        setDisablFields(false);
    };
    const handleDeliveredPO = async () => {
        const productsToUpdate = await Promise.all(
            poLine.map(async item => {
                const productRef = await item?.product.fetch();
                const prodBusinessRef = await productRef.getProdBusiness();

                return {
                    productRef: productRef,
                    prodBusinessRef: prodBusinessRef[0],
                    productTemplate: await productRef.product_template.fetch(),
                    value: {
                        cost_price: item?.cost_price,
                        quantity: item.quantity,
                        selectedUnit: item?.unit,
                    },
                };
            }),
        );

        await bulkUpdateProducts(productsToUpdate);
        await purchaseOrder.markDeliveredPO();
        Sync(user.email);

        navigate(-1);
    };

    const setPoDetails = async () => {
        const {reference, ship_to, po_date, po_expected_date, shipping_method, notes, notememo, purchase_order_discount} = purchaseOrder || {};

        const trimPoLine = await Promise.all(
            poLine.map(async item => ({
                id: item?.id,
                name: item?.name,
                qty: item?.quantity,
                discount: item?.discount || 0,
                unit_cost: item?.cost_price,
                unit: item?.unit,
                taxes: await item?.purchase_tax,
                product: await item?.product,
            })),
        );

        setReference(reference);
        shipping_method && setShippingMethod({name: shipping_method});
        setPoDate(po_date);
        setShip(ship_to);
        setExpected(po_expected_date);
        setCompany(companyDetails);
        setNotes(notes);
        setNoteMemo(notememo);
        setOrderLevelDiscount(purchase_order_discount || {});
        setItemData(trimPoLine);
    };

    const getItemsCount = async () => {
        const count = await getTotalItemsCount();
        setTotalItemsCount(count);
    };
    useEffect(() => {
        getItemsCount();
        if (purchaseOrder) {
            setPoDetails();
        }
    }, []);

    const setHeader = purchaseOrder => {
        if (purchaseOrder?.status === 'approved') {
            return (
                <Header
                    title={I18n.create_purchase_order}
                    buttonTitle={!purchaseOrder?.is_delivered && I18n.mark_as_delivered}
                    handleSave={!purchaseOrder?.is_delivered && handleDeliveredPO}
                    para={purchaseOrder?.is_delivered && I18n.delivered}
                />
            );
        }
        if (purchaseOrder?.status === 'rejected') {
            return <Header title={I18n.create_purchase_order} para={I18n.rejected} />;
        }
        if (purchaseOrder?.status === 'pending') {
            return (
                <Header
                    type="poHeader"
                    title={I18n.create_purchase_order}
                    approveTitle={purchaseOrder && !disablFields ? I18n.save : I18n.approve_po}
                    rejectTitle={!disablFields ? null : I18n.reject_po}
                    handleSave={!disablFields ? handleCreate : handleUpdate}
                    editIcon={{
                        title: I18n.edit,
                        action: updatePO,
                    }}
                />
            );
        }

        if (purchaseOrder?.status === 'draft') {
            return (
                <Header
                    type="poHeader"
                    title={I18n.create_purchase_order}
                    approveTitle={I18n.save}
                    handleSave={handleCreate}
                    saveLoad={btnLoading}
                    editIcon={{
                        title: I18n.edit,
                        action: updatePO,
                    }}
                />
            );
        } else {
            return (
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    backAction={toggleDiscardModal}
                    saveCta={{
                        title: I18n.save,
                        action: () => handleCreate(),
                    }}
                    saveLoad={btnLoading}
                    primaryCta={{
                        title: I18n.save_draft,
                        action: handleSaveDaft,
                    }}
                />
            );
        }
    };

    const handleOrderDiscount = (type, value) => {
        setOrderLevelDiscount({type, value});
        setOrderDiscountModal(false);
    };

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        setPersistData(null);
        navigate(-1);
    };

    return (
        <>
            {setHeader(purchaseOrder)}
            <CustomContainer reduceHeight={100} className="addScreenMainBox  createCutomerContainer">
                <CreatePurchaseOrderComponent
                    handleSelect={handleCreate}
                    company={company}
                    setCompany={setCompany}
                    ship={ship}
                    setShip={setShip}
                    reference={reference}
                    setReference={setReference}
                    poDate={poDate}
                    setPoDate={setPoDate}
                    expected={expected}
                    setExpected={setExpected}
                    shippingMethod={shippingMethod}
                    setShippingMethod={setShippingMethod}
                    notememo={notememo}
                    setNoteMemo={setNoteMemo}
                    notes={notes}
                    setNotes={setNotes}
                    itemData={itemData}
                    setItemData={setItemData}
                    errors={errors}
                    setErrors={setErrors}
                    disablFields={disablFields}
                    purchaseOrder={purchaseOrder}
                    setOrderDiscountModal={setOrderDiscountModal}
                    orderLevelDiscount={orderLevelDiscount}
                    totalItemsCount={totalItemsCount}
                    discardModalToggle={discardModalToggle}
                    setDiscardModalToggle={setDiscardModalToggle}
                    toggleDiscardModal={toggleDiscardModal}
                    handleDiscardAction={handleDiscardAction}
                    toggleCompanyModal={toggleCompanyModal}
                    setToggleCompanyModal={setToggleCompanyModal}
                    po_data={po_data}
                    setPersistData={setPersistData}
                    isCreatedPO={isCreatedPO}
                />
            </CustomContainer>
            <OrderDiscountsModal setToggle={setOrderDiscountModal} toggle={orderDiscountModal} handleSave={handleOrderDiscount} showDiscountType={true} title={I18n.back} />
        </>
    );
};

export default CreatePurchaseOrder;
