import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../context";
import { Button } from "../../common";

const SecondaryHeader = ({
  leftButton,
  orderTitle,
  holdButton,
  newButton,
  closeBtn,
  rightButton,
  orderNumber,
  orderDateTime,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <section style={{ background: theme.topBarBG }} className="topBarMainCont">
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="topBarLeftSide">
              <h1
                className="fontSize20 fontWeight600 marBot0 marRight5"
                style={{ color: theme.white }}
              >
                {orderTitle}
              </h1>
              <p className="headerTitleSeperator marLeft10 marRight10 height20"></p>
              <p
                className="fontSize20 fontWeight400 marBot0 marRight5"
                style={{ color: theme.white }}
              >
                {orderNumber}
              </p>
              <p
                className="orderDatetme fontSize14 fontWeight400 marBot0 marLeft10 marRight5"
                style={{ color: theme.white }}
              >
                {orderDateTime}
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="topBarRightSide">
              {rightButton && (
                <Button
                  className={`printBtn fontWeight600 fontSize16 cursorPointer marTop0 marRight15`}
                  title={rightButton.title}
                  handleClick={rightButton.action}
                />
              )}

              {closeBtn && (
                <Button
                  type="verticalButton"
                  title={closeBtn?.title}
                  handleClick={closeBtn.action}
                  icon_name={closeBtn.icon}
                  icon_fill={theme.white}
                  icon_width={"12"}
                  icon_height={"12"}
                  icon_viewBox={"0 0 15 15"}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SecondaryHeader;
