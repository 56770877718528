import {Model} from '@nozbe/watermelondb';
import {CREDIT_TRANSACTION_SCHEMA} from '../schema';
import {date, field, relation, text} from '@nozbe/watermelondb/decorators';

export default class CreditTransaction extends Model {
    static table = CREDIT_TRANSACTION_SCHEMA;

    static associations = {
        customer: {type: 'belongs_to', key: 'customer_id'},
    };

    @text('payment_method') payment_method;
    @field('amount') amount;
    @text('status') status;

    @date('completed_at') completed_at;
    @date('created_at') created_at;

    @relation('customer', 'customer_id') customer;
}
