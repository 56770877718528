import React, { useEffect, useState } from "react";
import { CheckBox } from "../../../../../common";
import { useThemeContext } from "../../../../../../context";
import { toSnakeCase } from "../../../../../../constants";

const ItemList = ({
  item,
  setSelectedItems,
  selectedItems,
  setErrors,
  currentPage,
}) => {
  const { theme } = useThemeContext();
  const id = item.id;

  const handleChange = (e) => {
    const value = e.target.checked;

    if (value) {
      setSelectedItems((prev) => [...prev, { item, isChecked: true }]);
      setErrors((prevErrors) => {
        const label = "items";
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[toSnakeCase(label)]) {
          delete updatedErrors[toSnakeCase(label)];
        }
        return updatedErrors;
      });
    } else {
      const filteredItems = selectedItems?.filter(
        (elem) => elem?.item?.id !== id
      );
      setSelectedItems(filteredItems);
    }
  };

  return (
    <div className="itemsListWrapper">
      <CheckBox
        onChange={handleChange}
        checked={selectedItems?.some((elem) => elem?.item?.id === id)}
      />
      <p className="marBot0 fontSize14" style={{ color: theme.white }}>
        {item?.name}
      </p>
    </div>
  );
};

export default ItemList;
