import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const VerticalButton = ({
  value,
  disabled,
  handleClick,
  icon_name,
  icon_fill,
  icon_height,
  icon_width,
  icon_viewBox,
  title,
  backgroundColor,
  count,
}) => {
  const { theme } = useThemeContext();

  return (
    <button
      value={value}
      className="buttonVertical"
      disabled={disabled}
      onClick={handleClick}
      style={{
        background: backgroundColor,
      }}
    >
      <span
        className="verticalButtonIcon"
        style={{ borderColor: theme.background }}
      >
        {count ? (
          <p className="countBox">{count}</p>
        ) : (
          <Icon
            name={icon_name}
            fill={icon_fill}
            height={icon_height || "19"}
            width={icon_width || "20"}
            viewBox={icon_viewBox || "0 0 16 15"}
          />
        )}
      </span>
      <span className="fontSize12" style={{ color: theme.white }}>
        {title}
      </span>
    </button>
  );
};

export default VerticalButton;
