import Icon from "../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../context";

const ListItem = ({ value, handleDelete }) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();
  return (
    <div className="optionListInner" style={{ borderColor: theme.posRightBg }}>
      <p
        className="optionListInnerLeft fontSize14 marBot0"
        style={{ color: theme.white }}
      >
        {value}
      </p>
      <p className="cursorPointer marBot0">
        <Icon
          name="delete2Icon"
          fill={theme.red}
          width="18"
          height="18"
          viewBox={"0 0 18 18"}
          onClick={() => handleDelete(value)}
        />
      </p>
    </div>
  );
};

export default ListItem;
