import {fetchCompletedOrders} from '../../pos-core/database/helpers';
import {useState} from 'react';

const useCatItemByDate = () => {
    const [catItemOverview, setCatItemOverview] = useState([]);
    const [loading, setLoading] = useState(true);

    const generateData = (data = []) => {
        let obj = {
            color: data?.length
                ? data.map(item => item.color)
                : [...Array(10)].map(item => {
                      return '#555968';
                  }),
            price: data?.length
                ? data.map(item => item.price)
                : [...Array(10)].map(item => {
                      return '1';
                  }),
            labels: data?.length
                ? data.map(item => item.name)
                : [...Array(10)].map(item => {
                      return '';
                  }),
            nonIdeal: !Boolean(data?.length),
            data: data || [],
        };

        setCatItemOverview(obj);
        setLoading(false);
    };

    const getCatItemOverview = async (currentStartDate, currentEndDate, type, locationId) => {
        setLoading(true);

        try {
            let orderLines = [],
                obj = {},
                count = 0;

            currentEndDate = currentEndDate || new Date(new Date().setHours(23, 59, 59, 999));
            currentStartDate = currentStartDate || new Date(new Date().setHours(0, 0, 0, 0));

            let orders = await fetchCompletedOrders(currentStartDate, currentEndDate, locationId);

            if (orders.length) {
                await Promise.all(
                    orders.map(async ordr => {
                        orderLines.push(...(await ordr.order_line.fetch()));
                    }),
                );

                await Promise.all(
                    orderLines.map(async item => {
                        let product = (await item?.product?.fetch()) || {};
                        let data = type == 'category' ? (await product?.category?.fetch()) || {} : product;

                        if (data?.name) {
                            if (obj[data.name]) obj[data.name].price += item.selling_price;
                            else obj[data.name] = {price: item.selling_price, name: data.name};
                        }
                    }),
                );

                if (Object.values(obj).length) {
                    let data = Object.values(obj)
                        .sort((a, b) => (b.price > a.price ? -1 : 1))
                        .slice(-10);
                    data = data.map((item, index) => {
                        return {
                            ...item,
                            color: index == data.length - 1 ? '#00AEEF' : index == 0 ? '#C6EDFB' : '#8EDBF8',
                        };
                    });
                    generateData(data);
                } else generateData();
            } else {
                generateData();
            }
        } catch (err) {
            generateData();
            setLoading(false);
        }
    };

    return {
        getCatItemOverview,
        catItemOverview,
        loading,
    };
};

export default useCatItemByDate;
