import {Container, Row, Col} from 'react-bootstrap';
import {Header, NonIdealScreen, ToastBox} from '../../../../common';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {observeArchivedPO, observeCreatedAndDraftPO, searchPO} from '../../../../../pos-core/database/helpers';
import withObservables from '@nozbe/with-observables';
import {useSearch} from '../../../../../hooks';
import List from './List';
import useFilter from '../../../../../hooks/useFilter';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';

const PurchaseOrderList = ({purchaseOrders, archived}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {text, setText, searchList} = useSearch(handleSearch);
    const {filteredList, setOption, option} = useFilter(handleFilter, archived);

    const sortedPO = purchaseOrders.sort((a, b) => b.created_at - a.created_at);

    const {renderToast, setRenderToast} = useAuthContext();
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const userPermission = usePermission('purchaseorder');

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'po_created') {
            setShowMessage({
                visible: true,
                message: I18n.po_created_successfully,
            });
        } else if (renderToast === 'po_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.po_saved_into_draft,
            });
        } else if (renderToast === 'po_rejected') {
            setShowMessage({
                visible: true,
                message: I18n.po_rejected,
            });
        } else if (renderToast === 'po_pending') {
            setShowMessage({
                visible: true,
                message: I18n.po_pending,
            });
        } else if (renderToast === 'po_approved') {
            setShowMessage({
                visible: true,
                message: I18n.po_approved,
            });
        }
    }, [renderToast]);
    function handleSearch(val) {
        return searchPO(val.toLowerCase());
    }

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    const handleCreatePO = async () => {
        navigate('/purchaseorder/create');
    };
    function handleFilter(selected) {
        if (selected == 'all') {
            setOption('');
            return sortedPO;
        } else {
            setOption(selected);
            return selected == 'archived' ? archived : sortedPO.filter(x => x.status.toLowerCase() === selected.toLowerCase());
        }
    }

    const filterOption = [
        {
            title: I18n.all,
            action: () => handleFilter('all'),
            checked: option === 'all' || option === '' ? true : false,
        },
        {
            title: I18n.rejected,
            action: () => handleFilter('rejected'),
            checked: option === 'rejected' ? true : false,
        },
        {
            title: I18n.draft,
            action: () => handleFilter('draft'),
            checked: option === 'draft' ? true : false,
        },
        {
            title: I18n.pending,
            action: () => handleFilter('pending'),
            checked: option === 'pending' ? true : false,
        },
        {
            title: I18n.approved,
            action: () => handleFilter('approved'),
            checked: option === 'approved' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter('archived'),
            checked: option === 'archived' ? true : false,
        },
    ];

    const renderList = text ? searchList : option ? filteredList : sortedPO;

    return sortedPO.length ? (
        <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
            <Header
                type="search"
                leftCta={{
                    title: option ? option : I18n.purchase_order,
                    name: 'downIcon2',
                    fill: theme.white,
                    width: '20',
                    height: '20',
                    viewBox: '0 0 18 18',
                    option: filterOption,
                }}
                title={`${I18n.purchase_order} (${sortedPO.length})`}
                search={{
                    type: 'pos',
                    placeholder: I18n.search_vendor_order_no,
                    prefix: {
                        name: 'search2Icon',
                        fill: theme.white,
                    },
                    onChange: onChange,
                }}
                className={'borderRadiusTopleftTopRigt'}
                rightCta={userPermission?.canCrud ? {title: I18n.create_po, action: handleCreatePO} : null}
            />

            <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <TableHeader className={`eightColumn`} style={{borderColor: theme.posRightBg}}>
                                <div className="BoxWidth justifyStart">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.po_num}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.status}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.purchase_date}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16purchaseorder OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.expected_on}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.vendor}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.ship_to}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.amount}
                                    </p>
                                </div>

                                {userPermission?.canCrud && (
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.action}
                                        </p>
                                    </div>
                                )}
                            </TableHeader>

                            <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                {renderList?.length === 0 ? (
                                    <NonIdealScreen
                                        heading={option == 'archived' ? I18n.archive_po : option === 'draft' ? I18n.draft_po : I18n.search}
                                        subHeading={
                                            option == 'archived'
                                                ? I18n.no_archive_po_found
                                                : option === 'draft'
                                                ? I18n.no_draft_po_found
                                                : I18n.we_couldnt_find_any_results_for_your_search
                                        }
                                        name={option == 'archived' ? 'archiveIcon' : option === 'draft' ? 'draftIcon' : 'seacrhNotFoundIcon'}
                                        fill={theme.white}
                                        isMultiStep={false}
                                        tablenonIdeal={true}
                                    />
                                ) : (
                                    renderList?.map((val, index) => <List item={val} userPermission={userPermission} />)
                                )}
                            </div>
                            <ToastBox
                                className="addSuccessfullyToast"
                                ToastVisiable={showMessage.visible}
                                bodyPara={showMessage.message}
                                setShowMessage={setShowMessage}
                                showIcon={true}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    ) : (
        <NonIdealScreen
            heading={I18n.purchase_order}
            subHeading={I18n.create_purchase_order_and_manage_inventory}
            name="purchaseOrderNewIon"
            fill={theme.white}
            secondaryCta={
                userPermission?.canCrud
                    ? {
                          title: I18n.create_po,
                          action: handleCreatePO,
                      }
                    : null
            }
            isMultiStep={false}
        />
    );
};

const enhance = withObservables([], () => ({
    purchaseOrders: observeCreatedAndDraftPO(),
    archived: observeArchivedPO(),
}));

export default enhance(PurchaseOrderList);
