import { useAuthContext } from "../../../../../context";
import { useSalesTax } from "../../../../../hooks";
import {
  CreateSalestaxComponent,
  CustomContainer,
  Loading,
} from "../../../../common";

const EditSalesTax = () => {
  const { business, user, account } = useAuthContext();

  const { isLoading, tax } = useSalesTax(account);
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox  createCustomerContainer"
      >
        <CreateSalestaxComponent tax={tax} />
      </CustomContainer>
    </>
  );
};

export default EditSalesTax;
