import {Dropdown} from 'react-bootstrap';

const DropdownList = ({dropDown}) => {
    return dropDown.option.map(value => (
        <Dropdown.Item key={value?.title} value={value?.title} href="#" onClick={value?.action}>
            {value?.title}
        </Dropdown.Item>
    ));
};

export default DropdownList;
