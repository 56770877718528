import {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../../context';
import {Button, CustomContainer, TextField} from '../../../common';
import {toSnakeCase, validateConfirmPassword} from '../../../../constants';
import {forgotPassword} from '../../../../api';

const ChangePassword = () => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const [errors, setErrors] = useState({});

    const onChange = (label, value) => {
        if (label === I18n.create_new_password) {
            setNewPassword(value);
        } else if (label === I18n.confirm_password) {
            setConfirmPassword(value);
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleFinish = async () => {
        try {
            setIsLoading(true);
            const payload = {
                password: newPassword,
                confirmPassword,
                otp: localStorage.getItem('otp'),
                email: localStorage.getItem('email'),
            };

            const formErrors = validateConfirmPassword(payload);
            console.log('formErrors', formErrors);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) return;

            const response = await forgotPassword(payload);

            if (response.success !== true) {
                setErrors({confirm_new_password: response.msg});
            } else {
                localStorage.removeItem('email');
                localStorage.removeItem('otp');

                navigate('/login');
            }

            console.log('response', response);
            setIsLoading(false);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleFinish();
        }
    };

    return (
        <CustomContainer handlePress={handleEnterPress} className="loginPageWrapper">
            <div className="logoBox">
                <Icon name={'oscarLogo'} fill={'white'} viewBox={'0 0 115 110'} width={'55'} height={'50'} />
            </div>

            <Row className="justify-content-md-center">
                <Col md={5}>
                    <div className="loginFormBox">
                        <h1 className="fontSize24 fontWeight700 marBot20 " style={{color: theme.text}}>
                            {I18n.create_new_password}
                        </h1>

                        <TextField
                            onChange={onChange}
                            label={I18n.create_new_password}
                            placeholder={I18n.create_new_password}
                            value={newPassword}
                            error={errors.create_new_password}
                            required={true}
                            autoFocus={true}
                            type={viewPassword ? 'text' : 'password'}
                            suffix={viewPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                            suffix_fill={theme.white}
                            suffix_width={'18'}
                            suffix_height={'18'}
                            suffix_viewBox={'0 0 18 18'}
                            suffixClick={() => setViewPassword(prev => !prev)}
                        />

                        <TextField
                            onChange={onChange}
                            label={I18n.confirm_password}
                            placeholder={I18n.confirm_password}
                            value={confirmPassword}
                            error={errors.confirm_new_password}
                            required={true}
                            type={viewPassword ? 'text' : 'password'}
                            suffix={viewConfirmPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                            suffix_fill={theme.white}
                            suffix_width={'18'}
                            suffix_height={'18'}
                            suffix_viewBox={'0 0 18 18'}
                            suffixClick={() => setViewConfirmPassword(prev => !prev)}
                        />

                        <div className="signinLoginButton">
                            <Button
                                className={`width100 fontWeight600 fontSize16 cursorPointer`}
                                title={I18n.finish}
                                saveLoad={isLoading ? true : false}
                                handleClick={handleFinish}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </CustomContainer>
    );
};

export default ChangePassword;
