import Icon from '../../../assets/icons';
import {firstLetterCaptilize} from '../../../constants';
import TextBox from '../TextBox';

const TextField = ({
    prefix_viewBox,
    prefix_height,
    prefix_width,
    prefixClick,
    prefix_fill,
    prefix,
    suffix_viewBox,
    suffix_height,
    suffixClick,
    suffix_width,
    suffix_fill,
    suffix,
    error,
    onChange,
    label,
    type,
    onlyNumbers = false,
    inputRef,
    highlighter,
    setOptionAsLabel = true,
    variant = 'filled',
    required = false,
    hideInputError = false,
    ...rest
}) => {
    const handleChange = (event, key) => {
        let value = key || event.target.value;

        onChange(label, type == 'numeric' || onlyNumbers ? value.replace(/[^0-9]/g, '') : value);
    };

    return (
        <div className={error ? 'form-group formError errorField' : 'form-group'}>
            <div className={`formGroupInner ${prefix ? 'formGroupWithprefix' : ''}`}>
                {prefix ? (
                    <span className="prefixIcon">
                        <Icon
                            name={prefix}
                            fill={prefix_fill}
                            onClick={prefixClick}
                            width={prefix_width || '18'}
                            height={prefix_height || '18'}
                            viewBox={prefix_viewBox || '0 0 18 18'}
                        />
                    </span>
                ) : null}

                <TextBox
                    label={label}
                    type={type}
                    onChange={handleChange}
                    inputRef={inputRef}
                    highlighter={highlighter}
                    setOptionAsLabel={setOptionAsLabel}
                    variant={variant}
                    required={required}
                    suffixIcon={
                        suffix ? (
                            <span className="sufixIcon">
                                <Icon viewBox={suffix_viewBox} height={suffix_height} onClick={suffixClick} width={suffix_width} fill={suffix_fill} name={suffix} />
                            </span>
                        ) : null
                    }
                    {...rest}
                />
            </div>
            {hideInputError ? null : <p className="inputError">{firstLetterCaptilize(error || '')}</p>}
        </div>
    );
};

export default TextField;
