import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context"

const IconAction = ({ type }) => {

    const { theme } = useThemeContext();

    return (
        <span className="backBoxIcon">
            <Icon
                name={type === "skip" ? "forwardIcon" : type === "close" ? "closeIcon" : "backIcon"}
                fill={theme.white}
                width={type === "skip" ? "9" : type === "close" ? "15" : "9"}
                height={type === "skip" ? "15" : type === "close" ? "15" : "15"}
                viewBox={type === "skip" ? "0 0 9 15" : type === "close" ? "0 0 15 15" : "0 0 9 15"}

            />
        </span>
    )
}

export default IconAction;