import { useEffect, useState } from "react";
import { useAuthContext } from "../../context";
import { findUserByID } from "../../pos-core/database/helpers";

const useSessionUser = () => {
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    handleSessionUser();
  }, []);

  const handleSessionUser = async () => {
    let userID = localStorage.getItem("session-user");

    if (userID) {
      let getUser = await findUserByID(userID);
      setLocalUser(getUser);
    } else {
      setLocalUser(user);
    }

    setIsLoading(false);
  };

  return { isLoading, localUser };
};

export default useSessionUser;
