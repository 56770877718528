import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Header, NonIdealScreen} from '../../../../common';
import withObservables from '@nozbe/with-observables';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {Col, Container, Row} from 'react-bootstrap';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {observeCompleteOrders} from '../../../../../pos-core/database/helpers';
import useFilter from '../../../../../hooks/useFilter';
import {useNavigate} from 'react-router-dom';
import usePermission from '../../../../../hooks/usePermission';

const OrderHistoryList = ({orders}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const {filteredList, setOption, option} = useFilter(handleFilter);
    let navigate = useNavigate();
    const userPermission = usePermission('pos');

    const actionList = [
        {
            title: I18n.all_types,
            action: () => {},
        },
        {
            title: I18n.dine_in,
            action: () => {},
        },
        {
            title: I18n.delivery,
            action: () => {},
        },
        {
            title: I18n.take_away,
            action: () => {},
        },
    ];

    const resturantfilterOption = [
        {
            title: I18n.all_orders,
            action: () => handleFilter('all_orders'),
            checked: option === 'all_orders' ? true : false,
        },
        {
            title: I18n.void_orders,
            action: () => handleFilter('void_orders'),
            checked: option === 'void_orders' ? true : false,
        },
        {
            title: I18n.paid_orders,
            action: () => handleFilter('paid_orders'),
            checked: option === 'paid_orders' ? true : false,
        },
        {
            title: I18n.cash_payment,
            action: () => handleFilter('cash_payment'),
            checked: option === 'cash_payment' ? true : false,
        },
        {
            title: I18n.card_payment,
            action: () => handleFilter('card_payment'),
            checked: option === 'card_payment' ? true : false,
        },
        {
            title: I18n.wallet_payment,
            action: () => handleFilter('wallet_payment'),
            checked: option === 'wallet_payment' ? true : false,
        },
        {
            title: I18n.credit_payment,
            action: () => handleFilter('credit_payment'),
            checked: option === 'credit_payment' ? true : false,
        },
    ];

    const posFilterOption = [
        {
            title: I18n.complete_order,
            action: () => handleFilter('complete_order'),
            checked: option === 'complete_order' ? true : false,
        },
        {
            title: I18n.saved_order,
            action: () => handleFilter('saved_order'),
            checked: option === 'saved_order' ? true : false,
        },
    ];
    function handleFilter(selected) {
        setOption(selected);
        return orders.filter(x => {
            if (selected === 'complete_order') {
                return x.status == 'complete';
            } else if (selected === 'saved_order') {
                return x.status === 'hold';
            }
        });
    }

    function handleSearch(val) {
        return orders.filter(x => x.number.toLowerCase().includes(val.toLowerCase()));
    }

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';

        setText(value);
    };

    const handleSelling = () => {
        navigate('/pos');
    };

    const renderList = text ? searchList : option ? filteredList : orders;
    return orders?.length ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    title={`${I18n.order_history} (${orders?.length})`}
                    type="search"
                    className={'borderRadiusTopleftTopRigt'}
                    leftCta={{
                        title: option || I18n.all_orders,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: posFilterOption,
                    }}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_order_number,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    // actionCta={{
                    //     title: I18n.all_types,
                    //     name: 'downIcon2',
                    //     fill: theme.white,
                    //     width: '20',
                    //     height: '20',
                    //     viewBox: '0 0 18 18',
                    //     option: actionList,
                    // }}
                />
                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`sevenColumn`} style={{borderColor: theme.posRightBg}}>
                                    <div className="BoxWidth justifyStart">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.order_no}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.date}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.customer}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.status}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.payment}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.action}
                                        </p>
                                    </div>
                                </TableHeader>

                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={text ? I18n.search : option == 'complete_order' ? I18n.complete_order : I18n.saved_order}
                                            subHeading={
                                                text
                                                    ? I18n.we_couldnt_find_any_results_for_your_search
                                                    : option == 'complete_order'
                                                    ? I18n.no_complete_order_found
                                                    : I18n.no_saved_order_found
                                            }
                                            name={text ? 'seacrhNotFoundIcon' : option == 'complete_order' ? 'soldOutIcon' : 'soldOutIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => <List key={val.id} order={val} />)
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <NonIdealScreen
            heading={I18n.order_history}
            subHeading={I18n.order_history_not_found_start_selling}
            name="clockhistoryIcon"
            fill={theme.white}
            secondaryCta={
                userPermission?.canCrud
                    ? {
                          title: I18n.start_selling,
                          action: handleSelling,
                      }
                    : null
            }
            isMultiStep={false}
        />
    );
};

const enhance = withObservables([], () => ({
    orders: observeCompleteOrders(),
}));

export default enhance(OrderHistoryList);
