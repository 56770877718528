import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {firstLetterCaptilize, formatSingleDigitNum} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';

const List = ({category, handleChecked, selected, setSelected, setShowMessage, filter, categoryItemCount, userPermission}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [archiveModal, setArchiveModal] = useState(false);

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async category => {
        setArchiveModal(false);
        let data = category?.id;
        if (selected[data]) {
            setSelected(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }

        await category.archiveCategory(category?.archive ? false : true);
        setShowMessage({
            visible: true,
            message: category?.archive ? I18n.category_archive_successfully : I18n.category_unarchive_successfully,
        });
    };

    const handleAction = async type => {
        switch (type) {
            case 'edit':
                navigate(`${category.id}/edit`, {
                    state: {screenName: filter},
                });
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
    ];

    const navigateToNextScreen = () => {
        if (!userPermission?.canCrud) return;
        navigate(`${category.id}/edit`);
    };

    return (
        <>
            <TableBody className={`threeColumn cursorPointer`} onClick={navigateToNextScreen} style={{borderColor: theme.inputBorder}}>
                <div
                    className="checkboxCol BoxWidth"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(category?.name)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {formatSingleDigitNum(categoryItemCount)}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n[category.archive ? 'do_you_really_want_to_unarchive_this_category' : 'do_you_really_want_to_archive_this_category']}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n[category.archive ? 'unarchive' : 'archive'],
                    action: () => {
                        handleArchive(category);
                    },
                }}
            />
        </>
    );
};

const enhance = withObservables(['category'], ({category}) => ({
    category: category.observe(),
    categoryItemCount: category.product.observeCount(),
}));

export default enhance(List);
