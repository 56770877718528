import { useLanguageContext, useThemeContext } from "../../../../../context";
import { NonIdealScreen } from "../../../../common";

const InvoiceList = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleCreateInvoice = () => {
    // handleCreateInvoice
  };

  const handleImportInvoice = () => {
    // handleImportInvoice
  };

  return (
    <NonIdealScreen
      heading={I18n.add_invoices}
      subHeading={I18n.create_new_invoice_and_add_to_sale}
      paragraph={I18n.before_you_open_your_store_first_you_need_some_invoice}
      name="invoiceIcon"
      fill={theme.white}
      primaryCta={{
        title: I18n.create_invoices,
        action: handleCreateInvoice,
      }}
      secondaryCta={{
        title: I18n.import_invoices,
        action: handleImportInvoice,
      }}
      isMultiStep={false}
    />
  );
};

export default InvoiceList;
