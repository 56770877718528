import {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {toSnakeCase, validateAccountForm} from '../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../context';
import {Button, TextField} from '../../../common';
import {getAllCountry} from '../../../../api';
import Icon from '../../../../assets/icons';

const Step1 = ({handleSave, navigateToLogin}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('Pakistan');
    const [countryList, setCountryList] = useState([]);

    const [errors, setErrors] = useState({});

    const onChange = (label, value) => {
        if (label === I18n.first_name) {
            setFirstName(value);
        } else if (label === I18n.last_name) {
            setLastName(value);
        } else if (label === I18n.enter_your_phone_number) {
            setPhoneNumber(value);
        } else if (label === I18n.enter_email) {
            setEmail(value);
        } else if (label === I18n.country) {
            setCountry(value);
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (label === I18n.enter_your_phone_number) {
                label = 'phone_number';
            }

            if (label === I18n.enter_email) {
                label = 'email';
            }
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleNext = async () => {
        const params = {
            firstName,
            lastName,
            email,
            phoneNumber,
        };
        const formErrors = await validateAccountForm(params);
        console.log('formErrors', formErrors);
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) {
            return;
        }

        let userDetails = {
            firstName,
            lastName,
            phoneNumber,
            email,
            country,
        };

        handleSave(userDetails);
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleNext();
        }
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const res = await getAllCountry();
                setCountryList(res?.data);
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchCountries();
    }, []);

    return (
        <>
            <Col md={5} onKeyDown={handleEnterPress}>
                <div className="loginFormBox">
                    <div className="signupTextBox">
                        <h1 className="fontSize24 fontWeight700 marBot10" style={{color: theme.text}}>
                            {I18n.lets_create_account}
                        </h1>
                        <p className="fontSize14 fontWeight400 marBot0 darkGrayTwo">{I18n.signing_oscar_is_fast}</p>
                    </div>

                    <div className="singupFields">
                        <TextField onChange={onChange} label={I18n.first_name} placeholder={I18n.first_name} value={firstName} type="text" error={errors.first_name} />

                        <TextField onChange={onChange} label={I18n.last_name} placeholder={I18n.last_name} value={lastName} type="text" error={errors.last_name} />
                    </div>

                    <TextField
                        label={I18n.country}
                        placeholder={I18n.country}
                        data={countryList?.map(item => item?.countryName)}
                        value={country}
                        onChange={onChange}
                        inputType="select"
                        type="text"
                        suffix="downIcon2"
                        suffix_fill={theme.white}
                        suffix_width={'24'}
                        suffix_height={'24'}
                        suffix_viewBox={'0 0 18 18'}
                    />

                    <TextField
                        onChange={onChange}
                        label={I18n.enter_your_phone_number}
                        placeholder={I18n.enter_your_phone_number}
                        value={phoneNumber}
                        error={errors.phone_number}
                        maxLength={11}
                        type="numeric"
                    />

                    <TextField
                        onChange={onChange}
                        label={I18n.enter_email}
                        placeholder={I18n.enter_email}
                        value={email}
                        type="email"
                        OnTextChangeType={'email'}
                        error={errors.email}
                    />

                    <Button className={`width100 fontWeight600 fontSize16 cursorPointer marTop20`} title={I18n.continue} type="secondary" handleClick={handleNext} />

                    <div>
                        <p className="fontSize14 marTop20">
                            <span className="white">{I18n.already_have_an_account}</span>
                            <span className="cursorPointer  marLeft5 barclaysBlueColor" onClick={navigateToLogin}>
                                {I18n.login}
                            </span>
                            <span className="marLeft5">
                                <Icon name="arrowRight" viewBox="0 0 18 18 " />
                            </span>
                        </p>
                    </div>
                </div>
            </Col>
        </>
    );
};

export default Step1;
