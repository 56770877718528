import withObservables from '@nozbe/with-observables';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {
    BillAmountBox,
    Breadcrums,
    Button,
    CreateCustomerModal,
    CustomerDetailModal,
    HoldOrderModal,
    LeftPane,
    MessageModal,
    OrderDiscountsModal,
    PosDiscountModal,
    RightPane,
    SearchBox,
    TabBox,
    ToastBox,
} from '../../../../common';
import {Container, Row} from 'react-bootstrap';
import {
    DEVICE_HEIGHT,
    INITIAL_PAGE,
    ITEMS_PER_PAGE,
    generateRandomNumbers,
    handleUpdateProductQty,
    formatSingleDigitNum,
    PREPARING,
    checkIfValueExists,
    VOID,
} from '../../../../../constants';
import {useCallback, useEffect, useState} from 'react';
import Pagination from '@mui/material/Pagination';
import NonIdeal from './NonIdeal.js';
import {
    observeActiveProducts,
    observeActiveCategories,
    observeActiveOrder,
    bulkDeleteOrderLines,
    observeHeldOrderCount,
    queryItems,
    getPerPageItems,
    queryCategories,
    observeGetOrderById,
} from '../../../../../pos-core/database/helpers';
import CategoryList from './CategoryList';
import ProductList from './ProductList';
import OrderItem from './OrderLine';
import {switchMap} from 'rxjs';
import {useOrderCalculation, useSessionUser} from '../../../../../hooks';
import useBarcode from '../../../../../hooks/useBarcode';
import BarcodeReader from 'react-barcode-reader';
import useShortcut from '../../../../../hooks/useShortcut';
import useFocus from '../../../../../hooks/useFocus/index.js';
import {ItemDiscountsModal, NewPosHeader, PosHeaderButton, SelectedCustomerModal, Tooltip} from '../../../../common/V2';
import NewAppModal from '../../../../common/NewAppModal/index.js';
import ModifierModal from '../../Restaurant/Modifiers/ModifierModal/index.js';
import SelectedWaiterModal from '../../../../common/V2/SelectedWaiterModal/index.js';
import ItemNotesModal from '../../../../common/ItemNotesModal/index.js';
import VoidReasonModal from '../../../../common/VoidReasonModal/index.js';
import LoyaltyPointsModal from '../../../../common/V2/LoyaltyPointsModal/index.js';

const RestaurantOrder = ({activeProducts, activeCategories, orders, orderLines, customer, waiter, discount, sales_tax, floor_plan, dine_in_table}) => {
    let order = orders[0];

    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, handleCreateOrder, account} = useAuthContext();
    const {localUser} = useSessionUser();
    const {textBoxRef, handleFocus} = useFocus(true);

    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    const [products, setProducts] = useState(activeProducts);
    const [categories, setCategories] = useState(activeCategories);
    const [search, setSearch] = useState('');
    const isRestaurantPos = true;

    const {handleScan, handleError, barcode} = useBarcode(products, handleAddBarcodeSearchedProduct);
    useShortcut(
        {
            keys: [
                {key: 'shift+o', action: handleOpenItem},
                {key: 'shift+enter', action: handlePlaceOrder},
                {key: 'shift+s', action: handleFocus},
                {key: 'shift+n', action: redirectToItemScreen},
                {key: 'esc', action: closeModal},
            ],
        },
        orderLines,
    );

    const beforePlacingOrderAction = [
        {
            title: 'Clear Order',
            action: () =>
                setConfirmationModal({
                    visible: true,
                    message: 'Are you sure you want to clear this order?',
                    action: handleClearOrder,
                    primaryCta: 'Clear',
                }),
        },
        {
            title: 'Save Order',
            action: () =>
                setConfirmationModal({
                    visible: true,
                    message: 'Are you sure you want to save this order?',
                    action: handleSaveOrder,
                    primaryCta: 'Save',
                }),
        },
    ];

    const afterPlacingOrderAction = [
        {
            title: 'Void order',
            action: () => {
                setVoidReason(order);
            },
        },
    ];

    const {subTotal, total, tax, giveItemLevelDiscount, givenPosDiscount, redeemAmount} = useOrderCalculation({
        order,
        orderLines,
        discount,
        sales_tax,
        isRestaurantPos,
    });

    const [tabValue, setTabVal] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [selectWaiter, setSelectWaiter] = useState(false);
    const [itemNotes, setItemNotes] = useState(null);
    const [voidReason, setVoidReason] = useState(null);
    const [redeemPoints, setRedeemPoints] = useState(null);
    const [customerDetailModal, setCustomerDetailModal] = useState(false);
    const [holdOrderModal, setHoldOrderModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [heldOrderCount, setHeldOrderCount] = useState();
    const [activeOpenItem, setActiveOpenItem] = useState(null);
    const [numOfPages, setNumOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [posDiscountModalToggle, setPosDiscountModalToggle] = useState(false);
    const [posDiscount, setPosDiscount] = useState(discount?.name || '');
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const [orderDiscountModal, setOrderDiscountModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [modifierModal, setModifierModal] = useState({
        visible: false,
        selectedItem: null,
        modifiers: null,
    });
    const [selectedModifier, setSelectedModifier] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(null);

    const clearConfirmationModal = () => setConfirmationModal(null);

    const handleChange = useCallback((event, newValue) => {
        if (newValue === 0) setSearch('');
        setSelectedCategory();
        setTabVal(newValue);
    }, []);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    const preparePagination = async () => {
        if (tabValue === 0) {
            const {totalCount} = await queryCategories('', search, currentPage, ITEMS_PER_PAGE);
            setNumOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        } else {
            const {totalCount} = await queryItems('', search, currentPage, ITEMS_PER_PAGE);
            setNumOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        }
    };

    const updateOrderPrice = async () => {
        await order.updateOrderTotal(total);
    };

    useEffect(() => {
        handleHeldOrderCount();
    }, []);

    useEffect(() => {
        onChange();
        preparePagination();
    }, [tabValue]);

    useEffect(() => {
        updateOrderPrice();
    }, [total]);

    const handleHeldOrderCount = async () => {
        let count = await observeHeldOrderCount();
        setHeldOrderCount(count);
    };

    const toggleDeleteModal = () => setDeleteModal(x => !x);

    const toggleSelectCustomer = async () => {
        let count = await account.customer.fetchCount();
        if (count) {
            setSelectCustomer(true);
        } else {
            handleCreateCustomer();
        }
    };

    const handleRedeem = () => {
        setRedeemPoints(true);
    };

    const toggleCustomerDetail = () => {
        setCustomerDetailModal(prev => !prev);
    };

    const handleCustomer = () => {
        if (customer) {
            toggleCustomerDetail();
        } else {
            toggleSelectCustomer();
        }
    };

    const handleCategory = val => setSelectedCategory(val);

    const handleProduct = (val, event) => {
        handleUpdateProductQty(val, event, orderLines, order);
    };

    function handleOpenItem() {
        let paramsForLine = {
            cost_price: 0,
            is_open: true,
            name: `Open item ${generateRandomNumbers()}`,
            quantity: 1,
            rate: 1,
            selling_price: 0,
            unit: 'Pieces',
        };

        order?.createOrderLine(paramsForLine);
    }

    function closeModal() {
        setSelectCustomer(false);
        setCustomerDetailModal(false);
    }

    async function handleAddBarcodeSearchedProduct(barcodeSearchItem) {
        let prodTemplate = await barcodeSearchItem?.product_template.fetch();

        let paramsForItem = {
            product: barcodeSearchItem,
            name: barcodeSearchItem.name,
            selling_price: barcodeSearchItem.selling_price,
            cost_price: barcodeSearchItem.cost_price,
            is_open: false,
            quantity: 1,
            tax_name: barcodeSearchItem?.sales_tax?.name,
            tax_rate: barcodeSearchItem?.sales_tax?.rate,
            is_tax_inclusive: barcodeSearchItem?.sales_tax?.is_inclusive,
            rate: 1,
            unit: prodTemplate?.unit,
        };

        handleProduct(paramsForItem);
    }

    const handleAddWaiter = () => {
        setSelectWaiter(true);
    };

    const onChange = async (event, key) => {
        let value = key || event?.target?.value || '';
        setSearch(value);

        if (tabValue === 0 && selectedCategory) return;
        if (tabValue === 0) {
            const {data, totalCount} = await queryCategories('', value.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE);
            setCategories(data);
            setCurrentPage(INITIAL_PAGE);
            setNumOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        } else {
            const {data, totalCount} = await queryItems('', value.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE);
            setProducts(data);
            setCurrentPage(INITIAL_PAGE);
            setNumOfPages(Math.ceil(totalCount / 10));
        }
    };

    const handleSelectCustomer = async val => {
        const customerId = order?.customer?.id;
        let message = '';
        if (val == 'removeCustomer') {
            await order?.updateCustomer(null);
            message = I18n.remove_Customer;
        } else {
            message = customerId ? I18n.update_Customer : I18n.customer_added;
            await order?.updateCustomer(val);
        }

        setSelectCustomer(false);
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    const handleSelectWaiter = async val => {
        const waiterId = order?.waiter?.id;
        let message = '';
        if (val == 'removeWaiter') {
            await order?.updateWaiter(null);
            message = I18n.remove_waiter;
        } else {
            message = waiterId ? I18n.update_waiter : I18n.add_waiter;
            await order?.updateWaiter(val);
        }

        setSelectWaiter(false);
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    const handleCreateCustomer = () => {
        navigate(`/customers/create?id=${order.number}&route=restuantpos`);
    };

    const handleCreateWaiter = () => {
        navigate(`/waiters/create?id=${order.number}`);
    };

    async function handlePlaceOrder() {
        if (orderLines.length == 0) return;

        navigate('/pos-cockpit');
        await order.updateOrderStatus(PREPARING);
    }

    function redirectToItemScreen() {
        navigate('/items/create');
    }

    const handleHoldOder = () => {
        setHoldOrderModal(prev => !prev);
    };

    const handleOrderStatus = async status => {
        // Need to check condition for creation of new order
        await handleCreateOrder();
        await order.updateOrderStatus(status);
        navigate('/pos-cockpit');
    };

    const handlePageChange = async e => {
        const pageNum = e?.target?.innerText - 1 || 0;
        setCurrentPage(pageNum);

        if (tabValue === 0 && selectedCategory) return;
        if (tabValue === 0) {
            if (search) {
                const {data} = await queryCategories('', search.toLowerCase(), pageNum, ITEMS_PER_PAGE);

                setProducts(data);
            } else {
                const category = await observeActiveCategories(pageNum, ITEMS_PER_PAGE);
                setCategories(category);
            }
        } else {
            if (search) {
                const {data} = await queryItems('', search.toLowerCase(), pageNum, ITEMS_PER_PAGE);

                setProducts(data);
            } else {
                const prod = await getPerPageItems(pageNum, ITEMS_PER_PAGE);
                setProducts(prod);
            }
        }
    };

    const togglePosDiscount = () => {
        setPosDiscountModalToggle(prev => !prev);
    };
    const handlePosDiscount = async val => {
        if (val == 'remove_discount') {
            await order.updatePosDiscount(null);
            setPosDiscount('');
        } else {
            setPosDiscount(val?.name);
            await order.updatePosDiscount(val);
        }
        setPosDiscountModalToggle(false);
    };

    const handleItemDiscount = () => {
        setOrderDiscountModal(true);
    };

    const handleOrderDiscount = async (type, value) => {
        let discountAvailable = selectedItem?.discount;
        let discountObj = {
            value: Number(value),
            type,
        };
        await selectedItem?.updateItemDistcount(discountObj);
        setOrderDiscountModal(false);
        if (!discountAvailable) {
            setShowMessage({
                visible: true,
                message: I18n.item_level_discount_added,
            });
        }
    };

    const handleSelectedItem = item => {
        setSelectedItem(item);
    };

    const handleSaveOrder = () => {
        handleOrderStatus('hold');
        setShowMessage({
            visible: true,
            message: I18n.order_saved,
        });
    };

    const handleClearOrder = () => {
        bulkDeleteOrderLines(orderLines);
        setShowMessage({
            visible: true,
            message: I18n.order_cleared,
        });
        setConfirmationModal(null);
    };

    const handleCloseModifierModal = () => {
        setModifierModal({visible: false, selectedItem: null, modifiers: null});
        setSelectedModifier({});
    };

    const saveModifier = async () => {
        const orderLineToUpdate = modifierModal.selectedItem;
        const resp = await orderLineToUpdate.updateOrderLineModifiers(selectedModifier);
        handleCloseModifierModal();
    };

    const saveItemNotes = async lineNote => {
        await itemNotes.updateNote(lineNote);
    };

    const saveItemVoidReason = async reason => {
        if (!reason) return;

        const businessVoidReason = business.void_reason;
        const toLowerCaseVoidReason = reason?.toLowerCase();

        if (voidReason.number) {
            await order.voidOrder(toLowerCaseVoidReason);
            // check condition here
            // await handleCreateOrder();
        } else {
            await voidReason.voidOrderLine(toLowerCaseVoidReason);
        }

        const exist = checkIfValueExists(businessVoidReason, toLowerCaseVoidReason);
        if (!exist) {
            await business.updateVoidReasons(toLowerCaseVoidReason);
        }
        setVoidReason(null);

        voidReason.number && navigate('/pos-cockpit');
    };

    const handleRedeemPoints = async val => {
        await order.updateRedeemedPoints(Number(val));
    };

    return (
        <>
            <section className="mainAppCont" style={{backgroundColor: 'white'}}>
                <Container fluid className="noPadding">
                    <Row className="noMargin">
                        <LeftPane>
                            <div className="width100">
                                <SearchBox
                                    type="pos"
                                    placeholder={I18n.search_item_by_name_and_barcode}
                                    value={search}
                                    prefix={{
                                        name: 'search2Icon',
                                        fill: theme.white,
                                    }}
                                    suffix={{
                                        name: 'scanIcon',
                                        fill: theme.white,
                                    }}
                                    onChange={onChange}
                                    inputRef={textBoxRef}
                                />

                                <PosHeaderButton
                                    className="restaurantPosHeaderBtn"
                                    firstBtn={{
                                        title: I18n.waiter,
                                        action: handleAddWaiter,
                                        value: waiter ? waiter.name : null,
                                        backgroundColor: waiter ? theme.barclaysBlue : theme.topBarBG,
                                        textColor: waiter ? theme.white : theme.barclaysBlue,
                                    }}
                                    secondBtn={{
                                        title: I18n.open_item_name,
                                        action: handleOpenItem,
                                    }}
                                />
                                <TabBox tabValue={tabValue} tabs={[I18n.categories, I18n.items]} handleChange={handleChange} />
                                <Breadcrums tabValue={tabValue} selectedCategory={selectedCategory} handleCategory={handleCategory} />
                            </div>

                            <div
                                className="leftPaneMainBox"
                                style={{
                                    height: DEVICE_HEIGHT - 303,
                                }}>
                                <div className="leftPaneItemsBox">
                                    {tabValue === 0 ? (
                                        <CategoryList
                                            data={categories}
                                            category={selectedCategory}
                                            handleCategory={handleCategory}
                                            handleProduct={handleProduct}
                                            setNumOfPages={setNumOfPages}
                                            currentPage={currentPage}
                                            search={search}
                                        />
                                    ) : (
                                        <>
                                            <ProductList data={products} handleProduct={handleProduct} />
                                        </>
                                    )}
                                </div>

                                {/* <div className="leftPaneBottomPagination paginationContainer">
                  {numOfPages > 1 && (
                    <Pagination
                      count={numOfPages}
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  )}
                </div> */}
                                <div className="leftpaneBottom">
                                    <p className="fontSize12 marBot0 cursorPointer" style={{color: theme.white}}>
                                        <span>{I18n.open_item}</span>
                                        <span className="posBottomSeperator height16"></span>
                                        <span>{I18n.add_new_item}</span>
                                    </p>
                                </div>
                            </div>
                        </LeftPane>

                        <RightPane>
                            <NewPosHeader
                                title={{
                                    name: I18n.order_details,
                                    decs: floor_plan?.name === 'Dine In' ? dine_in_table?.name : floor_plan?.name,
                                }}
                                customer={customer}
                                order={order}
                                handleRedeem={handleRedeem}
                                rightAction={{
                                    title: customer?.name || I18n.add_customer,
                                    icon_name: customer ? 'profileTicketIcon' : 'add2Icon',
                                    action: toggleSelectCustomer,
                                }}
                                showMoreIcon={{
                                    tableAction: order.status === 'active' ? beforePlacingOrderAction : afterPlacingOrderAction,
                                }}
                            />
                            {orderLines?.length ? (
                                <div
                                    className="posrightSectionInner"
                                    style={{
                                        height: DEVICE_HEIGHT - 105,
                                    }}>
                                    <div
                                        className="cartItemList"
                                        style={{
                                            height: '55%',
                                        }}>
                                        {orderLines?.map((item, index) => (
                                            <OrderItem
                                                order={order}
                                                item={item}
                                                key={index}
                                                isOpenItem={item.is_open}
                                                handleProduct={handleProduct}
                                                handleSelectedItem={handleSelectedItem}
                                                handleItemDiscount={handleItemDiscount}
                                                setModifierModal={setModifierModal}
                                                setSelectedModifier={setSelectedModifier}
                                                modifierModal={modifierModal}
                                                setItemNotes={setItemNotes}
                                                setVoidReason={setVoidReason}
                                                restauratPos={true}
                                            />
                                        ))}
                                    </div>
                                    <div className="posPaymentContainer">
                                        <div className="amountContainer">
                                            <BillAmountBox
                                                className={`width100`}
                                                subTotal={subTotal}
                                                total={total}
                                                tax={tax}
                                                giveItemLevelDiscount={giveItemLevelDiscount}
                                                givenPosDiscount={givenPosDiscount}
                                                redeemAmount={redeemAmount}
                                            />
                                        </div>

                                        <div className="flex verticalCenter horizontalCenter">
                                            <Button
                                                type="secondary"
                                                className={`fontWeight600 fontSize18 cursorPointer width50 marTop20`}
                                                backgroundColor={theme.brightGreen}
                                                txtColor={theme.white}
                                                title={orderNumber ? I18n.update_order : I18n.place_order}
                                                handleClick={handlePlaceOrder}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <NonIdeal />
                            )}
                        </RightPane>
                        <div>
                            <BarcodeReader onError={handleError} onScan={handleScan} />
                        </div>
                        <ToastBox
                            className="addSuccessfullyToast"
                            ToastVisiable={showMessage.visible}
                            bodyPara={showMessage.message}
                            setShowMessage={setShowMessage}
                            showIcon={true}
                        />
                    </Row>
                </Container>
            </section>

            <SelectedCustomerModal
                setToggle={setSelectCustomer}
                toggle={selectCustomer}
                order={order}
                handleSelect={handleSelectCustomer}
                handleCreateCustomer={handleCreateCustomer}
            />

            <SelectedWaiterModal setToggle={setSelectWaiter} toggle={selectWaiter} order={order} handleSelect={handleSelectWaiter} handleCreateWaiter={handleCreateWaiter} />

            <ItemDiscountsModal
                setToggle={setOrderDiscountModal}
                selectedItem={selectedItem}
                toggle={orderDiscountModal}
                handleSave={handleOrderDiscount}
                showDiscountType
                title={I18n.item_level_discount}
            />

            <ModifierModal
                modifierModal={modifierModal}
                handleCloseModifierModal={handleCloseModifierModal}
                saveModifier={saveModifier}
                setSelectedModifier={setSelectedModifier}
                selectedModifier={selectedModifier}
            />

            <ItemNotesModal visible={itemNotes} setVisible={setItemNotes} saveNotes={saveItemNotes} />

            <VoidReasonModal visible={voidReason} setVisible={setVoidReason} saveVoidReason={saveItemVoidReason} business={business} />

            <LoyaltyPointsModal visible={redeemPoints} setVisible={setRedeemPoints} handleRedeemPoints={handleRedeemPoints} customer={customer} order={order} />

            <HoldOrderModal
                setToggle={setHoldOrderModal}
                toggle={holdOrderModal}
                setShowMessage={setShowMessage}
                handlehandleSelect={handleHoldOder}
                handleHeldOrderCount={handleHeldOrderCount}
            />
            <PosDiscountModal setToggle={setPosDiscountModalToggle} toggle={posDiscountModalToggle} handleSelect={handlePosDiscount} order={order} />

            <MessageModal
                setToggle={setConfirmationModal}
                toggle={confirmationModal?.visible}
                className="messageModal"
                description={confirmationModal?.message}
                secondaryCta={{
                    title: I18n.cancel,
                    action: clearConfirmationModal,
                }}
                primaryCta={{
                    title: confirmationModal?.primaryCta,
                    action: () => {
                        confirmationModal.action();
                    },
                }}
            />
        </>
    );
};

const enhance = withObservables([''], ({}) => {
    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    return {
        activeProducts: observeActiveProducts(),
        activeCategories: observeActiveCategories(),
        orders: orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder(),
        orderLines: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => order[0].order_line.observeWithColumns(['quantity', 'archive', 'unit', 'discount', 'selling_price', 'order_line_modifiers', 'void_reason'])),
        ),
        customer: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => order[0].customer.observe())),
        waiter: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => order[0].waiter.observe())),
        discount: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => order[0].discount.observe())),
        sales_tax: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => order[0].sales_tax.observe())),
        floor_plan: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => order[0].floor_plan.observe())),
        dine_in_table: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => order[0].dine_in_table.observe())),
    };
});

export default enhance(RestaurantOrder);
