import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header} from '../../common';
import {toSnakeCase, validateDraftSalesTaxForm, validateSalesTaxForm} from '../../../constants';
import {useState} from 'react';
import {Sync} from '../../../api';
import {useLocation, useNavigate} from 'react-router-dom';

const CreateSalestaxComponent = ({tax}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {state} = useLocation();
    const {user, business, setRenderToast, account} = useAuthContext();

    const [name, setName] = useState(tax?.name || '');
    const [tax_behavior, setTaxBehavior] = useState(tax ? I18n[tax.is_inclusive ? 'tax_inclusive' : 'tax_exclusive'] : '');
    const [tax_rate, setTaxRate] = useState(tax?.rate || '');
    const [tax_calculation, setTaxCalculation] = useState(tax ? I18n[tax.is_pre_discounted ? 'calculate_tax_on_pre_discount' : 'calculate_tax_on_post_discount'] : '');
    const [tax_application, setTaxApplication] = useState(tax ? I18n[tax.is_selected_items ? 'select_items' : 'on_all_items'] : '');
    const [errors, setErrors] = useState({});
    const taxCalculationList = [I18n.calculate_tax_on_pre_discount, I18n.calculate_tax_on_post_discount];
    const taxApplicationList = [I18n.on_all_items, I18n.select_items];
    const taxBehaviorList = [I18n.tax_exclusive, I18n.tax_inclusive];

    let navigate = useNavigate();

    const onChange = (label, val) => {
        if (label == I18n.tax_name) setName(val);
        else if (label == I18n.taxation_behaviour) setTaxBehavior(val);
        else if (label == I18n.tax_calculation) setTaxCalculation(val);
        else if (label == I18n.tax_application) setTaxApplication(val);
        else if (label == I18n.tax_rate) setTaxRate(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (label === 'Tax rate %') {
                label = 'tax_rate';
            }
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleCreate = async val => {
        const {draft: taxrDraftStatus, id: taxId} = tax || {};
        try {
            let createdTax = null;
            let params = {
                name,
                rate: Number(tax_rate),
                is_inclusive: tax_behavior == I18n.tax_inclusive ? true : false,
                is_pre_discounted: tax_calculation == I18n.calculate_tax_on_pre_discount ? true : false,
                is_selected_items: tax_application == I18n.select_items ? true : false,
                draft: val === 'saveDraft',
            };

            const formErrors = await (val === 'save' ? validateSalesTaxForm(params) : validateDraftSalesTaxForm(params));

            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) {
                return;
            }
            if (taxId) {
                createdTax = await tax.updateDetails(params);
            } else {
                createdTax = await account.createSalesTax(params);
            }
            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = 'tax_saved_into_draft';
            } else {
                toastMessage = taxrDraftStatus ? 'tax_published_successfully' : taxId ? 'sale_tax_update' : 'sale_tax_created';
            }
            setRenderToast(toastMessage);
            await Sync(user.email);

            if (state?.from === 'item_creation') {
                navigate(`/items/create?tax_id=${createdTax?.id}`);
            } else if (state?.from === 'item_edit') {
                navigate(`/items/${state?.id}/edit?tax_id=${createdTax?.id}`);
            } else {
                navigate(-1);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleEnterPress = event => {
        if (event.key === 'Enter' && name) {
            handleCreate();
        }
    };

    return (
        <>
            <div onKeyDown={handleEnterPress}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    saveCta={{
                        title: I18n.save,
                        action: () => handleCreate('save'),
                    }}
                    primaryCta={
                        !tax?.id
                            ? {
                                  title: I18n.save_draft,
                                  action: () => handleCreate('saveDraft'),
                              }
                            : null
                    }
                />
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="loginFormBox">
                            <div className="signupTextBox">
                                <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                    {I18n.create_sales_tax}
                                </h3>
                                <h3 className="fontSize20 fontWeight500 marBot20 marTop0" style={{color: theme.text}}>
                                    {I18n.sales_tax_details}
                                </h3>
                            </div>

                            <div className="itemFields">
                                <div className="intventoryFormGridTwo">
                                    <div className="priceSectionInputLeft">
                                        <TextField
                                            onChange={onChange}
                                            error={errors['tax_name']}
                                            label={I18n.tax_name}
                                            placeholder={I18n.tax_name}
                                            value={name}
                                            required={true}
                                            autoFocus={true}
                                        />
                                    </div>
                                    <div className="priceSectionInputRight">
                                        <TextField onChange={onChange} error={errors['tax_rate']} label={I18n.tax_rate} placeholder={I18n.tax_rate} value={tax_rate} />
                                    </div>
                                </div>

                                <div className="intventoryFormGridTwo">
                                    <div className="priceSectionInputLeft">
                                        <TextField
                                            label={I18n.taxation_behaviour}
                                            placeholder={I18n.select_taxation_behaviour}
                                            onChange={onChange}
                                            data={taxBehaviorList}
                                            value={tax_behavior}
                                            inputType="select"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                    </div>

                                    <div className="priceSectionInputRight">
                                        <TextField
                                            label={I18n.tax_calculation}
                                            placeholder={I18n.select_tax_calculation}
                                            onChange={onChange}
                                            data={taxCalculationList}
                                            value={tax_calculation}
                                            inputType="select"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default CreateSalestaxComponent;
