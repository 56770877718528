import Filter from '../../../../../common/Filter';
import React, { useState } from 'react';

let AMOUNT_TYPE = {
    "eq": { name: 'Is equal to', value: 'eq' },
    // "between": { name: 'Is between', value: 'between' },
    "gt": { name: 'Is greater than', value: 'gt' },
    "lt": { name: 'Is less than', value: 'lt' },
};

const FilterItem = ({ getSortedPayments }) => {

    const [filterData, setFilterData] = useState({
        amount: { type: 'eq', total: '' },
        payment_method: 'all',
        date: [null, null],
        status: 'all',
    });

    const handleFilters = (type, value) => {
        setFilterData(prev => {
            let all = { ...prev };
            all[type] = value;
            return all;
        });
    };

    const onApply = (filters) => {
        const type = filters['Filter type'] || 'eq';
        const total = filters['Amount'] || 0;

        getSortedPayments({ ...filterData, total, type });
    };

    let FILTER_DATA = [
        {
            action: (value) => handleFilters('date', value),
            type: 'date',
        },
        {
            subTitle: Number(filterData.amount.total) > 0 ? AMOUNT_TYPE[filterData.amount.type].name : null,
            type: 'radioInput',
            ctaDisabled: false,
            title: 'amount',
            heading: 'more_filter_by_amount',
            onApply,
            textInputs: [
                {
                    data: Object.values(AMOUNT_TYPE),
                    placeholder: "Select amount type",
                    label: "Filter type",
                    inputType: "select",
                    suffix: 'downIcon2',
                },
                {
                    placeholder: "Enter amount",
                    label: "Amount",
                    type: 'numeric'
                },
            ]
        },
        {
            subTitle: filterData['status'] != 'all' ? filterData['status'] : null,
            type: 'radioList',
            title: 'status',
            heading: 'filter_by_status',
            option: [
                {
                    checked: Boolean(filterData['status'] == 'all'),
                    action: () => handleFilters('status', 'all'),
                    title: 'all'
                },
                {
                    checked: Boolean(filterData['status'] == 'succeeded'),
                    action: () => handleFilters('status', 'succeeded'),
                    title: 'succeeded',
                },
                {
                    checked: Boolean(filterData['status'] == 'pending'),
                    action: () => handleFilters('status', 'pending'),
                    title: 'pending',
                },
                {
                    checked: Boolean(filterData['status'] == 'refund'),
                    action: () => handleFilters('status', 'refund'),
                    title: 'refund',
                },
            ]
        },
        {
            subTitle: filterData['payment_method'] != 'all' ? filterData['payment_method'] : null,
            title: 'payment_method',
            heading: 'filter_by_method',
            type: 'radioList',
            option: [
                {
                    checked: Boolean(filterData['payment_method'] == 'all'),
                    action: () => handleFilters('payment_method', 'all'),
                    title: 'all'
                },
                {
                    checked: Boolean(filterData['payment_method'] == 'visa'),
                    action: () => handleFilters('payment_method', 'visa'),
                    title: 'visa'
                },
                {
                    checked: Boolean(filterData['payment_method'] == 'master'),
                    action: () => handleFilters('payment_method', 'master'),
                    title: 'master',
                },
                {
                    checked: Boolean(filterData['payment_method'] == 'cash'),
                    action: () => handleFilters('payment_method', 'cash'),
                    title: 'cash'
                },
                {
                    checked: Boolean(filterData['payment_method'] == 'easypaisa'),
                    action: () => handleFilters('payment_method', 'easypaisa'),
                    title: 'easypaisa',
                },
                {
                    checked: Boolean(filterData['payment_method'] == 'jazzcash'),
                    action: () => handleFilters('payment_method', 'jazzcash'),
                    title: 'jazzcash',
                },
            ]
        },
    ];
    return <Filter filters={FILTER_DATA} />
};

export default FilterItem