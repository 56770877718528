import { database } from "..";
import { BUSINESS_SCHEMA, PURCHASE_TAX_SCHEMA } from "../schema";
import { Q } from "@nozbe/watermelondb";
import { uuid } from "../../constants";

const purchase_tax = database.collections.get(PURCHASE_TAX_SCHEMA);

export const observeUnArchivedPurchaseTax = () =>
  purchase_tax
    .query(
      Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
      Q.where("archive", Q.notEq(true))
    )
    .observe();
export const observePurchaseTax = () =>
  purchase_tax
    .query(Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")))
    .observeWithColumns(["archive"]);

const prepareInsertionPurTax = (data, business) => {
  return data?.map((details) => {
    try {
      return purchase_tax.prepareCreate((field) => {
        field.business.set(business);
        field._raw.id = uuid();
        field.name = details.name;
        field.rate = details.rate;
        field.archive = false;
        field.created_at = new Date().getTime();
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const bulkCreatePurshasetax = async (data, business) => {
  await database.write(async () => {
    const allRecords = prepareInsertionPurTax(data, business);
    await database.batch(allRecords);
  });
};
