import React, { useEffect, useState } from "react";
import NewAppModal from "../NewAppModal";
import { useLanguageContext, useThemeContext } from "../../../context";
import TextField from "../TextField";
import Icon from "../../../assets/icons";

const VoidReasonModal = ({ visible, setVisible, saveVoidReason, business }) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();

  const [notes, setNotes] = useState();
  const [voidNotes, setVoidNotes] = useState(business.void_reason);
  const [selectedReason, setSelectedReason] = useState(
    visible?.void_reason || null
  );

  useEffect(() => {
    setVoidNotes(business.void_reason);
    setSelectedReason(visible?.void_reason);
  }, [visible, business]);

  const onChange = async (label, val) => {
    if (label == I18n.write_note) setNotes(val);
  };

  const clearNote = () => setNotes("");

  const hanldeClose = () => {
    setSelectedReason(null);
    setVisible(null);
    clearNote();
  };
  const handleSaveNotes = () => {
    saveVoidReason(selectedReason || notes);
    setSelectedReason(null);
    clearNote();
  };

  const handleSelect = (selectedItem) => {
    setSelectedReason((prev) => {
      if (prev === selectedItem) {
        return null;
      } else {
        return selectedItem;
      }
    });
  };
  return (
    <div>
      <NewAppModal
        className="deliveryFloorModal modal-backdrop-custom"
        toggle={visible}
        backCta={{
          name: "backArrowIcon",
          fill: theme.barclaysBlue,
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
        }}
        handleClose={() => hanldeClose()}
        primaryCta={{
          title: "Save",
          action: () => handleSaveNotes(),
        }}
        cancelCta={{
          title: I18n.cancel,
          action: () => hanldeClose(),
        }}
        title="Void item"
      >
        <div className="flex horizontalCenter width100">
          <div className="flex width50" style={{ flexDirection: "column" }}>
            <p className="fontSize20 marBot20" style={{ color: theme.white }}>
              {I18n.add_void_reason}
            </p>
            <TextField
              label={I18n.write_note}
              placeholder={I18n.write_note}
              onChange={onChange}
              inputType="textarea"
              rows="5"
              value={notes}
            />
            <div className="flex justifySpacebetween marTop5">
              <p className="fontSize12" style={{ color: theme.darkGrayTwo }}>
                {I18n.void_placeholder}
              </p>
              <p
                className="fontSize12 marBot10 cursorPointer"
                style={{ color: theme.barclaysBlue }}
                onClick={() => clearNote()}
              >
                {I18n.clear_note}
              </p>
            </div>
            <div className="voidNotesWrapper">
              <p
                className="fontSize14 fontWeight600 marBot10"
                style={{ color: theme.white }}
              >
                {I18n.void_notes}
              </p>
              <div className="thinDivider width100 marTop15 marBot15" />

              <div className="flex gap10 flexwrap">
                {voidNotes.map((item) => {
                  return (
                    <div
                      className={`voidNotesPill cursorPointer ${
                        selectedReason === item ? "selectedReason" : ""
                      } `}
                      onClick={() => handleSelect(item)}
                    >
                      <p
                        className="fontSize14 marBot0"
                        style={{ color: theme.white }}
                      >
                        {item}
                      </p>
                      <Icon
                        name="crossFilled"
                        viewBox="0 0 15 15"
                        height={15}
                        width={15}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </NewAppModal>
    </div>
  );
};

export default VoidReasonModal;
