import {Model} from '@nozbe/watermelondb';
import {MODIFIER_SCHEMA, PRODUCT_MODIFIER_SCHEMA} from '../schema';
import {field, text, relation, children, date, writer} from '@nozbe/watermelondb/decorators';
import {uuid} from '../../constants';

export default class Modifier extends Model {
    static table = MODIFIER_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        product_modifier: {type: 'has_many', foreignKey: 'modifier_id'},
        modifier_option: {type: 'has_many', foreignKey: 'modifier_id'},
    };

    @text('name') name;
    @field('print_in_receipt') print_in_receipt;
    @field('archive') archive;
    @date('created_at') created_at;

    @children('product_modifier') product_modifier;
    @children('modifier_option') modifier_option;

    @relation('account', 'account_id') account;

    @writer async archiveModifier(status = true) {
        return await this.update(modifier => {
            modifier.archive = status;
        });
    }

    @writer async createProductModifierRelation(details) {
        return await this.collections.get(PRODUCT_MODIFIER_SCHEMA).create(prodMod => {
            prodMod.modifier.set(this);
            prodMod.product.set(details.product);
            prodMod._raw.id = uuid();
        });
    }

    @writer async updateModifierDetails(details) {
        return await this.update(modifier => {
            modifier.name = details.name;
            modifier.print_in_receipt = details.print_in_receipt;
        });
    }
}
