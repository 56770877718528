import { useThemeContext } from "../../../context";
import React from "react";

const PercentageBar = ({ data }) => {
  const { theme } = useThemeContext();

  let total = data['total'];

  const renderBar = (type, color) => {
    const width = `${total ? (data[type] / total) * 100 : 30}%`;

    return width != '0%' ?
      <div
        key={type}
        className="baritem"
        style={{
          backgroundColor: color,
          width,
        }}
      >
    
        {total ? <p className="baritemText"> {parseFloat(width).toFixed(2)}%</p> : null}
      </div>
      : null
  };

  return (
    <div className="percentageBarContainer">
      {renderBar("cash", theme.brightGreen)}
      {renderBar("card", theme.lightGreen)}
      {renderBar("wallet", theme.extralightGreen)}
      {renderBar("credit", theme.white)}
    </div>
  );
};

export default PercentageBar;
