import {useState} from 'react';
import EmailScreen from './EmailScreen';
import VerifyOTP from './VerifyOTP';
import ChangePassword from './ChangePassword';

const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    return (
        <div>
            {step === 1 && <EmailScreen setStep={setStep} />}
            {step === 2 && <VerifyOTP setStep={setStep} />}
            {step === 3 && <ChangePassword />}
        </div>
    );
};

export default ForgotPassword;
