import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import React from "react";

Chart.register(CategoryScale);

const BarGraph = ({ data }) => {

  let chartData = {
    labels: data.labels,
    datasets: [
      {
        backgroundColor: data.color,
        data: data.price,
        borderRadius: 5,
      }
    ]
  };

  let options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: !Boolean(data.nonIdeal),
        intersect: true,
        backgroundColor: '#3D4253',
        borderColor:'#fff',
        borderWidth: '1',
        borderRadius: '8',
        titleFontColor: '#fff',
        titleFontSize: '20',
        bodyFontColor: '#fff',
        bodyFontSize: '20',
        footerFontColor: '#fff'

      }
    },
    scales: {
      x: {
        ticks: { display: false },
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        display: false,
        max: data.nonIdeal ? 6 : undefined,
        ticks: {
          beginAtZero: true,
          display: false,
        },
        grid: {
          drawBorder: false,
          display: true,
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <Bar
        data={chartData}
        options={options}
      />
    </div>
  )
};

export default BarGraph;
