import { useNavigate } from "react-router-dom";
import { useLanguageContext, useThemeContext } from "../../../context";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Dropdown, SearchBox, TextField } from "../../common";

const NewPosHeader = ({ search }) => {
  const navigate = useNavigate();

  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <section style={{ background: theme.topBarBG }} className="topBarMainCont">
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="topBarLeftSide">
              <SearchBox type={"pos"} {...search} />
            </div>
          </Col>
          <Col md={6}>
            <div className="topBarRightSide"></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NewPosHeader;
