import {useLocation, useNavigate} from 'react-router-dom';
import {Sync} from '../../../../../api';
import {useAuthContext, useLanguageContext} from '../../../../../context';
import {useWaiter} from '../../../../../hooks';
import {CustomContainer, Header, Loading} from '../../../../common';

import CreateWaiterComponent from '../../../../common/CreateWaiterComponent';

const EditWaiter = () => {
    let navigate = useNavigate();
    const {state} = useLocation();

    const {I18n} = useLanguageContext();
    const {business, user} = useAuthContext();

    const {isLoading, waiter} = useWaiter(business);

    const handleSave = async () => {
        await Sync(user.email);
        if (state?.isPos) navigate(-1);
        navigate('/waiters', {
            state: {
                isArchived: waiter._raw.archive,
            },
        });
    };

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateWaiterComponent handleSelect={handleSave} waiter={waiter} />
            </CustomContainer>
        </>
    );
};

export default EditWaiter;
