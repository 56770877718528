import {useAuthContext} from '../../../../../context';
import useProduct from '../../../../../hooks/useProduct';
import {CreateItemComponent, Loading} from '../../../../common';

const EditItem = () => {
    const {account} = useAuthContext();

    const {isLoading, product} = useProduct(account);

    return isLoading ? <Loading /> : <CreateItemComponent product={product} />;
};

export default EditItem;
