import React, { useEffect, useRef, useState } from "react";
import withObservables from "@nozbe/with-observables";
import DineinTable from "./DineinTable";
import Icon from "../../../../../../assets/icons";
import NewAppModal from "../../../../../common/NewAppModal";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../../context";
import {
  DEVICE_HEIGHT,
  uuid,
  validateTableData,
} from "../../../../../../constants";
import CreateTable from "./CreateTable";
import { bulkCreateDineInTables } from "../../../../../../pos-core/database/helpers/dine_in_table";
import { ItemDiscountsModal } from "../../../../../common/V2";
import { useNavigate } from "react-router-dom";

const Dinein = ({ floorData, tableDetails, activeOrder, orders }) => {
  const { business, handleIncrementCounter, handleCreateOrder } =
    useAuthContext();

  const floorPlaygroundRef = useRef(null);
  const [divDimensions, setDivDimensions] = useState({ width: 0, height: 0 });
  const { theme } = useThemeContext();
  const [editTable, setEditTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [visible, setVisible] = useState(false);
  const [seats, setSeats] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [shape, setShape] = useState(null);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [tables, setTables] = useState(tableDetails || []);
  const [guestModal, setGuestModal] = useState(false);
  const [clickedTable, setClickedTable] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    setTables(tableDetails);
  }, [tableDetails]);

  const { I18n } = useLanguageContext();

  useEffect(() => {
    if (floorPlaygroundRef.current) {
      const { width, height } =
        floorPlaygroundRef.current.getBoundingClientRect();
      setDivDimensions({ width, height });
    }
  }, [floorPlaygroundRef]);

  const updateTableProperties = (type, value) => {
    setSelectedTable((prev) => ({ ...prev, [type]: value }));
  };

  const saveTableDetails = async () => {
    const id = selectedTable?.id;
    const tableToUpdate = tables.find((obj) => obj.id === id);

    await tableToUpdate.updateTableDetails(selectedTable);
    setSelectedTable(null);
    setEditTable(false);
  };

  const duplicateTable = async () => {
    const duplicatedTable = {
      ...selectedTable,
      id: uuid(),
      xAxis: selectedTable?.xAxis + 5,
      yAxis: selectedTable?.yAxis + 5,
      xCoord: selectedTable?.xCoord + 50,
      yCoord: selectedTable?.yCoord + 50,
    };

    const duplicateTableArray = [duplicatedTable];
    await bulkCreateDineInTables(duplicateTableArray, floorData);
    setSelectedTable(duplicatedTable);
  };

  const deleteTable = async () => {
    const id = selectedTable?.id;
    const tableToUpdate = tables.find((obj) => obj.id === id);
    await tableToUpdate.archiveTable();
    setSelectedTable(null);
    setEditTable(false);
  };

  const handleClose = () => {
    setVisible(false);
    clearStates();
  };

  const clearStates = () => {
    setName("");
    setSeats("");
    setHeight("");
    setWidth("");
    setShape("");
    setErrors({});
  };

  const saveClose = async (openModal) => {
    if (selectedTable) {
      const id = selectedTable?.id;
      const updateTableObj = {
        name,
        seats,
        shape: shape?.name,
        height,
        width,
        xAxis: selectedTable?.xAxis,
        yAxis: selectedTable?.yAxis,
        xCoord: selectedTable?.xCoord,
        yCoord: selectedTable?.yCoord,
      };
      const tableToUpdate = tables.find((obj) => obj.id === id);
      await tableToUpdate.updateTableDetails(updateTableObj);

      setSelectedTable(null);
      setEditTable(false);
    } else {
      const tableObj = {
        id: uuid(),
        name,
        seats,
        shape: shape?.name,
        height,
        width,
        xAxis: 20,
        yAxis: 20,
        xCoord: 20,
        yCoord: 20,
      };

      const formErrors = validateTableData(tableObj);
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) return;

      const tableObjArray = [tableObj];
      await bulkCreateDineInTables(tableObjArray, floorData);

      setSelectedTable(tableObj);
      setEditTable(true);
    }

    !openModal && setVisible(false);
    clearStates();
  };

  const saveNew = () => {
    saveClose(true);
  };

  const handleEdit = () => {
    setVisible(true);
    setName(selectedTable.name);
    setHeight(selectedTable.height);
    setWidth(selectedTable.width);
    setShape(selectedTable.shape);
    setSeats(selectedTable.seats);
  };

  const handleAddTable = () => {
    setSelectedTable(null);
    setEditTable(false);
    setVisible(true);
  };

  const handleNoOfGuestModal = async (type, value) => {
    const payload = {
      floor_plan: floorData,
      dine_in_table: clickedTable,
      started_at: new Date().getTime(),
      type: "dinein",
      no_of_guests: value,
    };
    if (!activeOrder) {
      const createdOrder = await handleCreateOrder();
      await createdOrder.updateFloorAndTable(payload);
    } else {
      await activeOrder.updateFloorAndTable(payload);
    }

    setGuestModal(false);
    navigate("/restaurant-pos");
  };

  const handleGuestModal = (tableDetails) => {
    setGuestModal(true);
    setClickedTable(tableDetails);
  };

  return (
    <>
      <NewAppModal
        className="deliveryFloorModal"
        toggle={visible}
        backCta={{
          name: "backArrowIcon",
          fill: theme.barclaysBlue,
          width: "18",
          height: "18",
          viewBox: "0 0 18 18",
        }}
        handleClose={handleClose}
        primaryCta={{
          title: "Save & new",
          action: () => saveNew(),
        }}
        secondaryCta={{
          title: "Save & close",
          action: () => saveClose(),
        }}
        cancelCta={{
          title: I18n.cancel,
          action: () => handleClose(),
        }}
        title="Create table"
      >
        <CreateTable
          name={name}
          setName={setName}
          width={width}
          setWidth={setWidth}
          height={height}
          setHeight={setHeight}
          shape={shape}
          setShape={setShape}
          seats={seats}
          setSeats={setSeats}
          errors={errors}
          setErrors={setErrors}
        />
      </NewAppModal>

      <ItemDiscountsModal
        setToggle={setGuestModal}
        toggle={guestModal}
        handleSave={handleNoOfGuestModal}
        title="No of guests"
      />

      <div
        className="dineInFloorWrapper"
        style={{ height: DEVICE_HEIGHT - 100 }}
      >
        <div className="playGround">
          <div
            className="floorPlayground"
            ref={floorPlaygroundRef}
            style={{ height: DEVICE_HEIGHT - 130 }}
          >
            {tables?.map((table) => (
              <DineinTable
                table={table}
                divDimensions={divDimensions}
                editTable={editTable}
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
                floorPlaygroundRef={floorPlaygroundRef}
                handleGuestModal={handleGuestModal}
                orders={orders}
              />
            ))}
          </div>
        </div>
        <div className="tablesActions">
          <div
            className="editTableBtn"
            onClick={() =>
              selectedTable ? saveTableDetails() : setEditTable((prev) => !prev)
            }
          >
            <Icon
              name="editIcon"
              fill={theme.barclaysBlue}
              width="20"
              height="20"
            />
            <p className="fontSize12 fontWeight400  marBot0 white">
              {selectedTable ? "Save Close" : "Edit Tables"}
            </p>
          </div>
          {editTable && (
            <div className="tableEventsWrapper">
              <>
                <p className="fontSize10 fontWeight400  marBot5 white ">
                  Add Table
                </p>
                <div
                  className="tableInputBox cursorPointer"
                  onClick={() => handleAddTable()}
                >
                  <Icon
                    name="newIcon"
                    fill={theme.white}
                    width={"12"}
                    height={"12"}
                    viewBox={"0 0 15 15"}
                  />
                </div>
              </>
              <>
                <p className="fontSize10 fontWeight400 marTop15 marBot5 white">
                  Duplicate
                </p>
                <div
                  className="tableInputBox cursorPointer"
                  onClick={() => selectedTable && duplicateTable()}
                >
                  <div className="duplicateIcon" />
                </div>
              </>
              <>
                <p className="fontSize10 fontWeight400 marTop15 marBot5 white">
                  Seats
                </p>
                <div className="tableInputBox">
                  <input
                    type="text"
                    onBlur={(e) => {
                      const inputValue = e.target.value.trim();
                      let newValue;
                      if (!isNaN(inputValue)) {
                        const parsedValue = Number(inputValue);
                        newValue = parsedValue;
                      } else {
                        newValue = selectedTable?.height || "0";
                      }
                      updateTableProperties("seats", newValue);
                    }}
                    placeholder={selectedTable?.seats || "0"}
                    disabled={!selectedTable}
                  />
                </div>
              </>

              <>
                <p className="fontSize10 fontWeight400 marTop15 marBot5 white">
                  Shape
                </p>
                <div
                  className="tableInputBox cursorPointer"
                  onClick={() =>
                    selectedTable && updateTableProperties("shape", "Square")
                  }
                >
                  <div className="rectangleShape" />
                </div>
                <div
                  className="tableInputBox marTop10 cursorPointer"
                  onClick={() =>
                    selectedTable && updateTableProperties("shape", "Round")
                  }
                >
                  <div className="ovalShape" />
                </div>
              </>
              <>
                <p className="fontSize10 fontWeight400  marTop15 marBot5 white">
                  Width
                </p>
                <div className="tableInputBox">
                  <input
                    type="text"
                    onBlur={(e) => {
                      const inputValue = e.target.value.trim();
                      let newValue;
                      if (!isNaN(inputValue)) {
                        const parsedValue = Number(inputValue);
                        newValue = parsedValue;
                      } else {
                        newValue = selectedTable?.height || "0";
                      }
                      updateTableProperties("width", newValue);
                    }}
                    placeholder={selectedTable?.width || "0"}
                    disabled={!selectedTable}
                  />
                </div>
              </>
              <>
                <p className="fontSize10 fontWeight400  marTop15 marBot5 white">
                  Height
                </p>
                <div className="tableInputBox">
                  <input
                    type="text"
                    onBlur={(e) => {
                      const inputValue = e.target.value.trim();
                      let newValue;
                      if (!isNaN(inputValue)) {
                        const parsedValue = Number(inputValue);
                        newValue = parsedValue;
                      } else {
                        newValue = selectedTable?.height || "0";
                      }
                      updateTableProperties("height", newValue);
                    }}
                    disabled={!selectedTable}
                    placeholder={selectedTable?.height || "0"}
                  />
                </div>
              </>
              <div className="flex">
                <div
                  className="editDelIconBox marTop15 cursorPointer"
                  onClick={() => selectedTable && handleEdit()}
                >
                  <Icon
                    name="pencilIcon"
                    fill={theme.barclaysBlue}
                    viewBox={"0 0 13 13"}
                  />
                </div>
                <div
                  className="editDelIconBox marTop15 cursorPointer"
                  onClick={() => selectedTable && deleteTable()}
                >
                  <span
                    style={{
                      marginTop: "0px",
                      marginLeft: "5px",
                    }}
                  >
                    <Icon
                      name="deleteIcon"
                      fill={theme.red}
                      viewBox={"0 0 19 20"}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const enhance = withObservables(["floorData"], ({ floorData }) => ({
  tableDetails: floorData.getTables.observe(),
  orders: floorData.getActiveFloorOrders.observe(),
}));

export default enhance(Dinein);
