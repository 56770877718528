import {useLanguageContext, useThemeContext} from '../../../../../../context';
import TableBody from '../../../../../common/TableContainer/TableBody';
import {capitalizeFirstAlphabet, formatDayTime, formatNum} from '../../../../../../constants';
import withObservables from '@nozbe/with-observables';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import Icon from '../../../../../../assets/icons/';
import StatusBox from '../../../../../common/StatusBox';

const ListItem = ({business, customer, index, item}) => {
    const {payment_method, card_number, created_at, amount, id, status} = item || {};
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    let navigate = useNavigate();

    const handlePayment = () => navigate(`/payments-transactions/${id}`);
    return (
        <TableBody style={{borderColor: theme.inputBorder}} className={`sixColumn cursorPointer`} onClick={handlePayment} key={index}>
            <div className="BoxWidth textWithTag justifyStart">
                <p className="fontSize14 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {formatNum(amount)}
                </p>
                <StatusBox status={status} />
               
                {/* <p className='textWithTagInner' style={{ background: theme.peach, borderColor: theme.mustardYellow}}>
                    <span className='fontSize10 fontWeight500 mustardYellow'>
                        {I18n.pending}
                    </span>
                    <span className='marLeft5'>
                       <Icon
                            name="tilePendingIcon"    
                            viewBox="0 0 18 18"
                            width="10"
                            height="10"
                            fill={theme.mustardYellow}
                        />
                    </span>
                </p>
                <p className='textWithTagInner' style={{ background: theme.lightOrange, borderColor: theme.brightOrange}}>
                    <span className='fontSize10 fontWeight500 brightOrange'>
                        {I18n.refund}
                    </span>
                    <span className='marLeft5'>
                       <Icon
                            name="tileRefundIcon"    
                            viewBox="0 0 21 13"
                            width="18"
                            height="12"
                            fill={theme.brightOrange}
                        />
                    </span>
                </p> */}
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDayTime(created_at)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {business?.address || '-'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {
                        payment_method == 'card' ? 
                        <p className='ListCardBox'>
                        <span className='visaCard'></span> <span className='marLeft5'>{card_number}</span> 
                        </p>
                        || '-' 
                        : payment_method == 'cash' ? 'Cash' 
                        : payment_method == I18n.wallet ? '-' : '-'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {customer?.phone_number || '-'} 
                    {customer?.name ?
                        <sapn className="customerSeperator"><i className="marLeft5 marRight5">|</i>{customer?.name}</sapn> : '-'}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {id.split('-')[0]}
                </p>
            </div>
        </TableBody>

        // <div key={index} style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
        //     <span style={{ color: '#ffff' }}>Rs. {formatNum(amount)}</span>
        //     <span style={{ color: '#ffff' }}>{formatDayTime(created_at)}</span>
        //     <span style={{ color: '#ffff' }}>{business?.address || '-'}</span>
        //     <span style={{ color: '#ffff' }}>{payment_method == I18n.card ? (card_number || '-') : payment_method == I18n.cash ? 'COD' : payment_method == I18n.wallet ? '-' : '-'}</span>
        //     <span style={{ color: '#ffff' }}>{customer?.phone_number || '-'} {customer?.name || '-'}</span>
        //     <span style={{ color: '#ffff' }}>{id.split('-')[0]}</span>
        // </div>
    );
};

const enhance = withObservables(['item'], ({item}) => ({
    business: item.business.observe(),
    customer: item.customer.observe(),
    item: item.observe(),
}));

export default enhance(ListItem);
