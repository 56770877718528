import {database} from '..';
import {BUSINESS_SCHEMA, SALES_TAX_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';
import {uuid} from '../../constants';

const sales_tax = database.collections.get(SALES_TAX_SCHEMA);

export const observeUnArchivedSalesTax = () => sales_tax.query(Q.where('archive', Q.notEq(true))).observe();
export const observeArchivedSalesTax = () => sales_tax.query(Q.where('archive', Q.eq(true))).observe();
export const observeSalesTax = () => sales_tax.query().observeWithColumns(['archive']);

const prepareInsertionSalesTax = (data, account) => {
    return data?.map(details => {
        try {
            const salesTax = sales_tax.prepareCreate(field => {
                field.account.set(account);
                field._raw.id = uuid();
                field.name = details.name;
                field.rate = details.rate;
                field.is_inclusive = details.tax_behavior;
                field.is_pre_discounted = details.tax_calculation;
                field.is_selected_items = details.tax_applicaton;
                field.archive = false;
                field.created_at = new Date().getTime();
            });

            return [salesTax];
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateSalestax = async (data, account) => {
    await database.write(async () => {
        const allRecords = prepareInsertionSalesTax(data, account);
        await database.batch(...allRecords.flat());
    });
};
