import {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Sync} from '../../../api';
import {ROLE_PERMISSION, toSnakeCase, validateCreateRoleForm} from '../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {Header, TextField} from '../../common';
import {bulkCreateModules, bulkCreateRoles} from '../../../pos-core/database/helpers';
import RolePermission from '../RolePermission';

const CreateRoleComponent = ({handleSelect, roles, handleClose}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [results, setResults] = useState({});
    const [roleName, setRoleName] = useState('');
    const [errors, setErrors] = useState({});

    const {business, user} = useAuthContext();

    const HandleToggle = (check, item, access) => {
        const {id} = item || {};
        setResults(prev => {
            let all = {...prev};
            if (check) all[id] = {name: id, access};
            else delete all[id];
            return all;
        });
    };

    const onChange = async (label, value) => {
        if (label === I18n.role_name) setRoleName(value);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const creatingRole = async () => {
        try {
            const account = await business.account.fetch();
            const accountRoles = [{name: roleName}];
            const modules = Object.values(results);

            const teamDataObj = {
                name: roleName,
                modules: modules,
            };

            const formErrors = validateCreateRoleForm(teamDataObj, roles);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) {
                return;
            }

            const createdRoles = await bulkCreateRoles(account, accountRoles);
            const createdModules = await bulkCreateModules(createdRoles, modules);

            console.log('createdRoles', createdRoles);
            console.log('createdModules', createdModules);

            const createdRole = createdRoles[0];
            await Sync(user.email);
            handleSelect(createdRole);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleClose}
                saveCta={{
                    title: I18n.create,
                    action: () => creatingRole(),
                }}
            />

            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className="loginFormBox">
                        <div className="signupTextBox">
                            <h3 className="fontSize20 fontWeight500 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.role_details}
                            </h3>
                        </div>
                        <div className="itemFields">
                            <TextField onChange={onChange} error={errors['role_name']} label={I18n.role_name} placeholder={I18n.role_name} value={roleName} />
                            {ROLE_PERMISSION?.map((item, index) => {
                                return <RolePermission item={item} HandleToggle={HandleToggle} selected={results[item.id]} />;
                            })}
                            <p className="inputError createRoleError">{errors['modules'] && Object.keys(results).length === 0 ? errors['modules'] : null}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default CreateRoleComponent;
