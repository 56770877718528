import { Model } from '@nozbe/watermelondb';
import { field, text, relation, children, writer, date, lazy } from '@nozbe/watermelondb/decorators';
import { SESSION_SCHEMA } from '../schema';

export default class Session extends Model {
    static table = SESSION_SCHEMA;

    static associations = {
        business: { type: 'belongs_to', key: 'business_id' },
        user: { type: 'belongs_to', key: 'user_id' },
    }

    @field('is_active') is_active;
    @field('opening_balance') opening_balance;
    @field('closing_balance') closing_balance;
    @date('started_at') started_at;
    @date('closed_at') closed_at;

    @relation('business', 'business_id') business;
    @relation('user', 'user_id') user;

    @writer async closeSession(balance) {
        return await this.update(session => {
            session.closed_at = new Date().getTime()
            session.closing_balance = balance
            session.is_active = false
        });
    }
}