const NonIdealContainer = ({
  listNonideal,
  children,
  disabled,
  tablenonIdeal,
}) =>
  disabled ? (
    children
  ) : (
    <div
      className={`${
        listNonideal
          ? "listNonIdealBox"
          : tablenonIdeal
          ? "tableNonIdealBox"
          : "allScreenNonIdealBox"
      }`}
    >
      {children}
    </div>
  );

export default NonIdealContainer;
