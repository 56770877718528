import React from "react";
import { useLanguageContext, useThemeContext } from "../../../../context";
import TextField from "../../TextField";

const PaymentStep3 = ({ data, handleChange }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <div className="paymentStep3Conatiner">
      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>
          {data?.account_number || "Zubair"}
        </span>
        <span style={{ color: theme.white }}>
          {data?.account_title || "03472003439"}
        </span>
      </p>
      <TextField
        onChange={handleChange}
        label={I18n.enter_amount}
        onlyNumbers={true}
        value={data}
        // inputRef={textBoxRef}
      />
    </div>
  );
};

export default PaymentStep3;
