import {database} from '..';
import {getRoleModules, uuid} from '../../constants';
import {MODULE_SCHEMA} from '../schema';

const modules = database.collections.get(MODULE_SCHEMA);

const prepareInsertion = (roles, roleModules) => {
    const allRecords = [];

    roles.forEach(role => {
        let roleMod = null;
        if (roleModules) {
            roleMod = roleModules;
        } else {
            roleMod = getRoleModules(role.name);
        }

        roleMod.forEach(route => {
            const record = modules.prepareCreate(record => {
                record._raw.id = uuid();
                record.role.set(role);
                record.name = route.name;

                if (route.access === 'full_access') {
                    record.create = true;
                    record.read = true;
                    record.update = true;
                    record.delete = true;
                } else if (route.access === 'view_only') {
                    record.create = false;
                    record.read = true;
                    record.update = false;
                    record.delete = false;
                } else {
                    record.create = route.create;
                    record.read = route.read;
                    record.update = route.update;
                    record.delete = route.delete;
                }
            });

            allRecords.push(record);
        });
    });

    return allRecords;
};

const prepareDeletion = module => {
    return module.map(mod => {
        return mod.prepareMarkAsDeleted();
    });
};

export const bulkCreateModules = async (roles, modules) => {
    return await database.write(async () => {
        const allRecords = await prepareInsertion(roles, modules);
        await database.batch(...allRecords);
        return allRecords;
    });
};

export const bulkDeleteModules = async module => {
    await database.write(async () => {
        try {
            const allRecords = await prepareDeletion(module);
            await database.batch(...allRecords);
            return allRecords;
        } catch (error) {
            console.error('Error in bulkDeleteModules:', error);
        }
    });
};
