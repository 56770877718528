import { useThemeContext } from "../../../context";
import TableBody from "../../common/TableContainer/TableBody";
import { firstLetterCaptilize } from "../../../constants";

const CsvList = ({ value, errors }) => {
  const { theme } = useThemeContext();

  const {
    "Tax Name": name,
    "Tax Type (Sales / Purchase)": type,
    "Tax rate %": rate,
    "Tax behavior": tax_behavior,
    "Tax application": tax_application,
    "Tax Calculation": tax_calculation,
  } = value;
  const rowId = value.id;

  let is_invalid = {
    name: errors[rowId]?.name,
    type: errors[rowId]?.type,
    rate: errors[rowId]?.rate,
    tax_behavior: errors[rowId]?.tax_behavior,
    tax_application: errors[rowId]?.tax_application,
    tax_calculation: errors[rowId]?.tax_calculation,
  }



  return (
    <>
      <TableBody
        className={`sixColumn cursorPointer`}
        style={{ borderColor: theme.posRightBg }}
      >
        <div className="BoxWidth justifyStart" key={rowId}>
          <p
            className="fontSize14"
            style={{ color: is_invalid?.name ? theme.red : theme.white }}
            title={name}
          >
            <span className="OneLineTruncate">
              {firstLetterCaptilize(name || "NA")}
            </span>
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            title={type}
            style={{ color: is_invalid?.type ? theme.red : theme.white }}
          >
            {type || "-"}
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            title={rate}
            style={{ color: is_invalid?.rate ? theme.red : theme.white }}
          >
            {rate || "-"}
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{ color: is_invalid?.tax_behavior ? theme.red : theme.white }}
            title={tax_behavior}
          >
            {tax_behavior || "-"}
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{
              color: is_invalid?.tax_application ? theme.red : theme.white,
            }}
            title={tax_application}
          >
            {tax_application || "-"}
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{
              color: is_invalid?.tax_calculation ? theme.red : theme.white,
            }}
            title={tax_calculation}
          >
            {tax_calculation || "-"}
          </p>
        </div>
      </TableBody>
    </>
  );
};

export default CsvList;
