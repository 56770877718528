import {
  observePaginatedPayments,
  observePaymentsByBusiness,
} from "../../../../../../pos-core/database/helpers";
import { useLanguageContext, useThemeContext } from "../../../../../../context";
import { ITEMS_PER_PAGE, formatNum } from "../../../../../../constants";
import HeaderItem from "../../Transactions/TransactionList/HeaderItem";
import TotalTiles from "../../../../../common/TotalTiles";
import { Col, Container, Row } from "react-bootstrap";
import withObservables from "@nozbe/with-observables";
import { usePayments } from "../../../../../../hooks";
import Pagination from "@mui/material/Pagination";
import { Header } from "../../../../../common";
import React, { useState } from "react";
import FilterItem from "./FilterItem";
// import ListItem from './ListItem';

let HEADER_DATA = [
  { name: "amount", className: "justifyStart" },
  { name: "date_and_time", className: "justifyEnd" },
  { name: "type", className: "justifyStart" },
  { name: "transaction_id", className: "justifyCenter" },
  { name: "payment_method", className: "justifyCenter" },
  { name: "titles", className: "justifyEnd" },
];

const WalletList = ({ allPayments, payments }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const {
    getSortedPayments,
    getPagePayments,
    totalCompleted,
    totalCompTrans,
    getSearchItem,
    totalPenTrans,
    totalRefTrans,
    totalPending,
    filteredList,
    totalRefund,
    totalTrans,
    active,
    total,
  } = usePayments(allPayments, payments);

  const [pagination, setPaginnation] = useState({
    total: Math.ceil(allPayments.length / ITEMS_PER_PAGE),
    current: 0,
  });

  const OVERVIEW_DATA = [
    {
      iconBGcolor: theme.brightGreen,
      iconName: "tileRefundIcon",
      amount: totalRefund,
      heading: "refund",
      height: "13",
      width: "21",
      viewBox: "0 0 21 13",
      percent: 0,
      rs: true,
    },
    {
      iconBGcolor: theme.newBg,
      iconName: "moneyIcon",
      amount: totalCompleted,
      heading: "money_in",
      height: "12",
      width: "13",
      viewBox: "0 0 13 12",
      percent: 0,
      rs: true,
    },
    {
      iconBGcolor: theme.newBg,
      iconName: "payoutIcon",
      amount: totalPending,
      heading: "payout",
      height: "12",
      width: "12",
      viewBox: "0 0 12 12",
      percent: 0,
      rs: true,
    },
    {
      iconBGcolor: theme.newBg,
      iconName: "refundIcon",
      amount: totalRefund,
      heading: "refund",
      height: "21",
      width: "21",
      viewBox: "0 0 21 21",
      percent: 0,
      rs: true,
    },
  ];

  const handlePayout = () => {};

  const onChange = (label, value) => getSearchItem(value);

  const handlePagination = (event) => {
    const current = event?.target?.innerText - 1 || 0;
    setPaginnation({ ...pagination, current });

    getPagePayments(current, ITEMS_PER_PAGE);
  };

  return (
    <section
      className="layoutMainBox"
      style={{ backgroundColor: theme.topBarBG }}
    >
      <div className="layoutMainInnerBox">
        <Header
          title={I18n.wallet_summary}
          type="search"
          search={{
            placeholder: I18n.search_by_name_and_transactin_id,
            onChange,
            prefix: {
              name: "search2Icon",
              fill: theme.white,
            },
          }}
          rightCta={{
            action: handlePayout,
            title: I18n.payout,
          }}
        />
        <div className="topCardsBox walletCardBox">
          <Container fluid>
            <Row>
              {OVERVIEW_DATA.map((item, index) => {
                return (
                  <Col key={index} md={3} xs={12}>
                    <TotalTiles
                      percent={item.percent}
                      amount={item.amount}
                      item={item}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <Container fluid>
          <Row>
            <div className="walletFilterBox">
              <FilterItem getSortedPayments={getSortedPayments} />
            </div>

            <HeaderItem data={HEADER_DATA} />
            <Col md={12} xs={12}>
              {/* {(active ? filteredList : payments).map((item, index) => <ListItem index={index} item={item} />)} */}
            </Col>
          </Row>
        </Container>

        {pagination.total <= 1 || active ? null : (
          <Pagination
            onChange={handlePagination}
            count={pagination.total}
            shape="rounded"
          />
        )}
      </div>
    </section>
  );
};

const enhance = withObservables([""], ({}) => ({
  payments: observePaginatedPayments(0, 10),
  allPayments: observePaymentsByBusiness(),
}));

export default enhance(WalletList);
