import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import {observeActiveWaiters, observeArchivedWaiters, observeDraftWaiters} from '../../../../../pos-core/database/helpers/waiter';
import usePermission from '../../../../../hooks/usePermission';

const WaiterList = ({waiters, archivedList, draftList}) => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);

    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_customers);
    const [selected, setSelected] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [cvsModal, setCvsModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [waiterCount, setWaiterCount] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('waiters');

    useEffect(() => {
        let count;
        if (filter === I18n.archived) {
            count = archivedList.length;
        } else if (filter === I18n.drafts_saved) {
            count = draftList.length;
        } else {
            count = waiters.length;
        }
        setWaiterCount(count);
    }, [filter, waiters, archivedList, draftList]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast == 'customer_Created') {
            setShowMessage({
                visible: true,
                message: I18n.customer_created_successfully,
            });
        }
    }, [renderToast]);

    const toggleArchiveModal = () => {
        if (Object.values(selected)?.length) setArchiveModal(x => !x);
    };

    const filterOption = [
        {
            title: I18n.all_customers,
            action: () => handleFilter(I18n.all_customers),
            checked: filter == I18n.all_customers || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    const handleArchivedAction = async selected => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async item => {
                await item.archiveCustomer(!item.archive);
                selectedIds.push(item.id);
            }),
        );
        setSelected(selected => {
            let obj = {...selected};
            selectedIds.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });
        setloading(false);
    };

    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };

    const handleCreateWaiter = () => {
        navigate('/waiters/create');
    };

    function handleSearch(val) {
        const filterList = filter === I18n.archived ? archivedList : waiters;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values) || x?.phone_number?.includes(values));
    }

    const handleSelectAll = () => {
        let list = filter == I18n.archived ? archivedList : waiters;
        if (Object.keys(selected).length != list.length) {
            list?.map(val => {
                setSelected(values => {
                    let obj = {...values};
                    obj[val.id] = val;
                    return obj;
                });
            });
        } else {
            setSelected({});
        }
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelected(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const checkedClick = () =>
        Object.keys(selected)?.length > 0 && Object.keys(selected)?.length === (filter == I18n.archived ? archivedList.length : waiters.length) ? true : false;

    const onChange = (label, value) => setText(value);

    return loading ? (
        <Loading />
    ) : waiters.length > 0 ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.waiter} (${waiterCount})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    rightCta={{
                        title: I18n.create_customer,
                        action: handleCreateWaiter,
                    }}
                />

                <div className="itemListContainer borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 160}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`fiveColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol">
                                        <CheckBox
                                            checkboxLabel={
                                                <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                                    {I18n.customer}
                                                </p>
                                            }
                                            onChange={handleSelectAll}
                                            checked={checkedClick()}
                                        />
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.phone}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.email}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.city}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.actions}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                    {(text ? searchList : filter == I18n.archived ? archivedList : filter == I18n.drafts_saved ? draftList : waiters).map((val, index) => (
                                        <List handleChecked={handleChecked} selected={selected} setSelected={setSelected} key={index} waiter={val} />
                                    ))}
                                </div>
                                <ToastBox
                                    className="addSuccessfullyToast"
                                    ToastVisiable={showMessage.visible}
                                    bodyPara={showMessage.message}
                                    setShowMessage={setShowMessage}
                                    showIcon={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <MessageModal
                    setToggle={setArchiveModal}
                    toggle={archiveModal}
                    title={filter == I18n.all_customers ? I18n.archive_customer : I18n.unarchive_customer}
                    description={
                        filter == I18n.all_customers ? I18n.are_you_sure_you_want_to_archive_the_selected_Customer : I18n.are_you_sure_you_want_to_unarchive_the_selected_Customer
                    }
                    secondaryCta={{
                        title: I18n.no,
                        action: toggleArchiveModal,
                    }}
                    primaryCta={{
                        title: I18n.yes,
                        action: () => {
                            handleArchivedAction(selected);
                        },
                        isDelete: true,
                    }}
                />
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.waiter}
                subHeading={I18n.create_and_manage_your_team}
                name="waiterIcon"
                fill={theme.white}
                viewBox={'0 0 100 100'}
                secondaryCta={
                    userPermission.canCrud
                        ? {
                              title: I18n.create_waiter,
                              action: handleCreateWaiter,
                          }
                        : null
                }
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    waiters: observeActiveWaiters(),
    archivedList: observeArchivedWaiters(),
    draftList: observeDraftWaiters(),
}));

export default enhance(WaiterList);
