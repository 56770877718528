import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useLanguageContext, useThemeContext } from "../../../../context";
import PosAppModal from "../PosAppModal";
import { DEVICE_HEIGHT, firstLetterCaptilize } from "../../../../constants";
import TextField from "../../TextField";
import PaymentStep1 from "./PaymentStep1";
import PaymentStep2 from "./PaymentStep2";
import PaymentStep3 from "./PaymentStep3";
import PaymentStep4 from "./PaymentStep4";
import Otpverifiction from "./Otpverifiction";
import MPINverify from "./MPINverify";

const WalletPaymentModal = ({
  toggle,
  setToggle,
  selectedWallet,
  handleSave,
  customerInfo,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const textBoxRef = useRef(null);
  const [accountNo, setAccountNo] = useState("");
  const [amount, setAmount] = useState("");

  const [step, setStep] = useState(1);

  const handleClose = (event) => {
    setToggle(false);
    setAccountNo("");
  };

  const handleChange = (label, val) => {
    if (label == I18n.enter_number) setAccountNo(val);
    else if (label == I18n.enter_amount) {
      setAmount(val);
    }
  };

  const handleDone = () => {
    handleSave(accountNo);
    setAccountNo("");
  };

  return (
    <PosAppModal
      className="selectCustomerModal"
      toggle={toggle}
      backCta={{
        name: "backArrowIcon",
        fill: theme.barclaysBlue,
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
      }}
      handleClose={handleClose}
      bottomSaveCta={{
        title: I18n.next,
        action: handleDone,
        // disabled: !accountNo,
      }}
      bottomCancelCta={{
        title: I18n.cancel,
        action: handleClose,
      }}
      title={firstLetterCaptilize(I18n.back)}
      toastBox={
        accountNo && {
          message: customerInfo?.account_title
            ? I18n.account_fetched_successfully
            : I18n.sorry_account_not_found_Try_again,
        }
      }
    >
      <Container>
        <Row className="justify-content-md-center">
          <Col md={7}>
            <div
              style={{
                height: DEVICE_HEIGHT - 200,
              }}
            >
              {step == 1 ? (
                <PaymentStep1
                  selectedWallet={selectedWallet}
                  handleChange={handleChange}
                  data={accountNo}
                />
              ) : step == 2 ? (
                <PaymentStep2
                  selectedWallet={selectedWallet}
                  data={customerInfo}
                />
              ) : step == 3 ? (
                <PaymentStep3 handleChange={handleChange} data={amount} />
              ) : step == 4 ? (
                <PaymentStep4
                  data={customerInfo}
                  selectedWallet={selectedWallet}
                />
              ) : step == 5 ? (
                <Otpverifiction />
              ) : step == 6 ? (
                <MPINverify />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </PosAppModal>
  );
};

export default WalletPaymentModal;
