import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';

const Location = () => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const {account} = useAuthContext();
    const userPermission = usePermission('location');
    const [loading, setloading] = useState(false);
    const [locations, setLocations] = useState([]);

    const handleCreateLocation = () => {
        navigate('/location/create');
    };

    function handleSearch(val) {
        const values = val?.toLowerCase();
        return locations.filter(x => x?.location_name?.toLowerCase().includes(values));
    }

    const onChange = (label, value) => setText(value);

    const getAllBusinessLocations = async () => {
        setloading(true);
        let businesses = await account.business.fetch();
        setLocations(businesses);
        setloading(false);
    };

    useEffect(() => {
        getAllBusinessLocations();
    }, []);

    return loading ? (
        <Loading />
    ) : (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.location} (${locations?.length})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    className={'borderRadiusTopleftTopRigt'}
                    rightCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_location,
                                  action: handleCreateLocation,
                              }
                            : null
                    }
                />

                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`sixColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol">
                                        <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                            {I18n.name}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.type}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.city}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.email}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.phone}
                                        </p>
                                    </div>
                                    {userPermission?.canCrud && (
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.action}
                                            </p>
                                        </div>
                                    )}
                                </TableHeader>
                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {(text ? searchList : locations)?.map(val => (
                                        <List location={val} userPermission={userPermission} />
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Location;
