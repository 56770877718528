import { useNavigate } from "react-router-dom";
import { useLanguageContext, useThemeContext } from "../../../context";
import Icon from "../../../assets/icons";

const BackButton = ({ backgroundColor, txtColor, backIcon }) => {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();

  return (
    <p
      className="cursorPointer fontSize22 marBot0"
      style={{
        backgroundColor: backgroundColor,
        color: txtColor,
      }}
      onClick={() => navigate(-1)}
    >
      <span>
        <Icon
          name="backIcon"
          fill={theme.white}
          width={"9"}
          height={"15"}
          viewBox={"0 0 9 15"}
        />
      </span>
      <span className="marLeft10 fontSize20"> {I18n.back} </span>
    </p>
  );
};

export default BackButton;
