import React from "react";
import WithOutHeaderToast from "./WithOutHeaderToast";
import DefaultToast from "./DefaultToast";

const ToastBox = ({ type, ...rest }) => {
  return type == "withoutheHeader" ? (
    <WithOutHeaderToast {...rest} />
  ) : (
    <DefaultToast {...rest} />
  );
};

export default ToastBox;
