import {useLocation, useNavigate} from 'react-router-dom';
import {Sync} from '../../../../../api';
import {useAuthContext, useLanguageContext} from '../../../../../context';
import {useCustomer} from '../../../../../hooks';
import {CreateCustomerComponent, CustomContainer, Header, Loading} from '../../../../common';

const EditCustomer = () => {
    let navigate = useNavigate();
    const {state} = useLocation();

    const {I18n} = useLanguageContext();
    const {account, user} = useAuthContext();

    const {isLoading, customer} = useCustomer(account);

    const handleSave = async () => {
        await Sync(user.email);
        if (state?.isPos) navigate(-1);
        navigate('/customers', {
            state: {
                isArchived: customer._raw.archive,
            },
        });
    };

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateCustomerComponent handleSelect={handleSave} customer={customer} />
            </CustomContainer>
        </>
    );
};

export default EditCustomer;
