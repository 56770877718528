import {Model, Q} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date, lazy} from '@nozbe/watermelondb/decorators';
import {CREDIT_TRANSACTION_SCHEMA, CUSTOMER_SCHEMA} from '../schema';
import {uuid} from '../../constants';

export default class Customer extends Model {
    static table = CUSTOMER_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account'},
        order: {type: 'has_many', foreignKey: 'customer_id'},
        payment: {type: 'has_many', foreignKey: 'customer_id'},
        credit_transactions: {type: 'has_many', foreignKey: 'customer_id'},
    };

    @text('name') name;
    @text('phone_number') phone_number;
    @text('email') email;
    @text('province') province;
    @text('country') country;
    @text('city') city;
    @text('address') address;
    @field('archive') archive;
    @field('draft') draft;
    @date('dob') dob;
    @date('created_at') created_at;
    @field('available_points') available_points;
    @field('total_credit') total_credit;

    @children('order') order;
    @children('payment') payment;
    @children('credit_transactions') credit_transactions;

    @relation('account', 'account_id') account;

    @lazy getCompletedOrders = this.order.extend(Q.where('status', 'complete'));

    @writer async updateDetails(details) {
        return await this.update(customer => {
            customer.name = details.name;
            customer.phone_number = details.phone;
            customer.email = details.email;
            customer.dob = details.dob;
            customer.province = details.province;
            customer.city = details.city;
            customer.country = details.country;
            customer.draft = details.draft;
            customer.address = details.address;
        });
    }

    @writer async archiveCustomer(status = true) {
        return await this.update(customer => {
            customer.archive = status;
        });
    }

    @writer async addCustomertoDraft(status = true) {
        return await this.update(customer => {
            customer.draft = status;
        });
    }

    @writer async updateCustomerPoints(points, type) {
        return await this.update(customer => {
            customer.available_points = type == 'add' ? customer.available_points + points : customer.available_points - points;
        });
    }

    @writer async updateCredit(value, type) {
        console.log('value', value);
        console.log('type', type);

        return await this.update(customer => {
            customer.total_credit = type == 'add' ? Number(customer.total_credit + value) : Number(customer.total_credit - value);
        });
    }

    @writer async createCreditTransaction(payload) {
        return await this.collections.get(CREDIT_TRANSACTION_SCHEMA).create(credTrans => {
            credTrans.customer.set(this);
            credTrans._raw.id = uuid();
            credTrans.payment_method = payload.payment_method;
            credTrans.amount = payload.amount;
            credTrans.status = payload.status;
            credTrans.started_at = new Date().getTime();
            credTrans.completed_at = payload.completed_at;
        });
    }
}
