import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useOrder = (business) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [order, setOrder] = useState();

  useEffect(() => {
    handleOrder(id);
  }, [id]);

  const handleOrder = async (id) => {
    let orderList = await business.getOrder(id).fetch();

    if (orderList.length) setOrder(orderList[0]);

    setIsLoading(false);
  };

  return { isLoading, order };
};

export default useOrder;
