import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Loading} from '../../components/common';
import {useAuthContext} from '../../context';

const RequireOrder = ({children}) => {
    let navigate = useNavigate();
    const location = useLocation();
    const {user} = useAuthContext();

    let pathName = window.location.pathname;
    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    if (orderNumber) {
        pathName = `${pathName}?id=${orderNumber}`;
        console.log('path name', pathName);
    }

    const {business, handleCreateOrder} = useAuthContext();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {
        let orders = await business.getActiveOrders(user?.id).fetch();

        if (!orders.length) {
            await handleCreateOrder();
            navigate(pathName);
        }

        setIsLoading(false);
    };

    return isLoading ? <Loading /> : children;
};

export default RequireOrder;
