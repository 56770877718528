import {getFocus} from '../../../constants';
import {useThemeContext} from '../../../context';
import {Button} from '../../common';

const Calculator = ({className, columnFour, setState, maxLength}) => {
    const {theme} = useThemeContext();

    const onClick = (val, preset) => {
        if (setState) {
            const numericVal = Number(val);
            if (preset) {
                setState(state => {
                    const currentState = Number(state) || 0;
                    return currentState + numericVal;
                });
            } else {
                setState(state => {
                    if (!state) {
                        return numericVal.toString();
                    }
                    if (maxLength && state.toString().length < maxLength) {
                        return state + numericVal.toString();
                    }
                    return state;
                });
            }
        }
    };

    const removeCharacter = () => {
        if (setState) {
            setState(state => (state ? state.toString().slice(0, -1) : state));
        }
    };

    const resetAmount = () => {
        setState('');
    };

    return (
        <div className={`calculatorMainBox ${className}`}>
            <div className={`calculatorInnerBoxColumn ${columnFour ? 'calcFourColumn' : 'calcThreeColumn'}`}>
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="7"
                    type="secondary"
                    handleClick={() => onClick(7)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="4"
                    type="secondary"
                    handleClick={() => onClick(4)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="1"
                    type="secondary"
                    handleClick={() => onClick(1)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="Reset"
                    type="secondary"
                    handleClick={() => resetAmount()}
                />
            </div>
            <div className={`calculatorInnerBoxColumn ${columnFour ? 'calcFourColumn' : 'calcThreeColumn'}`}>
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="8"
                    type="secondary"
                    handleClick={() => onClick(8)}
                />

                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="5"
                    type="secondary"
                    handleClick={() => onClick(5)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="2"
                    type="secondary"
                    handleClick={() => onClick(2)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="0"
                    type="secondary"
                    handleClick={() => onClick(0)}
                />
            </div>
            <div className={`calculatorInnerBoxColumn ${columnFour ? 'calcFourColumn' : 'calcThreeColumn'}`}>
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="9"
                    type="secondary"
                    handleClick={() => onClick(9)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="6"
                    type="secondary"
                    handleClick={() => onClick(6)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer`}
                    backgroundColor={theme.posRightBg}
                    borderColor={theme.posRightBg}
                    txtColor={theme.white}
                    title="3"
                    type="secondary"
                    handleClick={() => onClick(3)}
                />
                <Button
                    id="calculatorKey"
                    className={`width100 fontSize20 cursorPointer buttonStyle`}
                    backgroundColor={theme.posRightBg}
                    type="iconButton"
                    icon_name="newBackSpaceIcon"
                    icon_fill={theme.white}
                    icon_width={'30'}
                    icon_height={'30'}
                    icon_viewBox={'0 0 30 30'}
                    handleClick={removeCharacter}
                />
            </div>
            {columnFour ? (
                <div className={`calculatorInnerBoxColumn ${columnFour ? 'calcFourColumn' : 'calcThreeColumn'}`}>
                    <Button
                        id="calculatorKey"
                        className={`width100 fontSize20 cursorPointer`}
                        backgroundColor={theme.blackBg}
                        txtColor={theme.barclaysBlue}
                        borderColor={theme.posRightBg}
                        title="+ Rs. 5,000"
                        type="secondary"
                        handleClick={() => onClick(5000, true)}
                    />
                    <Button
                        id="calculatorKey"
                        className={`width100 fontSize20 cursorPointer`}
                        backgroundColor={theme.blackBg}
                        txtColor={theme.barclaysBlue}
                        borderColor={theme.posRightBg}
                        title="+ Rs. 1,000"
                        type="secondary"
                        handleClick={() => onClick(1000, true)}
                    />
                    <Button
                        id="calculatorKey"
                        className={`width100 fontSize20 cursorPointer`}
                        backgroundColor={theme.blackBg}
                        txtColor={theme.barclaysBlue}
                        borderColor={theme.posRightBg}
                        title="+ Rs. 500"
                        type="secondary"
                        handleClick={() => onClick(500, true)}
                    />
                    <Button
                        id="calculatorKey"
                        className={`width100 fontSize20 cursorPointer`}
                        backgroundColor={theme.blackBg}
                        txtColor={theme.barclaysBlue}
                        borderColor={theme.posRightBg}
                        title="+ Rs. 100"
                        type="secondary"
                        handleClick={() => onClick(100, true)}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default Calculator;
