import React, {useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Button, MessageModal} from '..';
import {Col, Container, Row} from 'react-bootstrap';
import Icon from '../../../assets/icons';
import {getMarginActivation, uploadBusinessMargin} from '../../../api';
import {useEffect} from 'react';
const UploadMarginBreaKdown = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, userBusiness} = useAuthContext();
    const [selectedLocation, setSelectedLocation] = useState({name: business.location_name, id: business.id});
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [confirmModel, setConfirmModel] = useState(false);
    const [lastUpadteDate, setLastUpadteDate] = useState('');

    /**
     * Updates the selected location based on the provided label and value.
     *
     * @param {string} label - The label associated with the value.
     * @param {object} value - The value containing location information.
     * @return {void}
     */
    const onChange = (label, value) => {
        setSelectedLocation({name: value?._raw?.location_name, id: value?._raw?.id});
        setError('');
    };

    /**
     * Checks if the margin is activated for the selected business location.
     *
     * @return {void}
     */
    const checkMarginActivation = async () => {
        try {
            let params = {business_id: selectedLocation?.id};
            const response = await getMarginActivation(params);

            if (response.success) {
                setLastUpadteDate(response?.data?.last_activated_date);
            }
        } catch (err) {
            console.log('api getMarginActivation', err);
        }
    };

    /**
     * This function is used to Toggle Modal on Some condition.
     */
    const handleUploadFile = async () => {
        if (!file) {
            setError('No file selected');
            return;
        }
        if (!selectedLocation?.id) {
            setError('Location not set');
            return;
        }

        setError('');

        toggleConfirmModal();
    };

    /**
     * This function is used Upload File.
     */
    const handleFileChange = event => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(selectedFile);
        } else {
            setError('Please select a valid .xlsx file');
        }
    };

    /**
     * This function is used to handle drag and drop event.
     */
    const handleDragOver = event => {
        event.preventDefault();
    };

    const handleDrop = event => {
        event.preventDefault();
        const selectedFile = event.dataTransfer.files[0];
        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(selectedFile);
        } else {
            console.log('Please drop a valid .xlsx file');
        }
    };
    /**
     * Toggles the confirm modal state.
     *
     * @return {void}
     */
    const toggleConfirmModal = () => {
        setConfirmModel(x => !x);
    };

    /**
     * @description Add handleSubmit function for uploading business margin file with error handling and modal toggle
     * @return {void}
     */ const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('margin_file', file);
            formData.append('business_id', selectedLocation.id);
            const response = await uploadBusinessMargin(formData);
            console.log('res', response);
            setFile(null);
        } catch (error) {
            console.log('Error during file upload:', error);
            setError(error?.response?.data?.mesg || 'File upload failed. Please try again.');
            setFile(null);
        } finally {
            toggleConfirmModal();
        }
    };

    useEffect(() => {
        checkMarginActivation();
    }, [selectedLocation, file]);

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="UploadMarginConatiner">
                        <div className="spaceBetweenCenter">
                            <p className="fontSize16 fontWeight500" style={{color: theme.white}}>
                                {I18n.upload_margin_breakdown_file}
                            </p>

                            <Col md={4} sm={4}>
                                <TextField
                                    label={I18n.location}
                                    placeholder={I18n.location}
                                    data={[
                                        ...userBusiness?.map(item => ({
                                            ...item,
                                            name: item.location_name,
                                        })),
                                    ]}
                                    value={selectedLocation?.name}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>
                        </div>

                        <div>
                            <div className="fileUploder" onDragOver={handleDragOver} onDrop={handleDrop} onClick={() => document.getElementById('fileInput').click()}>
                                {file ? (
                                    <p className="fontSize16 fontWeight400 marBot0" style={{color: theme.white}}>
                                        <span> {file?.name} </span>
                                        <span className="cursorPointer marLeft5">
                                            <Icon name="delete2Icon" fill={theme.darkGrayTwo} width="18" height="18" viewBox={'0 0 18 18'} onClick={() => setFile(null)} />
                                        </span>
                                    </p>
                                ) : (
                                    <>
                                        <p className="fontSize14 fontWeight500 marBot0" style={{color: theme.darkGrayTwo}}>
                                            Drop file here or <span style={{color: theme.barclaysBlue}}> Browse </span>
                                        </p>
                                        <p className="fontSize14 fontWeight500 marBot0" style={{color: theme.darkGrayTwo}}>
                                            Upload only .xlsx
                                        </p>
                                    </>
                                )}
                            </div>
                            <input id="fileInput" type="file" accept=".xlsx" style={{display: 'none'}} onChange={handleFileChange} />

                            {error ? (
                                <p className="fontSize12 fontWeight400 marTop15 flexRow verticalCenter" style={{color: theme.red}}>
                                    {error}
                                </p>
                            ) : null}

                            <p className="fontSize12 fontWeight400 marTop15 flexRow verticalCenter" style={{color: theme.white}}>
                                <Icon name="infocircleIcon" fill={theme.barclaysBlue} width={'18'} height={'18'} viewBox={'0 0 18 18'} />
                                <span className="marLeft5">{I18n.you_can_share_only_one_file_every_hours_so_please_ensure_you_submit_the_correct_file}</span>
                            </p>
                            <Button className={'submitButton fontSize14 fontWeight500'} title={I18n.submit} handleClick={handleUploadFile} />

                            {lastUpadteDate || file ? (
                                <p className="fontSize12 fontWeight400 marTop15 flexRow verticalCenter" style={{color: theme.white}}>
                                    {lastUpadteDate ? `Last uploaded file date : ${lastUpadteDate}` : I18n.file_uploaded}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </Row>
            </Container>
            <MessageModal
                setToggle={setConfirmModel}
                toggle={confirmModel}
                className="messageModal"
                description={'Do you want to submit your file?'}
                subDescription={'Please ensure the file you are submitting is correct.'}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleConfirmModal,
                }}
                primaryCta={{
                    title: I18n.submit,
                    action: () => {
                        handleSubmit();
                    },
                }}
            />
        </>
    );
};

export default UploadMarginBreaKdown;
