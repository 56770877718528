import { database } from "..";
import { uuid } from "../../constants";
import { ROLE_SCHEMA, ACCOUNT_SCHEMA } from "../schema";
import { Q } from "@nozbe/watermelondb";

const accountRoles = database.collections.get(ROLE_SCHEMA);

export const observeRoles = () => accountRoles.query().observe();

const prepareInsertion = (account, roles) => {
  return roles.map((details) => {
    return accountRoles.prepareCreate((role) => {
      role.account.set(account);
      details.userBusiness && role.user_business.set(details.userBusiness);
      role._raw.id = uuid();
      role.name = details.name;
    });
  });
};

export const bulkCreateRoles = async (account, roles) => {
  return await database.write(async () => {
    const allRecords = await prepareInsertion(account, roles);
    await database.batch(...allRecords);
    return allRecords;
  });
};
