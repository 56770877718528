import BackButton from "./Back";
import IconButton from "./Icon";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./Secondary";
import SkipButton from "./Skip";
import ThreeColumnButton from "./ThreeColumnButton";
import TitleIconButton from "./TitleIcon";
import TwoColumnButton from "./TwoColumnButton";
import UploadButton from "./UploadButton";
import VerticalButton from "./Vertical";

const Button = ({ type, ...rest }) => {
  return type == "verticalButton" ? (
    <VerticalButton {...rest} />
  ) : type == "primaryButton" ? (
    <PrimaryButton {...rest} />
  ) : type == "threeColumn" ? (
    <ThreeColumnButton {...rest} />
  ) : type == "twoColumn" ? (
    <TwoColumnButton {...rest} />
  ) : type == "buttonTitleIcon" ? (
    <TitleIconButton {...rest} />
  ) : type == "iconButton" ? (
    <IconButton {...rest} />
  ) : type == "back" ? (
    <BackButton {...rest} />
  ) : type == "skip" ? (
    <SkipButton {...rest} />
  ) : type == "uploadButton" ? (
    <UploadButton />
  ) : (
    <SecondaryButton {...rest} />
  );
};

export default Button;
