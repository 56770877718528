import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import Button from '../Button';
import {read, utils, writeFile} from 'xlsx';
import Icon from '../../../assets/icons';
import {exportDataToCSV, uuid} from '../../../constants';

const CsvUploadComponent = ({setData, setViewCsvTable, type, data}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const drop = useRef(null);

    useEffect(() => {
        const handleDragOver = e => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDrop = e => {
            handleImportCSV(e);
        };

        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);

        return () => {
            drop?.current?.removeEventListener('dragover', handleDragOver);
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    }, []);

    const handleNext = () => {
        setViewCsvTable(true);
    };
    function handleImportCSV(e) {
        const files = e?.dataTransfer?.files || e?.target?.files;

        if (!files?.length) return;

        const file = files[0];

        const reader = new FileReader();
        reader.onload = event => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;

            if (!sheets.length) return;

            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            const formateRow = rows?.map(item => {
                switch (type) {
                    case 'customer':
                        return {
                            id: uuid(),
                            name: item?.Name,
                            phone_number: item?.['Phone Number'] && `0${item?.['Phone Number']?.toString()}`,
                            email: item?.Email,
                            province: item?.Province,
                            city: item?.City,
                            country: item?.Country,
                            dob: item?.['Date of brith'],
                            address: item?.Address,
                        };
                    case 'discount':
                        return {
                            id: uuid(),
                            name: item['Discount Name'],
                            is_inclusive: false,
                            type:
                                item['Select discount type (%/Rs)']?.includes('%') || item['Select discount type (%/Rs)']?.toLowerCase()?.includes('percentage')
                                    ? 'percentage'
                                    : 'amount',
                            limit: Number(item['Discount rate']) || 0,
                            max_discount_cap: Number(item['Max discount cap']) || 0,
                        };
                    case 'tax':
                        return {
                            id: uuid(),
                            name: item['Tax Name'],
                            rate: Number(item['Tax rate %']) || 0,
                            tax_behavior: item['Tax behavior']?.toLowerCase()?.includes('inclusive'),
                            tax_calculation: item['Tax Calculation']?.toLowerCase()?.includes('pre'),
                            tax_application: item['Tax application']?.toLowerCase()?.includes('select'),
                        };
                    case 'item':
                        const {
                            Name: name,
                            'Description (is Optional)': description,
                            Price: price,
                            'Cost per item': cost_per_item,
                            'Add Qty': qty,
                            'Low stock alert (is Optional)': low_stock_alert,
                            'Barcode (is Optional)': barcode,
                            Unit: units,
                            Category: category,
                        } = item;
                        return {
                            id: uuid(),
                            name,
                            description,
                            price,
                            cost_per_item,
                            qty,
                            low_stock_alert,
                            barcode,
                            category,
                            units,
                        };
                    default:
                        return null;
                }
            });

            const CSVExtractData = {
                name: file?.name,
                size: file?.size,
                formateRow,
            };
            setData(CSVExtractData);
        };

        reader.readAsArrayBuffer(file);
    }

    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB in bytes

    const checkFileSize = data?.size > MAX_FILE_SIZE;

    return (
        <div className="importLibararyMainBox">
            <div ref={drop} className="csvSeletedBox">
                <div className="csvSeletedInsideBox">
                    <Icon name={'uploadFileIcon'} fill={theme.white} width={'60'} height={'60'} viewBox={'0 0 60 60'} />
                    <p style={{color: theme.text}}>{I18n.select_a_file_or_drag_and_drop_here}</p>
                    <p style={{color: theme.darkGrayTwo}}>{I18n.or_select_files_from_device}</p>
                    <p
                        className="marBot0 marTop10"
                        style={{
                            color: checkFileSize ? theme.red : theme.darkGrayTwo,
                        }}>
                        {I18n.max_50MB}
                    </p>

                    <p onClick={() => exportDataToCSV(type)} className="cursorPointer" style={{color: theme.barclaysBlue, textDecoration: 'underline'}}>
                        {I18n.download_CSV_template}
                    </p>

                    {Object.values(data)?.length > 0 ? (
                        <div className="footerButtonBox">
                            <div className="changefileBtn cursorPointer">
                                <button class="btn">
                                    <span>{I18n.change_file}</span>
                                </button>
                                <input name="image" type="file" onChange={handleImportCSV} accept=".CSV , .xlsx" />
                            </div>
                            <Button className={`fontWeight600 width50 marLeft20 fontSize16 cursorPointer`} handleClick={handleNext} disabled={checkFileSize} title={I18n.next} />
                        </div>
                    ) : (
                        <div className="uploadphotoBtn">
                            <button class="btn">
                                <span>{I18n.select_file}</span>
                            </button>
                            <input name="image" type="file" onChange={handleImportCSV} accept=".CSV , .xlsx" />
                        </div>
                    )}

                    {Object.values(data).length > 0 && (
                        <div className="fileNameBox">
                            <p style={{color: theme.white}}>{data?.name}</p>
                            <p className="cursorPointer">
                                <Icon name="delete2Icon" fill={theme.darkGrayTwo} width="18" height="18" viewBox={'0 0 18 18'} onClick={() => setData('')} />
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CsvUploadComponent;
