import {database} from '..';
import {uuid} from '../../constants';
import {MODIFIER_OPTION_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';

const modifierOption = database.collections.get(MODIFIER_OPTION_SCHEMA);

const prepareInsertion = (modifier, modifierOptions) => {
    return modifierOptions.map(opt => {
        return modifierOption.prepareCreate(obj => {
            obj.modifier.set(modifier);

            obj._raw.id = opt.id;
            obj.name = opt.modifier_name;
            obj.price = opt.price;
            obj.in_stock = opt.isChecked;
            obj.created_at = new Date().getTime();
            obj.archive = false;
        });
    });
};

export const bulkCreateModifierOptions = async (modifier, modifierOptions) => {
    return await database.write(async () => {
        const allRecords = await prepareInsertion(modifier, modifierOptions);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareUpdation = modifierOptions => {
    return modifierOptions.map(opt => {
        return opt.ref.prepareUpdate(obj => {
            obj.name = opt.updatedObj.modifier_name;
            obj.price = opt.updatedObj.price;
            obj.in_stock = opt.updatedObj.isChecked;
            obj.archive = false;
        });
    });
};

export const bulkUpdateModifierOptions = async modifierOptions => {
    return await database.write(async () => {
        const allRecords = await prepareUpdation(modifierOptions);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareDeletion = modifierOptions => {
    return modifierOptions.map(line => {
        return line.prepareMarkAsDeleted();
    });
};

export const bulkDeleteModifierOptions = async modifierOptions => {
    await database.write(async () => {
        const allRecords = await prepareDeletion(modifierOptions);
        await database.batch(...allRecords);
        return allRecords;
    });
};
