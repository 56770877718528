import {fetchCompletedOrders} from '../../pos-core/database/helpers';
import {useState} from 'react';

const usePayTypesByDate = () => {
    const [payOverview, setPayOverview] = useState({});
    const [loading, setLoading] = useState(true);

    const getPayTypeOverview = async (currentStartDate, currentEndDate, locationId) => {
        try {
            let obj = {
                order_total: 0,
                credit: 0,
                wallet: 0,
                cash: 0,
                card: 0,
            };

            currentEndDate = currentEndDate || new Date(new Date().setHours(23, 59, 59, 999));
            currentStartDate = currentStartDate || new Date(new Date().setHours(0, 0, 0, 0));

            let orders = await fetchCompletedOrders(currentStartDate, currentEndDate, locationId);

            await Promise.all(
                orders.map(async ordr => {
                    if (ordr.payment_method == 'cash') obj['cash'] += ordr.total;
                    if (ordr.payment_method == 'card') obj['card'] += ordr.total;
                    if (ordr.payment_method == 'credit') obj['credit'] += ordr.total;
                    obj['order_total'] += ordr.total;
                }),
            );

            setPayOverview(obj);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    return {
        getPayTypeOverview,
        payOverview,
        loading,
    };
};

export default usePayTypesByDate;
