import Icon from "../../../assets/icons";
import { formatNum } from "../../../constants";
import { useThemeContext } from "../../../context";

const PrimaryButton = ({
  value,
  disabled,
  handleClick,
  title,
  backgroundColor,
  textColor,
  className,
  borderColor,
}) => {
  const { theme } = useThemeContext();

  return (
    <button
      value={value}
      className={`buttonPrimary ${className}`}
      disabled={disabled}
      onClick={handleClick}
      style={{
        border: "1px solid",
        borderColor: borderColor || theme.posRightBg,
        background: backgroundColor || theme.topBarBG,
      }}
    >
      {title && (
        <>
          <span
            className="fontSize16 fontWeight400 OneLineTruncate"
            style={{ color: textColor || theme.barclaysBlue }}
          >
            {title}
          </span>
          {value && (
            <span
              className="fontSize12 fontWeight400 OneLineTruncate"
              style={{ color: theme.white }}
            >
              {value}
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default PrimaryButton;
