import {useLocation, useNavigate} from 'react-router-dom';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import withObservables from '@nozbe/with-observables';
import {DEVICE_HEIGHT, exportDataToCSV} from '../../../../../constants';
import {Col, Container, Row} from 'react-bootstrap';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import Icon from '../../../../../assets/icons';
import List from './List';
import {useSearch, useTaxes} from '../../../../../hooks';
import {observePurchaseTax, observeSalesTax} from '../../../../../pos-core/database/helpers';
import {useState} from 'react';
import CsvUploadModal from '../../../../common/CsvUploadModal';
import {useEffect} from 'react';
import usePermission from '../../../../../hooks/usePermission';

const SalesTaxList = ({salesTax, purchaseTax}) => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const [filter, setFilter] = useState(state ? state?.screenName : I18n.all_taxes);
    const [selectedtax, setSelectedtax] = useState({});
    const {isLoading, taxList} = useTaxes(salesTax, purchaseTax, filter);
    const {text, setText, searchList} = useSearch(handleSearch);
    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [cvsModal, setCvsModal] = useState(false);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const toggleArchiveModal = () => {
        if (Object.values(selectedtax)?.length) setArchiveModal(x => !x);
    };

    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('tax');

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'tax_created') {
            setShowMessage({
                visible: true,
                message: I18n.purchase_tax_created_successfully,
            });
        } else if (renderToast === 'tax_update') {
            setShowMessage({
                visible: true,
                message: I18n.purchase_tax_updated_successfully,
            });
        } else if (renderToast === 'sale_tax_created') {
            setShowMessage({
                visible: true,
                message: I18n.sale_tax_created_successfully,
            });
        } else if (renderToast === 'sale_tax_update') {
            setShowMessage({
                visible: true,
                message: I18n.sale_tax_updated_successfully,
            });
        } else if (renderToast === 'tax_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.tax_saved_into_draft,
            });
        } else if (renderToast === 'sale_tax_update') {
            setShowMessage({
                visible: true,
                message: I18n.tax_published_successfully,
            });
        }
    }, [renderToast]);
    const option = [
        {
            title: I18n.all_taxes,
            action: () => handleFilter(I18n.all_taxes),
            checked: filter == I18n.all_taxes ? true : false,
        },
        {
            title: I18n.sales_tax,
            action: () => handleFilter(I18n.sales_tax),
            checked: filter == I18n.sales_tax ? true : false,
        },
        {
            title: I18n.purchase_tax,
            action: () => handleFilter(I18n.purchase_tax),
            checked: filter == I18n.purchase_tax ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    const actionList = [
        {
            title: I18n.create_sales_tax,
            action: () => handleCreateTax('sales'),
        },
        {
            title: I18n.create_purchase_tax,
            action: () => handleCreateTax('purchase'),
        },
        {
            title: filter == I18n.archived ? I18n.unarchived : I18n.archived,
            action: () => toggleArchiveModal(),
        },
    ];

    const handleArchivedAction = async selectedtax => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selectedtax)?.map(async item => {
                await item.archiveTax(!item.archive);
                selectedIds.push(item?.id);
            }),
        );
        setSelectedtax(selected => {
            let obj = {...selected};
            selectedIds?.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });
        setloading(false);
    };

    const handleFilter = val => {
        setSelectedtax({});
        setFilter(val);
    };

    const handleChange = () => {
        // handleChange
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelectedtax(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val?.id] = val;
            return obj;
        });
    };

    const handleSelectAll = () => {
        if (Object.keys(selectedtax)?.length != taxList?.length) {
            taxList?.map(val => {
                setSelectedtax(values => {
                    let obj = {...values};
                    obj[val?.id] = val;
                    return obj;
                });
            });
        } else {
            setSelectedtax({});
        }
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    const handleCreateTax = type => navigate(`${type}/create`);

    function handleSearch(val) {
        return taxList.filter(x => x.name.toLowerCase().includes(val.toLowerCase()));
    }

    const renderList = text ? searchList : taxList;
    return isLoading || loading ? (
        <Loading />
    ) : salesTax.length || purchaseTax.length ? (
        <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
            <Header
                className={'borderRadiusTopleftTopRigt'}
                type="search"
                title={`${I18n.taxes} (${taxList.length})`}
                leftCta={{
                    title: filter || I18n.all_taxes,
                    name: 'downIcon',
                    fill: theme.white,
                    width: '12',
                    height: '9',
                    viewBox: '0 0 8 5',
                    option: option,
                    onChange: handleChange,
                    checked: handleChecked,
                }}
                search={{
                    type: 'pos',
                    placeholder: I18n.search_by_name,
                    prefix: {
                        name: 'search2Icon',
                        fill: theme.white,
                    },
                    onChange: onChange,
                }}
                actionCta={
                    userPermission?.canCrud
                        ? {
                              title: I18n.actions,
                              name: 'downIcon',
                              fill: theme.white,
                              width: '12',
                              height: '9',
                              viewBox: '0 0 8 5',
                              option: actionList,
                          }
                        : null
                }
            />
            <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <TableHeader className={`fiveColumn`} style={{borderColor: theme.posRightBg}}>
                                <div className="checkboxCol">
                                    <CheckBox
                                        checkboxLabel={
                                            <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                {I18n.name}
                                            </p>
                                        }
                                        onChange={handleSelectAll}
                                        checked={Object.keys(selectedtax).length > 0 ? Object.keys(selectedtax)?.length == taxList?.length : false}
                                    />
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.tax_rate}
                                    </p>
                                </div>

                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.tax_type}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.tax_behavior}
                                    </p>
                                </div>
                                {userPermission?.canCrud && (
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.action}
                                        </p>
                                    </div>
                                )}
                            </TableHeader>

                            <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                {renderList.length === 0 ? (
                                    <NonIdealScreen
                                        heading={
                                            text
                                                ? I18n.search
                                                : filter == I18n.drafts_saved
                                                ? I18n.draft_tax
                                                : filter == I18n.archived
                                                ? I18n.archive_tax
                                                : filter == I18n.purchase_tax
                                                ? I18n.purchase_tax
                                                : I18n.sales_tax
                                        }
                                        subHeading={
                                            text
                                                ? I18n.we_couldnt_find_any_results_for_your_search
                                                : filter == I18n.drafts_saved
                                                ? I18n.no_draft_tax_found
                                                : filter == I18n.archived
                                                ? I18n.no_archive_tax_found
                                                : filter == I18n.purchase_tax
                                                ? I18n.no_purchase_found
                                                : I18n.no_sales_tax_found
                                        }
                                        name={text ? 'seacrhNotFoundIcon' : filter == I18n.drafts_saved ? 'draftIcon' : filter == I18n.archived ? 'archiveIcon' : 'taxNewIcon'}
                                        fill={theme.white}
                                        isMultiStep={false}
                                        tablenonIdeal={true}
                                    />
                                ) : (
                                    renderList?.map(val => (
                                        <List
                                            filter={filter}
                                            setSelectedtax={setSelectedtax}
                                            selectedtax={selectedtax}
                                            handleChecked={handleChecked}
                                            tax={val}
                                            userPermission={userPermission}
                                        />
                                    ))
                                )}
                            </div>
                            <ToastBox
                                className="addSuccessfullyToast"
                                ToastVisiable={showMessage.visible}
                                bodyPara={showMessage.message}
                                setShowMessage={setShowMessage}
                                showIcon={true}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} Csvdata={taxList} type={'tax'} />
            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                title={filter == I18n.all_taxes ? I18n.archive_tax : I18n.unarchive_tax}
                description={filter == I18n.all_taxes ? I18n.are_you_sure_you_want_to_archive_the_taxes_Customer : I18n.are_you_sure_you_want_to_unarchive_the_taxes_Customer}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => {
                        handleArchivedAction(selectedtax);
                    },
                    isDelete: true,
                }}
            />
        </div>
    ) : (
        <NonIdealScreen
            heading={I18n.create_tax}
            subHeading={I18n.the_tax_amount_will_be_automatically}
            name="taxNewIcon"
            fill={theme.white}
            secondaryCta={
                userPermission?.canCrud
                    ? {
                          title: I18n.create_purchase_tax,
                          action: () => handleCreateTax('purchase'),
                      }
                    : null
            }
            primaryCta={
                userPermission?.canCrud
                    ? {
                          title: I18n.create_sales_tax,
                          action: () => handleCreateTax('sales'),
                      }
                    : null
            }
            isMultiStep={false}
        />
    );
};

const enhance = withObservables([], () => ({
    salesTax: observeSalesTax(),
    purchaseTax: observePurchaseTax(),
}));

export default enhance(SalesTaxList);
