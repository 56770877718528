import { Model } from "@nozbe/watermelondb";
import { MODULE_SCHEMA } from "../schema";
import {
  field,
  text,
  relation,
  children,
  writer,
} from "@nozbe/watermelondb/decorators";

export default class Module extends Model {
  static table = MODULE_SCHEMA;

  static associations = {
    role: { type: "belongs_to", key: "role_id" },
  };

  @text("name") name;
  @field("create") create;
  @field("read") read;
  @field("update") update;
  @field("delete") delete;

  @relation("role", "role_id") role;

  @writer async createModule(details) {
    return await this.update((mod) => {
      mod.name = details.name;
    });
  }

  @writer async updateModule(details) {
    return await this.update((mod) => {
      mod.name = details.name;
    });
  }

  @writer async deleteModule(details) {
    return await this.update((mod) => {
      mod.name = details.name;
    });
  }
}
