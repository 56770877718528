import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../../context';
import NewAppModal from '../../NewAppModal';
import TextField from '../../TextField';
import {toSnakeCase, validateRedeemPointsForm} from '../../../../constants';

const LoyaltyPointsModal = ({visible, setVisible, handleRedeemPoints, customer, setShowMessage, order}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const [redeemPoints, setRedeemPoints] = useState();
    const [errors, setErrors] = useState({});
    const customerAvailablePoints = customer?.available_points;

    const onChange = async (label, val) => {
        if (label == I18n.redeem_points) setRedeemPoints(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const hanldeClose = () => {
        setVisible(null);
        setRedeemPoints();
    };

    const handleSave = () => {
        const payload = {
            redeem_points: redeemPoints,
        };

        const formErrors = validateRedeemPointsForm(payload, customerAvailablePoints);
        console.log('formErrors', formErrors);
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;

        handleRedeemPoints(redeemPoints);
        setVisible(null);
        setShowMessage({
            visible: true,
            message: I18n.points_redeemed_successfully,
        });
    };

    useEffect(() => {
        setRedeemPoints();
    }, [customer]);

    return (
        <div>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => hanldeClose()}
                primaryCta={{
                    title: I18n.confirm,
                    action: () => handleSave(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => hanldeClose(),
                }}
                title={I18n.back}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        <p className="fontSize20 marBot20" style={{color: theme.white}}>
                            {I18n.how_many_loyalty_points_would_you_like_to_redeem}
                        </p>
                        <div className="flex spaceBetweenCenter">
                            <p className="fontSize12 marBot10" style={{color: theme.lightGrayTwo}}>
                                1 point = 0.1 Rupee
                            </p>

                            <p className="fontSize12  marBot10" style={{color: theme.white}}>
                                {`Available = ${customerAvailablePoints} Points`}
                            </p>
                        </div>

                        <TextField
                            label={I18n.redeem_points}
                            placeholder={I18n.redeem_points}
                            onChange={onChange}
                            value={redeemPoints}
                            required={true}
                            error={errors.redeem_points}
                        />
                    </div>
                </div>
            </NewAppModal>
        </div>
    );
};

export default LoyaltyPointsModal;
