import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {CustomerDetailComponent, Loading, NonIdealScreen} from '../../../../common';
import {Col, Container, Row} from 'react-bootstrap';
import {DEVICE_HEIGHT, firstLetterCaptilize} from '../../../../../constants';
import {useCustomer} from '../../../../../hooks';
import OrderList from '../../../../common/CustomerDetailModal/OrderList';
import {NewPosHeader} from '../../../../common/V2';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';

const CustomerDetailScreen = () => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business, user, account} = useAuthContext();
    let navigate = useNavigate();
    const {isLoading, customer, orderCount} = useCustomer(account);
    const userPermission = usePermission('pos');

    const [order, setOrder] = useState([]);

    let headerTitle = `${customer?.name} (${orderCount} ${orderCount > 1 ? 'orders' : 'order'})`;

    const handleSelling = () => {
        navigate('/pos');
    };

    useEffect(() => {
        const fetchOrder = async () => {
            if (customer) {
                const fetchedOrder = await customer?.order?.fetch();
                setOrder(fetchedOrder);
            }
        };

        fetchOrder();
    }, [customer]);

    return isLoading ? (
        <Loading />
    ) : (
        <div className="itemListContainer orderDetailList" fluid style={{background: theme.topBarBG}}>
            <Container className="noPadding" fluid>
                <Row className="justify-content-md-center noMargin">
                    <Col md={7} className="noPadding garyBorderBottom">
                        <NewPosHeader backBtnCta={true} title={{name: headerTitle}} />
                        <div className="orderDetailMainBox" style={{height: DEVICE_HEIGHT - 110}}>
                            {orderCount > 0 ? (
                                <div>
                                    <OrderList customerDetails={true} customer={customer} />
                                </div>
                            ) : (
                                <NonIdealScreen
                                    heading={I18n.order_history}
                                    subHeading={`${firstLetterCaptilize(customer?.name)} ${I18n.order_history_not_found_start_selling}`}
                                    backgroundColor={theme.topBarBG}
                                    listNonideal={true}
                                    name="clockhistoryIcon"
                                    fill={theme.white}
                                    secondaryCta={
                                        userPermission?.canCrud
                                            ? {
                                                  title: I18n.start_selling,
                                                  action: handleSelling,
                                              }
                                            : null
                                    }
                                    isMultiStep={false}
                                />
                            )}
                        </div>
                    </Col>
                    <Col md={5} className="noPadding">
                        <div className="orderDetailRightSide" style={{background: theme.topBarBG}}>
                            <NewPosHeader
                                title={{
                                    name: I18n.summary_and_details,
                                }}
                            />
                            <CustomerDetailComponent buyerSummary={true} order={order} customer={customer} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CustomerDetailScreen;
