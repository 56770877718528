import React, { useState } from "react";
import { Button, TextField } from "../../../../common";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import { toSnakeCase } from "../../../../../constants";

const SendDeviceCode = ({ handleSend, loading, errors, setErrors }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [email, setEmail] = useState();

  const onChange = (label, val) => {
    if (label == I18n.enter_email_address) {
      setEmail(val);
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }

      return updatedErrors;
    });
  };

  return (
    <div className="createDeviceWrapper">
      <div className="createDeviceFieldsWrapper">
        <p
          className="fontSize24 textCenter fontWeight600"
          style={{ color: theme.white }}
        >
          {I18n.send_device_code}
        </p>
        <p className="fontSize14 textCenter" style={{ color: theme.white }}>
          {I18n.send_code_to_email}
        </p>
        <TextField
          onChange={onChange}
          error={errors?.["enter_email_address"]}
          label={I18n.enter_email_address}
          placeholder={I18n.enter_email_address}
          value={email}
        />
        <div className="flex verticalCenter horizontalCenter width100">
          <Button
            className={`width40 fontWeight600 fontSize16 cursorPointer marTop20`}
            title={I18n.send}
            handleClick={() => handleSend(email)}
            saveLoad={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default SendDeviceCode;
