import React from 'react';
import {useNavigate} from 'react-router-dom';
import {NonIdealScreen} from '../../../../../common';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import usePermission from '../../../../../../hooks/usePermission';

const ModifierList = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const navigate = useNavigate();
    const userPermission = usePermission('modifiers');

    const navigateToCreate = () => {
        navigate('/modifiers/create');
    };
    return (
        <div className="modifiersScreen">
            {false ? (
                <h1>Data</h1>
            ) : (
                <>
                    <NonIdealScreen
                        heading={I18n.setup_modifiers}
                        paragraph={I18n.setup_modifiers_and_customize}
                        name="modifierIcon"
                        viewBox="0 0 100 100"
                        fill={theme.white}
                        secondaryCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.create_modifier,
                                      action: () => navigateToCreate(),
                                  }
                                : null
                        }
                        isMultiStep={false}
                        skipText={I18n.skip_this_step}
                    />
                </>
            )}
        </div>
    );
};

export default ModifierList;
