import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useTeam = ({users, roles}) => {
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [memberDetails, setMemberDetails] = useState();

    useEffect(() => {
        handleMember(id);
    }, [id]);

    const handleMember = async id => {
        const memberDetails = users?.find(member => member.id == id.toString());
        const userBusiness = await memberDetails?.user_business.fetch();
        const userRole = await userBusiness?.[0].role.fetch();

        if (memberDetails) {
            const roleObj = roles?.find(role => role.id == userRole?.id);
            const modules = await roleObj?.module?.fetch();
            const resultObject = {};
            modules?.forEach(obj => {
                resultObject[obj.name] = {name: obj.name};
            });

            const memberDetailsWithRole = {
                memberDetails,
                role: roleObj,
                module: resultObject,
            };

            setMemberDetails(memberDetailsWithRole);
        }

        setIsLoading(false);
    };

    return {isLoading, memberDetails};
};

export default useTeam;
