import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../../context';
import {Button} from '../..';
import TooltipComponent from '../TooltipComponent';

const PosHeaderButton = ({firstBtn, fourBtn, secondBtn, thirdBtn, className, orderLines}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <>
            <div className={className || 'posHeaderBox'}>
                {firstBtn ? (
                    <Button
                        type="primaryButton"
                        title={firstBtn.title}
                        handleClick={firstBtn.action}
                        value={firstBtn.value}
                        backgroundColor={firstBtn.backgroundColor}
                        textColor={firstBtn.textColor}
                    />
                ) : null}

                {secondBtn && (
                    <Button
                        type="primaryButton"
                        title={secondBtn.title}
                        handleClick={secondBtn.action}
                        value={secondBtn.value}
                        backgroundColor={secondBtn.backgroundColor}
                        textColor={secondBtn.textColor}
                    />
                )}

                {thirdBtn && <Button type="primaryButton" title={thirdBtn.title} handleClick={thirdBtn.action} value={thirdBtn.value} />}

                <span className="width100">
                    {orderLines?.length > 0 && fourBtn ? (
                        <TooltipComponent
                            titleName={I18n.clear_all_item}
                            desc={I18n.do_you_want_to_clear_all_the_items_in_the_list}
                            yesCta={{action: fourBtn.action, title: I18n.yes}}
                            noCta={{
                                action: fourBtn.handletoggleTooltip,
                                title: I18n.no,
                            }}
                            toggle={fourBtn.toggle}
                            children={
                                <Button
                                    type="primaryButton"
                                    textColor={fourBtn.title == I18n.clear_all_items ? theme.brightOrange : theme.barclaysBlue}
                                    title={fourBtn.title}
                                    handleClick={fourBtn.handletoggleTooltip}
                                />
                            }
                        />
                    ) : null}
                </span>
            </div>
        </>
    );
};

export default PosHeaderButton;
