import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Dropdown} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {firstLetterCaptilize} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';

const List = ({userPermission, val, sales_tax, setToggleTaxModal, handleSeletedPaymentMethed}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    sales_tax = sales_tax?.length ? sales_tax[0] : sales_tax;

    const handletoggleModale = () => {
        setToggleTaxModal(x => !x);
    };

    const tableAction = [
        {
            title: I18n.apply_custom_tax,
            action: () => handletoggleModale(),
        },
    ];

    return (
        <>
            <TableBody className={`threeColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
                <div className="checkboxCol BoxWidth">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {firstLetterCaptilize(val.name || '')}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {sales_tax?.name || I18n.Default}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div className="BoxWidth justifyCenter" onClick={() => handleSeletedPaymentMethed(val)}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>
        </>
    );
};

const enhance = withObservables(['val'], ({val}) => ({
    sales_tax: val.sales_tax.observe(),
}));

export default enhance(List);
