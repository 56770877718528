import {firstLetterCaptilize} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import ListItem from './ListItem';

const VariantOptionsList = ({title, data, handleDelete}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <div className="selectoptionListInner" style={{borderColor: theme.posRightBg}}>
            <div className="selectoptionListHeader">
                <p className="marBot0 fontSize16 fontWeight500" style={{color: theme.white}}>
                    <span>{firstLetterCaptilize(title || '')}</span>
                    <span className="marLeft5">{I18n.options}</span>
                </p>
            </div>
            <div className="selectoptionListBody">
                {data?.map(val => (
                    <ListItem val={val} title={title} handleDelete={handleDelete} />
                ))}
            </div>
        </div>
    );
};

export default VariantOptionsList;
