import {useState, useEffect} from 'react';
import {formatTimeInSec, getColors} from '../../constants';
import {useAuthContext} from '../../context';

const useTimer = order => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const {deviceConfiguration} = useAuthContext();

    const orderTime = new Date(order?.created_at);
    const timeDifference = currentTime.getTime() - orderTime.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);

    const timerColor = getColors(secondsDifference, deviceConfiguration, order);
    const formattedTime = formatTimeInSec(secondsDifference);

    useEffect(() => {
        let intervalId;
        if (!timerColor?.stopTimer) {
            intervalId = setInterval(() => {
                setCurrentTime(new Date());
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [timerColor?.stopTimer]);

    return {
        timerColor,
        formattedTime,
    };
};

export default useTimer;
