import { createContext, useContext, useState } from "react";
import { blackTheme } from "../../constants";

export const ThemeContext = createContext({
    theme: blackTheme,
    setTheme: () => { }
});

export const ThemeProvider = (props) => {

    const [theme, setTheme] = useState(blackTheme);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export const useThemeContext = () => useContext(ThemeContext);