import easypaisa from '../../../../../assets/images/easypaisa.png';
import jazzcash from '../../../../../assets/images/jazzcash.png';
import {capitalizeFirstAlphabet} from '../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../context';

const WalletMethod = ({handleSelected}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const renderWalletRow = (text, image) => (
        <div onClick={() => handleSelected(text)} className="walletRow">
            <img src={image} style={{resizeMode: 'cover'}} alt={text} />
            <p className="marLeft20 marBot0 fontSize18 fontWeight400" style={{color: theme.white}}>
                {capitalizeFirstAlphabet(text)}
            </p>
        </div>
    );

    return (
        <div className="walletMainContainer">
            {renderWalletRow(I18n.easypaisa, easypaisa)}
            {renderWalletRow(I18n.jazzcash, jazzcash)}
        </div>
    );
};

export default WalletMethod;
