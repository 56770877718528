import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useCompany = account => {
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [company, setCompany] = useState();

    useEffect(() => {
        handleCustomer(id);
    }, [id]);

    const handleCustomer = async () => {
        let companyList = await account.getCompany(id).fetch();

        if (companyList.length) {
            let selectedCategory = companyList[0];

            setCompany(selectedCategory);
        }

        setIsLoading(false);
    };

    return {isLoading, company};
};

export default useCompany;
