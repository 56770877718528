const {innerHeight} = window;
export const BASE_URL = 'https://oscar-pos-api-stage.oscar.pk';
export const HASH_SALT = '$2a$10$sI9oxcJDNyGjoZl0jSqu/e';
export const INITIAL_PAGE = 0;
export const DEVICE_HEIGHT = innerHeight;
export const PHONE_REGEX = /^03[0-9]{9}$/;
export const DECIMAL_PATTERN_REGEX = /^\d*\.?\d*$/;
export const NumberRegex = /^[0-9]+$/;
export const ALPHABET_REGIX = /^[a-zA-Z]+$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9]+$/;
export const NAME_REGEX = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
export const CNIC_REGEX = /^[0-9]{13}$/;
export const PRICE_REGEX = /^\d+(\.\d+)?$/;
export const DATE_REGEX = /^\d{2} [a-zA-Z]{3} \d{4}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const total_list_space = DEVICE_HEIGHT - 290;
export const ITEMS_PER_PAGE = 20;

export const toSnakeCase = input => input?.replace(/ /g, '_').toLowerCase();
export const COUNTRY = [{name: 'Pakistan'}, {name: 'Saudi Arabia'}, {name: 'India'}, {name: 'Dubai'}, {name: 'UAE'}];
export const TABLE_SHAPES = [
    {
        name: 'Square',
    },
    {
        name: 'Round',
    },
];

export const SETTING_CONFIG_OPTIONS = [
    {name: 'open_item_keyboard', sub_title: 'Show open item keypad'},
    {name: 'item_service_library', sub_title: 'Select item or service library'},
    {name: 'price_edit', sub_title: 'Allow price edit'},
    {name: 'customer_details', sub_title: 'Mandate customer details'},
    {name: 'discount_button', sub_title: 'Show discount button'},
    {name: 'Margin_and_reports', sub_title: 'Margin and reports'},
];

export const SALARY_TYPE = [
    {
        name: 'Hourly',
    },
    {
        name: 'Weekly',
    },
    {
        name: 'Monthly',
    },
];

export const ROLE_PERMISSION = [
    {
        title: 'Point of Sale',
        permisionDetails: 'Allow team member to control inventory management',
        id: 'pos',
    },
    {
        title: 'Dashboard',
        permisionDetails: 'Allow team member to control dashboard',
        id: 'dashboard',
    },
    {
        title: 'Purchase Order',
        permisionDetails: 'Allow team member to control purchase orders',
        id: 'purchaseorder',
    },
    {
        title: 'Payments',
        permisionDetails: 'Allow team member to control payments',
        id: 'payments',
    },
    {
        title: 'Items',
        permisionDetails: 'Allow team member to control items',
        id: 'items',
    },
    {
        title: 'Order',
        permisionDetails: 'Allow team member to control orders',
        id: 'orders',
    },
    {
        title: 'Customer',
        permisionDetails: 'Allow team member to control customers',
        id: 'customers',
    },
    {
        title: 'Discounts',
        permisionDetails: 'Allow team member to control discounts',
        id: 'discounts',
    },
    {
        title: 'Supplier',
        permisionDetails: 'Allow team member to control suppliers',
        id: 'suppliers',
    },
    {
        title: 'Taxes',
        permisionDetails: 'Allow team member to control sales and purchase tax',
        id: 'tax',
    },
    {
        title: 'Teams',
        permisionDetails: 'Allow team member to control teams',
        id: 'team',
    },
    {
        title: 'Location',
        permisionDetails: 'Allow team member to control locations',
        id: 'location',
    },
    {
        title: 'Settings',
        permisionDetails: 'Allow team member to control settings',
        id: 'setting',
    },
    {
        title: 'Reports',
        permisionDetails: 'Allow team member to control reports',
        id: 'reports',
    },
];

export const CATEGORY_COLORS = [
    {
        name: 'gray',
        hexCode: '#898F93',
    },
    {
        name: 'blue',
        hexCode: '#1759B1',
    },
    {
        name: 'sea-green',
        hexCode: '#00B8AD',
    },
    {
        name: 'dark-green',
        hexCode: '#007052',
    },
    {
        name: 'light-green',
        hexCode: '#2BA72B',
    },
    {
        name: 'yellow',
        hexCode: '#FFB200',
    },
    {
        name: 'orange',
        hexCode: '#F75221',
    },
    {
        name: 'red',
        hexCode: '#C00000',
    },
    {
        name: 'brown',
        hexCode: '#574734',
    },

    {
        name: 'purple',
        hexCode: '#7E4CD5',
    },
];

export const ACTIVE = 'active';
export const VOID = 'void';
export const PREPARING = 'preparing';
export const READY = 'ready';
export const SERVE = 'serve';
export const PRINT_BILL = 'print_bill';
export const PAYMENT = 'payment';
export const COMPLETE = 'complete';

export const PROVINCE = [{name: 'Sindh'}, {name: 'Punjab'}, {name: 'KPK'}, {name: 'Balochistan'}];

export const LOCATION_TYPE = [
    {
        name: 'Physical location',
    },
    {
        name: 'Mobile location',
    },
];

export const ONBOARDING_KEYS = {
    Address: 'address',
    City: 'city',
    Province: 'province',
    'What is your business type?': 'type',
    'What kind of business are you?': 'kind',
    'Enter your business name': 'name',
    'Location type': 'location_type',
    'Location name': 'location_name',
};

export const Country_Name = [{name: 'Pakistan'}, {name: 'Saudi Arabia'}, {name: 'America'}, {name: 'United Kingdom'}];

export const CITY = [{name: 'Karachi'}, {name: 'Hyderabad'}, {name: 'Lahore'}];

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const SPLIT_BILL = [
    {
        title: 'Split bill by amount',
        subTitle: 'Splitting the bill among individuals based on specified amounts.',
        value: 'by_amount',
    },
    {
        title: 'Split into equal payments',
        subTitle: 'Split the total cost or bill evenly among multiple individuals.',
        value: 'in_equal_payments',
    },
];
export const kitchenOrders = [
    {
        notificationText: 'New order received',
        cashier: 'Mubashir',
        number: '01',
        type: 'Delivery',
        punchInTime: 1708326162956,
        punchInDate: '16 May 2023',
        status: 'on-time',
        items: [
            {
                id: 1,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,
                name: 'Chicken Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,
                name: 'Club sandwich',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,
                name: 'Fries',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,
                name: 'Crispo Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },

    {
        notificationText: 'New order received',
        cashier: 'Hassan',
        number: '02',
        punchInDate: '16 May 2023',
        type: 'Dine In',
        punchInTime: 1708326162966,
        status: 'delayed',
        items: [
            {
                id: 1,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
    {
        notificationText: 'Order cancelled',
        cashier: 'Farhan',
        number: '03',
        punchInDate: '16 May 2023',
        type: 'Take away',
        punchInTime: 1708326161956,
        status: 'late',
        items: [
            {
                id: 1,
                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
    {
        cashier: 'Mubashir',
        number: '05',
        punchInDate: '16 May 2023',
        notificationText: 'Order recalled',
        type: 'Delivery',
        punchInTime: 1708327482956,
        status: 'recalled',
        items: [
            {
                id: 1,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
    {
        cashier: 'Moiz',
        number: '04',
        notificationText: 'New order received',
        type: 'Delivery',
        punchInDate: '16 May 2023',
        punchInTime: 1708327162956,

        status: 'cancelled',
        items: [
            {
                id: 1,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['2 Cheese slice', '1 Crispy bun'],
            },
            {
                id: 2,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 3,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 4,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
            {
                id: 5,

                name: 'Cheese Burger',
                quantity: 1,
                extraInfo: ['1 Crispy bun'],
            },
        ],
    },
];
export const KDSIntegrations = [
    {
        icon: 'linkIcon',
        headText: 'Share Oscar kitchen display URL with member',
        paraText: 'Share Oscar kitchen display URL with member, then member will use this URL as a kitchen display.',
        linkText: 'https://oscar.pk/kds-login',
        linkRoute: 'https://oscar.pk/kds-login',
        iconHeight: '30',
        iconWidth: '30',
        copyCode: false,
    },
    {
        icon: 'lockIcon',
        headText: 'Sign in',
        paraText: 'Use this device code to sign in to Oscar kitchen display',
        linkText: 'Send device code',
        linkRoute: 'send-device-code',
        iconHeight: '30',
        iconWidth: '30',
        copyCode: true,
    },
    {
        icon: 'groupIcon',
        headText: 'Create pin code (Optional)',
        paraText: 'Use the pin code to add extra layer of security to your KDS device',
        linkText: 'Create pin code',
        linkRoute: 'create-pin',
        iconHeight: '26',
        iconWidth: '26',
        copyCode: false,
    },
    {
        icon: 'odooIcon',
        headText: 'Third party integration',
        paraText: 'Integrate your KDS with the Odoo Oscar POS',
        linkText: 'Connect Odoo',
        linkRoute: 'connect-odoo',
        iconHeight: '13',
        iconWidth: '40',
        copyCode: false,
    },
];

export const teamMembers = [
    {
        id: 123,
        fullName: 'Muhammad Moiz',
        username: 'Moiz',
        cnic: '4200027217632',
        password: 'pass123',
        phone: '03443457239',
        last_visited: 'Today',
        role: 'manager',
        created: '1 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 234,
        fullName: 'Uzair Hassan',
        username: 'Uzair',
        cnic: '4200027217732',
        password: 'pass123',
        phone: '03443457238',
        last_visited: 'Yesterday',
        role: 'admin',
        created: '2 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 345,
        fullName: 'Sherry Rahman',
        username: 'Sherry',
        cnic: '4200027217612',
        password: 'pass123',
        phone: '03443457229',
        last_visited: 'Last Month',
        role: 'admin',
        created: '3 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 456,
        fullName: 'Ghulam Mustafa',
        username: 'Ghulam',
        cnic: '4200027217633',
        password: 'pass123',
        phone: '03443457249',
        last_visited: 'Today',
        role: 'manager',
        created: '4 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 567,
        fullName: 'Asim Khan',
        username: 'Asim',
        cnic: '4200027217612',
        password: 'pass123',
        phone: '03443457230',
        last_visited: 'Yesterday',
        role: 'admin',
        created: '5 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 678,
        fullName: 'Obaid Ansari',
        username: 'Obaid',
        cnic: '4200027217632',
        password: 'pass123',
        phone: '03443457231',
        last_visited: 'Last month',
        role: 'head-cashier',
        created: '1 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
    {
        id: 789,
        fullName: 'Waleed Khan',
        username: 'Waleed',
        cnic: '4200027217612',
        password: 'pass123',
        phone: '03443457249',
        last_visited: 'Today',
        role: 'admin',
        created: '6 month ago',
        archive: false,
        cnic_back: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/4a87d307-a175-43f6-a9a2-09296099dba5.user',
        cnic_front: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/5eae8cc5-4760-40c4-9c39-24a47534be3f.user',
        user_image: 'https://oscarpos.s3.ap-southeast-1.amazonaws.com/6ba48abf-037e-4824-8d08-2c66721ea50e.user',
    },
];

export const dininTables = [
    {
        id: 1,
        name: 'Table 01',
        height: 100,
        width: 300,
        seats: 0,
        shape: 'square',
        xAxis: 100,
        yAxis: 300,
    },

    {
        id: 2,
        name: 'Table 02',
        height: 100,
        width: 300,
        seats: 0,
        shape: 'square',
        xAxis: 500,
        yAxis: 200,
    },
];

export const cockpitOrders = [
    {
        number: '0001',
        time: '04:14 PM/ 0mins',
        type: 'Dine in',
        table: 'Table 01',
        customer: 'Zubair',
        waiter: 'Karim',
        rider: 'Tanveer',
        status: 'Preparing',
        amount: '740',
    },
    {
        number: '0002',
        time: '02:14 PM/ 0mins',
        type: 'Delivery',
        table: 'Table 02',
        customer: 'Farhan',
        waiter: 'Ghulam',
        rider: 'Moiz',
        status: 'Preparing',
        amount: '840',
    },
    {
        number: '0003',
        time: '01:14 PM/ 0mins',
        type: 'Takeaway',
        table: 'Table 03',
        customer: 'Zubair',
        waiter: 'Karim',
        rider: 'Tanveer',
        status: 'Preparing',
        amount: '710',
    },
    {
        number: '0004',
        time: '09:14 PM/ 0mins',
        type: 'Dine in',
        table: 'Table 04',
        customer: 'Zubair',
        waiter: 'Obaid',
        rider: 'Farhan',
        status: 'Preparing',
        amount: '740',
    },
];

export const HEADER_VALUES = {
    pos: {
        name: 'POS',
        iconName: 'posNewIcon',
        viewBox: '0 0 24 24',
    },
    session: {
        name: 'POS',
        iconName: 'posNewIcon',
        viewBox: '0 0 24 24',
    },
    onboarding: {
        name: 'Setup POS',
        iconName: 'posNewIcon',
        viewBox: '0 0 24 24',
    },
    items: {
        name: 'Items',
        iconName: 'itemsIcon',
        viewBox: '0 0 18 18',
    },
    category: {
        name: 'Item Library',
        iconName: 'categoryIcon',
        viewBox: '0 0 18 18',
    },
    dashboard: {
        name: 'Dashboard',
        iconName: 'dashboardIcon',
        viewBox: '0 0 18 18',
    },
    suppliers: {
        name: 'Supplier',
        iconName: 'suppliesIcon',
        viewBox: '0 0 18 18',
    },
    company: {
        name: 'Suppliers',
        iconName: 'suppliesIcon',
        viewBox: '0 0 18 18',
    },
    vendor: {
        name: 'Suppliers',
        iconName: 'suppliesIcon',
        viewBox: '0 0 18 18',
    },
    orders: {
        name: 'Order history',
        iconName: 'ordersHistoryIcon',
        viewBox: '0 0 18 18',
    },
    orderdetail: {
        name: 'Order Detail',
        iconName: 'ordersHistoryIcon',
        viewBox: '0 0 18 18',
    },
    customers: {
        name: 'Customers',
        iconName: 'customersIcon',
        viewBox: '0 0 18 18',
    },
    discounts: {
        name: 'Discounts',
        iconName: 'discountIcon',
        viewBox: '0 0 18 18',
    },
    payment_method_tax: {
        name: 'Taxes',
        iconName: 'taxesIcon',
        viewBox: '0 0 18 18',
    },
    tax: {
        name: 'Taxes',
        iconName: 'taxesIcon',
        viewBox: '0 0 18 18',
    },
    invoice: {
        name: 'Invoice',
        iconName: 'invoiceIcon',
        viewBox: '0 0 18 18',
    },
    purchaseorder: {
        name: 'Purchase order',
        iconName: 'purchaseOrderNewIon',
        viewBox: '0 0 18 18',
    },
    report: {
        name: 'Reports',
        iconName: 'reportsIcon',
        viewBox: '0 0 18 18',
    },
    team: {
        name: 'Team',
        iconName: 'teamIcon',
        viewBox: '0 0 18 18',
    },
    setting: {
        name: 'Settings',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    device: {
        name: 'Devices',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    kitchendisplay: {
        name: 'Kitchen Display',
        iconName: 'posIcon',
        viewBox: '0 0 18 18',
    },
    displaysetting: {
        name: 'Display Setting',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
    timersetting: {
        name: 'Timer & Alerts',
        iconName: 'settingIcon',
        viewBox: '0 0 18 18',
    },
};
export const UNITS = [
    {name: 'Box'},
    {name: 'Bags'},
    {name: 'Bottles'},
    {name: 'Cans'},
    {name: 'Pieces'},
    {name: 'Pounds (lbs)'},
    {name: 'Ounces (oz)'},
    {name: 'Kilograms (kg)'},
    {name: 'Gallons (gal)'},
    {name: 'Liters (L)'},
    {name: 'Packs'},
    {name: 'Cartons'},
    {name: 'Slices'},
    {name: 'grams (g)'},
];

export const CSV_HEADER_VALUES = {
    customer: {
        create_heading: ['Name', 'Phone Number', 'Email', 'Date of brith', 'Country', 'Province', 'City', 'Address'],
        display_heading: ['Name', 'Phone #', 'Last visited', 'Visits', 'Created', 'Average spend', 'Credit amount', 'Total spent'],
    },
    discount: {
        create_heading: ['Discount Name', 'Select discount type (%/Rs)', 'Discount rate', 'Max discount cap'],
        display_heading: ['Name', 'Location', 'Rate', 'Type', 'Given'],
    },
    tax: {
        create_heading: ['Tax Name', 'Tax rate %', 'Tax Type (Sales / Purchase)', 'Tax behavior', 'Tax Calculation', 'Tax application'],
        display_heading: ['Name', 'Type', 'Rate', 'Tax application', 'Applied on # of items'],
    },
    item: {
        create_heading: ['Name', 'Category', 'Description (is Optional)', 'Price', 'Cost per item', 'Add Qty', 'Low stock alert (is Optional)', 'Barcode (is Optional)', 'Unit'],
        display_heading: ['Items', 'Category', 'Stock available', 'Low stock', 'Price', 'Image'],
    },
};

export const ORDER_HISTORY = [
    {
        number: '01',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '02',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Farhan',
        type: 'Dine In',
        status: 'On-time',
        timeTaken: '00:15:54',
    },
    {
        number: '03',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Bashir',
        type: 'Take away',
        status: 'Cancelled',
        timeTaken: '00:15:54',
    },
    {
        number: '04',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '05',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Hassan',
        type: 'Dine In',
        status: 'On-Time',
        timeTaken: '00:15:54',
    },
    {
        number: '06',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Take away',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '07',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Tanveer',
        type: 'Delivery',
        status: 'Cancelled',
        timeTaken: '00:15:54',
    },
    {
        number: '08',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Moiz',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
    {
        number: '09',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'On-Time',
        timeTaken: '00:15:54',
    },
    {
        number: '10',
        date: '16 May 2023',
        completedAt: '12:24:52 PM',
        waiter: 'Mubashir',
        type: 'Delivery',
        status: 'Delayed',
        timeTaken: '00:15:54',
    },
];
export const CSV_TEMPLATE_DUMMY_DATA = {
    customer: [
        {
            Name: 'Ali',
            'Phone Number': 3212356569,
            Email: 'Ali@gmail.com',
            'Date of brith': '18 jan 1999',
            Country: 'pakistan',
            Province: 'Sindh',
            City: 'Karachi',
            Address: 'F- 43, Block 8, Near Moti Masjid, Block 8 Clifton, Karachi, Karachi City, Sindh 75600',
        },
        {
            Name: 'Zain',
            'Phone Number': 3217646569,
            Email: 'Zain@gmail.com',
            'Date of brith': '17 jun 1999',
            Country: 'pakistan',
            Province: 'Sindh',
            City: 'Karachi',
            Address: 'Abyssinia Lines Abysenia Lines, Karachi, Karachi City, Sindh',
        },
    ],
    discount: [
        {
            'Discount Name': 'HBL Discount 10%',
            'Select discount type (%/Rs)': 'Percentage %',
            'Discount rate': 10,
            'Max discount cap': 2000,
        },
        {
            'Discount Name': 'UBL Discount 500',
            'Select discount type (%/Rs)': 'Amount Rs.',
            'Discount rate': 500,
            'Max discount cap': 3000,
        },
    ],
    tax: [
        {
            'Tax Name': 'GSt10',
            'Tax rate %': 10,
            'Tax Type (Sales / Purchase)': 'sales',
            'Tax behavior': 'exclusive',
            'Tax Calculation': 'Calculate tax based on the pre discount price',
            'Tax application': 'On all items',
        },
        {
            'Tax Name': 'GSt30',
            'Tax rate %': 30,
            'Tax Type (Sales / Purchase)': 'sales',
            'Tax behavior': 'inclusive ',
            'Tax Calculation': 'Calculate tax based on the post discount price',
            'Tax application': 'Select items',
        },
        {
            'Tax Name': 'purchase30',
            'Tax rate %': 30,
            'Tax Type (Sales / Purchase)': 'purchase',
            'Tax behavior': '-',
            'Tax Calculation': '-',
            'Tax application': '-',
        },
    ],
    item: [
        {
            Name: 'Apple',
            Category: 'fruit',
            'Description (is Optional)': 'golden Apple',
            Price: 500,
            'Cost per item': 200,
            'Add Qty': 20,
            'Low stock alert (is Optional)': 5,
            'Barcode (is Optional)': 20039994,
            Unit: 'Box',
        },
        {
            Name: 'orange',
            Category: 'fruit',
            'Description (is Optional)': 'fresh orange',
            Price: 400,
            'Cost per item': 250,
            'Add Qty': 30,
            'Low stock alert (is Optional)': 10,
            'Barcode (is Optional)': 20039995,
            Unit: 'Cartons',
        },
    ],
};

export const dateFilterMapping = {
    Today: 'today',
    Yesterday: 'yesterday',
    'This week': 'this_week',
    'Last week': 'last_week',
    'This month': 'this_month',
    'Last month': 'last_month',
    'This year': 'this_year',
    'Last year': 'last_year',
};

export const DateFilterOption = [
    {name: 'Today'},
    {name: 'Yesterday'},
    {name: 'This week'},
    {name: 'Last week'},
    {name: 'This month'},
    {name: 'Last month'},
    {name: 'This year'},
    {name: 'Last year'},
];