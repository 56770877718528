import { Model } from "@nozbe/watermelondb";
import { ROLE_SCHEMA } from "../schema";
import {
  field,
  text,
  relation,
  children,
  writer,
} from "@nozbe/watermelondb/decorators";

export default class Role extends Model {
  static table = ROLE_SCHEMA;

  static associations = {
    account: { type: "belongs_to", key: "account_id" },
    user_business: { type: "has_many", foreignKey: "role_id" },
    module: { type: "has_many", foreignKey: "role_id" },
  };

  @text("name") name;

  @relation("account", "account_id") account;
  @children("user_business") user_business;
  @children("module") module;

  @writer async deleteRole(details) {
    return await this.update((role) => {
      role.name = details.name;
    });
  }
}
