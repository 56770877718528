import { CreateDiscountComponent, CustomContainer } from "../../../../common";

const CreateDicounts = () => {
  return (
    <>
      <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox  createCustomerContainer"
      >
        <CreateDiscountComponent />
      </CustomContainer>
    </>
  );
};

export default CreateDicounts;
