import { useNavigate } from "react-router-dom";
import { useLanguageContext, useThemeContext } from "../../../context";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Dropdown, SearchBox } from "../../common";

const SelectHeader = ({ rightCta, search, filterCta }) => {
  const navigate = useNavigate();

  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <section
      style={{ background: theme.topBarBG }}
      className="topBarMainCont"
    >
      <Container fluid>
        <Row>
          <Col className="padRight0" md={10}>
            <div className="topBarLeftSide">
              <SearchBox {...search} />
            </div>
          </Col>
          <Col className="padLeft0" md={2}>
            <div className="topBarRightSide">
              {rightCta && (
                <Button
                  className={`headerRightCta marLeft10 fontWeight600 fontSize14 cursorPointer width75`}
                  background={theme.brightGreen}
                  txtColor={theme.white}
                  title={rightCta.title}
                  handleClick={rightCta.action}
                />
              )}
              {filterCta && (
                <div className="filterBoxMain">
                  <Dropdown
                    dropDown={{
                      name: filterCta.name,
                      fill: filterCta.fill,
                      width: filterCta.width,
                      height: filterCta.height,
                      viewBox: filterCta.viewBox,
                      title: filterCta.title,
                      option: filterCta.option,
                    }}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SelectHeader;
