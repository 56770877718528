import { useLanguageContext, useThemeContext } from "../../../../../context";
import { NonIdealScreen } from "../../../../common";

const ReportList = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleCreateReport = () => {
    // handleCreateReport
  };

  const handleImportReport = () => {
    // handleImportReport
  };

  return (
    <NonIdealScreen
      heading={I18n.add_report}
      subHeading={I18n.create_report_and_add_to_sale}
      paragraph={I18n.before_you_open_your_store_first_you_need_some_report}
      name="reportsIcon"
      fill={theme.white}
      primaryCta={{
        title: I18n.create_report,
        action: handleCreateReport,
      }}
      secondaryCta={{
        title: I18n.import_report,
        action: handleImportReport,
      }}
      isMultiStep={false}
    />
  );
};

export default ReportList;
