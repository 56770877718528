import React, { useState } from "react";
import { Button, TextField } from "../../../../common";
import { useLanguageContext, useThemeContext } from "../../../../../context";
import { toSnakeCase } from "../../../../../constants";

const ThirdPartyIntegration = ({ handleConnectOdoo, errors, setErrors }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [url, setUrl] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const onChange = (label, val) => {
    if (label == I18n.username) {
      setUsername(val);
    } else if (label == I18n.password) {
      setPassword(val);
    } else if (label == I18n.url) {
      setUrl(val);
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }
      return updatedErrors;
    });
  };

  const handleConnect = () => {
    const formData = {
      odoo_username: username,
      odoo_password: password,
      odoo_url: url,
    };
    handleConnectOdoo(formData);
  };

  return (
    <div className="createDeviceWrapper">
      <div className="thirdPartIntegrationWrapper">
        <p
          className="fontSize24 textCenter fontWeight600 marBot10"
          style={{ color: theme.white }}
        >
          {I18n.third_party_integration}
        </p>
        <p
          className="fontSize14 textCenter marBot30"
          style={{ color: "#4E515A" }}
        >
          {I18n.integrate_kds_with_odoo}
        </p>
        <TextField
          onChange={onChange}
          error={errors?.["username"]}
          label={I18n.username}
          placeholder={I18n.username}
          value={username}
        />

        <TextField
          type={showPassword ? "text" : "password"}
          onChange={onChange}
          error={errors?.["password"]}
          label={I18n.password}
          value={password}
          placeholder={I18n.password}
          suffix="eyeIcon"
          suffix_fill={"blackFont"}
          suffix_width={"22"}
          suffix_height={"16"}
          suffix_viewBox={"0 0 22 16"}
          suffixClick={() => setShowPassword((prev) => !prev)}
        />

        <TextField
          onChange={onChange}
          error={errors?.["url"]}
          label={I18n.url}
          placeholder={I18n.url}
          value={url}
        />
        <div className="flex verticalCenter horizontalCenter width100">
          <Button
            className={`width100 fontWeight600 fontSize16 cursorPointer marTop20`}
            title={I18n.connect_odoo}
            handleClick={handleConnect}
          />
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyIntegration;
