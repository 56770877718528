import Icon from "../../../assets/icons";
import { formatNum } from "../../../constants";
import { useThemeContext } from "../../../context";

const List = ({ discounts, handleClick, order }) => {
  const { theme } = useThemeContext();

  let isSelected = order._raw.discount_id == discounts._raw.id ? true : false;
  const handleSelect = (discounts) => {
    if (isSelected) {
      handleClick("remove_discount");
    } else {
      handleClick(discounts);
    }
  };
  return (
    <div
      onClick={() => handleSelect(discounts)}
      className="orderDiscountModalList"
    >
      <p className="marBot0" style={{ color: theme.text }}>
        <span className="fontSize16 fontWeight500">{discounts.name}</span>
        <span
          className="fontSize14  fontWeight400 marLeft15"
          style={{ color: theme.darkGrayTwo }}
        >
          {discounts?.type == "amount"
            ? `Rs. ${formatNum(discounts.limit)}`
            : `${formatNum(discounts.limit)}%`}
        </span>
      </p>

      <p className="marBot0">
        {isSelected && (
          <span>
            <Icon
              name="tickCircleIcon"
              fill={theme.brightGreen}
              width={"24"}
              height={"24"}
              viewBox={"0 0 24 24"}
            />
          </span>
        )}
      </p>
    </div>
  );
};

export default List;
