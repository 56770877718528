import { CreateCategoryComponent } from "../../../../common";

const CreateCategories = () => {
  return (
    <>
      <CreateCategoryComponent />
    </>
  );
};

export default CreateCategories;
