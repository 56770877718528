import { Outlet } from "react-router-dom";
import { useAuthContext, useSessionContext } from "../../../context";
import { RequireSession } from "../../../services";
import { useEffect, useState } from "react";
import { PosPinModal } from "../../common";
import RequireOrder from "../../../services/RequireOrder";

const KdsPin = () => {
  const { deviceConfiguration } = useAuthContext();
  const [pinModalToggle, setPinModalToggle] = useState(false);

  useEffect(() => {
    deviceConfiguration ? setPinModalToggle(false) : setPinModalToggle(true);
  }, []);

  return (
    <>
      <PosPinModal
        setToggle={setPinModalToggle}
        toggle={pinModalToggle}
        isKdsPin={true}
      />
      <Outlet />
    </>
  );
};

export default KdsPin;
