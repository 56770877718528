import Icon from "../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../context";
import NameSlice from "../NameSlice";

const Listitem = ({ customer, onPress }) => {
  const { theme } = useThemeContext();

  return (
    <div
      className="selectCustomerInnerRow cursorPointer"
      onClick={() => onPress(customer)}
      style={{ borderColor: theme.posRightBg }}
    >
      <div className="customerInitials">
        <NameSlice value={customer?.name || ""} />
      </div>
      <div className="customerDetail">
        <p className="marBot0 fontSize18" style={{ color: theme.white }}>
          {customer.name}
        </p>
        <p className="marBot0 fontSize14" style={{ color: theme.lightGrayTwo }}>
          {customer.phone_number}
        </p>
      </div>
      <div className="customeraction">
        <Icon
          name="forwardIcon"
          fill={theme.white}
          width="9"
          height="15"
          viewBox={"0 0 9 15"}
        />
      </div>
    </div>
  );
};

export default Listitem;
