import { Toast } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../context";
import { useState } from "react";
import Icon from "../../../assets/icons";
import cnicfront from "../../../assets/images/cnicfront.png";
import cnicback from "../../../assets/images/cnicback.png";

const CnicUploader = ({
  handleCnicUpload,
  cnicImage = false,
  cnictype = false,
  error,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <div className="cnicUploadConatiner">
      <label for={cnictype == "front" ? "file-ip-2" : "file-ip-3"}>
        <div className="cnicUploadLeft">
          <img
            src={cnictype == "front" ? cnicfront : cnicback}
            alt=""
            title=""
          />
          {cnicImage ? (
            <div className="tickIconBg">
              <Icon
                name="tickIcon"
                fill={theme.white}
                width={10}
                height={6}
                viewBox={"0 0 14 10"}
              />
            </div>
          ) : null}
        </div>
        <div className="cnicUploadRight">
          <p
            className="fontSize14 marBot0 fontWeight400"
            style={{ color: theme.white }}
          >
            {cnictype == "front" && cnicImage
              ? I18n.cnic_front_uploaded
              : cnictype == "front"
              ? I18n.upload_cnic_front
              : cnictype == "back" && cnicImage
              ? I18n.cnic_back_uploaded
              : cnictype == "back"
              ? I18n.upload_cnic_back
              : null}
          </p>
        </div>
      </label>

      <input
        id={cnictype == "front" ? "file-ip-2" : "file-ip-3"}
        type="file"
        accept="image/*"
        onChange={handleCnicUpload}
      />
      <p className="inputError">{error ? error : null}</p>
    </div>
  );
};

export default CnicUploader;
