import {Container} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import AppModal from '../AppModal';
import {CreateRoleComponent, CustomContainer} from '../../common';

const CreateRoleModal = ({toggle, setToggle, handleCreateRole, roles}) => {
    const {I18n} = useLanguageContext();

    const handleClose = event => {
        setToggle(false);
    };

    return (
        <AppModal className="createCustomerModal" toggle={toggle} handleClose={handleClose} title={I18n.create_role}>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateRoleComponent handleSelect={handleCreateRole} roles={roles} handleClose={handleClose} />
            </CustomContainer>
        </AppModal>
    );
};

export default CreateRoleModal;
