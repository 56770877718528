import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import NewAppModal from '../../common/NewAppModal';
import RadioButton from '../RadioButton';
import {SPLIT_BILL} from '../../../constants';
import SplitBillByAmount from './SplitBillByAmount';
import SplitBillIntoEqualPayments from './SplitBillIntoEqualPayments';

const SplitBillModal = ({toggle, setToggle, totalBill, order, business}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [selectedBillType, setSelectedBillType] = useState();
    const [payments, setPayments] = useState([]);
    const [billTypes, setBillTypes] = useState(SPLIT_BILL);

    const addlabelsToBillTypes = () => {
        const modifiedTypes = billTypes?.map(item => ({
            ...item,
            label: (
                <div className="splitBillWrapper">
                    <p className="marBot0 marTop0 fontSize14 fontWeight400 white">{item.title}</p>
                    <p className="marBot0 marTop0 fontSize12 fontWeight400 darkGray">{item.subTitle}</p>
                </div>
            ),
            value: item.title,
        }));

        setBillTypes(modifiedTypes);
    };

    useEffect(() => {
        addlabelsToBillTypes();
    }, []);

    function handleClose(event) {
        setToggle(false);
        setSelectedBillType('');
    }

    const handleChange = async e => {
        const value = e?.target.value;
        setSelectedBillType(value);
    };

    const hanldeContinue = () => {
        const paymentPayload = payments.map(payment => ({
            business,
            order,
            amount: Number(payment.amount),
            paymentNumber: payment.paymentNum,
            status: 'pending',
            expiry_date: new Date().getTime(),
            started_at: new Date().getTime(),
            created_at: new Date().getTime(),
        }));
    };

    return (
        <>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={toggle}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleClose()}
                primaryCta={
                    selectedBillType
                        ? {
                              title: 'Continue',
                              action: () => hanldeContinue(true),
                          }
                        : null
                }
                cancelCta={
                    selectedBillType
                        ? {
                              title: I18n.cancel,
                              action: () => handleClose(),
                          }
                        : null
                }
                title="Split Bill">
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        {selectedBillType === 'Split bill by amount' ? (
                            <SplitBillByAmount totalBill={totalBill} payments={payments} setPayments={setPayments} />
                        ) : selectedBillType === 'Split into equal payments' ? (
                            <SplitBillIntoEqualPayments />
                        ) : (
                            <>
                                <p className="fontSize20 marBot20" style={{color: theme.white}}>
                                    Split bill types
                                </p>

                                <RadioButton options={billTypes} className="billTypesWrapper" selectedSize={selectedBillType} handleChange={handleChange} showCustomBtn />
                            </>
                        )}
                    </div>
                </div>
            </NewAppModal>
        </>
    );
};

export default SplitBillModal;
