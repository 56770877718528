import React, { useEffect, useState } from "react";
import Icon from "../../../../../assets/icons";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../context";
import {
  DEVICE_HEIGHT,
  getDayComparison,
  kitchenOrders,
} from "../../../../../constants";
import NotificationBar from "./NotificationBar";
import { NonIdealScreen } from "../../../../common";
import { kitchenNotifications } from "../../../../../api";

const KitchenNotifications = () => {
  const { theme } = useThemeContext();
  const [kitchenNotification, setKitchenNotification] = useState([]);
  const { I18n } = useLanguageContext();
  const { deviceConfiguration } = useAuthContext();

  const getKitchenNotifications = async () => {
    try {
      const notificationsPayload = {
        secret_key: deviceConfiguration?.secret_key,
        days: 2,
      };
      const response = await kitchenNotifications(notificationsPayload);
      setKitchenNotification(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getKitchenNotifications();
  }, []);

  return (
    <div
      className="notificationsWrapper"
      style={{ height: DEVICE_HEIGHT - 250 }}
    >
      {kitchenNotification?.length > 0 ? (
        kitchenNotification?.map((notify) => {
          return (
            <>
              <p className="notificationDate fontSize24 fontWeight600 marBot0 white">
                {getDayComparison(notify?.date)}
              </p>
              <p className="notificationHeadSeperator"></p>

              {notify?.notifications?.map((notification) => (
                <NotificationBar notification={notification} />
              ))}
            </>
          );
        })
      ) : (
        <NonIdealScreen
          subHeading={I18n.notifications}
          paragraph={I18n.new_notifications_will_show_up_here}
          name="notificationIcon"
          fill={"lightGrayTwo"}
          isMultiStep={false}
          viewBox={"0 0 27 30"}
          height="50"
          width="50"
        />
      )}
    </div>
  );
};

export default KitchenNotifications;
