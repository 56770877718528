import {TextField} from '@mui/material';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Button} from '../../../../common';
import {useOrderCalculation} from '../../../../../hooks';
import Icon from '../../../../../assets/icons';
import {formatNum} from '../../../../../constants';

const CardMethod = ({givenPosDiscount, total, change, handleDone, orderLines}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <div className="cardContainer">
            <div className="billCardmount">
                <p className="marBot20 marTop10">
                    <Icon name="cardPaymentIcon" fill={theme.white} width={'151'} height={'140'} viewBox={'0 0 151 140'} />
                </p>
                <p className="marBot20 fontSize24 fontWeight400" style={{color: theme.white}}>
                    {I18n.swipe_your_card}
                </p>
                <p className="marBot0 fontSize24 fontWeight400" style={{color: theme.white}}>
                    {I18n.bill_amount}
                </p>
                <p className="marBot0 fontSize80 fontWeight600" style={{color: theme.white}}>
                    <span>Rs.</span>
                    <span>{formatNum(total)}</span>
                </p>
            </div>

            <div className="billScreenChangeButton">
                <Button
                    backgroundColor={theme.brightGreen}
                    txtColor={theme.white}
                    className={`width60 fontWeight600 fontSize18 cursorPointer`}
                    title={I18n.record_payment}
                    disabled={orderLines?.length ? false : true}
                    handleClick={() => handleDone('card', total, change, givenPosDiscount)}
                />
            </div>
        </div>
    );
};

export default CardMethod;
