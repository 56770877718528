import { Model } from "@nozbe/watermelondb";
import { MODIFIER_OPTION_SCHEMA } from "../schema";
import {
  field,
  text,
  relation,
  date,
  writer,
} from "@nozbe/watermelondb/decorators";

export default class ModifierOption extends Model {
  static table = MODIFIER_OPTION_SCHEMA;

  static associations = {
    modifier: { type: "belongs_to", key: "modifier_id" },
  };

  @text("name") name;
  @text("price") price;
  @field("in_stock") in_stock;
  @field("archive") archive;
  @date("created_at") created_at;

  @relation("modifier", "modifier_id") modifier;

  @writer async archiveModifierOption(status = true) {
    return await this.update((modifierOpt) => {
      modifierOpt.archive = status;
    });
  }
}
