import {Model} from '@nozbe/watermelondb';
import {text, relation, date, writer, field} from '@nozbe/watermelondb/decorators';
import {PAYMENT_METHOD_SCHEMA} from '../schema';

export default class PaymentMethod extends Model {
    static table = PAYMENT_METHOD_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        sales_tax: {type: 'belongs_to', key: 'sales_tax_id'},
    };

    @text('name') name;
    @field('placement') placement;
    @date('created_at') created_at;

    @relation('account', 'account_id') account;
    @relation('sales_tax', 'sales_tax_id') sales_tax;

    @writer async updateSalesTax(SalesTax) {
        return await this.update(payment_method => {
            payment_method.sales_tax.set(SalesTax);
        });
    }
}
