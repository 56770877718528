import React, { useState } from "react";
import Icon from "../../../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../../../context";

const List = ({ item, handleDeviceStep, setShowMessage, deviceData }) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();
  const [showPin, setShowPin] = useState(false);

  let {
    icon,
    headText,
    paraText,
    linkText,
    linkRoute,
    iconHeight,
    iconWidth,
    copyCode,
  } = item;

  const { secret_key, pin, odoo_active, pin_active } = deviceData || {};

  linkText =
    linkRoute === "connect-odoo" && odoo_active ? I18n.connected : linkText;
  const isDisabledFunction =
    linkRoute === "connect-odoo" && odoo_active ? true : false;

  const subStringCode01 = secret_key?.substring(0, 4);
  const subStringCode02 = secret_key?.substring(4, 8);
  const subStringCode03 = secret_key?.substring(8, 12);

  const handleCopyClick = (val) => {
    navigator.clipboard
      .writeText(val)
      .then(() => {
        setShowMessage({ visible: true, message: "Code copied successfully" });
      })
      .catch((error) => {
        console.error("Error copying code to clipboard", error);
        setShowMessage({ visible: false, message: "" });
      });
  };

  return (
    <>
      <div className="deviceListWrapper">
        <div className="kdsIconBox">
          <Icon
            name={icon}
            width={iconWidth}
            height={iconHeight}
            viewBox={`0 0 ${iconWidth} ${iconHeight}`}
          />
        </div>
        <div className="listTextWrapper" style={{ maxWidth: "50%" }}>
          <p
            className="marBot0 fontSize16 fontWeight600"
            style={{ color: theme.white }}
          >
            {headText}
          </p>
          <p
            className="marBot0 fontSize14 fontWeight400"
            style={{ color: "#4E515A" }}
          >
            {paraText}
          </p>

          <p
            className={`${
              linkRoute === "create-pin" && pin_active ? "hide" : ""
            } marBot0 fontSize14 fontWeight400 cursorPointer`}
            style={{ color: theme.barclaysBlue }}
            onClick={() => !isDisabledFunction && handleDeviceStep(linkRoute)}
          >
            {linkText}
          </p>
        </div>
        {linkRoute === "send-device-code" ? (
          <div className="copyCodeBox">
            <span
              className="fontSize20 fontWeight600"
              style={{ color: theme.white }}
            >
              {subStringCode01}
            </span>
            <span
              className="fontSize20 fontWeight600"
              style={{ color: theme.white }}
            >
              {subStringCode02}
            </span>
            <span
              className="fontSize20 fontWeight600"
              style={{ color: theme.white }}
            >
              {subStringCode03}
            </span>
            <span
              className="fontSize14 cursorPointer"
              style={{ color: theme.barclaysBlue }}
              onClick={() =>
                handleCopyClick(
                  `${subStringCode01}${subStringCode02}${subStringCode03}`
                )
              }
            >
              {I18n.copy}
            </span>
          </div>
        ) : linkRoute === "create-pin" && pin ? (
          <div className="width40 pinWrapper">
            <div className="copyPinCodeBox">
              <div className="width50 textCenter">
                <span
                  className="fontSize20 fontWeight600"
                  style={{ color: theme.white }}
                >
                  {showPin ? pin : " * * * * "}
                </span>
              </div>

              <div className="width50 flex verticalCenter gap20">
                <span
                  className="eyeIconWrapper cursorPointer"
                  onClick={() => setShowPin((prev) => !prev)}
                >
                  <Icon
                    name={showPin ? "showEyeIcon" : "hideEyeIcon"}
                    fill={theme.white}
                    width={"16"}
                    height={"16"}
                    viewBox={"0 0 16 16"}
                  />
                </span>

                <span
                  className="fontSize14 cursorPointer"
                  style={{ color: theme.barclaysBlue }}
                  onClick={() => handleCopyClick(pin)}
                >
                  {I18n.copy}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default List;
