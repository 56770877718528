import withObservables from '@nozbe/with-observables';
import {formatNum} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';

const BillAmountBox = ({className, subTotal, total, tax, givenPosDiscount, giveItemLevelDiscount, redeemAmount}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <div
            className={`billAmountBoxMain ${className}`}
            style={{
                background: theme.topBarBG,
                height: 'min-content',
            }}>
            <div className="billAmountRow billAmountTotal" style={{borderColor: theme.darkgrayBorderbg}}>
                <p className="marBot0 fontSize20" style={{color: theme.white}}>
                    {I18n.bill_amount}
                </p>
                <p className="marBot0 fontSize24 fontWeight500" style={{color: theme.white}}>
                    Rs. {formatNum(total)}
                </p>
            </div>

            <div className="billAmountRow billAmountBottomRow">
                <p className="marBot0 fontSize14" style={{color: theme.white}}>
                    {I18n.sub_total}
                </p>
                <p className="marBot0 fontSize14 fontWeight500" style={{color: theme.white}}>
                    Rs. {formatNum(subTotal)}
                </p>
            </div>

            {tax ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.taxes}
                    </p>
                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.white}}>
                        <span>{`Rs. ${formatNum(tax)}`}</span>
                    </p>
                </div>
            ) : null}

            {givenPosDiscount || giveItemLevelDiscount ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.discount}
                    </p>
                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.red}}>
                        <span>{`-Rs. ${formatNum(givenPosDiscount + giveItemLevelDiscount)}`}</span>
                    </p>
                </div>
            ) : null}

            {redeemAmount ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.redeem_points}
                    </p>
                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.red}}>
                        <span>{`-Rs. ${formatNum(redeemAmount)}`}</span>
                    </p>
                </div>
            ) : null}
        </div>
    );
};

export default BillAmountBox;
