import { AVATAR_COLORS } from "../../../constants";
import { useThemeContext } from "../../../context";

const NameSlice = ({ value }) => {
  const { theme } = useThemeContext();
  return (
    <p
      style={{
        background: AVATAR_COLORS[value.slice(0, 1).toUpperCase()],
      }}
    >
      {value.slice(0, 1).toUpperCase()}
    </p>
  );
};

export default NameSlice;
