import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useSalesTax = (account) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [tax, setTax] = useState();

  useEffect(() => {
    handleTax(id);
  }, [id]);

  const handleTax = async () => {
    let taxList = await account.getSalesTax(id).fetch();

    if (taxList.length) setTax(taxList[0]);

    setIsLoading(false);
  };

  return { isLoading, tax };
};

export default useSalesTax;
