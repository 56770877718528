import React from "react";
import { formatNum } from "../../../../constants";
import { useLanguageContext, useThemeContext } from "../../../../context";
import JazzCashLogo from "../../../../assets/images/JazzCashLogo.png";
import verifiedAccount from "../../../../assets/images/verifiedAccount.png";

const PaymentStep4 = ({ data, selectedWallet }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <div className="paymentStep4Conatiner">
      <p
        className="fontSize20  marTop30 fontWeight400"
        style={{ color: theme.white }}
      >
        {I18n.customer_information}
      </p>

      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>{I18n.account_title}</span>
        <span style={{ color: theme.white }}>
          {data?.account_title || "zubair"}
        </span>
      </p>
      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>{I18n.account_number}</span>
        <span style={{ color: theme.white }}>
          {data?.account_number || "03472003439"}
        </span>
      </p>
      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>{I18n.amount}</span>
        <span style={{ color: theme.white }}>{`Rs. ${formatNum(420)}`} </span>
      </p>
      <p className="fontSize16 marTop30 fontWeight400 paymentStepRow">
        <span style={{ color: theme.lightGrayTwo }}>{I18n.back}</span>
        <span style={{ color: theme.white }}>
          {selectedWallet || "JazzCash"}
        </span>
      </p>
      <div className="verifiedAccountTageline">
        <img
          src={verifiedAccount}
          alt="verifiedAccount"
          title="verifiedAccount"
        />
        <p
          className="fontSize14 fontWeight400 marBot0 marLeft5 marRight5"
          style={{
            color: theme.white,
          }}
        >
          {I18n.verified_account_by}
        </p>
        <img src={JazzCashLogo} alt="JazzCashLogo" title="JazzCashLogo" />
      </div>
    </div>
  );
};

export default PaymentStep4;
