import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useWaiter = (business) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [waiter, setWaiter] = useState();
  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    handleWaiter(id);
  }, [id]);

  const handleWaiter = async () => {
    let waiterList = await business.getWaiter(id).fetch();

    if (waiterList.length) {
      let selectedWaiter = waiterList[0];
      let selectedOrderCount =
        await selectedWaiter.getCompletedOrders.fetchCount();
      setOrderCount(selectedOrderCount);
      setWaiter(selectedWaiter);
    }
    setIsLoading(false);
  };

  return { isLoading, waiter, orderCount };
};

export default useWaiter;
