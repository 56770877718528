import {calculateTotal, formatNum} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';

const POAmountBox = ({className, itemData, setOrderDiscountModal, orderLevelDiscount, disable}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {subTotal, totalDiscount, totalTax, total} = calculateTotal(itemData, orderLevelDiscount);

    const showOrderLevelDiscountModal = () => {
        if (disable) return;
        setOrderDiscountModal(true);
    };

    return (
        <div className={`poAmountboxmain ${className}`} style={{}}>
            <div className="billAmountInnerRow ">
                <p className="marBot0 fontSize16" style={{color: theme.white}}>
                    {I18n.sub_total}
                </p>
                <p className="marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                    Rs. {formatNum(subTotal)}
                </p>
            </div>
            <div className="billAmountInnerRow">
                <p className="marBot0 fontSize16" style={{color: theme.white}}>
                    {I18n.discount}
                </p>
                <p className="marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                    Rs. {formatNum(totalDiscount)}
                </p>
            </div>
            <div className="billAmountInnerRow">
                <p className="marBot0 fontSize16" style={{color: theme.white}}>
                    {I18n.tax_value}
                </p>
                <p className="marBot0 fontSize16 fontWeight400 billAmountInnerRowRight" style={{color: theme.white}}>
                    <span>Rs. {formatNum(totalTax)}</span>
                </p>
            </div>
            <div className="billAmountInnerRow ">
                <p className="marBot0 fontSize16 fontWeight400" style={{color: theme.barclaysBlue, cursor: 'pointer'}} onClick={showOrderLevelDiscountModal}>
                    {I18n.add_global_discount_amount}
                </p>
                <p className="marBot0 fontSize16 fontWeight400 billAmountInnerRowRight" style={{color: theme.white}}>
                    <span>
                        {orderLevelDiscount?.type === 'amount' && 'Rs. '}
                        {orderLevelDiscount?.value}
                        {orderLevelDiscount?.type === 'percentage' && ' %'}
                    </span>
                </p>
            </div>

            <div className="poAmountBottomRow" style={{backgroundColor: theme.blackPearl}}>
                <p className="marBot0 fontWeight400 fontSize18" style={{color: theme.white}}>
                    {I18n.total_amount}
                </p>
                <p className="marBot0 fontSize18 fontWeight400" style={{color: theme.white}}>
                    Rs. {formatNum(total)}
                </p>
            </div>
        </div>
    );
};

export default POAmountBox;
