import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {firstLetterCaptilize, formatNum} from '../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';

const List = ({discount, handleChecked, selected, setSelected, filter, userPermission}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [archiveModal, setArchiveModal] = useState(false);

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleEdit = () => {
        if (!userPermission?.canCrud) return;
        navigate(`${discount.id}/edit`);
    };

    const handleArchive = async discount => {
        setArchiveModal(false);
        let data = discount?.id;
        if (selected[data]) {
            setSelected(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }
        await discount.archiveDiscount(discount?.archive ? false : true);
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: handleEdit,
        },
        {
            title: I18n[discount.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody className={`sixColumn`} style={{borderColor: theme.posRightBg}} onClick={handleEdit}>
                <div
                    className="checkboxCol"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    title={discount.name}>
                    <CheckBox
                        checkboxLabel={
                            <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.text}}>
                                {firstLetterCaptilize(discount.name || '--')}
                            </p>
                        }
                        onChange={() => handleChecked(discount)}
                        checked={selected?.[discount?.id]}
                    />
                </div>

                <div className="BoxWidth justifyCenter" title={discount.type}>
                    <p className="fontSize14" style={{color: theme.white}}>
                        {firstLetterCaptilize(discount?.type || '--')}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}} title={discount?.limit}>
                        {discount.type == 'percentage' ? `${formatNum(discount?.limit)}%` : `Rs. ${formatNum(discount?.limit)}`}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}} title={formatNum(discount?.total_given)}>
                        Rs. {formatNum(discount?.total_given)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}} title={formatNum(discount?.max_discount_cap)}>
                        {discount.max_discount_cap || '--'}
                    </p>
                </div>
                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                title={filter == I18n.all_discounts ? I18n.archive_discount : I18n.unarchive_discount}
                description={I18n[discount.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive'] + ' ' + discount.name + '?'}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => handleArchive(discount),
                    isDelete: true,
                }}
            />
        </>
    );
};

export default List;
