import { Col } from "react-bootstrap";
import { DEVICE_HEIGHT } from "../../../constants";

import { useThemeContext } from "../../../context";

const LeftPane = ({ children }) => {
  const { theme } = useThemeContext();
  return (
    <Col md={6} className="noPadding positioRelative">
      <div
        className="posleftSection"
        style={{
          background: theme.topBarBG,
          height: DEVICE_HEIGHT - 45,
          padding: "0px",
          borderRight: `1px solid ${theme.inputBorder}`,
        }}
      >
        {children}
      </div>
    </Col>
  );
};

export default LeftPane;
