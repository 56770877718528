import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import List from './List';
import withObservables from '@nozbe/with-observables';
import NonIdealScreen from '../NonIdeal';
import {useNavigate} from 'react-router-dom';
import {PosAppModal} from '../V2';
import SearchBox from '../SearchBox';
import {useSearch} from '../../../hooks';
import usePermission from '../../../hooks/usePermission';
import {observeUnArchivedDiscounts} from '../../../pos-core/database/helpers';
import {DEVICE_HEIGHT} from '../../../constants';

const PosDiscountModal = ({toggle, setToggle, discounts, handleSelect, order , screenName}) => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const userPermission = usePermission('discounts');

    const handleClose = event => {
        setToggle(false);
        setText('');
    };

    const handleCreateDiscount = () => {
        if(screenName === "creatOrder"){
            navigate(`/discounts/create?order_id=${order.number}`);
        }else{
            navigate('/discounts/create');
        }
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';

        setText(value);
    };

    function handleSearch(val) {
        return discounts?.filter(x => x?.name?.toLowerCase()?.includes(val?.toLowerCase()));
    }

    const handleback = () => {
        setToggle(false);
        setText();
    };

    const renderList = text ? searchList : discounts;
    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
                action: handleback,
            }}
            handleClose={handleClose}
            rightCta={
                discounts?.length && {
                    title: I18n.create_discount,
                    action: handleCreateDiscount,
                    iconName: 'add2Icon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }
            }
            title={I18n.order_discount}>
            <>
                {!discounts.length == 0 && (
                    <SearchBox
                        type="pos"
                        placeholder={I18n.search_discounts_by_name}
                        prefix={{
                            name: 'search2Icon',
                            fill: theme.white,
                        }}
                        onChange={onChange}
                    />
                )}

                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            {renderList?.length ? (
                                <section className="addScreenMainBox">
                                    <Container>
                                        <Row className="justify-content-md-center">
                                            <Col md={12}>
                                                <div
                                                    className="selectCustomerListBox"
                                                    style={{
                                                        height: DEVICE_HEIGHT - 280,
                                                    }}>
                                                    {renderList?.map((val, index) => (
                                                        <List key={index} discounts={val} order={order} handleClick={handleSelect} />
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            ) : (
                                <NonIdealScreen
                                    heading={text ? I18n.search : I18n.discount}
                                    subHeading={text ? I18n.we_couldnt_find_any_results_for_your_search : I18n.discount_credit_cards_entire_orders}
                                    name={text ? 'seacrhNotFoundIcon' : 'discountIcon'}
                                    fill={theme.white}
                                    primaryCta={
                                        text
                                            ? null
                                            : userPermission?.canCrud
                                            ? {
                                                  title: I18n.create_discount,
                                                  action: handleCreateDiscount,
                                              }
                                            : null
                                    }
                                    isMultiStep={false}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        </PosAppModal>
    );
};

const enhance = withObservables([], () => ({
    discounts: observeUnArchivedDiscounts(),
}));

export default enhance(PosDiscountModal);
