import {useEffect, useState} from 'react';

const useOrderCalculation = ({order, orderLines, discount, sales_tax, isRestaurantPos}) => {
    orderLines = orderLines?.filter(item => !item.void);
    const [subTotal, setSubTotal] = useState(0);
    const [amount, setAmount] = useState();
    const [change, setChange] = useState(0);
    const [total, setTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [inclusive, setInclusive] = useState(0);
    const [exclusive, setExclusive] = useState(0);
    const [redeemAmount, setRedeemAmount] = useState();
    const [giveItemLevelDiscount, setGiveItemLevelDiscount] = useState(0);
    const [givenPosDiscount, setGivenPosDiscount] = useState(0);

    useEffect(() => {
        handleCalculation(orderLines, discount, sales_tax);
    }, [orderLines, discount, sales_tax, order]);

    useEffect(() => {
        handleChange(total, amount);
    }, [total, amount]);

    const handleCalculation = async (orderLines, discount, sales_tax) => {
        let sub = 0,
            totalInclusive = 0,
            totalExclusive = 0,
            totalItemLevelDiscount = 0;
        if (!orderLines) {
            return;
        }
        await Promise.all(
            orderLines?.map(async val => {
                if (val?.discount) {
                    let itemlevelDiscount = 0;
                    if (val.discount_type == 'amount') {
                        itemlevelDiscount = val.discount;
                    } else {
                        itemlevelDiscount = (val.discount * val.selling_price) / 100;
                    }
                    totalItemLevelDiscount += itemlevelDiscount * val.quantity;
                }
                let rate = val.rate;
                let price = val.selling_price / rate,
                    isInclusive = val?.is_tax_inclusive,
                    taxRate = val?.tax_rate || 0;

                if (isRestaurantPos) {
                    let modifierPrice = 0;
                    Object.values(val?.order_line_modifiers || {})?.forEach(obj => {
                        Object.values(obj).forEach(itemObj => {
                            modifierPrice += Number(itemObj.price);
                        });
                    });

                    price = price + modifierPrice;
                }

                if (taxRate) {
                    if (isInclusive) {
                        price = (100 * (val.selling_price / rate)) / (100 + (sales_tax?.is_inclusive ? 0 : taxRate));
                        totalInclusive += (val.selling_price / rate - price) * val.quantity;
                    } else {
                        taxRate = ((sales_tax && !sales_tax.is_inclusive ? 0 : taxRate) || 0) / 100 || 1;
                        totalExclusive += (val.selling_price / rate) * taxRate * val.quantity;
                    }
                }

                sub += price * val.quantity;
            }),
        );

        if (sales_tax) {
            let totalPrice = 0;

            if (sales_tax.is_inclusive) {
                totalPrice = (sub * 100) / (100 + sales_tax.rate);
                totalInclusive = sub - totalPrice;
            } else {
                totalPrice = sub;
                totalExclusive = sub * (sales_tax.rate / 100 || 1);
            }

            sub = totalPrice;
        }

        let total = sub;

        if (order?.redeemed_points || order?.redeemed_points === 0) {
            const pointsRate = 10;
            const pointsToCurrencyrate = 0.1;
            let redeemedPointsDiscount = order?.redeemed_points * pointsToCurrencyrate;
            total = total - redeemedPointsDiscount;
            setRedeemAmount(redeemedPointsDiscount);
        }

        if (discount) {
            let totalDiscount = discount?.type == 'amount' ? Number(discount.limit) : (total * Number(discount.limit)) / 100;

            if (discount.max_discount_cap) {
                totalDiscount = totalDiscount > discount.max_discount_cap ? discount.max_discount_cap : totalDiscount;
            }

            setGivenPosDiscount(totalDiscount);

            total = total - totalDiscount;
        } else setGivenPosDiscount('');

        total = total - totalItemLevelDiscount;
        total = total < 0 ? 0 : total;

        setGiveItemLevelDiscount(totalItemLevelDiscount);
        setInclusive(totalInclusive);
        setExclusive(totalExclusive);
        setTax(totalInclusive + totalExclusive);
        setSubTotal(sub);
        setTotal(total + totalInclusive + totalExclusive);
    };

    const handleChange = (total, amount) => {
        let x = amount ? amount - total : 0;
        setChange(x < 0 ? 0 : x);
    };

    return {
        subTotal,
        amount,
        setAmount,
        change,
        total,
        tax,
        inclusive,
        exclusive,
        givenPosDiscount,
        giveItemLevelDiscount,
        redeemAmount,
    };
};

export default useOrderCalculation;
