import { database } from "..";
import { USER_BUSINESS_SCHEMA } from "../schema";

const userBusiness = database.collections.get(USER_BUSINESS_SCHEMA);

export const createUserBusiness = async (details) => {
  return await database.write(
    async () =>
      await userBusiness.create((entry) => {
        entry.user.set(details.user);
        entry.business.set(details.business);
        entry.role = details.role;
      })
  );
};
