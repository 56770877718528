import React from "react";
import { useLanguageContext, useThemeContext } from "../../../../context";
import TextField from "../../TextField";

const PaymentStep1 = ({ data, handleChange, selectedWallet }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  return (
    <div className="accountNumberConatiner">
      <p
        className="fontSize20  marTop30 fontWeight400"
        style={{ color: theme.white }}
      >
        {selectedWallet === "Jazzcash"
          ? I18n.enter_Jazzcash_account_number
          : I18n.enter_easypaisa_account_number}
      </p>
      <TextField
        onChange={handleChange}
        label={I18n.enter_number}
        onlyNumbers={true}
        value={data}
        // inputRef={textBoxRef}
        maxLength={11}
      />
    </div>
  );
};

export default PaymentStep1;
