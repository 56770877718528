import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../../../assets/icons";
import { ThemeConsumer } from "styled-components";
import { useThemeContext } from "../../../../../../context";
const DineinTable = ({
  editTable,
  table,
  divDimensions,
  selectedTable,
  setSelectedTable,
  handleGuestModal,
  orders,
}) => {
  //NEED TO FIX POSITION ISSUE

  const [tableOrder, setTableOrder] = useState(null);
  const isSelected = selectedTable?.id === table?.id;
  const { theme } = useThemeContext();
  let navigate = useNavigate();

  const [{ x, y }, api] = useSpring(() => ({
    x: table.xCoord,
    y: table.yCoord,
  }));

  const checkReservedTables = () => {
    const order = orders.find((ord) => ord?.dine_in_table?.id === table.id);
    if (order) {
      setTableOrder(order);
    }
  };

  const bindTable = useDrag(
    (params) => {
      const ox = params.movement[0];
      const oy = params.movement[1];

      api.start({ x: ox, y: oy });

      const percentX = (ox / divDimensions.width) * 100;
      const percentY = (oy / divDimensions.height) * 100;

      setSelectedTable((prev) => ({
        ...prev,
        xAxis: percentX,
        yAxis: percentY,
        xCoord: ox,
        yCoord: oy,
      }));
    },
    {
      initial: [x?.get(), y?.get()],
      bounds: {
        left: 0,
        right: divDimensions.width - selectedTable?.width,
        top: 0,
        bottom: divDimensions.height - selectedTable?.height,
      },
      rubberband: true,
    }
  );
  const tableClicked = () => {
    if (!editTable && tableOrder) {
      navigate(`/restaurant-pos?id=${tableOrder?.number}`);
      return;
    }

    if (!editTable || selectedTable) {
      handleGuestModal(table);
      return;
    }

    const tableObj = {
      id: table.id,
      name: table.name,
      seats: table.seats,
      shape: table.shape,
      height: table.height,
      width: table.width,
      xAxis: table.xAxis,
      yAxis: table.yAxis,
      xCoord: table.xCoord,
      yCoord: table.yCoord,
    };
    setSelectedTable(tableObj);
  };

  useEffect(() => {
    checkReservedTables();
  }, [orders]);

  return (
    <animated.div
      {...(editTable && isSelected ? bindTable() : {})}
      onClick={tableClicked}
      className={isSelected ? "selectedFloorTable" : "floorTable"}
      style={
        isSelected
          ? {
              x,
              y,
              height: `${selectedTable.height}px`,
              width: `${selectedTable.width}px`,
              borderRadius: `${selectedTable.shape == "Square" ? 0 : 100}px`,
            }
          : {
              // x,
              // y,
              top: `${table.yAxis}%`,
              left: `${table.xAxis}%`,
              height: `${table.height}px`,
              width: `${table.width}px`,
              borderRadius: `${table.shape == "Square" ? 0 : 100}px`,
            }
      }
    >
      <p className={`marBot0 ${tableOrder ? "fontWeight600 white" : ""}`}>
        {isSelected ? selectedTable?.name : table.name}
      </p>
      {tableOrder ? (
        <div className="flex gap5 marTop5">
          <Icon
            name={"usersProfile"}
            width={"14"}
            height={"14"}
            viewBox={"0 0 14 14"}
          />
          <p className={`marBot0 barclaysBlueColor fontSize12`}>
            {tableOrder.no_of_guests}
          </p>
        </div>
      ) : (
        <p className="marBot0">
          {isSelected ? selectedTable?.seats : table.seats}
        </p>
      )}
    </animated.div>
  );
};
export default DineinTable;
