import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/common";
import { useAuthContext } from "../../context";
import Cookies from "js-cookie";
import { loginDevice } from "../../api";

const RequireKDSAuth = ({ children }) => {
  const location = useLocation();
  const { handleKdsLogin } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    handleAuth();
  }, []);

  const handleAuth = async () => {
    let secret_key = Cookies.get("secret_key");

    if (secret_key) {
      loginDevice({ secret_key: secret_key })
        .then((res) => {
          if (res.success) {
            setIsValid(true);
            if (!res?.data?.pin_active) {
              handleKdsLogin(res.data);
            }
          } else setIsValid(false);
        })
        .catch((err) => console.log("========== er", err))
        .finally(() => setIsLoading(false));
    } else {
      setIsValid(false);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loading />
  ) : isValid ? (
    children
  ) : (
    <Navigate to="/kds-login" state={{ from: location }} replace />
  );
};

export default RequireKDSAuth;
