import { useLanguageContext, useThemeContext } from '../../../../../../context';
import TableHeader from '../../../../../common/TableContainer/TableHeader';
import { Col } from 'react-bootstrap';
import React from 'react';

const HeaderItem = ({ data }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  return (
    <Col md={12} xs={12}>
      <TableHeader
        className={`sixColumn`}
        style={{ borderColor: theme.inputBorder }}
      >
        {data.map((item, index) => {
          return (
            <div key={index} className={`BoxWidth ${item.className}`}>
              <p
                className="fontSize16 OneLineTruncate"
                style={{ color: theme.white }}
              >
                {I18n[item.name]}
              </p>
            </div>
          )
        })}
      </TableHeader>
    </Col>
  );
};

export default HeaderItem;