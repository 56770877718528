import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../../../assets/icons";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../../context";
import {
  Button,
  CheckBox,
  CustomContainer,
  TextField,
} from "../../../../../common";
import Modal from "../../Modal";
import ItemList from "./ItemList";
import AddModifierList from "./AddModifierList";
import {
  INITIAL_PAGE,
  ITEMS_PER_PAGE,
  toSnakeCase,
  uuid,
  validateModifierForm,
} from "../../../../../../constants";
import {
  getPerPageItems,
  observeActiveProducts,
  queryItems,
} from "../../../../../../pos-core/database/helpers";
import withObservables from "@nozbe/with-observables";
import { bulkCreateModifierOptions } from "../../../../../../pos-core/database/helpers/modifier_option";
import { bulkCreateProductModifierRelation } from "../../../../../../pos-core/database/helpers/product_modifier";
import Pagination from "@mui/material/Pagination";

const CreateModifier = ({ activeProducts }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const { business, account } = useAuthContext();
  const [errors, setErrors] = useState({});
  const [modifierName, setModifierName] = useState("");
  const [modifier, setModifier] = useState([
    { id: uuid(), modifier_name: "", price: "", isChecked: false },
  ]);
  const [isPrintInReceipt, setIsPrintInReceipt] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [products, setProducts] = useState(activeProducts);
  const [numOfPages, setNumOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  let navigate = useNavigate();

  const togglePrint = () => setIsPrintInReceipt((x) => !x);

  const handleClose = () => {
    navigate("/pos-cockpit");
  };

  const handleConfirm = () => {
    navigate("/items/create");
  };

  const closeModal = () => {
    setSelectedItems([]);
    setToggle(false);
  };

  const saveModal = () => {
    setSelectedItems((prev) => [...prev]);
    setToggle(false);
  };

  const onChangeSearch = async (event, key) => {
    let value = key || event?.target?.value || "";
    setSearch(value);
    const { data, totalCount } = await queryItems(
      null,
      value.toLowerCase(),
      INITIAL_PAGE,
      ITEMS_PER_PAGE
    );
    setProducts(data);
    setCurrentPage(INITIAL_PAGE);
    setNumOfPages(Math.ceil(totalCount / 10));
  };

  const handleChange = async (e) => {
    const pageNum = e?.target?.innerText - 1 || 0;
    setCurrentPage(pageNum);

    if (search) {
      const { data } = await queryItems(
        null,
        search.toLowerCase(),
        pageNum,
        ITEMS_PER_PAGE
      );

      setProducts(data);
    } else {
      const prod = await getPerPageItems(pageNum, ITEMS_PER_PAGE);
      setProducts(prod);
    }
  };

  const onChange = (label, value) => {
    if (label == I18n.modifier_name) setModifierName(value);

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }
      return updatedErrors;
    });
  };

  const emptyForm = () => {
    setModifierName("");
    setIsPrintInReceipt(false);
    setModifier([]);
    setSelectedItems([]);
  };

  const handleAddModifier = () => {
    const initialzieModifier = {
      id: uuid(),
      modifier_name: "",
      price: "",
      isChecked: false,
    };
    setModifier((prev) => [...prev, initialzieModifier]);
  };

  const handleDeleteModifier = (id) => {
    const filteredModifiers = modifier.filter((item) => item.id != id);
    setModifier(filteredModifiers);
  };

  const updateListItem = (label, val, id) => {
    const updatedItem = modifier?.map((allItem) => {
      if (allItem.id === id) {
        return {
          ...allItem,
          [label]: val,
        };
      }
      return allItem;
    });
    setModifier(updatedItem);
  };

  const handleSave = async () => {
    try {
      console.log("In hanlde save");
      const formData = { modifierName, selectedItems, modifier };

      const formErrors = validateModifierForm(formData);

      console.log("form errors", formErrors);
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) return;

      const paramsForModifier = {
        name: modifierName,
        print_in_receipt: isPrintInReceipt,
      };

      console.log("paramsForModifier", paramsForModifier);
      let createdModifier = await account.createModifier(paramsForModifier);
      console.log("created modifier", createdModifier);
      const opt = await bulkCreateModifierOptions(createdModifier, modifier);
      console.log("mod opt", opt);
      const response = await bulkCreateProductModifierRelation(
        createdModifier,
        selectedItems
      );
      console.log("response", response);
      emptyForm();
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeSelectedItem = (clickedItem) => {
    const filterList = selectedItems.filter(
      (elem) => elem?.item?.id !== clickedItem.item.id
    );
    setSelectedItems(filterList);
  };

  const preparePagination = async () => {
    const { totalCount } = await queryItems(
      null,
      search?.toLowerCase(),
      currentPage,
      ITEMS_PER_PAGE
    );
    setNumOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  };

  useEffect(() => {
    preparePagination();
  }, []);

  return (
    <>
      <CustomContainer className="createModifierContainer">
        <div className="width100 flex verticalCenter padt20">
          <div
            className="width90 flex verticalCenter"
            onClick={() => handleClose()}
          >
            <Icon
              name="backArrowIcon"
              fill={theme.barclaysBlue}
              width={"18"}
              height={"18"}
              viewBox={"0 0 18 18"}
            />
            <p
              className="marBot0 fontSize18 fontWeight500 marLeft20"
              style={{ color: theme.white }}
            >
              {I18n.back}
            </p>
          </div>

          <div className="createFloorPlanButtonWrapper">
            <Button
              //   saveLoad={saveCta.saveLoad}
              title={I18n.save}
              handleClick={handleSave}
              //   disabled={saveCta?.disabled}
              className={`width100 borderRadius5 fontWeight600 fontSize16 cursorPointer marBot0 marRight20`}
            />
          </div>
        </div>

        <div className="width100 flex verticalCenter horizontalCenter">
          <div className="width40 marTop20">
            <p
              className="marBot30 fontSize24 fontWeight600 textCenter"
              style={{ color: theme.white }}
            >
              {I18n.create_modifier}
            </p>

            <TextField
              onChange={onChange}
              error={errors?.["modifier_name"]}
              label={I18n.modifier_name}
              placeholder={I18n.modifier_name}
              value={modifierName}
            />

            <div className="marTop20">
              <CheckBox
                checkboxLabel={
                  <p
                    className="marBot0 fontSize14"
                    style={{ color: theme.text }}
                  >
                    {I18n.print_in_receipt}
                  </p>
                }
                onChange={togglePrint}
                checked={isPrintInReceipt}
              />
            </div>

            <div className="divider width100 marTop20" />
            <p
              className="marTop20 marBot20 fontSize20 fontWeight400"
              style={{ color: theme.white }}
            >
              {I18n.apply_modifier_on_these_items}
            </p>

            <div className="addTableDivWrapper" onClick={() => setToggle(true)}>
              <Icon
                name={"add2Icon"}
                width="24"
                height="24"
                viewBox={"0 0 18 18"}
                fill={theme.barclaysBlue}
              />
              <p
                className="marBot0 fontSize14 fontWeight400"
                style={{ color: theme.barclaysBlue }}
              >
                {I18n.add_items}
              </p>
            </div>
            {errors?.items && <p className="inputError">{errors.items}</p>}

            {selectedItems?.length > 0 && (
              <div className="selectedItemsWrapper">
                <p
                  className="marBot10 marTop10 fontSize14 fontWeight600"
                  style={{ color: theme.white }}
                >
                  {I18n.items}
                </p>

                <div className="divider width100 marTop10 marBot10" />

                <div className="flex flexwrap gap10 ">
                  {selectedItems?.map((elem) => (
                    <div className="itemChip" key={elem?.item?.id}>
                      <p
                        className="marBot0 fontSize14 fontWeight400"
                        style={{ color: theme.white }}
                      >
                        {elem?.item?.name}
                      </p>
                      <span
                        className="flex verticalCenter"
                        onClick={() => removeSelectedItem(elem)}
                      >
                        <Icon
                          name="crossFilled"
                          viewBox="0 0 15 15"
                          height={15}
                          width={15}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="modifiersDivWrapper">
              <div className="flex">
                <p
                  className="marBot0 fontSize14 fontWeight400"
                  style={{ color: theme.white, width: "40%" }}
                >
                  {I18n.modifier}
                </p>
                <p
                  className="marBot0 fontSize14 fontWeight400"
                  style={{ color: theme.white, width: "30%" }}
                >
                  {I18n.price}
                </p>
                <span
                  style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "30px",
                  }}
                >
                  <p
                    className="marBot0 fontSize14 fontWeight400"
                    style={{ color: theme.white }}
                  >
                    {I18n.stock_status}
                  </p>

                  <span
                    onClick={() => handleAddModifier()}
                    className="cursorPointer"
                  >
                    <Icon
                      name={"add2Icon"}
                      width="24"
                      height="24"
                      viewBox={"0 0 18 18"}
                      fill={theme.barclaysBlue}
                    />
                  </span>
                </span>
              </div>
              {errors?.modifier && (
                <p className="inputError">{errors.modifier}</p>
              )}

              {modifier?.map((item) => (
                <div key={item.id}>
                  <AddModifierList
                    item={item}
                    updateListItem={updateListItem}
                    handleDeleteModifier={handleDeleteModifier}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </CustomContainer>

      <Modal
        toggle={toggle}
        setToggle={setToggle}
        handleConfirm={handleConfirm}
        title={I18n.add_items_to_modifier}
        searchPlaceholder={I18n.search_item_by_name}
        createButtonTitle={I18n.create_item}
        onChange={onChangeSearch}
        bottomCancelCta={{
          title: I18n.cancel,
          action: () => closeModal(),
        }}
        bottomSaveCta={{
          title: I18n.save,
          action: () => saveModal(),
        }}
      >
        {products?.map((item) => (
          <ItemList
            item={item}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            setErrors={setErrors}
          />
        ))}

        <div className="paginationContainer marTop40">
          {numOfPages > 1 && (
            <Pagination
              count={numOfPages}
              shape="rounded"
              onChange={handleChange}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

const enhance = withObservables([], () => ({
  activeProducts: observeActiveProducts().observeWithColumns(["archive"]),
}));

export default enhance(CreateModifier);
