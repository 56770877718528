import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Loading } from "../../components/common";
import { useAuthContext, useSessionContext } from "../../context";

const RequireSession = ({ children }) => {
    const location = useLocation();

    const { business } = useAuthContext();
    const { session, setSession } = useSessionContext();

    const [isLoading, setIsLoading] = useState(session ? false : true);

    useEffect(() => {
        handleSession();
    }, []);

    const handleSession = async () => {
        if (!session) {
            let activeSessions = await business.getActiveSession.fetch();

            if (activeSessions.length) {
                setSession(activeSessions[0]);
            }
        }

        setIsLoading(false);
    }

    return isLoading ? (
        <Loading />
    ) : session ? (
        children
    ) : (
        <Navigate to="/session" state={{ from: location }} replace />
    )
}

export default RequireSession;