import {database} from '..';
import {BUSINESS_SCHEMA, COMPANY_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';

const company = database.collections.get(COMPANY_SCHEMA);

export const findCompanyByID = async id => await company.find(id);
export const observeUnArchiveCompany = () => company.query(Q.where('archive', Q.notEq(true))).observe();
export const observeArchivedCompany = () => company.query(Q.where('archive', Q.eq(true))).observe();
export const observeActiveCompany = () => company.query(Q.where('archive', Q.notEq(true)), Q.where('draft', Q.notEq(true))).observe();
export const observeDraftCompany = () => company.query(Q.where('draft', Q.eq(true))).observe();
