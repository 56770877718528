import {database} from '..';
import {uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {BUSINESS_SCHEMA, FLOOR_PLAN_SCHEMA} from '../schema';

const floor_plan = database.collections.get(FLOOR_PLAN_SCHEMA);

export const observeActiveFloorPlan = () => floor_plan.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('archive', Q.notEq(true))).observe();

const prepareInsertion = (floorPlans, business) => {
    return floorPlans.map(item => {
        try {
            return floor_plan.prepareCreate(floorPlan => {
                floorPlan.business.set(business);
                floorPlan._raw.id = uuid();
                floorPlan.name = item.name;
                floorPlan.type = item.type;
                floorPlan.componentType = item.componentType;
                floorPlan.created_at = new Date().getTime();
                floorPlan.archive = false;
            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateFloorPlan = async (floorPlans, business) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(floorPlans, business);
        await database.batch(allRecords);
    });
};
