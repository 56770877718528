import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../common";
import Icon from "../../../assets/icons";
import { useLanguageContext, useThemeContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { firstLetterCaptilize } from "../../../constants";

const DefaultHeader = ({
  title,
  handleSave,
  buttonTitle,
  borderButton,
  isDisabled = false,
  para,
  ...rest
}) => {
  let navigate = useNavigate();

  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleBack = () => navigate(-1);

  return (
    <section
      className="addScreenTopBar"
      style={{
        backgroundColor: theme.blackBg,
        borderColor: theme.background,
      }}
    >
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="backInnerBox">
              <p className="marBot0 white">
                <span
                  className="backBoxIcon cursorPointer"
                  onClick={handleBack}
                >
                  <Icon
                    name="backArrowIcon"
                    fill={theme.barclaysBlue}
                    width={"18"}
                    height={"18"}
                    viewBox={"0 0 18 18"}
                  />
                </span>
                <span className="marLeft10 fontSize18">
                  {firstLetterCaptilize(title || I18n.back)}
                </span>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="closeButtonBox">
              {para && (
                <span className="marRight50 fontSize18 white bold">{para}</span>
              )}
              {borderButton && (
                <Button
                  disabled={isDisabled}
                  className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                  backgroundColor="transparent"
                  borderColor={theme.barclaysBlue}
                  title={borderButton.title}
                  txtColor={theme.barclaysBlue}
                  handleClick={borderButton.action}
                />
              )}
              {handleSave ? (
                <Button
                  disabled={isDisabled}
                  className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                  title={buttonTitle || I18n.save}
                  handleClick={handleSave}
                  {...rest}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DefaultHeader;
