import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const IconButton = ({
  value,
  className,
  disabled,
  handleClick,
  icon_name,
  icon_fill,
  icon_height,
  icon_width,
  icon_viewBox,
  backgroundColor,
}) => {
  const { theme } = useThemeContext();
  return (
    <button
      value={value}
      className={`buttonIconStyle ${className}`}
      disabled={disabled}
      onClick={handleClick}
      style={{
        backgroundColor: backgroundColor || "transparent",
      }}
    >
      <span className="">
        <Icon
          name={icon_name}
          fill={icon_fill}
          height={icon_height || "19"}
          width={icon_width || "20"}
          viewBox={icon_viewBox || "0 0 16 15"}
        />
      </span>
    </button>
  );
};

export default IconButton;
