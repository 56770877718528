import { useLanguageContext, useThemeContext } from "../../../../../context";
import { NonIdealScreen } from "../../../../common";

const SupplierList = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const handleCreateSupplier = () => {
    // handleCreateSupplier
  };

  const handleImportSupplier = () => {
    // handleImportSupplier
  };

  return (
    <NonIdealScreen
      heading={I18n.add_supplier}
      subHeading={I18n.create_new_supplier_and_add_to_sale}
      paragraph={I18n.before_you_open_your_store_first_you_need_some_supplier}
      name="suppliesIcon"
      fill={theme.white}
      primaryCta={{
        title: I18n.create_supplier,
        action: handleCreateSupplier,
      }}
      secondaryCta={{
        title: I18n.import_suppliers,
        action: handleImportSupplier,
      }}
      isMultiStep={false}
    />
  );
};

export default SupplierList;
