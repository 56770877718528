import React, { useState } from "react";
import { useLanguageContext, useThemeContext } from "../../../../../../context";
import { TextField } from "../../../../../common";
import { Col, Row } from "react-bootstrap";
import { TABLE_SHAPES, toSnakeCase } from "../../../../../../constants";

const CreateTable = ({
  name,
  setName,
  seats,
  setSeats,
  height,
  setHeight,
  width,
  setWidth,
  shape,
  setShape,
  errors,
  setErrors,
}) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();

  const onChange = (label, val) => {
    if (label === I18n.table_name) setName(val);
    else if (label === I18n.no_of_seats) setSeats(Number(val));
    else if (label === I18n.table_shape) setShape(val);
    else if (label === I18n.table_height) setHeight(Number(val));
    else if (label === I18n.table_width) setWidth(Number(val));

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (label === "Table width (px)") {
        label = "table_width";
      }
      if (label === "Table height (px)") {
        label = "table_height";
      }
      if (updatedErrors[toSnakeCase(label)]) {
        delete updatedErrors[toSnakeCase(label)];
      }
      return updatedErrors;
    });
  };

  return (
    <div className="flex justifyCenter">
      <div className="width50">
        <Row>
          <Col md={12}>
            <p
              className="optionListInnerLeft fontSize20 marBot0"
              style={{ color: theme.white }}
            >
              {I18n.table_details}
            </p>
          </Col>
          <Col md={6}>
            <TextField
              onChange={onChange}
              error={errors.table_name}
              label={I18n.table_name}
              placeholder={I18n.table_name}
              value={name}
            />
          </Col>
          <Col md={6}>
            <TextField
              onChange={onChange}
              error={errors.no_of_seats}
              label={I18n.no_of_seats}
              placeholder={0}
              value={seats}
            />
          </Col>
          <Col md={12}>
            <p
              className="optionListInnerLeft fontSize20 marBot10 marTop20"
              style={{ color: theme.white }}
            >
              {I18n.appearance}
            </p>
          </Col>
          <Col md={6}>
            <TextField
              label={I18n.table_shape}
              placeholder={0}
              onChange={onChange}
              data={TABLE_SHAPES}
              value={shape}
              inputType="select"
              type="text"
              suffix="downIcon"
              suffix_fill={"blackFont"}
              suffix_width={"14"}
              suffix_height={"11"}
              suffix_viewBox={"0 0 8 5"}
              error={errors.table_shape}
            />
          </Col>
          <Col md={6}>
            <TextField
              onChange={onChange}
              error={errors.table_width}
              label={I18n.table_width}
              placeholder={0}
              value={width}
            />
          </Col>
          <Col md={6}>
            <TextField
              onChange={onChange}
              error={errors.table_height}
              label={I18n.table_height}
              placeholder={0}
              value={height}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateTable;
