import {Container, Col, Row} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import {useEffect, useState} from 'react';

import {PosAppModal} from '../V2';
import TableHeader from '../TableContainer/TableHeader';
import CheckBox from '../CheckBox';
import List from './List';
import {DEVICE_HEIGHT} from '../../../constants';

const RefundItemModal = ({toggle, setToggle, order_lines}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [cloneItems, setCloneItems] = useState();
    const [selectedItems, setSelectedItems] = useState({});

    const handleClose = event => {
        setToggle(false);
    };

    const handleConfirm = () => {
        const refundItems = Object.keys(selectedItems).map(item => cloneItems.find(cloneItem => cloneItem.id === item));
    };

    const handleUpdateItemQty = (item, type) => {
        const updatedItems = cloneItems?.map(x => {
            if (x.id === item.id)
                return {
                    ...x,
                    id: x.id,
                    selling_price: x.selling_price,
                    name: x.name,
                    discount: x.discount,
                    quantity: type === 'increment' ? Number(x.quantity + 1) : Number(x.quantity - 1),
                };
            else {
                return x;
            }
        });

        setCloneItems(updatedItems);
    };

    const handleSelectAll = () => {
        let list = cloneItems;
        if (Object.keys(selectedItems)?.length !== list.length) {
            const newSelectedItems = {};
            list?.forEach(val => {
                newSelectedItems[val?.id] = val?.id;
            });
            setSelectedItems(newSelectedItems);
        } else {
            setSelectedItems({});
        }
    };

    const handleChecked = val => {
        const id = val?.id;
        setSelectedItems(items => {
            const updatedItems = {...items};
            if (updatedItems[id]) {
                delete updatedItems[id];
            } else {
                updatedItems[id] = id;
            }
            return updatedItems;
        });
    };

    useEffect(() => {
        setCloneItems(order_lines);
    }, [toggle]);

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            handleClose={handleClose}
            title={I18n.refund}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            bottomSaveCta={{
                title: I18n.confirm,
                action: () => handleConfirm(),
            }}
            bottomCancelCta={{
                title: I18n.cancel,
                action: () => handleClose(),
            }}>
            <Container className="width100">
                <Row className="marLeft10">
                    <Col md={12}>
                        <div className="itemFields">
                            <h3 className="fontSize18 fontWeight600 marBot20 marTop30" style={{color: theme.text}}>
                                {I18n.select_items_for_refund}
                            </h3>

                            <div className="width100">
                                <TableHeader className={`fiveColumn`} style={{borderColor: theme.posRightBg}}>
                                    <div className="BoxWidth fontWeight600 justifyStart refundScreenCheckbox">
                                        <CheckBox
                                            checkboxLabel={
                                                <p className="marBot0 fontSize16" style={{color: theme.text}}>
                                                    {I18n.item}
                                                </p>
                                            }
                                            onChange={() => handleSelectAll()}
                                            checked={Object.keys(selectedItems)?.length === cloneItems?.length}
                                        />
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.qty}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.discount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.rate}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox orderListBodyBox" style={{height: DEVICE_HEIGHT - 300}}>
                                    {cloneItems?.map((val, index) => (
                                        <List
                                            key={index}
                                            item={val}
                                            handleUpdateItemQty={handleUpdateItemQty}
                                            selectedItems={selectedItems}
                                            handleChecked={handleChecked}
                                            order_lines={order_lines}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PosAppModal>
    );
};

export default RefundItemModal;
