import { createContext, useContext, useState } from "react";
import { en, romn, snd, ur } from "../../languageConfig";

export const LanguageContext = createContext({
    I18n: en,
    changeLanguage: () => { }
});

export const LanguageProvider = (props) => {

    const localLang = localStorage.getItem('language');

    const [I18n, changeLanguage] = useState(localLang === 'ur' ? ur : localLang === 'snd' ? snd : localLang === 'romn' ? romn : en);

    return (
        <LanguageContext.Provider value={{ I18n, changeLanguage }}>
            {props.children}
        </LanguageContext.Provider>
    )
}

export const useLanguageContext = () => useContext(LanguageContext);