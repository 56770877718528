import React from "react";
import notificationSound from "../../assets/Audios/notificationSound.wav";

const useNotificationSound = () => {
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio?.play();
  };

  return { playNotificationSound };
};

export default useNotificationSound;
