import {useLanguageContext} from '../../../../context';
import CatItemSummary from './CatItemSummary';
import React from 'react';

const ItemSummary = ({selectedLocation}) => {
    const {I18n} = useLanguageContext();
    const handleClick = () => {
        // handleClick
    };
    return (
        <CatItemSummary
            type="item"
            selectedLocation={selectedLocation}
            action={{
                title: I18n.setup_items,
                click: handleClick,
            }}
        />
    );
};

export default ItemSummary;
