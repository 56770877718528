import Mousetrap from "mousetrap";
import { useEffect } from "react";

const useShortcut = (allKeys, data) => {
  useEffect(() => {
    binder();
    return () => {
      unbindShortcuts();
    };
  }, [data]);

  const binder = () => {
    allKeys.keys.map((each) =>
      Mousetrap.bind(each.key, (e) => {
        e.preventDefault();
        console.log(`${each.key} is pressed !!!`);
        each.action();
      })
    );
  };

  const unbindShortcuts = () => {
    allKeys.keys.map((each) => Mousetrap.unbind(each.key));
  };

  return { unbindShortcuts, binder };
};

export default useShortcut;
