import Icon from '../../../assets/icons';
import {formatNum} from '../../../constants';
import {useThemeContext} from '../../../context';

const List = ({val, handleClick, data, screen_name}) => {
    const {theme} = useThemeContext();

    let isSelected = screen_name ? (data && data?._raw.id == val?._raw?.id ? true : false) : data && data?.sales_tax?.id == val?._raw?.id ? true : false;

    const handleSelect = val => {
        if (isSelected) {
            handleClick('remove_saleTax');
        } else {
            handleClick(val);
        }
    };
    return (
        <div onClick={() => handleSelect(val)} className="orderDiscountModalList">
            <p className="marBot0" style={{color: theme.text}}>
                <span className="fontSize16 fontWeight500">{val.name}</span>
                <span className="fontSize14  fontWeight400 marLeft15" style={{color: theme.darkGrayTwo}}>
                    {`${formatNum(val?.rate || '0')}%`}
                </span>
            </p>

            <p className="marBot0">
                {isSelected && (
                    <span>
                        <Icon name="tickCircleIcon" fill={theme.brightGreen} width={'24'} height={'24'} viewBox={'0 0 24 24'} />
                    </span>
                )}
            </p>
        </div>
    );
};

export default List;
