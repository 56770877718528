/* eslint-disable react-hooks/rules-of-hooks */
import {DEVICE_HEIGHT} from '../../../constants';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useNavigate} from 'react-router-dom';
import HamburgerItem from './HamburgerItem';
import {SUB_NAV_LIST} from './subNavData';
import MessageModal from '../MessageModal';
import Nav from 'react-bootstrap/Nav';
import {useState} from 'react';
import {useLanguageContext, useSessionContext, useThemeContext, useAuthContext, useMenuContext} from '../../../context';
import usePermission from '../../../hooks/usePermission';

const HamburgerMenu = () => {
    let navigate = useNavigate();

    const {isVisible, setIsVisible} = useMenuContext();
    const {session, setSession} = useSessionContext();
    const {handleLogoutInDB, hanldlePermission} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [selectedRoute, setSelectedRoute] = useState('')

    const [logoutModal, setLogoutModal] = useState(false);
    const [subRoute, setSubRoute] = useState('');

    const toggleMenu = () => {
        setIsVisible(false);
        setSubRoute('');
    };

    const handleSelect = route => {
        setSelectedRoute(route)
        if (SUB_NAV_LIST[route]) {
            route == subRoute ? setSubRoute('') : setSubRoute(route);
            return;
        } else if (route == 'logout') setLogoutModal(true);
        else navigate(`/${route}`);
        setIsVisible(false);
        setSubRoute('');
    };

    const handleLogout = async () => {
        setLogoutModal(false);

        if (session) {
            navigate('/session', {
                state: 'logout',
            });
        } else {
            await handleLogoutInDB();

            navigate('/login', {replace: true});
        }
    };

    return (
        <>
            <Offcanvas
                className="navigationSiderbar"
                backdrop={true}
                show={isVisible}
                onHide={toggleMenu}
                style={{
                    backgroundColor: theme.background,
                    height: DEVICE_HEIGHT - 40,
                }}>
                <Offcanvas.Body>
                    <Nav onSelect={route => handleSelect(route)} className="sidePaneNav" variant="pills" activeKey="1">
                        {hanldlePermission('dashboard') && <HamburgerItem hamburgerIcon="dashboardIcon" hamburgerTitle={I18n.dashboard} eventKey="dashboard" selectedRoute={selectedRoute} />}
                        {hanldlePermission('pos') && <HamburgerItem hamburgerIcon="posNewIcon" hamburgerTitle={I18n.nav_pos} eventKey="pos" selectedRoute={selectedRoute} />}
                        {hanldlePermission('purchaseorder') && <HamburgerItem hamburgerIcon="purchaseOrderNewIon" hamburgerTitle={I18n.purchase_order} eventKey="purchaseorder" selectedRoute={selectedRoute} />}
                        {hanldlePermission('reports') && <HamburgerItem hamburgerIcon="reportsIcon" hamburgerTitle={I18n.reports} eventKey="report" selectedRoute={selectedRoute} />}
                        {hanldlePermission('payments') && (
                            <HamburgerItem
                                hamburgerTitle={I18n.payments}
                                hamburgerIcon="paymentIcon"
                                hamburgerSubIcon="rightArrowIconNew"
                                eventKey={'payments'}
                                subRoute={subRoute === 'payments' ? subRoute : null}
                                selectedRoute={selectedRoute}
                            />
                        )}
                        <hr className="sidebarNavSeperator" />
                        {hanldlePermission('items') && (
                            <HamburgerItem
                                hamburgerSubIcon="rightArrowIconNew"
                                hamburgerTitle={I18n.item_library}
                                eventKey="itemLibrary"
                                subRoute={subRoute === 'itemLibrary' ? subRoute : null}
                                selectedRoute={selectedRoute}
                            />
                        )}
                        {hanldlePermission('orders') && <HamburgerItem hamburgerTitle={I18n.orders_history} eventKey="orders" selectedRoute={selectedRoute} />}
                        {hanldlePermission('customers') && <HamburgerItem hamburgerTitle={I18n.customers} eventKey="customers" selectedRoute={selectedRoute}/>}
                        {hanldlePermission('suppliers') && (
                            <HamburgerItem
                                hamburgerTitle={I18n.suppliers}
                                hamburgerSubIcon="rightArrowIconNew"
                                eventKey="suppliers"
                                subRoute={subRoute === 'suppliers' ? subRoute : null}
                                selectedRoute={selectedRoute}
                            />
                        )}
                        {hanldlePermission('discounts') && <HamburgerItem hamburgerTitle={I18n.discounts} eventKey="discounts" selectedRoute={selectedRoute} />}
                        {hanldlePermission('tax') && <HamburgerItem hamburgerTitle={I18n.taxes} eventKey="tax" selectedRoute={selectedRoute} />}
                        {hanldlePermission('team') && <HamburgerItem hamburgerTitle={I18n.teams} eventKey="team"  selectedRoute={selectedRoute}/>}
                        {hanldlePermission('device') && <HamburgerItem hamburgerTitle={I18n.devices} eventKey="device" selectedRoute={selectedRoute} />}
                        {hanldlePermission('setting') && <HamburgerItem hamburgerTitle={I18n.settings} eventKey="setting" selectedRoute={selectedRoute} />}
                        {hanldlePermission('location') && <HamburgerItem hamburgerTitle={I18n.location} eventKey="location" selectedRoute={selectedRoute} />}
                        <HamburgerItem hamburgerTitle={I18n.nav_logout} eventKey="logout" />
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

            <MessageModal
                setToggle={setLogoutModal}
                className="messageModal"
                toggle={logoutModal}
                title={I18n.logout}
                description={I18n.are_you_sure_you_want_to_logout}
                secondaryCta={{
                    title: I18n.no,
                    action: () => setLogoutModal(false),
                }}
                primaryCta={{
                    title: I18n.logout,
                    action: handleLogout,
                    isDelete: true,
                }}
            />
        </>
    );
};

export default HamburgerMenu;
