import { Model } from "@nozbe/watermelondb";
import { PO_LINE_SCHEMA } from "../schema";
import {
  field,
  text,
  date,
  relation,
  writer,
} from "@nozbe/watermelondb/decorators";

export default class PurchaseOrderLine extends Model {
  static table = PO_LINE_SCHEMA;

  static associations = {
    purchase_order: { type: "belongs_to", key: "purchase_order_id" },
    product: { type: "belongs_to", key: "product_id" },
    purchase_tax: { type: "belongs_to", key: "purchase_tax_id" },
  };

  @text("name") name;
  @field("quantity") quantity;
  @field("selling_price") selling_price;
  @field("cost_price") cost_price;
  @field("discount") discount;
  @field("unit") unit;
  @field("archive") archive;

  @relation("purchase_order", "purchase_order_id") purchase_order;
  @relation("product", "product_id") product;
  @relation("purchase_tax", "purchase_tax_id") purchase_tax;

  @writer async updateQuantity(val) {
    return await this.update((line) => {
      line.quantity = val;
    });
  }

  @writer async updateCostPrice(val) {
    return await this.update((line) => {
      line.cost_price = val;
    });
  }

  @writer async updateSellingPrice(val) {
    return await this.update((line) => {
      line.selling_price = val;
    });
  }

  @writer async updateDiscount(val) {
    return await this.update((line) => {
      line.discount = val;
    });
  }

  @writer async updatePurchaseTax(val) {
    return await this.update((line) => {
      line.purchase_tax.set(val);
    });
  }

  @writer async archivePOLine() {
    return await this.update((line) => {
      line.archive = true;
    });
  }

  @writer async deleteOrderLine() {
    return await this.markAsDeleted();
  }
}
