import { Model } from "@nozbe/watermelondb";
import { OPTION_SCHEMA, USER_SCEHMA } from "../schema";
import {
  field,
  text,
  relation,
  children,
  writer,
  date,
  lazy,
  json,
} from "@nozbe/watermelondb/decorators";
import { sanitizeOptionValues } from "../../constants";

export default class Option extends Model {
  static table = OPTION_SCHEMA;

  static associations = {
    business: { type: "belongs_to", key: "business_id" },
  };

  @text("name") name;
  @text("type") type;
  @json("values", (val) => sanitizeOptionValues(val)) values;

  @relation("business", "business_id") business;
}
