import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Loading, NonIdealScreen } from "../../../../common";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../context";
import {
  DEVICE_HEIGHT,
  formatStringAndCapitalize,
} from "../../../../../constants";
import OrderCard from "../../../../common/OrderCard";
import WebSocketService from "../../../../../services/WebSocket";
import { getKitchenOrders } from "../../../../../api";
import API_ENDPOINTS from "../../../../../api/endpoints";
import useNotificationSound from "../../../../../hooks/useNotificationSound";
import Cookies from "js-cookie";

const KitchenOrderList = ({ setShowMessage, filter, search }) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { deviceConfiguration, setCounter } = useAuthContext();
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const { playNotificationSound } = useNotificationSound();

  useEffect(() => {
    const socket_id = deviceConfiguration?.secret_key;
    const webSocketUrl = `${API_ENDPOINTS.WEB_SOCKET_URL}${socket_id}/`;
    const orderReceivedSound =
      deviceConfiguration?.configuration?.play_sound_order_arrived;
    const orderCancelledSound =
      deviceConfiguration?.configuration?.play_sound_order_cancelled;

    const handleWebSocketMessage = (data) => {
      try {
        setLoading(true);
        if (data.status) {
          if (
            (orderReceivedSound && data.status.new_order_arrived) ||
            (orderCancelledSound && data.status.order_status_change)
          ) {
            const unReadNotifications = Cookies.get("notificationsCount") || 0;
            Cookies.set("notificationsCount", Number(unReadNotifications) + 1);

            //setting counter and consuming the value of kds header to get the updated value
            setCounter(Number(unReadNotifications) + 1);
            playNotificationSound();
          }
          getKitchenOrders(socket_id)
            .then((response) => {
              setOrders(response?.orders);
            })
            .catch((error) => console.error("API error:", error));
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    if (socket_id) {
      WebSocketService.connect(webSocketUrl, handleWebSocketMessage);
    }

    getKitchenOrders(socket_id)
      .then((response) => {
        setOrders(response?.orders);
      })
      .catch((error) => console.error("API error:", error));
  }, [deviceConfiguration]);

  useEffect(() => {
    if (filter !== "All") {
      const filteredByType = orders?.filter(
        (item) => formatStringAndCapitalize(item.order_type) === filter
      );

      if (search !== "") {
        const filteredBySearch = filteredByType.filter(
          (x) =>
            x.order_id.toLowerCase().includes(search.toLowerCase()) ||
            x.waiter_name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredOrders(filteredBySearch);
      } else {
        setFilteredOrders(filteredByType);
      }
    } else {
      if (search !== "") {
        const filteredBySearch = orders.filter(
          (x) =>
            x.order_id.toLowerCase().includes(search.toLowerCase()) ||
            x.waiter_name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredOrders(filteredBySearch);
      } else {
        setFilteredOrders(null);
      }
    }
  }, [filter, search, orders]);

  return (
    <div className="kitchenOrderListWrapper">
      {loading ? (
        <Loading />
      ) : orders?.length > 0 ? (
        <div>
          <Row
            style={{
              height: DEVICE_HEIGHT - 200,
              overflowY: "scroll",
            }}
            className="kitchenOrderListContainer"
          >
            {(filteredOrders ? filteredOrders : orders)?.map((ord) => (
              <Col sm={3} md={3} lg={3} xl={3} xxl={2} className="marBot20">
                <OrderCard order={ord} setShowMessage={setShowMessage} />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div style={{ height: DEVICE_HEIGHT - 110 }}>
          <NonIdealScreen
            subHeading={I18n.hold_tight}
            paragraph={I18n.all_incoming_orders_will_display_here}
            name="orderIcon"
            fill={theme.white}
            isMultiStep={false}
            width="106"
            height="120"
            viewBox="0 0 106 120"
          />
        </div>
      )}
    </div>
  );
};

export default KitchenOrderList;
