import {useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {useNavigate} from 'react-router-dom';
import {formatDate} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';
import {of, switchMap} from 'rxjs';

const List = ({membersDetails, userRole, userBusiness, userPermission}) => {
    let navigate = useNavigate();

    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [archiveModal, setArchiveModal] = useState(false);
    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async () => {
        setArchiveModal(false);
        await membersDetails.archiveUser(membersDetails.archive ? false : true);
    };

    const handleAction = type => {
        switch (type) {
            case 'archive':
                toggleArchiveModal();
                break;
            case 'edit':
                navigate(`${membersDetails.id}/edit`);
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
        {
            title: I18n[membersDetails.archive ? 'unarchive' : 'archive'],
            action: () => handleAction('archive'),
        },
    ];

    return (
        <>
            <TableBody className={`sixColumn`} style={{borderColor: theme.posRightBg}}>
                <div className="BoxWidth justifyStart">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {membersDetails?.first_name} {membersDetails?.last_name}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {membersDetails?.phone_number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {formatDate(membersDetails?.last_visited)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {userRole?.name || '-'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {formatDate(membersDetails?.created_at)}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {userBusiness?.location_name || userBusiness?.[0]?.location_name || '-'}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                title={I18n.archive_product}
                description={I18n[membersDetails.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive'] + membersDetails?.name + '?'}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: handleArchive,
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables(['membersDetails'], ({membersDetails}) => ({
    userRole: membersDetails?.user_business.observe().pipe(switchMap(userBus => (userBus?.length ? userBus[0]?.role.observe() : of(null)))),
    userBusiness: membersDetails?.user_business.observe().pipe(switchMap(userBus => (userBus?.length ? userBus[0]?.business.observe() : of(null)))),
}));

export default enhance(List);
