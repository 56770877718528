import { Container, Row } from "react-bootstrap";
import { useThemeContext } from "../../../context";
import NavbarRight from "./NavbarRight";
import NavbarLeft from "./NavbarLeft";

const Navbar = () => {
  const { theme } = useThemeContext();

  return (
    <section
      className="headerMainCont"
      style={{ background: theme.background,  }}
    >
      <Container fluid>
        <Row>
          <NavbarLeft />
          <NavbarRight />
        </Row>
      </Container>
    </section>
  );
};

export default Navbar;
