import React, { useState } from "react";

const TimeList = ({ setTimer }) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const time = Array.from({ length: 60 }, (_, index) => index + 1);

  const handleTimeClick = (index) => {
    setSelectedTime(index);
    setTimer((prev) => ({ ...prev, value: index + 1 }));
  };

  return (
    <div>
      {time.map((item, index) => (
        <div
          className={`clockRow ${
            selectedTime === index ? "selectedclockRow" : ""
          }`}
          onClick={() => handleTimeClick(index)}
        >{`${item} minutes`}</div>
      ))}
    </div>
  );
};

export default TimeList;
