import { useLanguageContext, useThemeContext } from "../../../../../../context";
import { useNavigate } from "react-router-dom";
import withObservables from "@nozbe/with-observables";
import TableBody from "../../../../../common/TableContainer/TableBody";
import { Button } from "../../../../../common";
import {
  PAYMENT,
  PREPARING,
  PRINT_BILL,
  READY,
  SERVE,
  capitalizeFirstAlphabet,
  formatDate,
  formatNum,
} from "../../../../../../constants";

const List = ({ order, customer, user, waiter, table }) => {
  let navigate = useNavigate();
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const tableAction = [
    {
      title: I18n.edit,
    },
    {
      title: I18n.details,
    },
    {
      title: I18n.delete_order,
    },
  ];


  const navigateToDetail = () => {
    if (order.status === "complete") {
      navigate(`/orders/${order.number}`);
    } else {
      navigate(`/restaurant-pos?id=${order?.number}`);
    }
  };

  const getButtonTitle = () => {
    let orderTitle = "ready";
    if (order.status === PREPARING) {
      orderTitle = READY;
    }
    if (order.status === READY) {
      orderTitle = SERVE;
    }
    if (order.status === SERVE) {
      orderTitle = PRINT_BILL;
    }
    if (order.status === PRINT_BILL) {
      orderTitle = PAYMENT;
    }
    // if (order.status === PAYMENT) {
    //   redirectToPayment();
    // }
    return orderTitle;
  };

  const handleButtonClick = async (e) => {
    e.stopPropagation();
    const orderStatus = e.target.innerText?.toLowerCase();
    await order.updateOrderStatus(orderStatus);
    if (order.status === PAYMENT) {
      redirectToPayment();
    }
  };

  function redirectToPayment() {
    navigate(`/pos/billing?id=${order?.number}`);
  }

  return (
    <TableBody
      className={`tenColumn cursorPointer`}
      style={{ borderColor: theme.posRightBg }}
      onClick={navigateToDetail}
    >
      <div className="BoxWidth justifyCenter itemListBox">
        <p className="fontSize14" style={{ color: theme.barclaysBlue }}>
          {order?.number}
        </p>
      </div>
      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {formatDate(order?.started_at)}
        </p>
      </div>
      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {order?.type}
        </p>
      </div>
      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {table?.name}
        </p>
      </div>

      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {customer?.name || "-"}
        </p>
      </div>

      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.barclaysBlue }}
        >
          {order.type === "dinein" ? waiter?.name || "Add waiter" : "-"}
        </p>
      </div>

      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          {order?.type === "delivery" ? "Shoaib" : "-"}
        </p>
      </div>
      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.brightOrange }}
        >
          {order.status}
        </p>
      </div>
      <div className="BoxWidth justifyCenter">
        <p
          className="fontSize14 OneLineTruncate"
          style={{ color: theme.white }}
        >
          Rs. {formatNum(order?.total)}
        </p>
      </div>
      <div className="BoxWidth justifyCenter">
        <Button
          title={capitalizeFirstAlphabet(getButtonTitle())}
          handleClick={(e) => handleButtonClick(e)}
          className={`${getButtonTitle()}Btn   fontWeight400 fontSize14 cursorPointer`}
        />
      </div>
    </TableBody>
  );
};

const enhance = withObservables(["order"], ({ order }) => ({
  customer: order.customer.observe(),
  waiter: order.waiter.observe(),
  user: order.user.observe(),
  table: order.dine_in_table.observe(),
}));

export default enhance(List);
