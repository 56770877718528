import {Model, Q} from '@nozbe/watermelondb';
import {DISCOUNT_BUSINESS_SCHEMA, DISCOUNT_SCHEMA} from '../schema';
import {field, text, date, relation, writer, children, lazy} from '@nozbe/watermelondb/decorators';

export default class Discount extends Model {
    static table = DISCOUNT_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        order: {type: 'has_many', foreignKey: 'discount_id'},
        discount_business: {type: 'has_many', foreignKey: 'discount_id'},
    };

    @text('name') name;
    @text('tax_behavior') tax_behavior;
    @text('type') type;
    @field('limit') limit;
    @field('max_discount_cap') max_discount_cap;
    @field('archive') archive;
    @field('draft') draft;
    @field('is_inclusive') is_inclusive;
    @field('total_given') total_given;

    @relation('account', 'account_id') account;

    @children('order') order;
    @children('discount_business') discount_business;

    @lazy getDiscBusiness = () => this.discount_business.extend(Q.where('business_id', localStorage.getItem('business_id')));

    @writer async createDiscBuss(payload) {
        return await this.collections.get(DISCOUNT_BUSINESS_SCHEMA).create(item => {
            item.discount.set(this);
            item.business.set(payload.business);
        });
    }

    @writer async updateTotalGiven(amount) {
        return await this.update(discount => {
            discount.total_given = discount.total_given + amount;
        });
    }

    @writer async updateDetails(details) {
        return await this.update(discount => {
            discount.name = details.name;
            discount.type = details.type;
            discount.limit = details.limit;
            discount.max_discount_cap = details.max_discount_cap;
            discount.is_inclusive = details.is_inclusive;
            discount.draft = details.draft;
        });
    }

    @writer async archiveDiscount(status = true) {
        return await this.update(discount => {
            discount.archive = status;
        });
    }
    @writer async draftDiscount(status = true) {
        return await this.update(discount => {
            discount.draft = status;
        });
    }
}
