import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {DateSelector, TextField, Header} from '../../common';
import {CITY, PROVINCE, validateCreateCustomerForm, toSnakeCase, Country_Name} from '../../../constants';
import {useNavigate} from 'react-router-dom';
import {Sync} from '../../../api';
import {fetchOrderByNum, observeGetOrderById} from '../../../pos-core/database/helpers';

const CreateWaiterComponent = ({handleSelect, waiter}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast} = useAuthContext();

    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    let navigate = useNavigate();

    const [name, setName] = useState(waiter?.name || '');
    const [phone, setPhone] = useState(waiter?.phone_number || '');
    const [email, setEmail] = useState(waiter?.email || '');
    const [dob, setDob] = useState(waiter?.dob || '');
    const [province, setProvince] = useState(waiter?.province || '');
    const [country, setCountry] = useState(waiter?.country || '');
    const [city, setCity] = useState(waiter?.city || '');
    const [address, setAddress] = useState(waiter?.address || '');
    const [errors, setErrors] = useState({});

    const handleEnterPress = event => {
        if (event.key === 'Enter' && name && phone.length == 11 && !Object.keys(errors).length) {
            handleCreateWaiter();
        }
    };

    const onChange = (label, val) => {
        if (label == I18n.full_name) setName(val);
        else if (label == I18n.phone) setPhone(val);
        else if (label == I18n.email) setEmail(val);
        else if (label == I18n.province) setProvince(val.name);
        else if (label == I18n.country) setCountry(val.name);
        else if (label == I18n.city) setCity(val.name);
        else if (label == I18n.address) setAddress(val);
        else if (label === I18n.date_of_birth) setDob(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    async function handleCreateWaiter(val) {
        let order = null;

        try {
            const paramsForWaiter = {
                name,
                phone,
                email,
                dob: new Date(dob).getTime(),
                province,
                city,
                country,
                address,
            };
            let createdWaiter;
            if (val !== 'saveDraft') {
                const formErrors = await validateCreateCustomerForm(paramsForWaiter);
                setErrors(formErrors);
                if (Object.keys(formErrors).length > 0) return;
            }

            if (waiter) {
                createdWaiter = await waiter.updateDetails(paramsForWaiter);
            } else {
                createdWaiter = await business.createWaiter(paramsForWaiter);

                if (orderNumber) {
                    order = await fetchOrderByNum(orderNumber);
                    await order[0]?.updateWaiter(createdWaiter);
                }
            }

            if (val === 'saveDraft') {
                createdWaiter.addCustomertoDraft();
            }

            order ? navigate(`/restaurant-pos?id=${order[0]?.number}`) : navigate(-1);
            setRenderToast('waiter_created');
            await Sync(user.email);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                saveCta={{
                    title: I18n.save,
                    action: () => handleCreateWaiter('save'),
                }}
                primaryCta={{
                    title: I18n.save_draft,
                    action: () => handleCreateWaiter('saveDraft'),
                }}
            />

            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className="signupTextBox">
                        <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {I18n.create_waiter}
                        </h3>
                        <h3 className="fontSize20 fontWeight00 marBot10 marTop0" style={{color: theme.text}}>
                            {I18n.waiter_details}
                        </h3>
                    </div>

                    <div className="itemFields">
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField onChange={onChange} error={errors['name']} label={I18n.full_name} placeholder={I18n.enter_name} value={name} />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    onChange={onChange}
                                    error={errors['phone_number']}
                                    label={I18n.phone}
                                    placeholder={I18n.enter_phone_number}
                                    value={phone}
                                    type={'numeric'}
                                    maxLength={11}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField onChange={onChange} error={errors.email} label={I18n.email} placeholder={I18n.enter_email_address} value={email} />
                            </div>
                            <div className="priceSectionInputRight">
                                <DateSelector label={I18n.date_of_birth} disableFutureDate={true} value={dob} setValue={onChange} />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    label={I18n.country}
                                    placeholder={I18n.select_country}
                                    data={Country_Name}
                                    value={country}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.inputText}
                                    suffix_width={'18'}
                                    suffix_height={'18'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    label={I18n.province}
                                    placeholder={I18n.select_province}
                                    data={PROVINCE}
                                    value={province}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.inputText}
                                    suffix_width={'18'}
                                    suffix_height={'18'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    label={I18n.city}
                                    placeholder={I18n.select_city}
                                    onChange={onChange}
                                    data={CITY}
                                    value={city}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.inputText}
                                    suffix_width={'18'}
                                    suffix_height={'18'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField onChange={onChange} errors={errors} label={I18n.address} placeholder={I18n.enter_address} value={address} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CreateWaiterComponent;
