import { useAuthContext, useLanguageContext } from "../../../../../context";
import { usePurchaseOrder } from "../../../../../hooks";
import { Loading, OrderDetailCompnent } from "../../../../common";
import CreatePurchaseOrder from "../CreatePurchaseOrder";

const PurchaseOrderDetails = () => {
  const { business } = useAuthContext();

  const { isLoading, purchaseOrder, companyDetails, poLine } =
    usePurchaseOrder(business);

  return isLoading ? (
    <Loading />
  ) : (
    <CreatePurchaseOrder
      purchaseOrder={purchaseOrder}
      companyDetails={companyDetails}
      poLine={poLine}
    />
  );
};

export default PurchaseOrderDetails;
