import { database } from "..";
import {
  BUSINESS_SCHEMA,
  PURCHASE_ORDER_SCHEMA,
  COMPANY_SCHEMA,
} from "../schema";
import { Q } from "@nozbe/watermelondb";

const purchaseOrder = database.collections.get(PURCHASE_ORDER_SCHEMA);

export const findPurchaseOrderByID = async (id) => await purchaseOrder.find(id);

export const observeCreatedAndDraftPO = () =>
  purchaseOrder
    .query(
      Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
      Q.where("status", Q.notEq("active")),
      Q.where("archive", Q.notEq(true))
    )
    .observe();

export const observeActivePO = () =>
  purchaseOrder
    .query(
      Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
      Q.where("status", "active"),
      Q.where("archive", Q.notEq(true))
    )
    .observe();

export const observeArchivedPO = () =>
  purchaseOrder
    .query(
      Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
      Q.where("archive", Q.eq(true))
    )
    .observe();
export const observeCreatedPO = () =>
  purchaseOrder
    .query(
      Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
      Q.where("status", "created"),
      Q.where("archive", Q.notEq(true))
    )
    .observe();

export const observePOCount = async () =>
  await purchaseOrder
    .query(Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")))
    .fetchCount();

export const searchPO = async (searchTerm) =>
  purchaseOrder
    .query(
      Q.experimentalJoinTables([COMPANY_SCHEMA]),
      Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
      Q.or(
        Q.on(COMPANY_SCHEMA, Q.where("name", Q.like(`%${searchTerm}%`))),
        Q.where("number", Q.like(`%${searchTerm}%`))
      ),
      Q.where("archive", Q.notEq(true))
    )
    .fetch();
