import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const ThreeColumnButton = ({
  actionButton,
  className,
  background,
  borderColor,
  txtColor,
}) => {
  const { theme } = useThemeContext();
  return (
    <button
      className={`buttonStyle ${className}`}
      disabled={actionButton.disabled}
      onClick={actionButton.action}
      style={{
        backgroundColor: background,
        borderColor: borderColor,
      }}
    >
      <p className="btnIconBox">
        <Icon
          name={actionButton.name}
          fill={actionButton.fill}
          height={actionButton.height || "19"}
          width={actionButton.width || "20"}
          viewBox={actionButton.viewBox || "0 0 16 15"}
        />
      </p>
      <p className="btnDetail">
        <span className="btnText OneLineTruncate" style={{ color: txtColor }}>
          {actionButton.title}
        </span>
        <span className="btnValue OneLineTruncate" style={{ color: txtColor }}>
          {actionButton.value}
        </span>
      </p>
    </button>
  );
};

export default ThreeColumnButton;
