import {useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';

const ImageUploader = ({image, toggleImageModal}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <>
            {image ? (
                <div className="profileImageView" onClick={toggleImageModal}>
                    <img src={image} alt="" title="" />
                    <div className="imageEditBox cursorPointer">
                        <p className="marBot0 fontSize14" style={{color: theme.white}}>
                            {I18n.change}
                        </p>

                        <p className="marBot0">
                            <Icon name="editIcon2" fill={theme.barclaysBlue} width="18" height="18" viewBox={'0 0 18 18'} />
                        </p>
                    </div>
                </div>
            ) : (
                <div className="profileImageUpload">
                    <div className="imageConatiner" onClick={toggleImageModal}>
                        <Icon name={'cameraIcon'} fill={theme.white} width={'31'} height={'22'} viewBox={'0 0 31 22'} />
                        <p className="fontSize12 fontWeight400 marTop10 marBot0 textCenter imageUploadLebel">
                            <span style={{color: theme.darkGrayTwo}}>{I18n.drag_image} </span>
                            <span style={{color: theme.darkGrayTwo}}>{I18n.or} </span>
                            <span style={{color: theme.barclaysBlue}}>{I18n.upload} </span>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageUploader;
