import {useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';
import {firstLetterCaptilize} from '../../../constants';
import ImagePicker from '../ImagePicker';

const CsvList = ({value, errors, index, handleUpadteImage}) => {
    const {theme} = useThemeContext();

    const {barcode, cost_per_item, description, id, low_stock_alert, name, price, qty, units, category, image} = value;

    let imageId = index + name;

    const handleUploadImage = e => {
        try {
            let val = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(val);
            reader.onload = () => {
                if (e.target.id == `file-ip-${imageId}`) {
                    handleUpadteImage(reader?.result, value, index);
                }
            };
        } catch (e) {
            console.log(e.message);
        }
    };

    let is_invalid_item = {
        name: errors[id]?.name,
        category: errors[id]?.category,
        description: errors[id]?.description,
        price: errors[id]?.price,
        cost_per_item: errors[id]?.cost_per_item,
        qty: errors[id]?.qty,
        low_stock_alert: errors[id]?.low_stock_alert,
        barcode: errors[id]?.barcode,
        unit: errors[id]?.unit,
    };

    return (
        <>
            <TableBody className={`fiveColumn cursorPointer`} style={{borderColor: theme.posRightBg}}>
                <div className="BoxWidth justifyStart itemListBox" key={id}>
                    <p
                        className="fontSize14"
                        style={{
                            color: is_invalid_item?.name ? theme.red : theme.white,
                        }}
                        title={name}>
                        <span className="itemListImage">
                            <ImagePicker image={image} imageId={imageId} handleImageUpload={handleUploadImage} />
                        </span>

                        <span className="OneLineTruncate">{firstLetterCaptilize(name || '-')}</span>
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        title={category}
                        style={{
                            color: is_invalid_item?.category ? theme.red : theme.white,
                        }}>
                        {category || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        title={description}
                        style={{
                            color: is_invalid_item?.description ? theme.red : theme.white,
                        }}>
                        {description || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid_item?.price ? theme.red : theme.white,
                        }}
                        title={price}>
                        {price || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid_item?.cost_per_item ? theme.red : theme.white,
                        }}
                        title={cost_per_item}>
                        {cost_per_item || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid_item?.qty ? theme.red : theme.white,
                        }}
                        title={qty}>
                        {qty || '0'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid_item?.low_stock_alert ? theme.red : theme.white,
                        }}
                        title={low_stock_alert}>
                        {low_stock_alert || '0'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid_item?.barcode ? theme.red : theme.white,
                        }}
                        title={barcode}>
                        {barcode || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color: is_invalid_item?.unit ? theme.red : theme.white,
                        }}
                        title={units}>
                        {units || '-'}
                    </p>
                </div>
            </TableBody>
        </>
    );
};

export default CsvList;
