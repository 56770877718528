import { CustomContainer } from "../../../../common";
import CreateWaiterComponent from "../../../../common/CreateWaiterComponent";

const CreateWaiter = () => {
  return (
    <>
      <CustomContainer className="addScreenMainBox  createCustomerContainer">
        <CreateWaiterComponent />
      </CustomContainer>
    </>
  );
};

export default CreateWaiter;
