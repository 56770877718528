import {Col, Row, Container} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import {Header, NonIdealScreen, ToastBox} from '../../../../common';
import {filterUserBasedRoles, observeAccountActiveUsers, observeAccountInActiveUsers, observeActiveUsers, observeInActiveUsers} from '../../../../../pos-core/database/helpers';
import withObservables from '@nozbe/with-observables';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT, capitalizeFirstAlphabet} from '../../../../../constants';
import List from './List';
import {useSearch} from '../../../../../hooks';
import useFilter from '../../../../../hooks/useFilter';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';

const TeamList = ({activeUsers, inactiveUsers}) => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {role, setRenderToast, renderToast} = useAuthContext();
    const userPermission = usePermission('team');

    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'team_member_created') {
            setShowMessage({
                visible: true,
                message: I18n.team_member_created_successfully,
            });
        } else if (renderToast === 'team_member_update') {
            setShowMessage({
                visible: true,
                message: I18n.team_member_updated_successfully,
            });
        }
    }, [renderToast]);

    const handleCreateTeamMember = () => {
        navigate('/team/create');
    };

    const handleImportCustomer = () => {
        // handleImportCustomer;
    };

    const {text, setText, searchList} = useSearch(handleSearch);
    const {filteredList, setOption, option} = useFilter(handleFilter);

    const filterOptions = role?.map(item => ({
        title: capitalizeFirstAlphabet(item.name),
        action: () => handleFilter(item.name),
        checked: option === item.name ? true : false,
    }));

    const extraOpt = [
        {
            title: I18n.all,
            action: () => handleFilter('all'),
            checked: option === 'all' || option === '' ? true : false,
        },
        {
            title: I18n.archive,
            action: () => handleFilter('archived'),
            checked: option === 'archived' ? true : false,
        },
    ];

    const filterOption = [...extraOpt, ...filterOptions];

    function handleSearch(val) {
        return (option ? filteredList : activeUsers).filter(x => x.phone_number?.toLowerCase().includes(val.toLowerCase()) || x.name?.toLowerCase().includes(val.toLowerCase()));
    }

    async function handleFilter(selected) {
        if (selected == 'all' || selected == '') {
            setOption('');
            return activeUsers;
        } else {
            setOption(selected);

            return selected == 'archived' ? inactiveUsers : await filterUserBasedRoles(selected);
        }
    }

    const onChange = (label, val) => {
        setText(val);
    };

    console.log('option', option);
    const renderList = text ? searchList : option == 'archived' ? inactiveUsers : filterOption.some(item => item?.title?.toLowerCase() == option) ? filteredList : activeUsers;
    return activeUsers ? (
        <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
            <Header
                type="search"
                leftCta={{
                    title: capitalizeFirstAlphabet(option) || I18n.all,
                    name: 'downIcon',
                    fill: theme.white,
                    width: '12',
                    height: '9',
                    viewBox: '0 0 8 5',
                    option: filterOption,
                }}
                className={'borderRadiusTopleftTopRigt'}
                title={`${I18n.teams} (${Object.values(activeUsers).length})`}
                search={{
                    prefix: {
                        name: 'search2Icon',
                        fill: theme.white,
                    },
                    type: 'pos',
                    placeholder: I18n.search_team_member_by_phone,
                    onChange: onChange,
                }}
                rightCta={userPermission?.canCrud ? {title: I18n.create_member, action: handleCreateTeamMember} : null}
            />
            <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <TableHeader className={`sixColumn`} style={{borderColor: theme.posRightBg}}>
                                <div className="BoxWidth justifyStart">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.name}
                                    </p>
                                </div>

                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.phone_num}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.last_visited}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.role}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.created}
                                    </p>
                                </div>
                                <div className="BoxWidth justifyCenter">
                                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                        {I18n.location}
                                    </p>
                                </div>

                                {userPermission?.canCrud && (
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.action}
                                        </p>
                                    </div>
                                )}
                            </TableHeader>

                            <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                {renderList?.length === 0 ? (
                                    <NonIdealScreen
                                        heading={text ? I18n.search : null}
                                        subHeading={text ? I18n.we_couldnt_find_any_results_for_your_search : null}
                                        name={text ? 'seacrhNotFoundIcon' : null}
                                        fill={theme.white}
                                        isMultiStep={false}
                                        tablenonIdeal={true}
                                    />
                                ) : (
                                    renderList?.map((val, index) => <List membersDetails={val} userPermission={userPermission} />)
                                )}
                            </div>
                            <ToastBox
                                className="addSuccessfullyToast"
                                ToastVisiable={showMessage.visible}
                                bodyPara={showMessage.message}
                                setShowMessage={setShowMessage}
                                showIcon={true}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    ) : (
        <NonIdealScreen
            heading={I18n.team_member}
            subHeading={I18n.create_and_manage_your_team}
            name="teamNewIcon"
            fill={theme.white}
            secondaryCta={
                userPermission?.canCrud
                    ? {
                          title: I18n.create_member,
                          action: handleCreateTeamMember,
                      }
                    : null
            }
            isMultiStep={false}
        />
    );
};

const enhance = withObservables([], () => {
    const is_hq = JSON.parse(localStorage.getItem('is_hq'));

    return {
        activeUsers: is_hq === true ? observeAccountActiveUsers() : observeActiveUsers(),
        inactiveUsers: is_hq === true ? observeAccountInActiveUsers() : observeInActiveUsers(),
    };
});

export default enhance(TeamList);
