import {Model, Q} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date, lazy} from '@nozbe/watermelondb/decorators';
import {CATEGORY_SCHEMA, PRODUCT_BUSINESS_SCHEMA} from '../schema';

export default class Category extends Model {
    static table = CATEGORY_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        item: {type: 'has_many', foreignKey: 'category_id'},
        product_template: {type: 'has_many', foreignKey: 'category_id'},
        product: {type: 'has_many', foreignKey: 'category_id'},
    };

    @text('name') name;
    @text('color_code') color_code;
    @field('archive') archive;

    @children('item') item;
    @children('product_template') product_template;
    @children('product') product;

    @relation('account', 'account_id') account;

    @lazy getActiveProducts = async (currentPage = 0, itemsPerPage = 10, searchTerm = '') => {
        const data = await this.product
            .extend(
                Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
                Q.where('archive', Q.notEq(true)),
                Q.or(Q.where('name', Q.like(`%${searchTerm}%`)), Q.where('barcode', Q.like(`%${searchTerm}%`))),
                Q.skip(currentPage * itemsPerPage),
                Q.take(itemsPerPage),
                Q.sortBy('name', Q.asc),
            )
            .fetch();

        const totalCount = await this.product
            .extend(Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('archive', Q.notEq(true)), Q.sortBy('name', Q.asc))
            .fetchCount();

        return {
            data,
            totalCount,
        };
    };

    @writer async updateDetails(details) {
        return await this.update(category => {
            category.name = details.name;
            category.color_code = details.color_code;
        });
    }
}
