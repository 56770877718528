import withObservables from '@nozbe/with-observables';
import useTeam from '../../../../../hooks/useTeam';
import {Loading} from '../../../../common';
import CreateTeam from '../CreateTeam';
import {observeAccountActiveUsers, observeActiveUsers, observeRoles} from '../../../../../pos-core/database/helpers';

const EditTeamMember = ({users, roles}) => {
    const {isLoading, memberDetails} = useTeam({users, roles});

    return isLoading ? <Loading /> : <CreateTeam member={memberDetails} />;
};

const enhance = withObservables([], () => {
    const is_hq = JSON.parse(localStorage.getItem('is_hq'));
    return {
        users: is_hq === true ? observeAccountActiveUsers() : observeActiveUsers(),
        roles: observeRoles(),
    };
});

export default enhance(EditTeamMember);
