import React, { useState } from "react";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../context";
import { Button } from "../../../../common";
import Icon from "../../../../../assets/icons";
import {
  DEVICE_HEIGHT,
  formatDate,
  formatStringAndCapitalize,
  formatTime,
  formatTimeInSec,
  getColors,
  getOrderStatusColor,
  isDisableRecall,
} from "../../../../../constants";
import { updateKitchenOrder } from "../../../../../api";

const OrderDetail = ({ order, setSidebar, setShowMessage }) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const { deviceConfiguration } = useAuthContext();
  const {
    order_id,
    created_at,
    completed_at,
    waiter_name,
    order_type,
    status,
    order_line,
    time_taken,
    process_staus,
  } = order || {};
  const { color } = getOrderStatusColor(process_staus);

  const recallOrder = async () => {
    try {
      const status = "recall";
      const orderPayload = {
        secret_key: deviceConfiguration?.secret_key,
        order_id: order_id,
        line_ids: [],
        status: status,
      };

      await updateKitchenOrder(orderPayload);
      setShowMessage({
        visible: true,
        message: `Order #${order_id} recalled`,
      });
      setSidebar(null);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="orderDetailWrapper" style={{ height: DEVICE_HEIGHT - 220 }}>
      <div className="recallModalleftSide width50">
        <p
          className="fontSize16 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {I18n.order_details}
        </p>
        <Button
          className={`${
            isDisableRecall(order) ? "grayColoredDisableCta" : "secondaryCta"
          } secondaryCta width60 height35 fontWeight600 fontSize14 cursorPointer marTop20`}
          title={isDisableRecall(order) ? I18n.paid : I18n.recall_order}
          disabled={isDisableRecall(order)}
          //   saveLoad={isLoading ? true : false}
          handleClick={() => recallOrder(order)}
        />
        <p
          className="marTop30 fontSize24 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {waiter_name}
        </p>
        <p
          className="fontSize18 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {`Order #${order_id}`}
        </p>

        <p
          className="marTop30 fontSize12 marBot0 fontWeight400"
          style={{ color: theme.lightGrayTwo }}
        >
          Date
        </p>

        <p
          className="fontSize18 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {formatDate(created_at)}
        </p>

        <p
          className="marTop30 fontSize12 marBot0 fontWeight400"
          style={{ color: theme.lightGrayTwo }}
        >
          Time
        </p>

        <p
          className="fontSize18 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {formatTime(completed_at, false)}
        </p>

        <p
          className="marTop30 fontSize12 marBot0 fontWeight400"
          style={{ color: theme.lightGrayTwo }}
        >
          Order type
        </p>

        <p
          className="fontSize18 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {formatStringAndCapitalize(order_type)}
        </p>

        <p
          className="marTop30 fontSize12 marBot0 fontWeight400"
          style={{ color: theme.lightGrayTwo }}
        >
          Time taken
        </p>

        <p
          className="fontSize18 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {time_taken}
        </p>

        <p
          className="marTop30 fontSize12 marBot0 fontWeight400"
          style={{ color: theme.lightGrayTwo }}
        >
          Order status
        </p>

        <p className={`fontSize18 marBot0 fontWeight400 ${color}`}>{status}</p>
      </div>

      <div className="recallModalRightSide width50">
        <p
          className="fontSize16 marBot0 fontWeight400"
          style={{ color: theme.white }}
        >
          {I18n.order_items}
        </p>

        {order_line?.map((item) => {
          return (
            <div className="marTop20 ">
              <div className="flex justifySpacebetween">
                <p
                  className="fontSize16 marBot0 fontWeight600"
                  style={{ color: theme.lightGrayTwo }}
                >
                  {item.name}
                </p>

                <div className="flex gap10 verticalCenter">
                  {item.status === "completed" ? (
                    <Icon name="tickFilled" viewBox="0 0 25 25" />
                  ) : item.status === "cancel" ? (
                    <Icon name="crossFilled" viewBox="0 0 17 17" />
                  ) : null}

                  <p
                    className={`fontSize14 fontWeight600 marBot0 lightGrayTwo"`}
                    style={{ color: theme.lightGrayTwo }}
                  >
                    {`x${item.qty}`}
                  </p>
                </div>
              </div>
              {item.addon.map((addons) => {
                return (
                  <div className="flex justifySpacebetween">
                    <p
                      className="fontSize14 marBot0 fontWeight400"
                      style={{ color: theme.lightGrayTwo }}
                    >
                      {addons.name}
                    </p>

                    {/* <p
                  className={`fontSize14 fontWeight400 marBot0 lightGrayTwo"`}
                  style={{ color: theme.white }}
                >
                  00:05:54
                </p> */}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderDetail;
