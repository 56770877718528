import Icon from "../../../assets/icons";
import { useThemeContext } from "../../../context";

const TwoColumnButton = ({
  actionButton,
  className,
  background,
  borderColor,
}) => {
  const { theme } = useThemeContext();
  return (
    <button
      className={`buttonStyle ${className}`}
      disabled={actionButton.disabled}
      onClick={actionButton.action}
      style={{
        backgroundColor: background,
        borderColor: borderColor,
      }}
    >
      <p className="btnDetail">
        <span
          className="btnText OneLineTruncate"
          style={{ color: theme.white }}
        >
          {actionButton.title}
        </span>
        <span
          className="btnValue OneLineTruncate"
          style={{ color: theme.white }}
        >
          {actionButton.value}
          {actionButton.valueOne}
        </span>
      </p>
    </button>
  );
};

export default TwoColumnButton;
