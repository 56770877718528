import newImagePlaceholder from '../../../assets/images/newImagePlaceholder.svg';

const ImagePicker = ({handleImageUpload, image, imageId}) => {
    return (
        <div className="imagePickerConatiner">
            <label for={`file-ip-${imageId}`}>
                <div>
                    <img src={image ? image : newImagePlaceholder} alt="item image" title="" style={{resizeMode: 'cover'}} />
                </div>
            </label>
            <input id={`file-ip-${imageId}`} type="file" accept="image/*" onChange={handleImageUpload} />
        </div>
    );
};

export default ImagePicker;
