import InfiniteScroll from 'react-infinite-scroll-component';
import CategoryItem from './CategoryItem';

const CategoryList = ({data, handleCategory, fetchMoreData, hasMoreCategory}) => {
    return (
        <>
            <InfiniteScroll
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(137px, 1fr))',
                    gap: '10px',
                }}
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMoreCategory}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableItemsWrapper">
                {data.map((category, index) => (
                    <CategoryItem category={category} key={index} onPress={handleCategory} />
                ))}
            </InfiniteScroll>
        </>
    );
};

export default CategoryList;
