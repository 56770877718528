import { CreateSalestaxComponent, CustomContainer } from "../../../../common";

const CreateSalesTax = () => {
  return (
    <>
      <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox  createCustomerContainer"
      >
        <CreateSalestaxComponent />
      </CustomContainer>
    </>
  );
};

export default CreateSalesTax;
