import {Model} from '@nozbe/watermelondb';
import {COMPANY_SCHEMA, PURCHASE_ORDER_SCHEMA} from '../schema';
import {field, text, relation, children, date, writer} from '@nozbe/watermelondb/decorators';
import {uuid} from '../../constants';

export default class Company extends Model {
    static table = COMPANY_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        purchase_order: {type: 'has_many', foreignKey: 'company_id'},
    };

    @text('name') name;
    @text('email') email;
    @text('phone_number') phone_number;
    @text('country') country;
    @text('province') province;
    @text('city') city;
    @text('address') address;
    @text('postal_code') postal_code;
    @field('archive') archive;
    @field('draft') draft;

    @relation('account', 'account_id') account;

    @writer async archiveCompany(status = true) {
        return await this.update(company => {
            company.archive = status;
        });
    }

    @writer async updateDetails(details) {
        return await this.update(company => {
            company.name = details.name;
            company.email = details.email;
            company.phone_number = details.phone;
            company.country = details.country;
            company.province = details.province;
            company.city = details.city;
            company.address = details.address;
            company.postal_code = details.postal_code;
            company.archive = details.archive;
            company.draft = details.draft;
        });
    }
}
