import React from "react";
import {
  formatDate,
  formatDateComparison,
  formatNum,
} from "../../../constants";
import { useLanguageContext, useThemeContext } from "../../../context";

const BuyerSummary = ({
  orders = [],
  averageSpend = 0,
  totalSpent = 0,
  created_at = "--",
}) => {
  const { theme } = useThemeContext();
  const { I18n } = useLanguageContext();
  return (
    <>
      <div className="detailBotCol ">
        <p className="fontSize14 marBot0" style={{ color: theme.white }}>
          {I18n.buyer_Summary}
        </p>
      </div>
      <div className="rowConatainer ">
        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.first_visit}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {orders?.length ? formatDate(orders[0]?.completed_at) : I18n.never}
          </p>
        </div>

        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.last_visit}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {orders.length
              ? formatDate(orders[orders.length - 1]?.completed_at)
              : I18n.never}
          </p>
        </div>

        <div className="detailBotCol ">
          <p
            className="fontSize12 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.created}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {formatDateComparison(created_at)}
          </p>
        </div>
      </div>
      <div className="rowConatainer">
        <div className="detailBotCol ">
          <p
            className="fontSize12 fontWeight400 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.total_visits}
          </p>
          <p className="fontSize14 marBot0" style={{ color: theme.white }}>
            {orders.length}
          </p>
        </div>

        <div className="detailBotCol ">
          <p
            className="fontSize12 fontWeight400 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.average_spend}
          </p>
          <p
            className="fontSize14 marBot0"
            style={{ color: theme.white }}
          >{`Rs. ${formatNum(averageSpend || 0)}`}</p>
        </div>

        <div className="detailBotCol ">
          <p
            className="fontSize12 fontWeight400 marBot0"
            style={{ color: theme.lightGrayTwo }}
          >
            {I18n.total_spent}
          </p>
          <p
            className="fontSize14 marBot0"
            style={{ color: theme.white }}
          >{`Rs. ${formatNum(totalSpent || 0)}`}</p>
        </div>
      </div>
    </>
  );
};

export default BuyerSummary;
