import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLanguageContext, useThemeContext } from "../../../../context";
import Button from "../../Button";
import Otp from "../../Otp";

const Otpverifiction = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [otp, setOtp] = useState("");

  return (
    <>
      <div className="otpverifictionConatiner">
        <p
          className="marTop30 fontSize20 fontWeight400"
          style={{ color: theme.white }}
        >
          {I18n.customer_information}
        </p>
        <p style={{ color: theme.white }} className="fontSize16 fontWeight400">
          {`${I18n.oTP_code_has_been_sent_to} +93212478789`}
        </p>
        <div className="otpverification">
          <Otp otp={otp} setOtp={setOtp} numInputs={4} focus={true} />
        </div>
        {/* <div className="otpverificationTimer">
          <p
            className="fontSize16 fontWeight400 textCenter marTop20"
            style={{ color: theme.white }}
          >
            {`${I18n.sms_code_ka_wait_time} 00:37`}
          </p>
          <Spinner animation="border" variant="white" className="otpSpiner" />
        </div> */}
        <div>
          <p
            className="fontSize16 fontWeight400 textCenter marTop20"
            style={{ color: theme.white }}
          >
            {I18n.code_nahi_mila}
          </p>
          <div className="callAndSmsBox">
            <Button
              className={`headerCustomerButton`}
              title={I18n.sms}
              type="buttonTitleIcon"
              icon_name="smsIcon"
              icon_fill={theme.barclaysBlue}
              icon_width={"22"}
              icon_height={"17"}
              icon_viewBox={"0 0 22 17"}
            />
            <p className="headerTitleSeperator marLeft10 marRight10 height20"></p>
            <Button
              className={`headerCustomerButton`}
              title={I18n.call}
              type="buttonTitleIcon"
              icon_name="callIcon"
              icon_fill={theme.barclaysBlue}
              icon_width={"19"}
              icon_height={"18"}
              icon_viewBox={"0 0 19 18"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Otpverifiction;
