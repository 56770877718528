import {
  CreatePurchasetaxComponent,
  CustomContainer,
} from "../../../../common";

const CreatePurchaseTax = () => {
  return (
    <>
      <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox  createCustomerContainer"
      >
        <CreatePurchasetaxComponent />
      </CustomContainer>
    </>
  );
};

export default CreatePurchaseTax;
