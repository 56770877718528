import { Model } from "@nozbe/watermelondb";
import { relation } from "@nozbe/watermelondb/decorators";
import { DISCOUNT_BUSINESS_SCHEMA } from "../schema";

export default class DiscountBusiness extends Model {
  static table = DISCOUNT_BUSINESS_SCHEMA;

  static associations = {
    business: { type: "belongs_to", key: "business_id" },
    discount: { type: "belongs_to", key: "discount_id" },
  };

  @relation("business", "business_id") business;
  @relation("discount", "discount_id") discount;
}
