import { database } from "..";
import { uuid } from "../../constants";
import { PRODUCT_BUSINESS_SCHEMA } from "../schema";

const productBusiness = database.collections.get(PRODUCT_BUSINESS_SCHEMA);

const prepareInsertion = (locations, product, quantity) => {
  return locations.map((business) => {
    return productBusiness.prepareCreate((prodBus) => {
      prodBus._raw.id = uuid();
      prodBus.product.set(product);
      prodBus.business.set(business);
      prodBus.quantity = quantity;
    });
  });
};

export const bulkCreateProductBusiness = async ({
  locations,
  product,
  quantity,
}) => {
  return await database.write(async () => {
    const allRecords = await prepareInsertion(locations, product, quantity);
    await database.batch(...allRecords);
    return allRecords;
  });
};
