import { Toast } from "react-bootstrap";
import { useThemeContext } from "../../../context";
import Icon from "../../../assets/icons";
const DefaultToast = ({
  headerTitle = false,
  bodyPara,
  ToastVisiable = false,
  showHeader = false,
  showIcon = false,
  errorToast = false,
  className,
  setShowMessage,
}) => {
  const { theme } = useThemeContext();

  const hideToast = () => {
    setShowMessage({
      message: "",
      visible: false,
    });
  };
  return (
    <>
      {ToastVisiable ? (
        <Toast className={className} onClose={hideToast} show={ToastVisiable}>
          {showHeader && (
            <Toast.Header
              style={{ background: theme.topBarBG, color: theme.red }}
            >
              <p className="fontWeight500 fontSize18  marBot0">{headerTitle}</p>
            </Toast.Header>
          )}
          <Toast.Body style={{ color: theme.white }}>
            <div className="toastHeadingBox">
              {showIcon && (
                <Icon
                  name={errorToast ? "infocircleIcon" : "tickCircleIcon"}
                  fill={errorToast ? theme.red : theme.barclaysBlue}
                  height={18}
                  width={18}
                  viewBox="0 0 18 18"
                />
              )}
              <p className="fontSize16 marBot0 marLeft5">{bodyPara}</p>
            </div>
            <div className="toastIconBox">
              <Icon
                name="closeIcon"
                fill={theme.inputBorder}
                width={"13"}
                height={"13"}
                viewBox={"0 0 15 15"}
                onClick={hideToast}
              />
            </div>
          </Toast.Body>
        </Toast>
      ) : null}
    </>
  );
};
export default DefaultToast;
