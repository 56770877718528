import Icon from '../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../context';

const Breadcrums = ({selectedCategory, handleCategory, tabValue}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleHome = () => handleCategory(null);

    return (
        <div className="breadCrumBox">
            <ul>
                <li>
                    <a href="#" onClick={handleHome}>
                        {I18n.home}
                    </a>
                </li>

                {tabValue == 1 && selectedCategory ? (
                    <>
                        <li>
                            <Icon name={'forwardIcon'} fill={theme.white} width={'9'} height={'15'} viewBox={'0 0 9 15'} />
                        </li>
                        <li>
                            <a style={{color: theme.white}}>{selectedCategory.name}</a>
                        </li>
                    </>
                ) : null}
            </ul>
        </div>
    );
};

export default Breadcrums;
