import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useCustomer = (account) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [customer, setCustomer] = useState();
  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    handleCustomer(id);
  }, [id]);

  const handleCustomer = async () => {
    let customerList = await account.getCustomer(id).fetch();

    if (customerList.length) {
      let selectedCustomer = customerList[0];
      let selectedOrderCount =
        await selectedCustomer.getCompletedOrders.fetchCount();
      setOrderCount(selectedOrderCount);
      setCustomer(selectedCustomer);
    }

    setIsLoading(false);
  };

  return { isLoading, customer, orderCount };
};

export default useCustomer;
