import { Model, Q } from "@nozbe/watermelondb";
import {
  BUSINESS_SCHEMA,
  DINE_IN_TABLE_SCHEMA,
  FLOOR_PLAN_SCHEMA,
} from "../schema";
import {
  field,
  text,
  relation,
  children,
  writer,
  date,
  lazy,
} from "@nozbe/watermelondb/decorators";
import { uuid } from "../../constants";

export default class FloorPlan extends Model {
  static table = FLOOR_PLAN_SCHEMA;

  static associations = {
    business: { type: "belongs_to", key: "business_id" },
    dine_in_table: { type: "has_many", foreignKey: "floor_plan_id" },
    order: { type: "has_many", foreignKey: "floor_plan_id" },
  };

  @text("name") name;
  @text("type") type;
  @text("componentType") componentType;
  @date("created_at") created_at;
  @field("archive") archive;

  @relation("business", "business_id") business;

  @children("dine_in_table") dine_in_table;
  @children("order") order;

  @lazy getActiveFloorOrders = this.order.extend(
    Q.on(BUSINESS_SCHEMA, "id", localStorage.getItem("business_id")),
    Q.and(
      Q.where("status", Q.notEq("active")),
      Q.where("status", Q.notEq("complete")),
      Q.where("status", Q.notEq("void")),
      Q.where("status", Q.notEq("hold"))
    )
  );

  @lazy getTables = this.dine_in_table.extend(
    Q.where("archive", Q.notEq(true))
  );

  @writer async createDineInTable(details) {
    return await this.collections.get(DINE_IN_TABLE_SCHEMA).create((table) => {
      table.floor_plan.set(this);
      table._raw.id = uuid();
      table.name = details.name;
      table.height = details.height;
      table.width = details.width;
      table.seats = details.seats;
      table.shape = details.shape;
      table.xAxis = details.xAxis;
      table.yAxis = details.yAxis;
      table.xCoord = details.xCoord;
      table.yCoord = details.yCoord;
      table.created_at = new Date().getTime();
      table.archive = false;
      table.is_active = false;
    });
  }
}
