import {formatNum} from '../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import useFocus from '../../../../../hooks/useFocus';
import {Button, Calculator, SearchBox} from '../../../../common';

const CashMethod = ({handleDone, paymentType, setAmount, amount, total, change, givenPosDiscount, orderLines, customer, setShowMessage}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {textBoxRef} = useFocus(true);

    const handleChange = (label, value) => {
        setAmount(value);
    };

    const handleKeyPress = event => {
        if (event.keyCode == 13 && amount >= total) {
            handlePayment();
        }
    };

    const handlePayment = () => {
        if (paymentType?.name === 'credit' && !customer) {
            let message = 'Add customer first to proceed.';
            setShowMessage({
                visible: true,
                message: message,
            });
            return;
        } else {
            handleDone(paymentType?.name, total, change, givenPosDiscount);
        }
    };

    return (
        <>
            <div className="cashMainContainer">
                <div className="amountInputBox">
                    <SearchBox
                        type="pos"
                        placeholder={I18n.enter_amount}
                        onlyNumbers={true}
                        value={amount}
                        maxLength={10}
                        prefix={{
                            name: 'rsIcon2',
                            fill: theme.seperatorTwo,
                            height: 35,
                            width: 35,
                            viewBox: '0 0 34 34',
                        }}
                        onChange={handleChange}
                        inputRef={textBoxRef}
                    />
                </div>

                <div className="billScreenCalculator">
                    <Calculator className={`width100`} setState={setAmount} columnFour={true} maxLength={10} />
                </div>
                <div className="billScreenChangeButton">
                    <Button
                        backgroundColor={Number(amount) < Number(total) ? theme.seperatorTwo : theme.brightGreen}
                        txtColor={Number(amount) < Number(total) ? theme.lightGrayTwo : theme.white}
                        className={`width60 fontWeight500 fontSize18 cursorPointer`}
                        disabled={amount >= total && orderLines?.length ? false : true}
                        title={`${I18n.change} Rs. ${formatNum(change)} (Enter)`}
                        handleClick={handlePayment}
                    />
                </div>
            </div>
        </>
    );
};

export default CashMethod;
