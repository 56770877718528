import {useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';
import {firstLetterCaptilize, formatDateAndTimewithoutYear, formatNum} from '../../../constants';

const Listitem = ({transaction, customer}) => {
    const {theme} = useThemeContext();

    return (
        <TableBody className={`fourColumn`} style={{borderColor: theme.posRightBg}}>
            <div className="BoxWidth justifyStart marLeft30">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {customer?._raw?.name ? customer?._raw?.name : '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {`Rs. ${formatNum(transaction?.amount)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDateAndTimewithoutYear(transaction?.completed_at)}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {firstLetterCaptilize(transaction?.status)}
                </p>
            </div>
        </TableBody>
    );
};

export default Listitem;
