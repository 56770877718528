import {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Header, Loading, MessageModal, NonIdealScreen, TaxesModal, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';
import List from './List';
import {useSearch} from '../../../../../hooks';
import usePermission from '../../../../../hooks/usePermission';
import {observeActivePaymentMethod} from '../../../../../pos-core/database/helpers/payment_method';
import {observeUnArchivedSalesTax} from '../../../../../pos-core/database/helpers';

const PaymentTaxList = ({payment_method}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const [toggleTaxModal, setToggleTaxModal] = useState(false);
    const [tax, setTax] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);
    const userPermission = usePermission('payment-method-tax');

    function handleSearch(val) {
        const filterList = payment_method;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values));
    }

    const onChange = (label, value) => setText(value);

    const renderList = text ? searchList : payment_method;

    const handleSalesTax = async tax => {
        if (tax == 'remove_saleTax') {
            setTax('');
            await selectedPaymentMethod.updateSalesTax(null);
        } else {
            setTax(tax);
            await selectedPaymentMethod.updateSalesTax(tax);
        }
        setToggleTaxModal(false);
    };

    const handleSeletedPaymentMethed = val => {
        setSelectedPaymentMethod(val);
    };

    return (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.Payment_method_tax}`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    className={'borderRadiusTopleftTopRigt'}
                />

                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`threeColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol BoxWidth">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.name}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.custom_tax}
                                        </p>
                                    </div>

                                    {userPermission?.canCrud && (
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.action}
                                            </p>
                                        </div>
                                    )}
                                </TableHeader>
                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {renderList?.map((val, index) => {
                                        return (
                                            <List
                                                handleSeletedPaymentMethed={handleSeletedPaymentMethed}
                                                userPermission={userPermission}
                                                val={val}
                                                setToggleTaxModal={setToggleTaxModal}
                                            />
                                        );
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <TaxesModal setToggle={setToggleTaxModal} toggle={toggleTaxModal} handleSelect={handleSalesTax} data={selectedPaymentMethod} />
                    </Container>
                </div>
            </div>
        </>
    );
};

const enhance = withObservables([], () => ({
    payment_method: observeActivePaymentMethod(),
}));

export default enhance(PaymentTaxList);
