import { useEffect, useState } from "react";

const useSearch = (handleSearch) => {
  const [text, setText] = useState("");
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    handleSearchList(text);
  }, [text]);

  const handleSearchList = async (val) =>
    setSearchList(await handleSearch(val));

  return { text, setText, searchList };
};

export default useSearch;
