import { useEffect, useState } from "react";

const useCustomerOrdersCalculation = (orders) => {
  const [totalSpent, setTotalSpent] = useState(0);
  const [averageSpend, setAverageSpend] = useState(0);

  useEffect(() => {
    handleCalculation(orders);
  }, [orders]);

  const handleCalculation = (orders) => {
    let total = orders?.reduce((total, val) => total + val?.total, 0);

    setTotalSpent(total);
    setAverageSpend(total / orders.length);
  };

  return { totalSpent, averageSpend };
};

export default useCustomerOrdersCalculation;
