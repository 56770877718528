import { useThemeContext } from "../../../context";
import TableBody from "../../common/TableContainer/TableBody";
import { firstLetterCaptilize, formatNum } from "../../../constants";

const CsvList = ({ value, errors }) => {
  const { theme } = useThemeContext();

  const { name, type, limit, max_discount_cap, id } = value;

  let is_invalid_discount = {
    name: errors[id]?.name,
    type: errors[id]?.type,
    limit: errors[id]?.limit,
    max_discount_cap: errors[id]?.max_discount_cap,
  };

  return (
    <>
      <TableBody
        className={`fourColumn cursorPointer`}
        style={{ borderColor: theme.posRightBg }}
      >
        <div className="BoxWidth justifyStart" key={id}>
          <p
            className="fontSize14"
            style={{
              color: is_invalid_discount?.name ? theme.red : theme.white,
            }}
          >
            <span className="OneLineTruncate">
              {(name && firstLetterCaptilize(name)) || "-"}
            </span>
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{
              color: is_invalid_discount?.type ? theme.red : theme.white,
            }}
          >
            {firstLetterCaptilize(type || "-")}
          </p>
        </div>
        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{
              color: is_invalid_discount?.limit ? theme.red : theme.white,
            }}
          >
            {type == "percentage"
              ? `${formatNum(limit)}%`
              : `Rs. ${formatNum(limit)}`}
          </p>
        </div>

        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{
              color: is_invalid_discount?.max_discount_cap
                ? theme.red
                : theme.white,
            }}
          >
            {max_discount_cap || "-"}
          </p>
        </div>
      </TableBody>
    </>
  );
};

export default CsvList;
