import AppModal from '.';
import Icon from '../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../context';
import {Button, CheckBox, TextField, VariantOptionsList} from '../../common';
import withObservables from '@nozbe/with-observables';
import {useEffect, useState} from 'react';
import {useOptions} from '../../../hooks';
import {PosAppModal} from '../V2';
import {DEVICE_HEIGHT, uuid} from '../../../constants';

const VariantsOptionModal = ({toggle, setToggle, options, createdOption, handleCreateOption, handleCreateVariants}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {optionList, handleRemoveOption, handleAddOption} = useOptions(options, handleSelect, createdOption, toggle);

    const [selected, setSelected] = useState({});
    const [text, setText] = useState('');
    const [combinationList, setCombinationList] = useState([]);
    const [selectedCombinations, setSelectedCombinations] = useState({});

    const handleClose = event => {
        setSelected({});
        setSelectedCombinations({});
        setCombinationList([]);
        setText('');
        setToggle(false);
    };

    const onChange = val => {
        setSelectedCombinations(items => {
            let obj = {...items};
            let item = obj[val];

            if (item) delete obj[val];
            else
                obj[val] = {
                    name: val,
                    barcode: '',
                    quantity: 1,
                    costPrice: 0,
                    sellingPrice: 0,
                    lowStock: 0,
                };

            return obj;
        });
    };

    const handleSelectAllCombinations = () => {
        let obj = {};

        if (Object.keys(selectedCombinations).length != combinationList.length) {
            combinationList.map(
                val =>
                    (obj[val] = {
                        name: val,
                        barcode: '',
                        id: uuid(),
                        quantity: 1,
                        costPrice: '',
                        sellingPrice: '',
                        lowStock: 0,
                    }),
            );
        }

        setSelectedCombinations(obj);
    };

    function handleSelect(label, val) {
        if (!val) return;
        setSelected(values => {
            let obj = {...values};
            obj[val.name] = val.values;
            return obj;
        });

        handleRemoveOption(val);
    }

    const handleNext = () => {
        if (combinationList.length) {
            handleCreateVariants(selectedCombinations);
        } else {
            let data = Object.values(selected);

            let combinations = getCombinations(data);

            setCombinationList(combinations);
        }
    };

    const getCombinations = arr => {
        if (arr.length == 1) {
            return arr[0];
        } else {
            let ans = [];

            let otherCases = getCombinations(arr.slice(1));

            for (var i = 0; i < otherCases.length; i++) {
                for (var j = 0; j < arr[0].length; j++) {
                    ans.push(`${arr[0][j]}-${otherCases[i]}`);
                }
            }

            return ans;
        }
    };

    const handleDelete = (title, value) => {
        setSelected(selected => {
            let obj = {...selected};
            let arr = obj[title];

            if (arr.length == 1) {
                handleAddOption(title);
                delete obj[title];
            } else {
                obj[title] = arr.filter(x => x != value);
            }

            return obj;
        });
    };

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            handleClose={handleClose}
            title={I18n.back}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            bottomSaveCta={{
                title: I18n.next,
                action: () => handleNext(),
                disabled: Object.keys(selected).length ? false : true,
            }}
            bottomCancelCta={{
                title: I18n.cancel,
                action: () => handleClose(),
            }}>
            <div
                // className="selectCustomerListBox"
                style={{
                    height: DEVICE_HEIGHT - 150,
                }}>
                {combinationList.length ? (
                    <div className="variantOptionBody">
                        <p className="fontSize16 marBot20" style={{color: theme.white}}>
                            {I18n.variations_below_will_be_created}
                        </p>
                        <div className="variantPopupCheckbox">
                            <div className="allVaritionsBox" style={{borderColor: theme.tableBorder}}>
                                <CheckBox
                                    checkboxLabel={
                                        <p className="marBot0 fontSize16" style={{color: theme.text}}>
                                            {I18n.all_variations}
                                        </p>
                                    }
                                    onChange={handleSelectAllCombinations}
                                    checked={Object.keys(selectedCombinations).length == combinationList.length ? true : false}
                                />
                            </div>
                            <div className="variantsCheckboxList" style={{borderColor: theme.seperatorTwo}}>
                                {combinationList?.map(item => (
                                    <CheckBox
                                        checkboxLabel={
                                            <p className="marBot0 fontSize16" style={{color: theme.text}}>
                                                {item}
                                            </p>
                                        }
                                        onChange={() => onChange(item)}
                                        checked={selectedCombinations[item]}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="variantOptionBody">
                        <div className="variantOptionSets">
                            <TextField
                                label={I18n[Object.keys(selected).length ? 'add_additional_option_set' : 'add_option_set']}
                                placeholder={I18n.add_option_set}
                                onChange={handleSelect}
                                inputType="select"
                                data={optionList}
                                type="text"
                                suffix="downIcon2"
                                suffix_fill={theme.white}
                                suffix_width={'24'}
                                suffix_height={'24'}
                                suffix_viewBox={'0 0 18 18'}
                                value={text}
                                blurOnSelect
                                setOptionAsLabel
                            />
                            {Object.keys(selected) && (
                                <div>
                                    {Object.keys(selected)?.map(val => (
                                        <VariantOptionsList title={val} data={selected[val]} handleDelete={handleDelete} />
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="optionActionBox">
                            <Button
                                title={I18n.create_option}
                                type="buttonTitleIcon"
                                icon_name="newIcon"
                                icon_fill={theme.barclaysBlue}
                                icon_height="15"
                                icon_width="15"
                                icon_viewBox={'0 0 13 13'}
                                handleClick={handleCreateOption}
                                className={`addOptioButton width100 fontWeight400 fontSize16 cursorPointer marBot15`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </PosAppModal>
    );
};

const enhance = withObservables(['business'], ({business}) => ({
    options: business.option.observe(),
}));

export default enhance(VariantsOptionModal);
