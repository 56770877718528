import { useAuthContext, useLanguageContext } from "../../../../../context";
import { usePurchaseTax } from "../../../../../hooks";
import {
  CreatePurchasetaxComponent,
  CustomContainer,
  Loading,
} from "../../../../common";

const EditPurchaseTax = () => {
  const { business, user } = useAuthContext();

  const { isLoading, tax } = usePurchaseTax(business);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <CustomContainer
        reduceHeight={45}
        className="addScreenMainBox  createCustomerContainer"
      >
        <CreatePurchasetaxComponent tax={tax} />
      </CustomContainer>
    </>
  );
};

export default EditPurchaseTax;
