import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {firstLetterCaptilize, formatDate} from '../../../../../constants';

const List = ({item, company, userPermission}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);

    const navigateToDetail = () => {
        if (!userPermission?.canCrud) return;
        navigate(`/purchaseorder/${item.id}`);
    };

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async () => {
        setArchiveModal(false);
        await item.archivePO(item.archive ? false : true);
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: navigateToDetail,
        },
        {
            title: I18n[item.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody className={`eightColumn`} style={{borderColor: theme.posRightBg}} onClick={navigateToDetail}>
                <div className="BoxWidth justifyStart itemListBox">
                    <p className="fontSize14" style={{color: theme.white}}>
                        {item.number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{
                            color:
                                item?.status === 'approved'
                                    ? theme.brightGreen
                                    : item.status === 'rejected'
                                    ? theme.red
                                    : item.status === 'pending'
                                    ? theme.barclaysBlue
                                    : theme.darkGrayTwo,
                        }}>
                        {firstLetterCaptilize(item.status || '--')}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {item.po_date ? formatDate(item.po_date) : '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {item.po_expected_date ? formatDate(item.po_expected_date) : '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {company?.name}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        -
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {item.amount}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n[item.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive'] + item.number + '?'}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: handleArchive,
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables(['item'], ({item}) => ({
    company: item.company.observe(),
}));

export default enhance(List);
