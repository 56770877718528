import React from 'react';
import {Loading} from '../../../../common';
import CreateLocation from './CreateLocation';
import {useAuthContext} from '../../../../../context';
import useLocation from '../../../../../hooks/useLocation';

const EditLocation = () => {
    const {user} = useAuthContext();
    const {isLoading, location} = useLocation(user);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CreateLocation location={location} />
        </>
    );
};

export default EditLocation;
