import { createContext, useContext, useState } from "react";

export const MenuContext = createContext({
  isVisible: false,
  setIsVisible: () => {},
});

export const MenuProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <MenuContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
