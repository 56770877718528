import { Model, Q } from "@nozbe/watermelondb";
import {
  field,
  text,
  relation,
  children,
  writer,
  date,
  lazy,
} from "@nozbe/watermelondb/decorators";
import { WAITER_SCHEMA } from "../schema";

export default class Waiter extends Model {
  static table = WAITER_SCHEMA;

  static associations = {
    business: { type: "belongs_to", key: "business_id" },
    order: { type: "has_many", foreignKey: "waiter_id" },
  };

  @text("name") name;
  @text("phone_number") phone_number;
  @text("email") email;
  @text("province") province;
  @text("country") country;
  @text("city") city;
  @text("address") address;
  @field("archive") archive;
  @field("draft") draft;
  @date("dob") dob;
  @date("created_at") created_at;

  @children("order") order;

  @relation("business", "business_id") business;

  @lazy getCompletedOrders = this.order.extend(Q.where("status", "complete"));

  @writer async updateDetails(details) {
    return await this.update((waiter) => {
      waiter.name = details.name;
      waiter.phone_number = details.phone;
      waiter.email = details.email;
      waiter.dob = details.dob;
      waiter.province = details.province;
      waiter.city = details.city;
      waiter.country = details.country;
      waiter.address = details.address;
    });
  }

  @writer async archiveWaiter(status = true) {
    return await this.update((waiter) => {
      waiter.archive = status;
    });
  }

  @writer async addWaitertoDraft(status = true) {
    return await this.update((waiter) => {
      waiter.draft = status;
    });
  }
}
