import {FILTER_MONTHS, formatDateAndDays, formatHours} from '../../constants';
import {fetchCompletedOrders} from '../../pos-core/database/helpers';
import {useState} from 'react';

const useSaleByDate = () => {
    const [saleOverview, setSaleOverview] = useState({});
    const [loading, setLoading] = useState(true);

    const calNetAmount = async (orders, graphDates = {}, filter = {}) => {
        let total = 0,
            count = 0;

        await Promise.all(
            orders.map(async item => {
                let saleTax = await item.sales_tax.fetch();
                let amount;

                if (saleTax?.id) amount = item.total - (item.total / 100) * saleTax.rate;
                else amount = item.total;

                total += amount;
                count += 1;

                if (filter?.value == 'day') {
                    let date = new Date(item.completed_at);
                    let convertToHour = date.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        hour12: true,
                    });

                    if (graphDates[`${convertToHour}`]) {
                        graphDates[`${convertToHour}`].amount += amount;
                    }
                } else if (graphDates[formatDateAndDays(item.completed_at)]) {
                    graphDates[formatDateAndDays(item.completed_at)].amount += amount;
                }
            }),
        );

        return {graphDates, total, count};
    };

    const getSaleOverview = (label, currentStartDate, currentEndDate, diffInDays, locationId) => {
        try {
            let filter = FILTER_MONTHS.find(x => x.filter == label);

            currentEndDate = currentEndDate || new Date(new Date().setHours(23, 59, 59, 999));
            currentStartDate = currentStartDate || new Date(new Date().setHours(0, 0, 0, 0));

            let previousStartDate = new Date(currentStartDate);
            let previousEndDate = new Date(currentEndDate);

            switch (filter.value) {
                case 'week':
                    previousStartDate.setDate(previousStartDate.getDate() - 7);
                    previousEndDate.setDate(previousEndDate.getDate() - 7);
                    break;
                case 'month':
                    previousStartDate = new Date(previousStartDate.getFullYear(), previousStartDate.getMonth() - 1, 1);
                    previousEndDate = new Date(previousEndDate.getFullYear(), previousEndDate.getMonth(), 0, 23, 59, 59, 999);
                    break;
                case 'year':
                    previousStartDate.setFullYear(previousStartDate.getFullYear() - 1);
                    previousEndDate.setFullYear(previousEndDate.getFullYear() - 1);
                    break;
                case 'day':
                    previousStartDate.setDate(previousStartDate.getDate() - 1);
                    previousEndDate.setDate(previousEndDate.getDate() - 1);
                    break;
                default:
                    previousStartDate = new Date(previousStartDate.setDate(previousStartDate.getDate() - diffInDays));
                    previousEndDate = new Date(previousEndDate.setDate(previousEndDate.getDprevious_gross_amountate() - diffInDays));
                    break;
            }

            let graphDates = {},
                dateToInc = new Date(currentStartDate);

            if (filter.value == 'day') {
                while (dateToInc <= currentEndDate) {
                    graphDates[formatHours(dateToInc)] = {amount: 0};
                    dateToInc.setHours(dateToInc.getHours() + 1);
                }
            } else {
                while (dateToInc <= currentEndDate) {
                    graphDates[formatDateAndDays(dateToInc)] = {amount: 0};
                    dateToInc.setDate(dateToInc.getDate() + 1);
                }
            }

            Promise.all([fetchCompletedOrders(currentStartDate, currentEndDate, locationId), fetchCompletedOrders(previousStartDate, previousEndDate, locationId)])
                .then(async orders => {
                    let obj = {
                        transactions: {percent: 0, amount: 0},
                        avg_net_sale: {percent: 0, amount: 0},
                        gross_sales: {percent: 0, amount: 0},
                        net_sale: {percent: 0, amount: 0},
                        returns: {percent: 0, amount: 0},
                        graph_net_sales: {list: []},
                    };

                    let current_net_sales = await calNetAmount(orders[0], graphDates, filter);
                    let previous_net_sales = await calNetAmount(orders[1]);

                    let previous_gross_sales = orders[1].reduce((total, val) => (total += val.total), 0);
                    let current_gross_sales = orders[0].reduce((total, val) => (total += val.total), 0);

                    obj['transactions'].percent = ((orders[0].length - orders[1].length) / (orders[1].length || 1)) * 100;
                    obj['transactions'].amount = orders[0].length;

                    obj['gross_sales'].percent = ((current_gross_sales - previous_gross_sales) / (previous_gross_sales || 1)) * 100;
                    obj['gross_sales'].amount = current_gross_sales;

                    obj['net_sale'].percent = ((current_net_sales.total - previous_net_sales.total) / (previous_net_sales.total || 1)) * 100;
                    obj['net_sale'].amount = current_net_sales.total;

                    let previous_avg_net_sale = previous_net_sales.total ? previous_net_sales.total / previous_net_sales.count : 0;
                    obj['avg_net_sale'].amount = current_net_sales.total ? current_net_sales.total / current_net_sales.count : 0;

                    obj['avg_net_sale'].percent = ((obj['avg_net_sale'].amount - previous_avg_net_sale) / (previous_avg_net_sale || 1)) * 100;
                    obj['graph_net_sales'].list = current_net_sales.graphDates;

                    setSaleOverview(obj);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        } catch (err) {
            setLoading(false);
        }
    };

    return {
        getSaleOverview,
        saleOverview,
        loading,
    };
};

export default useSaleByDate;
