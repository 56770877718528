import { Model } from "@nozbe/watermelondb";
import { relation } from "@nozbe/watermelondb/decorators";
import { PRODUCT_MODIFIER_SCHEMA } from "../schema";

export default class ProductModifier extends Model {
  static table = PRODUCT_MODIFIER_SCHEMA;

  static associations = {
    product: { type: "belongs_to", key: "product_id" },
    modifier: { type: "belongs_to", key: "modifier_id" },
  };

  @relation("product", "product_id") product;
  @relation("modifier", "modifier_id") modifier;

  //   @writer async setItemModifier(details) {
  //     return await this.update((itemMod) => {
  //       itemMod.role.set(details.role);
  //     });
  //   }
}
