import React from 'react'
import UploadMarginBreaKdown from './UploadMarginBreaKdown'
import DownloadMarginReport from './DownloadMarginReport'

const MarginBreakDownComponent = () => {

  return (
    <div>
      <UploadMarginBreaKdown/>
      <DownloadMarginReport/>
    </div>
  )
}

export default MarginBreakDownComponent
