import { useLanguageContext, useThemeContext } from "../../../../../context";
import { Dropdown, MessageModal } from "../../../../common";
import TableBody from "../../../../common/TableContainer/TableBody";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const List = ({ floorPlan }) => {
  let navigate = useNavigate();

  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const [archiveModal, setArchiveModal] = useState(false);

  const toggleArchiveModal = () => setArchiveModal((x) => !x);

  const onChange = () => {
    // onChange
  };
  const handleAction = async (type) => {
    switch (type) {
      case "delete":
        let deleted = await floorPlan.archiveFloorPlan();
        break;
      case "details":
        navigateToDetail();
        break;
    }
  };

  const tableAction = [
    {
      title: I18n.edit,
      action: () => handleAction("edit"),
    },
    {
      title: I18n[floorPlan.archive ? "unarchive" : "archive"],
      action: toggleArchiveModal,
    },
  ];

  const navigateToDetail = () => {
    // navigate(`/customers/${customer.id}`);
    console.log("navigate");
  };

  const handleArchive = async () => {
    //write archive function
    // await floorPlan.archiveOrder();
  };

  return (
    <>
      <TableBody
        className={`threeColumn cursorPointer`}
        style={{ borderColor: theme.inputBorder }}
      >
        <div className="BoxWidth justifyStart">
          <p
            className="fontSize14 OneLineTruncate fontWeight500"
            style={{ color: theme.white }}
          >
            {floorPlan.name}
          </p>
        </div>

        <div className="BoxWidth justifyCenter">
          <p
            className="fontSize14 OneLineTruncate"
            style={{ color: theme.white }}
          >
            {floorPlan.type}
          </p>
        </div>

        <div className="BoxWidth justifyCenter ">
          <div className="listActionBox">
            <Dropdown
              dropDown={{
                name: "threeDotIcon",
                fill: theme.barclaysBlue,
                width: 18,
                height: 4,
                viewBox: "0 0 18 4",
                option: tableAction,
              }}
            />
          </div>
        </div>
      </TableBody>

      <MessageModal
        setToggle={setArchiveModal}
        toggle={archiveModal}
        title={I18n.archive_customer}
        description={
          I18n[
            floorPlan.archive
              ? "are_you_sure_you_want_to_unarchive"
              : "are_you_sure_you_want_to_archive"
          ] +
          floorPlan.name +
          "?"
        }
        secondaryCta={{
          title: I18n.no,
          action: toggleArchiveModal,
        }}
        primaryCta={{
          title: I18n.yes,
          action: () => {
            handleArchive(floorPlan);
          },
          isDelete: true,
        }}
      />
    </>
  );
};

export default List;
