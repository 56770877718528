import {useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';
import Icon from '../../../assets/icons';
import React from 'react';

const TotalTiles = ({withOutIcon, percent, amount, item, key}) => {
    const {iconBGcolor, iconName, viewBox, width, height, subTitle, heading, rs} = item || {};
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <>
            <div className={`${withOutIcon ? 'titlecontainer' : 'totaltitlecontainer'} ${subTitle ? 'titlecontainerSub' : ''} `} key={key}>
                <div className="tileContTop">
                    {withOutIcon ? null : (
                        <p className="iconBox marRight10 marBot0" style={{backgroundColor: iconBGcolor || theme.brightGreen,}}>
                            <Icon name={iconName || 'customersIcon'} viewBox={viewBox || '0 0 18 18'} height={height || '18'} width={width || '18'} fill={theme.white} />
                        </p>
                    )}
                    <p
                        className="marBot0 fontSize14 fontWeight400"
                        style={{
                            color: withOutIcon ? theme.newBorderColorTwo : theme.white,
                        }}>
                        {I18n[heading]}
                    </p>
                </div>

                <div className={`tileContBottom ${subTitle ? 'Bottomsubtitle' : ''}`}>
                    <div className="tileContBottomLeft">
                        <p className="fontSize20 fontWeight500 marBot0" style={{color: theme.white}}>
                            {rs && <span className="marRight5">Rs.</span>}
                            <span>{formatNum(amount)}</span>
                        </p>
                        {subTitle ? (
                            <p className="fontSize10 fontWeight500 marBot0 tileContBottomsubtitle" style={{color: theme.white}}>
                                <span>{subTitle}</span>
                            </p>
                        ) : null}
                    </div>

                    {percent ? (
                        <div className={`amountIdeal ${percent > 0 ? 'amountIncrease' : 'amountDecrease'}`}>
                            <Icon
                                name={percent > 0 ? 'upNewIcon' : 'downNewIcon'}
                                fill={percent > 0 ? theme.brightGreen : theme.red}
                                viewBox={'0 0 7 8'}
                                height={'8'}
                                width={'8'}
                            />

                            <p className="fontSize14 fontWeight400 marBot0 OneLineTruncate marLeft5" style={{color: percent > 0 ? theme.brightGreen : theme.red}}>
                                {`${formatNum(Math.abs(percent))} %`}
                            </p>
                        </div>
                    ) : (
                        <div className="amountNonIdeal">
                            <p className="fontSize14  textCenter fontWeight400 marBot0" style={{color: theme.lightGrayTwo}}>
                                N/A
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TotalTiles;
