import {observeAllCategories, observeAllProducts} from '../../../../pos-core/database/helpers';
import {useLanguageContext, useThemeContext} from '../../../../context';
import RangePicker from '../../../common/RangePicker';
import withObservables from '@nozbe/with-observables';
import {useCatItemByDate} from '../../../../hooks';
import {formatNum} from '../../../../constants';
import BarGraph from '../../../common/BarGraph';
import Icon from '../../../../assets/icons';
import React, {useEffect} from 'react';
import nonIdealImage from '../../../../assets/images/barChartNonIdeal.png';
import {Button} from '../../../common';

const CatItemSummary = ({data, type, action, selectedLocation}) => {
    const {getCatItemOverview, catItemOverview, loading} = useCatItemByDate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    useEffect(() => {
        if (data.length) onFilterDate();
    }, [data, selectedLocation]);

    const onFilterDate = (label = 'Today', startDate = '', endDate = '') => getCatItemOverview(startDate, endDate, type, selectedLocation?.id);

    return (
        <>
            <div className="summaryConatiner">
                <div className="summaryBoxHeading">
                    <p className="fontSize24 fontWeight700 marBot0" style={{color: theme.white}}>
                        {type == 'category' ? I18n.top_10_categories : I18n.top_10_items}
                    </p>

                    <div
                        className="iconBox"
                        style={{
                            backgroundColor: theme.lightGrayThree,
                        }}>
                        <Icon name={'navigateArrowIcon'} viewBox={'0 0 18 18'} fill={theme.white} height={'18'} width={'18'} />
                    </div>
                </div>

                {data.length ? (
                    <RangePicker onFilterDate={onFilterDate} vs={false} />
                ) : (
                    <div className="summaryDateBox">
                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.newBorderColorTwo}}>
                            You’ll see a sales comparison by category here <br />
                            one you setup categories.
                        </p>
                    </div>
                )}

                <div className="barGraphConatiner">
                    {data.length ? (
                        loading ? (
                            <span>Loading ...</span>
                        ) : (
                            <BarGraph data={catItemOverview} />
                        )
                    ) : (
                        <div className="barGraphImage">
                            <img src={nonIdealImage} alt="" title="" />
                        </div>
                    )}
                </div>

                {data.length ? (
                    loading ? (
                        <div className="lowestText">
                            <span>Loading ...</span>
                        </div>
                    ) : (
                        <div className="lowestText">
                            <p className="fontSize14 marBot0 fontWeight400 " style={{color: theme.darkGrayTwo}}>
                                Lowest
                            </p>
                            <p className="fontSize14 marBot0 fontWeight400 " style={{color: theme.darkGrayTwo}}>
                                Highest
                            </p>
                        </div>
                    )
                ) : (
                    <div className="lowestTextNonIdeal">
                        <p className="fontSize14 marBot0 fontWeight400 textCenter" style={{color: theme.darkGrayTwo}}>
                            You’ve not added any category yet
                        </p>
                    </div>
                )}

                {data.length ? (
                    loading ? (
                        <span>Loading ...</span>
                    ) : (
                        <>
                            <div className="topItemInnerMain">
                                {catItemOverview.nonIdeal ? (
                                    <div className="topItemInnerRow">
                                        <p className="marBot0 marTop10 fontSize14 fontWeight400">
                                            <span style={{color: theme.newBorderColorTwo}}>No sales in this time period</span>
                                        </p>
                                    </div>
                                ) : (
                                    catItemOverview.data.reverse().map((item, index) => {
                                        return index < 2 ? (
                                            <div className="topItemInnerRow">
                                                <p className="marBot0 fontSize16 fontWeight400 white">
                                                    <span className="itmeFirstPara" style={{background: item.color}}></span>
                                                    <span>{item.name}</span>
                                                </p>
                                                <p className="marBot0 fontSize16 fontWeight400 white">Rs. {formatNum(item.price)}</p>
                                            </div>
                                        ) : null;
                                    })
                                )}
                            </div>
                        </>
                    )
                ) : (
                    <div className="chartNonIdealButton">
                        <Button
                            className={`chartButton marTop20 fontWeight500 fontSize18 cursorPointer`}
                            background={theme.brightGreen}
                            txtColor={theme.white}
                            title={action.title}
                            handleClick={action.click}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const enhance = withObservables(['type'], type => ({
    data: type == 'category' ? observeAllCategories() : observeAllProducts(),
}));

export default enhance(CatItemSummary);
