import { Model } from "@nozbe/watermelondb";
import { PURCHASE_TAX_SCHEMA } from "../schema";
import {
  field,
  text,
  relation,
  children,
  date,
  writer
} from "@nozbe/watermelondb/decorators";

export default class PurchaseTax extends Model {
  static table = PURCHASE_TAX_SCHEMA;

  static associations = {
    business: { type: "belongs_to", key: "business_id" },
    product: { type: "has_many", foreignKey: "purchase_tax_id" },
    purchase_order_line: { type: 'has_many', foreignKey: 'purchase_order_id' },

  };

  @text("name") name;
  @field("rate") rate;
  @field("archive") archive;
  @date('created_at') created_at;

  @relation("business", "business_id") business;
  @children("product") product;
  @children("purchase_order_line") purchase_order_line;


  @writer async archiveTax(status = true) {
    return await this.update((tax) => {
      tax.archive = status
    })
  }

  @writer async updateDetails(details) {
    return await this.update((tax) => {
      tax.name = details.name
      tax.rate = details.rate
    });
  }
}
