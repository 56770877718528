import Icon from '../../../../assets/icons';
import TextBox from '../../TextBox';

const InputField = ({prefix, suffix, onChange, placeholder, type, onlyNumbers = false, inputRef, label, value, disabled, onFocus, onBlur, onKeyDown, maxLength}) => {
    const handleChange = (event, key) => {
        let value = key || event.target.value;

        onChange(label, type == 'numeric' || onlyNumbers ? value.replace(/[^0-9]/g, '') : value);
    };
    return (
        <>
            <div className="inputFormGroupInner">
                {prefix ? (
                    <span className="inputPrefixIcon">
                        <Icon name={prefix?.name} fill={prefix?.fill} width={prefix?.width || 18} height={prefix?.height || 18} viewBox={prefix?.viewBox || '0 0 18 18'} />
                    </span>
                ) : null}

                <input
                    className="customInputFeild"
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                    label={label}
                    disabled={disabled}
                    ref={inputRef}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                />

                {suffix ? (
                    <span className="sufixIcon">
                        <Icon name={suffix?.name} fill={suffix?.fill} width={18} height={18} viewBox={'0 0 18 18'} />
                    </span>
                ) : null}
            </div>
        </>
    );
};

export default InputField;
