import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import withObservables from "@nozbe/with-observables";
import {
  Header,
  HoldOrderModal,
  NonIdealScreen,
  SearchBox,
  ToastBox,
} from "../../../../../common";
import {
  useAuthContext,
  useLanguageContext,
  useThemeContext,
} from "../../../../../../context";
import TableHeader from "../../../../../common/TableContainer/TableHeader";
import {
  DEVICE_HEIGHT,
  cockpitOrders,
  formatSingleDigitNum,
} from "../../../../../../constants";
import List from "./List";
import {
  observeCockpitActiveOrders,
  observeHeldOrderCount,
} from "../../../../../../pos-core/database/helpers";
import { useNavigate } from "react-router-dom";
import SelectFloorPlanModal from "../../../../../common/SelectFloorPlanModal";
import { observeActiveFloorPlan } from "../../../../../../pos-core/database/helpers/floor_plan";

const Cockpit = ({
  floorData,
  activeOrder,
  orders,
  tableDetails,
  floorPlans,
  setTabComponent,
  setTabVal,
}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();
  const { handleCreateOrder, business } = useAuthContext();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [holdOrderModal, setHoldOrderModal] = useState(false);
  const [heldOrderCount, setHeldOrderCount] = useState();
  const [selectFloorPlanModal, setSelectFloorPlanModal] = useState(false);

  const [showMessage, setShowMessage] = useState({
    visible: false,
    message: "",
  });

  let navigate = useNavigate();

  const onChange = (label, val) => {};
  const handleCreateNewOrder = async () => {
    if (floorData.id === "cockpit") {
      setSelectFloorPlanModal(true);
      return;
    }

    const payload = {
      floor_plan: floorData,
      dine_in_table: tableDetails?.[0],
      started_at: new Date().getTime(),
      type: floorData.type,
      no_of_guests: "0",
    };
    if (!activeOrder) {
      const createdOrder = await handleCreateOrder();
      await createdOrder.updateFloorAndTable(payload);
    } else {
      await activeOrder.updateFloorAndTable(payload);
    }
    navigate("/restaurant-pos");
  };

  const handleHoldOder = () => {
    setHoldOrderModal((prev) => !prev);
  };

  const handleHeldOrderCount = async () => {
    let count = await observeHeldOrderCount();
    setHeldOrderCount(count);
  };

  const handleFilter = (val) => {
    setFilter(val);
  };

  const filterOption = [
    {
      title: I18n.all_customers,
      action: () => handleFilter(I18n.all_customers),
      checked: filter == I18n.all_customers || filter === "" ? true : false,
    },
    {
      title: I18n.archived,
      action: () => handleFilter(I18n.archived),
      checked: filter == I18n.archived ? true : false,
    },
  ];

  useEffect(() => {
    if (showMessage.visible) {
      const timeoutId = setTimeout(() => {
        setShowMessage({
          visible: false,
          message: "",
        });
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [showMessage]);

  return (
    <>
      <div className="marTop20">
        <Header
          type="posCockpit"
          search={{
            label: I18n.search_item_by_name,
            onChange: onChange,
            value: search,
          }}
          centerCta={{
            title: I18n.saved_order,
            action: handleHoldOder,
            value: heldOrderCount ? formatSingleDigitNum(heldOrderCount) : "",
            backgroundColor: heldOrderCount
              ? theme.barclaysBlue
              : theme.topBarBG,
            textColor: heldOrderCount ? theme.white : theme.barclaysBlue,
          }}
          rightCta={{ title: I18n.new_order, action: handleCreateNewOrder }}
          leftCta={{
            title: filter,
            name: "downIcon",
            fill: theme.white,
            width: "12",
            height: "9",
            viewBox: "0 0 8 5",
            option: filterOption,
          }}
        />
        <div
          className="restaurantOrderListContainer"
          style={{ height: DEVICE_HEIGHT - 160 }}
        >
          <Container fluid>
            <Row>
              <Col style={{ padding: "0px" }}>
                <TableHeader className={`tenColumn borderTop`}>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.order_number}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.time}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.type}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.table}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.customer}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.waiter}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.rider}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.status}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter">
                    <p
                      className="fontSize16 fontWeight600 OneLineTruncate"
                      style={{ color: theme.white }}
                    >
                      {I18n.amount}
                    </p>
                  </div>
                  <div className="BoxWidth justifyCenter" />
                </TableHeader>

                {orders?.length > 0 ? (
                  <div
                    className="tableListMainBox"
                    style={{ height: DEVICE_HEIGHT - 280 }}
                  >
                    {orders?.map((val, index) => (
                      <List order={val} key={index} />
                    ))}
                  </div>
                ) : (
                  <NonIdealScreen
                    paragraph={I18n.nothing_found_create_order}
                    name="noOrderFound"
                    isMultiStep={false}
                    viewBox="0 0 100 100"
                  />
                )}
              </Col>
              <ToastBox
                className="addSuccessfullyToast"
                ToastVisiable={showMessage.visible}
                bodyPara={showMessage.message}
                setShowMessage={setShowMessage}
                showIcon={true}
              />
            </Row>
          </Container>
        </div>
      </div>
      <HoldOrderModal
        setToggle={setHoldOrderModal}
        toggle={holdOrderModal}
        setShowMessage={setShowMessage}
        handlehandleSelect={handleHoldOder}
        handleHeldOrderCount={handleHeldOrderCount}
        isRestaurantPos={true}
      />
      <SelectFloorPlanModal
        toggle={selectFloorPlanModal}
        setToggle={setSelectFloorPlanModal}
        floorPlans={floorPlans}
        setTabComponent={setTabComponent}
        setTabVal={setTabVal}
      />
    </>
  );
};

const enhance = withObservables(["floorData"], ({ floorData }) =>
  floorData.id === "cockpit"
    ? {
        orders: observeCockpitActiveOrders(),
        floorPlans: observeActiveFloorPlan(),
      }
    : {
        orders: floorData.getActiveFloorOrders.observe(),
        tableDetails: floorData.getTables.observe(),
      }
);
export default enhance(Cockpit);
