import withObservables from '@nozbe/with-observables';
import {formatNum} from '../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../context';

const ProductItem = ({item, sales_tax, onPress, prodBusiness, product_template}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleSelect = () => {
        let paramsForItem = {
            product: item,
            name: item.name,
            selling_price: item.selling_price,
            cost_price: item.cost_price,
            is_open: false,
            quantity: 1,
            tax_name: sales_tax?.name,
            tax_rate: sales_tax?.rate,
            is_tax_inclusive: sales_tax?.is_inclusive,
            unit: product_template.unit,
            rate: 1,
        };

        onPress(paramsForItem, 'increment');
    };

    return (
        <div
            className="posItemsBox cursorPointer itemtabsBox"
            onClick={handleSelect}
            style={{
                background: theme.white,
                borderColor: theme.lightGrayTwo,
            }}>
            {prodBusiness[0]?.quantity ? (
                <p
                    className="itemQuantity"
                    style={{
                        color: theme.white,
                        background: prodBusiness[0]?.quantity < 0 ? theme.brightOrange : theme.barclaysBlue,
                    }}>
                    {formatNum(prodBusiness[0]?.quantity)}
                </p>
            ) : null}
            {item?.selling_price ? (
                <p
                    className="itemPrice"
                    style={{
                        color: theme.blackBg,
                    }}>
                    Rs. {formatNum(item.selling_price)}
                </p>
            ) : null}
            <p
                className="fontSize16 marBot0 twoLineTruncate"
                style={{
                    color: theme.seperatorBg,
                }}>
                {item.name}
            </p>
        </div>
    );
};

const enhance = withObservables(['item'], ({item}) => ({
    item: item.observe(),
    sales_tax: item.sales_tax.observe(),
    product_template: item.product_template.observe(),
    prodBusiness: item?.getProdBusiness(),
}));

export default enhance(ProductItem);
