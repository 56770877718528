import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import {
  Button,
  CheckBox,
  CustomContainer,
  Header,
  TextField,
} from "../../../../common";
import { useLanguageContext, useThemeContext } from "../../../../../context";

const SendPurchaseorder = ({}) => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const [notememo, setNoteMemo] = useState("");
  const [toemail, setToemail] = useState("");

  const onChange = (label, val) => {
    if (label == I18n.notes_Memo) setNoteMemo(val);
    else if (label == I18n.to) setToemail(val);
  };

  const handleDone = () => {};

  const handlePDF = () => {};
  const handleSend = () => {};
  const handleCSV = () => {};
  return (
    <>
      <Header
        buttonTitle={I18n.done}
        handleSave={handleDone}
        title={I18n.send_purchase_order}
      />
      <CustomContainer className="addScreenMainBox">
        <Row className="justify-content-md-center">
          <Col md={12}>
            <div className="loginFormBox">
              <div className="signupTextBox">
                <h3
                  className="fontSize20 fontWeight500 marBot35 marTop0"
                  style={{ color: theme.text }}
                >
                  {I18n.send_purchase_order}
                </h3>
                <h2
                  className="optionPara fontSize16 fontWeight500 marBot15"
                  style={{ color: theme.text }}
                >
                  {I18n.would_you_like_to_email_or_Save_a_copy_of_your_order}
                </h2>
              </div>
              <div className="itemFields">
                <div className="intventoryFormGridTwo poSendInput">
                  <div className="priceSectionInputLeft">
                    <TextField
                      label={I18n.to}
                      placeholder={I18n.to}
                      onChange={onChange}
                      value={toemail}
                    />
                  </div>

                  <div className="priceSectionInputRight">
                    <TextField
                      label={I18n.notes_Memo}
                      placeholder={I18n.notes_Memo}
                      onChange={onChange}
                      value={notememo}
                    />
                  </div>
                </div>
                <div className="itemScreenCheckbox">
                  <CheckBox
                    checkboxLabel={
                      <p
                        className="marBot0 fontSize14"
                        style={{ color: theme.text }}
                      >
                        {`${I18n.send_me_a_copy} (zubair@toko.pk)`}
                      </p>
                    }
                    onChange={onChange}
                  />
                </div>
                <Button
                  disabled={false}
                  className={`normalWidth fontWeight600 fontSize16 cursorPointer marTop0 marRight25`}
                  title={I18n.send}
                  handleClick={handleSend}
                />

                <p
                  style={{ background: theme.topBarBG }}
                  className="itemSeperator"
                ></p>

                <div className="rowConatainer marTop30 ">
                  <p
                    className="fontSize16 fontWeight500 marBot0"
                    style={{ color: theme.text }}
                  >
                    {I18n.save_your_purchase_order_as}
                  </p>

                  <div className="rowConatainer">
                    <Button
                      disabled={false}
                      className={`fontWeight600  normalWidth fontSize16 cursorPointer  marLeft30`}
                      backgroundColor="transparent"
                      borderColor={theme.brightGreen}
                      title={I18n.pdf}
                      handleClick={handlePDF}
                    />

                    <Button
                      disabled={false}
                      className={`fontWeight600 normalWidth fontSize16 cursorPointer  marLeft15`}
                      backgroundColor="transparent"
                      borderColor={theme.brightGreen}
                      title={I18n.csv}
                      handleClick={handleCSV}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CustomContainer>
    </>
  );
};

export default SendPurchaseorder;
