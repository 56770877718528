import React from "react";
import Icon from "../../../assets/icons";
import {
  DEVICE_HEIGHT,
  formatStringAndCapitalize,
  formatTime,
  getColorBasedOnOrderType,
} from "../../../constants";
import { useAuthContext, useLanguageContext } from "../../../context";
import Button from "../Button";
import useTimer from "../../../hooks/useTimer";
import { updateKitchenOrder } from "../../../api";

const OrderCard = ({ order, setShowMessage }) => {
  const { I18n } = useLanguageContext();
  const { waiter_name, order_id, status, order_type, created_at, order_line } =
    order;

  const { timerColor, formattedTime } = useTimer(order);
  const { deviceConfiguration } = useAuthContext();
  const isHalfLength =
    deviceConfiguration?.configuration?.card_size === "standard";

  const rowClicked = async (e, rowItem) => {
    try {
      if (rowItem.status === "cancel") return;
      const status =
        rowItem?.status === "completed" ? "preparing" : "completed";
      const orderPayload = {
        secret_key: deviceConfiguration?.secret_key,
        order_id: order_id,
        line_ids: [rowItem.uuid],
        status: status,
      };

      await updateKitchenOrder(orderPayload);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClick = async (e) => {
    try {
      let orderStatus = "";
      if (e.target.innerText === "Mark all as ready") {
        orderStatus = "ready";
      }
      if (e.target.innerText === "Mark as complete") {
        orderStatus = "served";
      }

      if (e.target.innerText === "Done") {
        orderStatus = "cancelled";
      }
      const orderPayload = {
        secret_key: deviceConfiguration?.secret_key,
        order_id: order_id,
        line_ids: [],
        status: orderStatus,
      };

      await updateKitchenOrder(orderPayload);
      setShowMessage({
        visible: true,
        message: `Order #${order_id} ${orderStatus}`,
      });

      console.log("event", e);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      className="orderCardWrapper"
      style={{ height: isHalfLength ? "460px" : DEVICE_HEIGHT - 210 }}
    >
      <div className={`orderCardHeader ${timerColor?.primaryColor}`}>
        <div className="flex justifySpacebetween">
          <p className="fontSize16 fontWeight600 marBot0">{waiter_name}</p>
          {order_type === "dine_in" && (
            <p
              className={"fontSize14 fontWeight500 marBot0 separatorGray"}
            >{`Table # ${order.table}`}</p>
          )}
        </div>
        <div className="flex justifySpacebetween">
          <p className="fontSize14 fontWeight400 marTop10 darkGrayTwo">
            {formatTime(created_at, true)}
          </p>
          <div
            className={`durationPill marBot20 ${timerColor?.secondaryColor}`}
          >
            <p className="fontSize14 fontWeight400 marBot0 white">
              {timerColor?.stopTimer
                ? order.status === "recall"
                  ? "Recalled"
                  : order.status === "cancel"
                  ? "Cancelled"
                  : "Late"
                : formattedTime}
            </p>
          </div>
        </div>
      </div>
      <div className="orderDelivery">
        <div className="flex gap5">
          <Icon
            name={
              order_type === "delivery"
                ? "deliveryIcon"
                : order_type === "dine_in"
                ? "dineinIcon"
                : "takeawayIcon"
            }
            viewBox="0 0 16 17"
          />
          <p
            className={`fontSize16 fontWeight600 marBot0 ${
              getColorBasedOnOrderType(order_type)?.color
            }`}
          >
            {formatStringAndCapitalize(order_type)}
          </p>
        </div>
        <div className="orderId">
          <p className="fontSize14 fontWeight400 marBot0 black OneLineTruncate">{`#${order_id}`}</p>
        </div>
      </div>
      <div
        className="orderItemsWrapper"
        style={{ height: isHalfLength ? "240px" : DEVICE_HEIGHT - 430 }}
      >
        {order_line?.map((item) => (
          <div className="cursorPointer " onClick={(e) => rowClicked(e, item)}>
            <div className="flex justifySpacebetween marTop10">
              <div>
                <p
                  className={`fontSize14 fontWeight600 marBot0 ${
                    item.status === "completed"
                      ? "lightGrayTwo"
                      : "separatorGray"
                  }`}
                >
                  {item.name}
                </p>
              </div>
              <div className="flex gap10 verticalCenter">
                {item.status === "completed" ? (
                  <Icon name="tickFilled" viewBox="0 0 25 25" />
                ) : item.status === "cancel" ? (
                  <Icon name="crossFilled" viewBox="0 0 17 17" />
                ) : null}

                <p
                  className={`fontSize14 fontWeight600 marBot0 ${
                    item.status === "completed"
                      ? "lightGrayTwo"
                      : "separatorGray"
                  } `}
                >
                  {`x${item.qty}`}
                </p>
              </div>
            </div>
            {/* {item?.addon?.map((info) => (
              <p
                className={`fontSize14 fontWeight400 marTop5 marBot0 ${
                  item.status === "completed" ? "lightGrayTwo" : "darkGrayTwo"
                } `}
              >
                {info.name}
              </p>
            ))} */}
            <p
              className={`fontSize12 fontWeight400 marTop5 marBot0 ${
                item.status === "completed" ? "lightGrayTwo" : "red"
              } `}
            >
              {item.note !== "" ? `Note: ${item.note}` : ""}
            </p>
          </div>
        ))}
      </div>
      <div className="cardFooter">
        <Button
          className={`${
            status === "cancel" && "disableCta"
          } width100 fontWeight600 fontSize16 cursorPointer`}
          title={
            status === "preparing"
              ? I18n.mark_all_as_ready
              : status === "ready" || status === "recall"
              ? I18n.mark_as_complete
              : status === "cancel"
              ? I18n.done
              : I18n.mark_all_as_ready
          }
          disabled={false}
          //   saveLoad={isLoading ? true : false}
          handleClick={(e) => handleClick(e)}
        />
      </div>
    </div>
  );
};

export default OrderCard;
