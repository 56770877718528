import {useLanguageContext, useThemeContext} from '../../../../context';
import usePayTypesByDate from '../../../../hooks/usePayTypesByDate';
import PercentageBar from '../../../common/PercentageBar';
import RangePicker from '../../../common/RangePicker';
import {formatNum} from '../../../../constants';
import Icon from '../../../../assets/icons';
import React, {useEffect} from 'react';

const PaymentSummary = ({selectedLocation}) => {
    const {getPayTypeOverview, payOverview, loading} = usePayTypesByDate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    useEffect(() => {
        onFilterDate();
    }, [selectedLocation]);

    const paymentDetails = [
        {
            color: theme.brightGreen,
            icon: 'cash2Icon',
            title: I18n.cash,
            label: 'cash',
        },
        {
            color: theme.lightGreen,
            icon: 'cardIcon',
            title: I18n.card,
            label: 'card',
        },
        {
            color: theme.extralightGreen,
            icon: 'walletIcon',
            title: I18n.wallet,
            label: 'wallet',
        },
        {
            title: I18n.credit,
            color: theme.white,
            icon: 'cashIcon',
            label: 'credit',
        },
    ];

    const onFilterDate = (label = 'Today', startDate = '', endDate = '') => getPayTypeOverview(startDate, endDate, selectedLocation.id);

    return (
        <>
            <div className="summaryConatiner">
                <div className="summaryBoxHeading">
                    <p className="fontSize24 fontWeight700 marBot0" style={{color: theme.white}}>
                        {I18n.payment_types}
                    </p>

                    <div
                        className="iconBox"
                        style={{
                            backgroundColor: theme.lightGrayThree,
                        }}>
                        <Icon name={'navigateArrowIcon'} viewBox={'0 0 18 18'} fill={theme.white} height={'18'} width={'18'} />
                    </div>
                </div>
                <RangePicker onFilterDate={onFilterDate} vs={false} />

                {loading ? (
                    <span>Loading ...</span>
                ) : (
                    <>
                        <PercentageBar
                            data={{
                                total: payOverview['order_total'],
                                cash: payOverview['cash'],
                                card: payOverview['card'],
                                wallet: payOverview['wallet'],
                                credit: payOverview['credit'],
                            }}
                        />
                        <div className="paymentBoxRowMain">
                            {paymentDetails.map((item, index) => {
                                const {title, label, color, icon} = item || {};
                                return (
                                    <div key={index} className="paymentBoxRow">
                                        <p className="marBot0">
                                            <span>
                                                <Icon viewBox={'0 0 32 22'} height={'24'} width={'34'} fill={color} name={icon} />
                                            </span>
                                            <span className="fontSize16 fontWeight400 marLeft10" style={{color: theme.white}}>
                                                {title}
                                            </span>
                                        </p>
                                        <p className=" fontSize16 fontWeight400 marBot0" style={{color: theme.white}}>
                                            {`Rs. ${formatNum(payOverview[label])}`}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default PaymentSummary;
